import React, {useEffect, useState} from 'react';
import './LinkPhoneNumberComponent.css'
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth } from '../../contexts/AuthContext'
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneAuthProvider,
	linkWithCredential, unlink} from "firebase/auth";
import { auth } from "../../firebase";
import IconButton from '@mui/material/IconButton';
import { RecaptchaVerifier } from "firebase/auth";

export default function LinkPhoneNumberComponent(props) {
	
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")
	const { setCurrentUserForContext } = useAuth()

	const [verificationCode, setVerificationCode] = useState('');
	const [confirmationResult, setConfirmationResult] = useState(null);
	const [userUnlinked, setUserUnlinked] = useState(false);
	const [openUnlinkPhoneModal, setOpenUnlinkPhoneModal] = useState(false);

	useEffect(() => {
		window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-button', {
			'size': 'invisible',
			'callback': (response) => {
			}
		}, auth);
	
	}, [])
	const handlePhoneNumberChange = (phone) => {
		setPhoneNumber(phone);
	};
	const handleVerificationCodeChange = (event) => {
		setVerificationCode(event.target.value);
	};
	async function handleSendVerificationCode(event) {
		event.preventDefault()
		setLoading(true)
		if (phoneNumber.length >= 0) {
			// send phone verification code
			const appVerifier = window.recaptchaVerifier;
			const phoneAuthProvider = new PhoneAuthProvider(auth);
		
			phoneAuthProvider.verifyPhoneNumber(phoneNumber, appVerifier)
			.then((verificationId) => {
				setConfirmationResult({ verificationId });
				setLoading(false)
			})
			.catch((error) => {
				setError(t("linkPhoneNumberComponent.sendVerificationCodeError"))
				setLoading(false)
			});
			
		} else {
			setError(t("linkPhoneNumberComponent.phoneNumberCantBeNullError"))
			setLoading(false)
		}
	}
	async function handleVerifyPhone(event) {
		event.preventDefault()
		setLoading(true)
		
		// verify user phone..
		if (confirmationResult) {
			const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, verificationCode);
			linkWithCredential(auth.currentUser, credential)
			.then((result) => {
				setCurrentUserForContext(result.user)
				props.handlePhoneHasBeenLinked()
				setLoading(false)
			})
			.catch((error) => {
				setError(t("linkPhoneNumberComponent.verifyUserPhoneError"))
				setLoading(false)
			});
		}
	}
	async function unlinkUserPhone(event) {
		event.preventDefault()
		setLoading(true)
		if (auth.currentUser) {
			// unlink phone to user   
			unlink(auth.currentUser, PhoneAuthProvider.PROVIDER_ID)
			.then((user) => {
				setUserUnlinked(true)
				props.handlePhoneHasBeenUnlinked()
			})
			.catch((error) => {
			});
			
			setLoading(false)
		}
		
	}

	const handleOpenUnlinkPhoneModal = () => {
		setOpenUnlinkPhoneModal(true);
	  };
	
	  const handleCloseUnlinkPhoneModal = () => {
		setOpenUnlinkPhoneModal(false);
	  };

	return (
		<>
			<div id="recaptcha-button"></div>
			<>
				{(props.profileConfigurationComponent === true && auth.currentUser.phoneNumber) || userUnlinked ?
					<>

						<Dialog
							open={openUnlinkPhoneModal}
							onClose={handleCloseUnlinkPhoneModal}
						>
							<DialogTitle>
								<Typography variant='h2'>
									{t('linkPhoneNumberComponent.unlinkPhoneTitle')}
								</Typography>
							</DialogTitle>
							<DialogContent>							
								<Typography variant='body1'>
									{t('linkPhoneNumberComponent.unlinkPhoneDescription')}
								</Typography>
							</DialogContent>
							<DialogActions>
								<Button autoFocus onClick={handleCloseUnlinkPhoneModal}>
									{t('cancel')}
								</Button>
								<Button onClick={unlinkUserPhone} autoFocus>
									{t('accept')}
								</Button>
							</DialogActions>
						</Dialog>
						<IconButton  style={{marginRight: 6}} color="primary" component="label" onClick={handleOpenUnlinkPhoneModal}>
							<CloseIcon fontSize='small'/>
						</IconButton>
					</>
					: null
				}
			</>

			
			<Dialog
				open={props.reportModalOpen}
				onClose={props.reportModalClose}
			>
				<DialogTitle>
					<Typography variant='h2'>
						{!confirmationResult ? 
							t('linkPhoneNumberComponent.sendVerificationCodeTitle')
							:
							t('linkPhoneNumberComponent.verifyPhoneTitle')
						}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Typography variant='body1'>
						{!confirmationResult ? 
							t('linkPhoneNumberComponent.sendVerificationCodeDescription')
							:
							t('linkPhoneNumberComponent.verifyPhoneDescription')
						}
					</Typography>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="center"
						sx={{padding: "12px"}}
						spacing={2}
					>						
						{!confirmationResult ? 
							<Box component="form" onSubmit={handleSendVerificationCode} noValidate>	
								{error && <Alert sx={{height: "auto!important", marginTop: "8px"}} severity='error'>{error}</Alert>}
								<PhoneInput
									defaultCountry="ar"
									value={phoneNumber}
									onChange={(phone) => handlePhoneNumberChange(phone)}
								/>
								<Stack
									direction="row"
									justifyContent="center"
									alignItems="center"
									sx={{padding: "12px"}}
									spacing={2}
								>	
									<Button
										sx={{ mt: "15px!important", mb: 2, float: "right", paddingRight: "12px!important", paddingLeft: "12px!important" }}
										disabled={loading}
										variant="contained"
										color="error"
										onClick={props.reportModalClose}
										>
											{t('cancel')}
									</Button>
									<Button
										type="submit"
										variant="contained"
										sx={{ mt: "15px!important", mb: 2, float: "right", paddingRight: "12px!important", paddingLeft: "12px!important" }}
										disabled={loading}
										>
											{t('linkPhoneNumberComponent.submit')}
									</Button>
								</Stack>
							</Box>
						:
							<Box component="form" onSubmit={handleVerifyPhone} noValidate>	
								{error && <Alert sx={{height: "auto!important", marginTop: "8px"}} severity='error'>{error}</Alert>}
								<TextField
									className='MuiBodyTextField'
									margin="normal"
									required
									fullWidth
									id="outlined-required"
									label={<>{t('linkPhoneNumberComponent.verificationCode')}</>}
									name="verificationCode"
									value={verificationCode}
									onChange={handleVerificationCodeChange}
									spellCheck={false}
									/>
								<Stack
									direction="row"
									justifyContent="center"
									alignItems="center"
									sx={{padding: "12px"}}
									spacing={2}
								>	
									<Button
										sx={{ mt: "15px!important", mb: 2, float: "right", paddingRight: "12px!important", paddingLeft: "12px!important" }}
										disabled={loading}
										variant="contained"
										color="error"
										onClick={props.reportModalClose}
										>
											{t('cancel')}
									</Button>
									<Button
										type="submit"
										variant="contained"
										sx={{ mt: "15px!important", mb: 2, float: "right", paddingRight: "12px!important", paddingLeft: "12px!important" }}
										disabled={loading}
										>
											{t('linkPhoneNumberComponent.submit')}
									</Button>
								</Stack>
							</Box>
						}
					</Stack>
				</DialogContent>
			</Dialog>
		</>
	)

}