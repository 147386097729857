import React, {useEffect, useState} from 'react';
import './DeleteTopicComponent.css'
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { deleteTopicFromCloudSpanner } from '../../firebaseFunctions/spannerFunctions/deleteFunctions';
import { getTopicData } from '../../firebaseFunctions/spannerFunctions/topicFunctions';

export default function DeleteTopicComponent(props) {
	
	const { t } = useTranslation();

	const navigate = useNavigate()
	
	const { currentUser } = useAuth()
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
	const [isTopicAuthor, setIsTopicAuthor] = useState(false);
	
	useEffect(() => {
		getTopicData(props.topicId).then((topicData) => {
			try {
				if (topicData !== null) {
					if (topicData.user.userId === currentUser.uid) {
						setIsTopicAuthor(true)
					} else {
						setIsTopicAuthor(false)
					}
				} else {
					setIsTopicAuthor(false)
				}	
			} catch (error) {
				setIsTopicAuthor(false)
			}
		})
		return () => {
		}
	}, [])
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	async function submitDeleteTopic(e) {
		e.preventDefault();
		setLoading(true)
		if (props.topicId) {
			deleteTopicFromCloudSpanner(props.topicId).then((res) => {
				if (res.error === true) {
					openErrorAlert()
					handleCloseModal()
					setLoading(false)
				} else {
					navigate(0)
					handleCloseModal()
					setLoading(false)
				}
			})
		}
	}
	
	const openErrorAlert = () => {
		setErrorAlertOpen(true);
	};
	const closeErrorAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setErrorAlertOpen(false);
	};
	return (
		<>	
			
			<Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={closeErrorAlert}>
				<Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
					{t("deleteTopicComponent.error")}
				</Alert>
			</Snackbar>		

			{/* Delete Topic Dialog */}
			{
				isTopicAuthor === true ? 
				<>
					<Dialog
						open={openModal}
						onClose={handleCloseModal}
						sx={{padding: "12px"}}
						PaperProps={{
						component: 'form',
						onSubmit: (event) => submitDeleteTopic(event),
						}}
					>
						<DialogTitle sx={{ m: 0, p: 2 }}>
							<Typography	variant='h2' sx={{paddingBottom: "12px"}}>
								{t('deleteTopicComponent.title')}
							</Typography>
						</DialogTitle>
						<DialogContent>
							<Typography	variant='body1' sx={{paddingBottom: "12px"}}>
								{t('deleteTopicComponent.description')}
							</Typography>
							{	loading ?
								<Box sx={{ width: '100%' }}>
									<LinearProgress />
								</Box> : null
							}
						</DialogContent>
						<DialogActions>
							<Button variant="contained" color="error" disabled={loading} onClick={handleCloseModal}>{t('cancel')}</Button>
							<Button variant="contained" type="submit" disabled={loading}>{t('submit')}</Button>
						</DialogActions>
					</Dialog>
					
					<Box sx={{ display: 'flex', float: "right", alignItems: 'center', textAlign: 'center' }}>
					{props.color ?
							<Tooltip title={t('deleteTopicComponent.title')} >
								<IconButton
								disabled={loading}
								onClick={handleOpenModal}
								size="small"
								sx={{":hover": {backgroundColor: "transparent"}, marginBottom: "22px"}}
								>
									<DeleteIcon fontSize='small' sx={{color: props.color}}/>
								</IconButton>
							</Tooltip>
							:	
							<Tooltip title={t('deleteTopicComponent.title')}>
								<IconButton
								disabled={loading}
								onClick={handleOpenModal}
								size="small"
								>
									<DeleteIcon fontSize='small'/>
								</IconButton>
							</Tooltip>
						}
					</Box>
				</>
				:
				null
			}

		</>
	)
}