
import { httpsCallable } from "firebase/functions";
import {  functions } from "../../firebase";
export async function getSearchedUsersFromElasticsearch(offset, query){
	const getsearchedusersfromelasticsearch = httpsCallable(functions, 'getsearchedusersfromelasticsearch');
	return await getsearchedusersfromelasticsearch({offset: offset, query: query})
	.then((result) => {
		const data = result.data.data;
		const dataInLocalFormat =  data !== null ? data.map((row) => {
			return {
                userId: row.UserId,
                username: row.Username,
                avatarSmallUrl: row.AvatarSmallUrl,
                avatarMediumUrl: row.AvatarMediumUrl,
                avatarBigUrl: row.AvatarBigUrl,
                follows: row.Follows,
                followers: row.Followers,
                bio: row.Bio
			}
		}) : []
		return {data: dataInLocalFormat, isLastUser: result.data.isLastUser}
	})
	.catch((error) => {
	});
}

export async function getSearchedTopicPostsFromElasticsearch(offset, query){
	const getsearchedtopicpostsfromelasticsearch = httpsCallable(functions, 'getsearchedtopicpostsfromelasticsearch');
	return await getsearchedtopicpostsfromelasticsearch({offset: offset, query: query})
	.then((result) => {
		const data = result.data.data;
		const dataInLocalFormat =  data !== null ? data.map((row) => {
			return {
				user: {

					userId: row.UserId,
					username: row.Username,
					avatarSmallUrl: row.AvatarSmallUrl,
					avatarMediumUrl: row.AvatarMediumUrl,
					avatarBigUrl: row.AvatarBigUrl,
					follows: row.Follows,
					followers: row.Followers,
					bio: row.Bio
				},
				post: {
					postId: row.PostId,
					post: row.Post,
					timestamp: row.UnixMillisPostTimestamp,
					type: row.Type 
				},
				topic: {
					title: row.Title,
					topicId: row.TopicId,
					reportedTitle: row.ReportedTitle

				}

			}
		}) : []
		return {data: dataInLocalFormat, isLastTopic: result.data.isLastTopic}
	})
	.catch((error) => {
	});
}

export async function getSearchedDiscussionPostsFromElasticsearch(offset, query){
	const getsearcheddiscussionpostsfromelasticsearch = httpsCallable(functions, 'getsearcheddiscussionpostsfromelasticsearch');
	return await getsearcheddiscussionpostsfromelasticsearch({offset: offset, query: query})
	.then((result) => {
		const data = result.data.data;
		const dataInLocalFormat =  data !== null ? data.map((row) => {
			return {
				user: {

					userId: row.UserId,
					username: row.Username,
					avatarSmallUrl: row.AvatarSmallUrl,
					avatarMediumUrl: row.AvatarMediumUrl,
					avatarBigUrl: row.AvatarBigUrl,
					follows: row.Follows,
					followers: row.Followers,
					bio: row.Bio
				},
				post: {
					postId: row.PostId,
					post: row.Post,
					timestamp: row.UnixMillisPostTimestamp,
					type: row.Type  
				},
				topic: {
					title: row.Title,
					topicId: row.TopicId,
					reportedTitle: row.ReportedTitle

				},
				discussion: {
					discussionId: row.DiscussionId,
					timestamp: row.UnixMillisDiscussionTimestamp,
					ratingsSum: row.RatingsSum,
					ratingsCounter: row.RatingsCounter,
					postsCounter: row.PostsCounter,
					lastPostId: row.LastPostId
				}

			}
		}) : []
		return {data: dataInLocalFormat, isLastDiscussion: result.data.isLastDiscussion}
	})
	.catch((error) => {
	});
}


export async function getSearchedTopicDiscussionPostsFromElasticsearch(offset, query, topicId){
	const getsearchedtopicdiscussionpostsfromelasticsearch = httpsCallable(functions, 'getsearchedtopicdiscussionpostsfromelasticsearch');
	return await getsearchedtopicdiscussionpostsfromelasticsearch({offset: offset, query: query, topicId:topicId})
	.then((result) => {
		const data = result.data.data;
		const dataInLocalFormat =  data !== null ? data.map((row) => {
			return {
				user: {

					userId: row.UserId,
					username: row.Username,
					avatarSmallUrl: row.AvatarSmallUrl,
					avatarMediumUrl: row.AvatarMediumUrl,
					avatarBigUrl: row.AvatarBigUrl,
					follows: row.Follows,
					followers: row.Followers,
					bio: row.Bio
				},
				post: {
					postId: row.PostId,
					post: row.Post,
					timestamp: row.UnixMillisPostTimestamp,
					type: row.Type  
				},
				topic: {
					title: row.Title,
					topicId: row.TopicId,
					reportedTitle: row.ReportedTitle

				},
				discussion: {
					discussionId: row.DiscussionId,
					timestamp: row.UnixMillisDiscussionTimestamp,
					ratingsSum: row.RatingsSum,
					ratingsCounter: row.RatingsCounter,
					postsCounter: row.PostsCounter,
					lastPostId: row.LastPostId
				}

			}
		}) : []
		return {data: dataInLocalFormat, isLastPost: result.data.isLastPost}
	})
	.catch((error) => {
	});
}