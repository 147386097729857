import React, {useContext} from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import PoliciesNotificationNonModalDialog from '../components/PoliciesNotificationNonModalDialog/PoliciesNotificationNonModalDialog'
import { PoliciesAcceptedContext } from '../contexts/PoliciesAcceptedContext'

export default function VerificationPageRoute() {
	const { currentUser, currentProfile } = useAuth()

	const { acceptedPolicies } = useContext(PoliciesAcceptedContext)
	return ( 
		(!(currentUser && !currentProfile) ? ((currentUser && !currentUser.emailVerified) ? 
			acceptedPolicies ?
				<>
					<PoliciesNotificationNonModalDialog/>
					<Outlet/>
				</> 
			:
				<Navigate to="/flow/newpolicies" />
				:
					(currentUser ? <Navigate to='/'/> : <Navigate to="/flow/signin"/>)) : <Navigate to="/error/userprofile" />)
	)
}

