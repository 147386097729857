import React, {useState, useRef} from 'react';
import './DiscussionPopoverCardComponent.css'
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/Star';
import Divider from '@mui/material/Divider';
import { FeedDate } from '../../utils/Dates';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { ShortenNumber } from '../../utils/NumberShortener';
import Box from '@mui/material/Box';
import ReportedTopicTitleComponent from '../ReportedTopicTitleComponent';
import { auth } from "../../firebase";
const shapeStyles = { bgcolor: 'red', width: 7, height: 7 };
const shapeCircleStyles = { borderRadius: '50%' };
const circle = (
	<Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }} />
);

export default function DiscussionPopoverCardComponent(props) {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [discussionData, setDiscussionData]  = useState(null);
	const navigate = useNavigate()  
	const [discussionRatingMean, setDiscussionRatingMean] = useState();
	const itemButtonRef = useRef(null);

	const handlePopoverOpen = (event) => {
	  	setAnchorEl(event.currentTarget);
		setDiscussionData(props.discussion)
		setDiscussionRatingMean(props.discussion.ratingSum === 0 ? 0 : (props.discussion.ratingsSum/props.discussion.ratingsCounter))
	};

	const handlePopoverClose = () => {
	  	setAnchorEl(null);
	};
  
	const handleNavigateToDiscussion = () => {
		const topicId = props.topic.topicId 
		const discussionId = props.discussion.discussionId
		if (discussionId !== "" && discussionId !== null){
			navigate('/topic/' + topicId + '/discussion/' + discussionId)
		} else{
			// should never trigger?
			navigate('/topic/' + topicId)
		} 
	}

	const handleNavigateToDiscussionPost = () => {
		if (props.discussion.discussionId !== "" && props.discussion.discussionId !== null){
			navigate('/topic/' + props.topic.topicId + '/discussion/' + props.discussion.discussionId + '/post/' + props.postId)
		} else{
			// should never trigger
			navigate('/topic/' + props.topic.topicId)
		} 
	}

	return (
		<>
			{
				props.recent === true ? 
					<ListItemButton
						aria-owns={open ? 'mouse-over-popover' : undefined}
						aria-haspopup="true"
						ref={itemButtonRef}
						href={(props.discussion.discussionId !== "" && props.discussion.discussionId !== null) ? '/topic/' + props.topic.topicId  + '/discussion/' + props.discussion.discussionId : '/topic/' + props.topic.topicId}
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose} sx={{paddingRight: "6px", marginLeft: '-10px'}} onClick={() => handleNavigateToDiscussion()}>
						<ListItemText primary={
							<Stack direction="row" 
							justifyContent="space-between"					
							alignItems="center">
								<Typography variant="subtitle1" sx={{
									overflow: "hidden",
									textOverflow: "ellipsis",
									display: "-webkit-box",
									WebkitLineClamp: "1",
									WebkitBoxOrient: "vertical"}}>	

									{
										props.topic.reportedTitle === true ?
											<ReportedTopicTitleComponent />
										:
											
											props.topic.title
									}
								</Typography>
								{props.discussion.lastVisitedTimestamp >= props.discussion.lastPostTimestamp || auth.currentUser.uid === props.discussion.authorOfLastPost  ? null : circle}
							</Stack>}/>
					</ListItemButton>
					:
					props.summary === true ?
						<Button
							aria-owns={open ? 'mouse-over-popover' : undefined}
							aria-haspopup="true"
							ref={itemButtonRef}
							color="inherit" underline="hover"
							variant='text'
							href={(props.discussion.discussionId !== "" && props.discussion.discussionId !== null) ? '/topic/' + props.topic.topicId  + '/discussion/' + props.discussion.discussionId + '/post/' + props.postId : '/topic/' + props.topic.topicId}
							onMouseEnter={handlePopoverOpen}
							onMouseLeave={handlePopoverClose} 
							sx={{
								width: "100%!important",
								marginLeft: '-18px!important', 
								textTransform: 'none',
								'&:hover': {
									background: "transparent!important"
								},
								display: "flex",
								justifyContent: "flex-start"
								
							}} 
							onClick={() => handleNavigateToDiscussionPost()} >
							{props.type ==="opening" ? 
									<>
									{/* seems to not be used */}
										<Typography variant="caption" sx={{fontStyle:"italic", 
											overflow: "hidden",
											textOverflow: "ellipsis",
											display: "-webkit-box",
											WebkitLineClamp: "2",
											WebkitBoxOrient: "vertical"}}>
											{t("topicSummary.created") + ":"}
											<Stack direction="row">
												<Typography variant="caption" sx={{fontStyle:"italic",}}>	
													{
														props.type === "share" ? 
															props.topic.reportedSharedTitle === true ?
																<ReportedTopicTitleComponent />
															:
																props.topic.title
														:
															props.topic.reportedTitle === true ?
																<ReportedTopicTitleComponent />
															:
																props.topic.title
													}
												</Typography>
											</Stack>
										</Typography> 
									</>
								:
									<>
										<Typography variant="caption" sx={{fontStyle:"italic", 
											overflow: "hidden",
											textOverflow: "ellipsis",
											display: "-webkit-box",
											WebkitLineClamp: "2",
											WebkitBoxOrient: "vertical"}}>
											<Stack direction="row">

												{t("topicSummary.postedOn") + ":"}
												<Box sx={{paddingLeft: "8px"}}/>
												<Typography variant="caption" sx={{fontStyle:"italic"}}>
													{
														props.type === "share" ? 
															props.topic.reportedSharedTitle === true ?
																<ReportedTopicTitleComponent />
															:
																props.topic.title
														:
															props.topic.reportedTitle === true ?
																<ReportedTopicTitleComponent />
															:
																props.topic.title
													}
												</Typography>
											</Stack>
										</Typography>
									</>
							}
						</Button> 
						: null
			}
			{
				props.discussion.discussionId !== "" && props.discussion.discussionId !== null ? 
			
					<Popover
						id="mouse-over-popover"
						sx={{
							pointerEvents: 'none',
						}}
						open={open}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						onClose={handlePopoverClose}
						disableRestoreFocus
					>
					{discussionData ? 
						(discussionData !== false ?
							<Card sx={{ width: "320px", maxWidth: "90vw"}}>
								<CardHeader sx={{marginBottom:0}}
									title={
										<>
											<Typography sx={{
												overflow: "hidden",
												textOverflow: "ellipsis",
												display: "-webkit-box",
												WebkitLineClamp: "2",
												WebkitBoxOrient: "vertical"}} variant="h5" color="text.primary">
												{
													props.recent === true ? 
														(
															props.topic.reportedTitle === true ?
																<ReportedTopicTitleComponent />
															:
																props.topic.title
														)
														:
														props.summary === true ? 
															( 
																props.type === "share" ? 
																	props.topic.reportedSharedTitle === true ?
																		<ReportedTopicTitleComponent />
																	:
																		props.topic.title
																:
																	props.topic.reportedTitle === true ?
																		<ReportedTopicTitleComponent />
																	:
																		props.topic.title
															)
														: null
													} 
											</Typography>
										</>
									}
								/>
								<CardContent  sx={{padding:0, margin:0}}>
									
									<Typography variant="caption" sx={{marginLeft: "16px"}} color="text.secondary">
										{t("topicSummary.created") + " " + FeedDate(props.discussion.timestamp)} 
									</Typography>
									<Divider sx={{marginTop:"16px"}} variant="middle" flexItem />
									<Grid direction="row" container 
										justifyContent="space-evenly"
										alignItems="center" spacing={0} sx={{textAlign: 'center', padding:0, marginTop:"16px"}}>
										<Grid item>
											<Typography variant="body1" color="text.secondary">
												{t("topicPosts.postQty")}
											</Typography>
											<Typography  variant="caption" color="text.secondary">
												{ShortenNumber(props.discussion.postsCounter)}
											</Typography>
										</Grid>
										<Divider orientation="vertical" variant="middle" flexItem />
										<Grid item >
											<Typography variant="body1" color="text.secondary">
												{t("topicPosts.rating")}
											</Typography>
											<Stack
												direction="row"
												justifyContent="center"
												alignItems="center">
												<StarIcon fontSize='small' style={{ opacity: 0.55, color: '#faaf00' }}/>
												<Typography variant="caption" color="text.secondary" sx={{ marginBottom: "-3px!important"}}>
													{discussionRatingMean ? discussionRatingMean : "-"}
												</Typography>
											</Stack>
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						:
						
						<Card sx={{ width: "320px", maxWidth: "90vw", border: "none!important"}}>
							<CardContent sx={{padding:0, margin:0}}>
								<Typography sx={{paddingLeft:"16px", paddingRight:"16px"}} variant="caption">
									{t("error.noDiscussion")}
								</Typography>
							</CardContent>
						</Card>
						)
					
					:
						<CircularProgress sx={{margin: "6px", width:"26px!important", height:"26px!important", color:"primary!important"}}/>
					}
					</Popover>
				: null
			}
		</>
	)

}