import React, {useState, useEffect} from 'react';
import './AuthActionComponent.css'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { applyActionCode, confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../firebase';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from '../../utils/Constants';

export default function AuthActionComponent() {
	const { t } = useTranslation();
	
	const query = new URLSearchParams(useLocation().search); 
	const [message, setMessage] = useState()
	const [loading, setLoading] = useState(true)
	const navigate = useNavigate()
	const [error, setError] = useState("")
	const [loadingVerification, setLoadingVerification] = useState(false)
	const [actionCompleted, setActionCompleted] = useState(false);
    useEffect(() => {  
		setLoadingVerification(true)
		const oobCode = query.get('oobCode');
		const mode = query.get('mode');
		switch (mode) { 
			case 'resetPassword': 
				handleResetPassword(oobCode); 
				break; 
			case 'verifyEmail': 
				handleVerifyEmail(oobCode); 
				break; 
			case 'recoverEmail': 
				handleRecoverEmail(oobCode); 
				break; 
			default: 
				setMessage(t('authActionComponent.error')); 
		}
		setLoading(false)
		if (!oobCode) {
			navigate("/")
		}
		setLoadingVerification(false)	
		return () => {
		}
	}, [])


	const handleResetPassword = async (oobCode) => {
		setMessage(t('authActionComponent.newPassword'));
		setActionCompleted(false);
	};
	
	const handleVerifyEmail = async (oobCode) => {
		try {
		  await applyActionCode(auth,oobCode)
		  setMessage(t('authActionComponent.emailVerificationSuccessful'));
		  if (auth.currentUser) {
			await auth.currentUser.reload();
		  }
		  setActionCompleted(true);
		} catch (error) {
			setMessage(t('authActionComponent.emailVerificationError'));
		  	setActionCompleted(true);
		}
	};
	
	const handleRecoverEmail = async (oobCode) => {
		try {
		  await applyActionCode(auth,oobCode)
		  setMessage(t('authActionComponent.recoverEmailSuccess'));
		  if (auth.currentUser) {
			await auth.currentUser.reload();
		  }
		  setActionCompleted(true);
		} catch (error) {
			setMessage(t('authActionComponent.recoverEmailError'));
		  setActionCompleted(true);
		}
	};

	async function handleNewPasswordSubmit(event) {
		event.preventDefault();
		setLoading(true)
		const data = new FormData(event.currentTarget);
		
		const oobCode = query.get('oobCode');
		if (oobCode) {
			try {
				if ((data.get('password') === "")|| (data.get('email') === "") || (data.get('username') === "")){
					setLoading(false)
					return setError(t("signUpComponent.allFieldsRequired"))
				}else {
					if (data.get('password').length < PASSWORD_MIN_LENGTH || data.get('password').length > PASSWORD_MAX_LENGTH){
						setLoading(false)
						return setError(t("signUpComponent.passwordCharacterMinimun"))
					} else {
						if (data.get('password') !== data.get('confirmPassword')) {
							setLoading(false)
							return setError(t("signUpComponent.passwordDoesNotMatch"))
						} else {
							const passwordValid = PASSWORD_REGEX.test(data.get('password'))
							if (passwordValid) {
								await confirmPasswordReset(auth, oobCode, data.get('password'));
								setMessage(t('authActionComponent.passwordChangedSuccess'));
								if (auth.currentUser) {
								  await auth.currentUser.reload();
								}
								setActionCompleted(true);
								setLoading(false)
							}
						}	
					}
				}
			} catch (error) {
				setMessage(t('authActionComponent.passwordChangedError'));
				setLoading(false)
			}
		} else {
			navigate("/")
		}
	};

	return (
		<>
			{loadingVerification || !message ? 
		
				<Box sx={{ display: 'flex',
					alignContent: 'center', 
					height: "100vh",
					alignItems:"center",
					justifyContent:"center"}}>
					<CircularProgress />
				</Box> 
				: 		
				<Grid container direction="column" 
					justifyContent="center"
					alignItems="center">
					<Typography variant="h3"> 
						{message}
					</Typography>
					{!actionCompleted && query.get('mode') === 'resetPassword' && (
						<Box component="form" onSubmit={handleNewPasswordSubmit} noValidate >	
							<Grid container spacing={0}>
								{error && <Alert sx={{height: "auto!important", width: "100%!important", marginTop: "8px"}} severity='error'>{error}</Alert>}
								<Grid item xs={12}>
									<TextField
										margin="dense"
										sx={{backgroundColor:"#fff", borderRadius: "4px"}}
										spellCheck={false}
										required
										fullWidth
										name="password"
										label={t("password")}
										type="password"
										id="password"
										autoComplete="new-password"
										size="small"
										
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										margin="dense"
										sx={{backgroundColor:"#fff", borderRadius: "4px"}}
										required
										spellCheck={false}
										fullWidth
										name="confirmPassword"
										label={t("confirmPassword")}
										type="password"
										id="confirmPassword"
										autoComplete="confirm-new-password"
										size="small"
									/>
								</Grid>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									className="FlowButton"
									sx={{ margin: "16px!important", marginLeft: "0px!important", marginRight:"0px!important", borderRadius: "22px" }}
									disabled={loading}
								>
									{t('authActionComponent.newPasswordButton')}
								</Button>
							</Grid>
						</Box>
					)}
				</Grid>
			}
		</>
	)

}