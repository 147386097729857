export const MAX_TOPIC_TITLE_LENGTH = 300 // IN CHARS
export const MAX_POST_LENGTH = 30000 // IN BYTES OR UTF-8 CHARS
export const MIN_TOPIC_TITLE_LENGTH = 1 // IN CHARS
export const MIN_POST_LENGTH = 1 // IN CHARS
export const MAX_INVITES_PER_TOPIC = 15 // users that can be invited to same topic. 15 
export const MAX_DOCUMENT_TITLE_LENGTH = 50 // chars of maximum title length of tabs for SEO
export const MAX_DOCUMENT_DESCRIPTION_LENGTH = 150 // chars of maximum description length of tabs for SEO

export const REPLY_DRAWER_FIXED_POST_HEADER_HEIGHT_ABSOLUTE = 84
export const REPLY_BUTTON__STICKY_COLLAPSIBLE_INFO_BAR_HEIGHT = 32

export const DEFAULT_LANGUAGE_CODE = 'en' // used for user language preference to send notification emails

// styled post constants
export const USERNAME_USER_ID_POINTER_REGEX = /(?<=\s|^)(@userid:\S+\b)/g // has the form @userid:id, where id is the user id.
// Textfield functionality
export const USERNAME_MENTION_REGEX = /(@\w+\s)/; // starts on @ ends with whitespace
export const URL_REGEX = /(https?:\/\/[^\s]+|www\.[^\s]+)/g
export const URL_OR_USERNAME_MENTION_REGEX = /(https?:\/\/[^\s]+|www\.[^\s]+|(?<=\s|^)@userid:\S+\b)/g
export const MAX_USERNAMES_MENTIONED = 20 // max usernames cited per post should be the same as backend
export const MAX_EXTERNAL_LINKS_PER_POST = 5 // should be the same as backend limit (used for posts, description and titles)
export const MAX_LOCAL_LINKS_PER_POST = 10 // should be the same as backend limit, includes external links (used for posts, description and titles)
export const MAX_CHARACTERS_PER_LOCAL_POST_MENTIONED = 200
// USER
export const BIO_CHARACTER_LIMIT = 1024;
export const USERNAME_CHARACTER_MAX = 36
export const USERNAME_CHARACTER_MIN = 4
export const PASSWORD_MIN_LENGTH = 10 // firebase auth is 6
export const PASSWORD_MAX_LENGTH = 30 // based on firebase auth
export const PASSWORD_REGEX = /^[A-Za-z\d@$!%*?&]+$/; // only allow uppercase, lowercase, numbers, and symbols
export const USERNAME_REGEX = /^[a-z0-9_]+$/; // only allow lowercase, numbers and underscore

// Max post height collapsed:
export const MAX_SEARCH_RESULT_LINES_COLLAPSED = 8
export const MAX_TOPIC_DISCUSSION_SEARCH_ITEM_LINES_COLLAPSED = 10
export const MAX_TOPIC_SUMMARY_ITEM_LINES_COLLAPSED = 13
export const MAX_SHARED_POST_ITEM_LINES = 5
// Max post height expanded on feed, profiles and search results (on topic and on search engine):
export const MAX_SEARCH_RESULT_LINES_EXPANDED = 15
export const MAX_TOPIC_SUMMARY_ITEM_LINES_EXPANDED = 30
export const MAX_TOPIC_DISCUSSION_SEARCH_ITEM_LINES_EXPANDED = 15
// Profile
export const AVATAR_MAX_SIZE = 512 // Preview Size max
export const AVATAR_MEDIUM_SIZE = 84 // Thumbnail
export const AVATAR_SMALL_SIZE = 42 // icon size
export const AVATAR_XS_SIZE = 24 // share size
//Feed
export const MAX_CHARACTERS_PER_PREVIEW_POST = 600
// SEARCH
export const MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST = 300
// MENTIONS
export const MAX_CHARACTERS_PER_MENTION_PREVIEW_POST =200
export const MAX_MENTION_POST_ITEM_LINES_COLLAPSED = 5
//SIDEBAR
export const DEBATE_LIST_ITEM_HEIGHT = "30px"
export const DEBATE_LIST_CREATED_ITEM_HEIGHT = "46px"

//RIGHT SIDEBAR
export const RECOMMENDED_DISCUSSIONS_ITEM_HEIGHT = "50px"

//APP
export const BUTTON_REPLY_DRAWER_STACK_HEIGHT_ABSOLUTE = 82

export const MIN_CONTENT_WIDTH = "320px" // minimum width with content
export const MAX_MOBILE_WIDTH = 1024 //pixels (Laptop size)
export const SHOW_HOME_SLOGAN_WIDTH = 900 //pixels 
export const MAX_LAPTOP_WIDTH = 1440

export const NAVBAR_HEIGHT = "58px"
export const NAVBAR_HEIGHT_ABSOLUTE = 58
export const NAVBAR_MOBILE_LOGO_WIDTH = "160px"
export const NAVBAR_LOGO_WIDTH = "185px"
export const HOME_LOGO_ICON_WIDTH = "90px"
export const NAVBAR_ANONYMOUS_MAX_MOBILE_WIDTH = 1024

export const SIDEBAR_WIDTH = "300px"
export const MIN_SIDEBAR_WIDTH = "250px"
export const COLLAPSIBLE_HEIGHT_ABSOLUTE = 65 // this height is not alterable, is data as taken from accordion default and immutable height
export const RIGHT_SIDEBAR_WIDTH = "300px"
export const MIN_RIGHT_SIDEBAR_WIDTH = "250px"
export const MIN_HEIGHT_FOR_RIGHT_SIDEBAR_AD_DISPLAY = 555 // fixed depending on how the right sidebar has been designed
export const POST_AD_FREQUENCY = 10 // ad every 10 posts

export const STICKY_COLLAPSIBLE_EXPANDED_HEIGHT = 158
export const STICKY_COLLAPSIBLE_BIO_MAX_HEIGHT = 62
export const STICKY_COLLAPSIBLE_FIXED_HEIGHT = 112
export const STICKY_COLLAPSIBLE_COLLAPSED_HEIGHT = 54

export const CONTENT_MOBILE_MARGIN = "6px"
export const CONTENT_MARGIN = "18px"
export const CONTENT_MOBILE_MARGIN_ABSOLUTE = 6
export const CONTENT_MARGIN_ABSOLUTE = 18


// NOTIFICATIONS
export const NOTIFICATIONS_MENU_WIDTH = "390px" 