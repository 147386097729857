import React, {useState, useEffect} from 'react';
import './RecommendedDiscussionsComponent.css'
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import StarIcon from '@mui/icons-material/Star';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import {  RECOMMENDED_DISCUSSIONS_ITEM_HEIGHT} from '../../utils/Constants';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';

import { useTranslation } from 'react-i18next';
import { getRecommendedDiscussions } from '../../firebaseFunctions/spannerFunctions/recommendedFunctions';


export default function RecommendedDiscussionsComponent(props) {
	const { t, i18n } = useTranslation();

  const [recommendedList, setRecommendedList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {  
    // Anything in here is fired on component mount.	

    // current user language
    const currentLang = i18n.language
    getRecommendedDiscussions(currentLang).then((res) => {
      setRecommendedList([...res])
    })

    setLoading(false)
    
    return () => {
    }

  }, [])


  return (
    <>
      <Card className="RecommendedCard" sx={{height:"100%!important", maxHeight: "100%!important", width: "inherit",
        position: 'relative',
        overflow: 'auto'
        }}>        
          {
            loading ? 
              <Box sx={{ display: 'flex',
                alignContent: 'center', 
                height: "100%!important",
                alignItems:"center",
                justifyContent:"center"}}>
                <CircularProgress />
              </Box> 
              :
                recommendedList.length !== 0 ? 
                  <>
                    <Box className="RecommendedTitle" sx={{ width:"inherit", paddingTop:"15px",paddingBottom:"15px", position: "fixed", zIndex: 2}}>
                      <Typography sx={{marginLeft: "8px"}} variant='body1'>{t('recommendedDiscussionsComponent.recommended')}</Typography>
                    </Box> 
                    <List 
                      sx={{ 
                        paddingTop: "0!important",
                        height: "inherit!important"
                      }}>
                      <Box sx={{ backgroundColor: "white", width:"inherit", height: "50px"}}/>
                      {
                        recommendedList.map((discussion) => {
                          return (
                            <>	
                              <ListItem 
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={0}
                                sx={{height: RECOMMENDED_DISCUSSIONS_ITEM_HEIGHT, paddingLeft: "6px"}}>
                                <Stack direction='row'
                                    justifyContent="center"
                                    alignItems="center">
                                  <Stack direction='column' sx= {{ marginTop: "4px", marginRight: "6px"}}
                                    justifyContent="center"
                                    alignItems="center">
                                    <StarIcon fontSize='small' style={{ marginTop: "0px", opacity: 0.55, color: '#faaf00' }}/>
                                    <Typography variant="caption">                                  
                                      {(discussion.discussion.ratingsSum) === 0 ? 0 : (discussion.discussion.ratingsSum/discussion.discussion.ratingsCounter).toFixed(1)}
                                    </Typography>  
                                  </Stack>
                                  <Link component={RouterLink} to={"/topic/" + discussion.topic.topicId + "/discussion/" + discussion.discussion.discussionId} color="inherit" underline="hover">
                                      <Typography variant="subtitle1" sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "2",
                                        WebkitBoxOrient: "vertical"}}>
                                        {discussion.topic.title}
                                      </Typography>  
                                  </Link>	
                                </Stack>
                              </ListItem>
                              <Divider />
                            </>
                          )
                        })
                      }
                    </List>
                  </>
              :
              <>
                <Box className="RecommendedTitle" sx={{ width:"inherit", paddingTop:"15px",paddingBottom:"15px", position: "fixed", zIndex: 2}}>
                  <Typography sx={{marginLeft: "8px"}} variant='body1'>{t('recommendedDiscussionsComponent.recommended')}</Typography>
                </Box> 
                <Box sx={{ display: 'flex',
                  alignContent: 'center', 
                  height: "100%!important",
                  alignItems:"center",
                  justifyContent:"center"}}>
                  <Typography variant='caption'>{t('recommendedDiscussionsComponent.noResults')}</Typography>
                </Box>
              </> 
          }
      </Card>
    
    </>
  );
}

