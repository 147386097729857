import React from 'react';
import './ScrollToTopComponent.css'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useTranslation } from 'react-i18next';


export default function ScrollToTopComponent() {
	const { t } = useTranslation();
    const scrollToTop = () => {
      window.scrollTo({top:0,left:0, behavior:"smooth"})
    }
	return (
		<Tooltip title={<>{t('scrollToTop')}</>}>	
			<IconButton size='small' aria-label="settings" onClick={scrollToTop} 
				sx={{
				float:"right"
				}}>
				<ArrowDropUpIcon  fontSize="small"/>
			</IconButton>
		</Tooltip>
	)

}