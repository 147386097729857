import React, {useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import logoWhite from '../../utils/logoWhite.svg'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LogoutIcon from '@mui/icons-material/Logout';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import './NavBarClean.css'
import { MAX_MOBILE_WIDTH, NAVBAR_HEIGHT, NAVBAR_LOGO_WIDTH, NAVBAR_MOBILE_LOGO_WIDTH } from '../../utils/Constants';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import {Link as RouterLink} from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import Link from '@mui/material/Link';
import { auth } from "../../firebase";

export default function NavBarClean() {
	const { t } = useTranslation();

	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const { logOut } = useAuth()
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [logoutOpen, setLogoutOpen] = useState(false);	
	const setWindowDimensions = () => {
		setWindowWidth(window.innerWidth)
	}	
	useEffect(() => {  
		// Anything in here is fired on component mount.	

		window.addEventListener('resize', setWindowDimensions);
		return () => {
			window.removeEventListener('resize', setWindowDimensions)
		}

	}, [])

	async function handleLogOut(event) {
		event.preventDefault()
		handleLogoutClose()
		try {
			setLoading(true)
			await logOut()
			navigate("/")
		} catch {
			alert(t("navBar.failedToLogOut"))
		}
	
		setLoading(false)
	  }
	  const handleLogoutClickOpen = () => {
		  setLogoutOpen(true);
	  };
  
	  const handleLogoutClose = () => {
		  setLogoutOpen(false);
	  };
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="fixed" sx={{height: NAVBAR_HEIGHT, zIndex: (theme) => theme.zIndex.drawer + 1, // zIndex to be able to see navbar while using textfield drawer in response to posts
			 	}}>
				<Toolbar>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						>
						<Grid item>
							<Stack direction="row" sx={{marginTop:  windowWidth < MAX_MOBILE_WIDTH ?  "0px" : 0}}>							
								<Link component={RouterLink} to={"/"} color="inherit" underline="none" sx={{
										marginTop:  windowWidth < MAX_MOBILE_WIDTH ? "5px" : "-5px",
										width: windowWidth < MAX_MOBILE_WIDTH ? NAVBAR_MOBILE_LOGO_WIDTH : NAVBAR_LOGO_WIDTH, marginLeft: windowWidth < MAX_MOBILE_WIDTH ? "5px" : "20px", paddingRight: windowWidth < MAX_MOBILE_WIDTH ? "7px" : "16px"
										,':hover': {	
											backgroundColor: "transparent!important"
										}
									}} 
									className='NavbarLogo'>
									{
										windowWidth < MAX_MOBILE_WIDTH ?
											<img src={logoWhite} alt="intergraphy_logo" style={{ width: "inherit!important" }} />
										:
											<img src={logoWhite} alt="intergraphy_logo"/>
									}
								</Link>
							</Stack>
						</Grid>
						{
							auth.currentUser ?
							<Grid item>
								<Dialog
									open={logoutOpen}
									onClose={handleLogoutClose}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									>
									<DialogTitle id="alert-dialog-title">
										{t("logOut")}
									</DialogTitle>
									<DialogContent>
										<DialogContentText id="alert-dialog-description">
											{t("navBar.wantToLogOutQuestion")}
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button color="error" onClick={handleLogoutClose}>{t("cancel")}</Button>
										<Button onClick={handleLogOut} autoFocus>
											{t("yes")}
										</Button>
									</DialogActions>
								</Dialog>
								<IconButton
								size="large"
								aria-label="log out"
								onClick={handleLogoutClickOpen}
								color="inherit"
								disabled={loading}
								>
									<LogoutIcon />
								</IconButton>
							</Grid>
							: null
						}
					</Grid>
				</Toolbar>
			</AppBar>
		</Box>
	);
}
