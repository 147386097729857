const downloadJSON = (json, filename) => {
    const blob = new Blob([json], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    a.click();
};

export default async function handleDownloadAsJson(fileName, table) {
    const json = JSON.stringify(table, null, 2);
    downloadJSON(json, `${fileName}.json`);
};
