import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import PoliciesNotificationNonModalDialog from '../components/PoliciesNotificationNonModalDialog/PoliciesNotificationNonModalDialog'

export default function CreateUserProfileErrorRoute() {
	const { currentUser, currentProfile } = useAuth()

	return ((currentUser && !currentProfile) ? <>
		<PoliciesNotificationNonModalDialog/>
		<Outlet />
	</> : <Navigate to="/" />)
}

