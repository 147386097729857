import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

export async function hasUserAcceptedLatestSitePolicies() {	
	const hasuseracceptedlatestsitepolicies = httpsCallable(functions, 'hasuseracceptedlatestsitepolicies');
	return await hasuseracceptedlatestsitepolicies()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function acceptLatestPolicies() {	
	const acceptlatestsitepolicies = httpsCallable(functions, 'acceptlatestsitepolicies');
	return await acceptlatestsitepolicies()
	.then((result) => {
		const data = result.data;
		return data // resolves true if has been accepted
	})
	.catch((error) => {
	});
}
export async function updateUsersEmailListInCloudSpanner(language) {	
	const updateusersemaillistincloudspanner = httpsCallable(functions, 'updateusersemaillistincloudspanner');
	return await updateusersemaillistincloudspanner({language: language})
	.then((result) => {
		const data = result.data;
		return data // resolves true if has been accepted
	})
	.catch((error) => {
	});
}