import React, {useState, useEffect} from 'react';
import { Global } from '@emotion/react';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import AvatarPreviewComponent from '../AvatarPreviewComponent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import UserPopoverCardComponent from '../UserPopoverCardComponent';
import DiscussionPopoverCardComponent from '../DiscussionPopoverCardComponent';
import { useTranslation } from 'react-i18next';
import { AVATAR_SMALL_SIZE, CONTENT_MARGIN, CONTENT_MARGIN_ABSOLUTE, CONTENT_MOBILE_MARGIN, CONTENT_MOBILE_MARGIN_ABSOLUTE, MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST, MAX_MOBILE_WIDTH, MAX_TOPIC_DISCUSSION_SEARCH_ITEM_LINES_COLLAPSED, MAX_TOPIC_DISCUSSION_SEARCH_ITEM_LINES_EXPANDED } from '../../utils/Constants';
import { SearchResultWordsArray } from '../../utils/SearchResultWordsArray';
import { getSearchedTopicDiscussionPostsFromElasticsearch } from '../../firebaseFunctions/elasticsearchFunctions/searchFunctions';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import PostWithLinksComponent from '../PostWithLinksComponent/PostWithLinksComponent';
import { getDataForPostLocalLinks } from '../../firebaseFunctions/spannerFunctions/postLocalLinksFunctions';

const drawerButtonHeight = 60
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: "22px",
  backgroundColor: alpha(theme.palette.common.black, .08),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, .12),
  },
  marginRight: theme.spacing(2),
  marginLeft: "12px!important",
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'calc(100% - 26px)!important',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 0, 1, 0),
    transition: theme.transitions.create('width'),
  },
  width: 'calc(100% - 26px)!important'
}));

function TopicDiscussionDrawerItem(props) {
	
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	const [postLocalLinksDisplayData, setPostLocalLinksDisplayData] = useState([]);	
	useEffect(() => {
		getDataForPostLocalLinks(props.discussion.post.post).then((dataForLinks) => {
			setPostLocalLinksDisplayData(dataForLinks)
		})

		return () => {
		}
	}, [])
	return(
		<>	
		<ListItem alignItems="flex-start">
			<ListItemAvatar>
				{
					props.discussion.user.avatarSmallUrl !== "" ?
						<AvatarPreviewComponent avatar={props.discussion.user.avatarSmallUrl}
							avatarBig={props.discussion.user.avatarBigUrl}
							size={AVATAR_SMALL_SIZE + "px"}/>
					: 
						<Avatar src={props.discussion.user.avatarSmallUrl} sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE, color:"white" }} aria-label="avatar">
							{props.discussion.user.username[0].toUpperCase()}
						</Avatar>
				}
			</ListItemAvatar>
			<ListItemText
				primary={
					
					<Stack direction="column" sx={{marginLeft: "10px"}}>
						<Typography variant='body1'>
							<UserPopoverCardComponent uid={props.discussion.user.userId} user={props.discussion.user} type={props.discussion.post.type} place={null}/>
						</Typography>
						<DiscussionPopoverCardComponent summary={true} topic={props.discussion.topic} discussion={props.discussion.discussion} type={props.discussion.post.type} postId={props.discussion.post.postId} />
					</Stack>
				}
				secondary={
				<>
					<Stack direction="column" sx={{marginTop: "12px"}}>
						{!expanded ? 
							<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: MAX_TOPIC_DISCUSSION_SEARCH_ITEM_LINES_COLLAPSED, overflow: 'hidden', textOverflow: 'ellipsis'}}>
								{props.discussion.post.post.length > MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST ? 
										<PostWithLinksComponent key={0} showReferencedPosts={false} post={props.discussion.post.post.substring(0, MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST)+"..."} localLinksData={postLocalLinksDisplayData} wordsToBold={props.searchedWords}/>
									:
										<PostWithLinksComponent key={1} showReferencedPosts={false} post={props.discussion.post.post.substring(0, MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST)} localLinksData={postLocalLinksDisplayData} wordsToBold={props.searchedWords}/>
								}
							</Typography> 
							: 
							<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: MAX_TOPIC_DISCUSSION_SEARCH_ITEM_LINES_EXPANDED, overflow: 'hidden', textOverflow: 'ellipsis'}}>
								<PostWithLinksComponent key={2} showReferencedPosts={false} post={props.discussion.post.post} localLinksData={postLocalLinksDisplayData} wordsToBold={props.searchedWords}/>
							</Typography>
						}
						{
							props.discussion.post.post.length <= MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST ? 
								null
								:
								<div style= {{marginLeft: 'auto'}}>
									
									<Button
										disabled={props.discussion.post.post.length <= MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST}
										onClick={handleExpandClick}
										sx={{
											justifyContent: "flex-end",
											textTransform: 'none',
											'&:hover': {
												textDecoration: "underline",
												background: "transparent!important"
											}}}>
										<Typography variant='caption' color="inherit">
											{!expanded ? t('topicDiscussionDrawerComponent.readMore') : t('topicDiscussionDrawerComponent.readLess')}
										</Typography>
									</Button>
								</div>
						}
					</Stack>
				</>
			}
			/>	
		</ListItem>
		<Divider />
	</>
	)
}
export default function TopicDiscussionsDrawerComponent(props) {
	
	const [open, setOpen] = React.useState(false);
	const { t } = useTranslation();
  
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [searchTerm, setSearchTerm] = useState('');
	const [searchedWords, setSearchWords] = useState([]); 
	const [loadingDiscussions, setLoadingDiscussions] = useState(false);
	const [loadingMoreDiscussions, setLoadingMoreDiscussions] = useState(false);
	const [isLastPost, setIsLastPost] = useState(false)
	
	const [discussionsList, setDiscussionsList] = useState([]);
	const [topicId, setTopicId] = useState(props.topicId)
	const setWindowDimensions = () => {
		setWindowWidth(window.innerWidth)
	}	
	useEffect(() => {  
		
		window.addEventListener('resize', setWindowDimensions);
		return () => {
		  	window.removeEventListener('resize', setWindowDimensions)
		}

    }, [])
	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};	

	async function handleSearch(event) {
		event.preventDefault()
		const data = new FormData(event.currentTarget);  

		if (data.get("search") !== ""){
			setLoadingDiscussions(true)
			const tempSearchedWords = SearchResultWordsArray(data.get("search").toString())
			setSearchTerm(data.get("search").toString());
			setSearchWords(tempSearchedWords)
			
			getSearchedTopicDiscussionPostsFromElasticsearch(0, data.get("search").toString(), topicId).then((res) => {
				setDiscussionsList([...res.data])
				setIsLastPost(res.isLastPost)
				setLoadingDiscussions(false)
			})
		}
	}

	async function loadMoreTopicDiscussions(){
		setLoadingMoreDiscussions(true)
		
		if (!isLastPost) {
			getSearchedTopicDiscussionPostsFromElasticsearch(discussionsList.length, searchTerm, topicId).then((res) => {
				setDiscussionsList((prevList) => [...prevList, ...res.data])
				setIsLastPost(res.isLastPost)
				setLoadingMoreDiscussions(false)
			})
		}
	}	
	return (	
	<>	
		<Global
		styles={{
			'.MuiDrawer-root > .MuiPaper-root': {
			overflow: 'visible',
			backgroundColor: "inherit",
			},
		}}
		/>
		<Drawer
			sx={{
			height: 'auto',
			minHeight: `calc(${drawerButtonHeight}px)`,
			maxHeight: `calc(70% - ${drawerButtonHeight}px)`,
			flexShrink: 0,
			'& .MuiDrawer-paper': {
				height: 'auto',
				minHeight: `calc(${drawerButtonHeight}px)`,
				maxHeight: `calc(70% - ${drawerButtonHeight}px)`,
				marginLeft: windowWidth < MAX_MOBILE_WIDTH ? CONTENT_MOBILE_MARGIN : CONTENT_MARGIN, 
				marginRight: windowWidth < MAX_MOBILE_WIDTH ? CONTENT_MOBILE_MARGIN : CONTENT_MARGIN, 
				width: windowWidth < MAX_MOBILE_WIDTH ? windowWidth-(2*CONTENT_MOBILE_MARGIN_ABSOLUTE) : 0.5*windowWidth-(2*CONTENT_MARGIN_ABSOLUTE),	right: 0,
			
				left: windowWidth < MAX_MOBILE_WIDTH ? "0px" : windowWidth * 0.25 +"px",
				boxSizing: 'border-box',
			},
			marginLeft: windowWidth < MAX_MOBILE_WIDTH ? CONTENT_MOBILE_MARGIN : CONTENT_MARGIN, 
			marginRight: windowWidth < MAX_MOBILE_WIDTH ? CONTENT_MOBILE_MARGIN : CONTENT_MARGIN, 
			width: windowWidth < MAX_MOBILE_WIDTH ? windowWidth-(2*CONTENT_MOBILE_MARGIN_ABSOLUTE) : 0.5*windowWidth-(2*CONTENT_MARGIN_ABSOLUTE)
			
			}}
			variant="persistent"
			anchor="bottom"
			open={open}
		>
			<Box
			className='TopicDiscussionsDrawer'>
				<div
				style={{
					position: 'relative',
					top: -drawerButtonHeight,
					borderTopLeftRadius: 8,
					borderTopRightRadius: 8,
					visibility: 'visible',
					width: "inherit!important"
				}}
				>
					<Button onClick={toggleDrawer(!open)} sx={{width: "100%!important", height: drawerButtonHeight + "px!important"}} 
						className='DiscussionsDrawerButton'>
						<Stack 
							direction="row"
							justifyContent="center"
							alignItems="center" spacing={1}>
							
							<SearchIcon />
							<Typography variant='body1' sx={{textTransform:"none!important"}}>
								{t('topicDiscussionDrawerComponent.title')}
							</Typography>
							{open === false ? 
								<KeyboardArrowUpIcon /> 
								:
								<KeyboardArrowDownIcon/> 
							}
						</Stack>
					</Button>
				</div>
				<Search
					sx={{width: "calc(100% - 26px)!important", marginTop: "-36px!important"}}>		
					<SearchIconWrapper>
						<SearchIcon />
					</SearchIconWrapper>				
					<Box
						component="form"
						sx={{
							'& .MuiTextField-root': { m: 1, width: '100%!important' },
							display: 'flex', alignItems: 'start'
						}}
						autoComplete="off"
						noValidate
						onSubmit={handleSearch}
					>
						<StyledInputBase
							spellCheck={false}
							name="search"
							id="search"
							required
							style={{height: "34px", paddingLeft: windowWidth < MAX_MOBILE_WIDTH ? "42px" : "50px", width: "100%!important"}}
							placeholder={t("topicDiscussionDrawerComponent.searchBar")}
							inputProps={{ 'aria-label': 'search' }}/>
					</Box>
				</Search>
			</Box>
			<Box
			sx={{overflow:"auto"}}
			className='TopicDiscussionsDrawer'>
				
				{loadingDiscussions ? 
					<Box sx={{ display: 'flex',
						alignContent: 'center',
						alignItems:"center",
						padding: "12px",
						justifyContent:"center"}}>
						<CircularProgress />
					</Box> 
					:
					discussionsList.length > 0 ?
						<List sx={{ width: '100%'}}>
							{discussionsList.map((discussion) => {
								return (
									<TopicDiscussionDrawerItem discussion={discussion} searchedWords={searchedWords} />
								)
							})}
							{
								loadingMoreDiscussions === true ? 
									<Box sx={{ display: 'flex',
										alignContent: 'center', 
										height: "auto",
										padding: "12px",
										alignItems:"center",
										justifyContent:"center"}}>
										<CircularProgress size='26px'/>
									</Box>  : null
							}
							{!isLastPost ?
							
								<Button className='LoadMoreButton' fullWidth disabled={loadingDiscussions || loadingMoreDiscussions} onClick={loadMoreTopicDiscussions}>
									<Stack direction="row"
										justifyContent="center"
										alignItems="center">
										<Typography color="inherit" variant="body1">{t("topicDiscussionDrawerComponent.loadMoreDiscussions")}</Typography>
										<ArrowDropDownIcon />
									</Stack>
								</Button>
								:
								null
							}
							
						</List>
						:
						
						<Box sx={{ display: 'flex',
							alignContent: 'center', 
							padding: "12px",
							alignItems:"center",
							justifyContent:"center"}}>
							<Typography variant='caption'>{t('topicDiscussionDrawerComponent.noResults')}</Typography>
						</Box> 
					
				}
			</Box>
		</Drawer>
	</>
	)
}

