import React, {useState, useEffect} from 'react';
import './PostAdComponent.css'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Paper from '@mui/material/Paper';

export default function PostAdComponent(props) {
	
	return (
		null
		// <Card sx={{ flexGrow: 1, marginTop: '12px'}}>	
		// 	<CardContent sx={{paddingTop:0}}>
		// 		Feed Ad
		// 	</CardContent>
		// </Card>
	)

}