import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

export async function getCurrentUserFeedPosts(lastPostIdOffset, lastPostId) {	
	const getcurrentuserfeedpostsfromcloudspanner = httpsCallable(functions, 'getcurrentuserfeedpostsfromcloudspanner');
	return await getcurrentuserfeedpostsfromcloudspanner({ lastPostIdOffset: lastPostIdOffset, lastPostId: lastPostId })
	.then((result) => {
		const posts = result.data.posts;
		const data = posts !== null ? posts.map((row) => {
			return {
				post: {
					postId: row.PostId,
					first: row.First,
					post: row.Post,
					type: row.Type,
					sharedPostId: row.SharedPostId,
					sharedPostAuthorId: row.SharedPostAuthorId,
					timestamp: row.UnixMillisPostTimestamp,
					reportedPost: row.ReportedPost  
				},
				sharedPost: {
					first: row.SharedPostFirst,
					post: row.SharedPostPost,
					timestamp: row.SharedPostUnixMillisPostTimestamp,
					reportedSharedPost: row.ReportedSharedPost
				},
				user: {
					userId: row.UserId,
					username: row.Username,
					bio: row.Bio,
					avatarSmallUrl: row.AvatarSmallUrl,
					avatarMediumUrl: row.AvatarMediumUrl,
					avatarBigUrl: row.AvatarBigUrl,
					follows: row.Follows,
					followers: row.Followers, 
					points: row.Points,
					timestamp: row.UnixMillisUserTimestamp
				},
				sharedPostUser: {
					userId: row.SharedPostUserUserId,
					username: row.SharedPostUserUsername,
					bio: row.SharedPostUserBio,
					avatarSmallUrl: row.SharedPostUserAvatarSmallUrl,
					avatarMediumUrl: row.SharedPostUserAvatarMediumUrl,
					avatarBigUrl: row.SharedPostUserAvatarBigUrl,
					follows: row.SharedPostUserFollows,
					followers: row.SharedPostUserFollowers, 
					points: row.SharedPostUserPoints,
					timestamp: row.SharedPostUserUnixMillisUserTimestamp
				},
				postId: row.PostId,
				topic: {
					topicId: row.TopicId,
					title: row.Title,
					timestamp: row.UnixMillisTopicTimestamp,
					open: row.Open,
					opening: row.Opening,
					reportedTitle: row.ReportedTitle
				},
				sharedPostTopic: {
					topicId: row.SharedPostTopicsTopicId,
					title: row.SharedPostTopicsTitle,
					timestamp: row.SharedPostTopicsUnixMillisTopicTimestamp,
					reportedSharedTitle: row.ReportedSharedTitle
				},
				discussion: {
					discussionId: row.DiscussionId,
					timestamp: row.UnixMillisDiscussionTimestamp,
					followers: row.DiscussionFollowers,
					ratingsSum: row.RatingsSum,
					ratingsCounter: row.RatingsCounter,
					postsCounter: row.PostsCounter,
					lastPostId: row.LastPostId
				},
				sharedPostDiscussion: {
					discussionId: row.SharedPostDiscussionsDiscussionId,
					timestamp: row.SharedPostDiscussionsUnixMillisDiscussionTimestamp,
					followers: row.SharedPostDiscussionsDiscussionFollowers,
					ratingsSum: row.SharedPostDiscussionsRatingsSum,
					ratingsCounter: row.SharedPostDiscussionsRatingsCounter,
					postsCounter: row.SharedPostDiscussionsPostsCounter
				},
			}
		}) : []
		return {data: data, isLastPost: result.data.isLastPost}
	})
	.catch((error) => {
	});
}