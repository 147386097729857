import React, {useState, useEffect, useContext, useRef} from 'react';
import './CreateTopicComponent.css'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { createTopic, deleteUserTopicInvitesNotifications } from '../../firebaseFunctions/spannerFunctions/topicFunctions';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { MAX_EXTERNAL_LINKS_PER_POST, MAX_INVITES_PER_TOPIC, MAX_LOCAL_LINKS_PER_POST, MAX_POST_LENGTH, MAX_TOPIC_TITLE_LENGTH, MAX_USERNAMES_MENTIONED, MIN_POST_LENGTH, MIN_TOPIC_TITLE_LENGTH, NAVBAR_HEIGHT, NAVBAR_HEIGHT_ABSOLUTE } from '../../utils/Constants';
import { getUserListToInviteBySearchedUsername } from '../../firebaseFunctions/spannerFunctions/profileFunctions';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import PostTextFieldComponent from '../PostTextFieldComponent';

export default function CreateTopicComponent() {

	const { t } = useTranslation();
	const [open, setOpen] = useState(true)
	const [title, setTitle] = useState('');
	const [post, setPost] = useState('');
	const [error, setError] = useState("")
	const [invite, setInvite] = useState([])
	const [inviteOptions, setInviteOptions] = useState([])
	const [inviteLoading, setInviteLoading] = useState(false)
	const [inviteOpen, setInviteOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [showCreateTopicLoading, setShowCreateTopicLoading] = useState(false)
	const navigate = useNavigate()
	const [textFieldRows, setTextFieldRows] = useState(null)

	// variables to determine limits and show alert
	const [usernamesMentionedCounter, setUsernamesMentionedCounter] = useState(0);
	const [externalLinksCounter, setExternalLinksCounter] = useState(0);
	const [localLinksCounter, setLocalLinksCounter] = useState(0);

	const { setDocumentTitle } = useContext(DocumentTitleContext)

	const setTextFieldRowsValue = () => {
		const spareWindowHeight = window.innerHeight - NAVBAR_HEIGHT_ABSOLUTE - 8 * 24  // 2 * 24 each of the inputs and submit button
		const newTextFieldRows = Math.floor((spareWindowHeight - 2*24)/24) // substracting the estimated height 
		setTextFieldRows(newTextFieldRows)
	}
    const setWindowDimensions = () => {
		setTextFieldRowsValue()
    }	
    useEffect(() => {  
		// Anything in here is fired on component mount.	
	
		window.addEventListener('resize', setWindowDimensions);

		setTextFieldRowsValue()
		setDocumentTitle(t('documentTitles.createTopic'))
		return () => {
			removePostTextFieldRefListeners()
			window.removeEventListener('resize', setWindowDimensions)
		}
  
      }, [])
	const handleOpenChange = () => {
		setOpen(!open)
	}
	const handleInviteSelect = (event, item) => {
		
		if (item.length > invite.length) {
			// a new user has been invited
			if (item.length <= MAX_INVITES_PER_TOPIC) {
				const invitedUserIdsList = item.map((user) => {return user.userId})
				setInvite(invitedUserIdsList)
			} 
		} else {
			// a user has been uninvited
			if (invite.length > 0){
				const invitedUserIdsList = item.map((user) => {return user.userId})
				setInvite(invitedUserIdsList)
			} else {
				setInvite([])
			}
		}
	}
	const handleInviteInputChange = (event) => {
		// Timeout while loading users
		if (!inviteLoading && invite.length < MAX_INVITES_PER_TOPIC && event.type === "change") {
			setInviteLoading(true)
			setTimeout(() => {
				const usernameSearched = event.target.value
				getUserListToInviteBySearchedUsername(usernameSearched).then((res) => {
					setInviteOptions(res)
					setInviteLoading(false)
				})
			}, 1000);
		}
	}
	
	const inviteSetOpen = (value) => {
		if (invite.length < MAX_INVITES_PER_TOPIC) {
			setInviteOpen(value)

			// Empty options when autocomplete is closed.
			if (value === false){
				setInviteOptions([])
			}
		}		
	}

	const handleTitleChange = (event) => {
	  if (event.target.value.length <= MAX_TOPIC_TITLE_LENGTH) {
		setTitle(event.target.value);
	  }
	};
	
	const handlePostChange = (text, mentionsCounter, externalLinksCounter, localLinksCounter) => {
        if (text.length <= MAX_POST_LENGTH) {
			setUsernamesMentionedCounter(mentionsCounter)
			setLocalLinksCounter(externalLinksCounter)
			setExternalLinksCounter(localLinksCounter)
            setPost(text.toString());
        }
    };
	const [usernamesList, setUsernamesList] = useState([]);
	const handleUsernamesListChange = (usernames) => {
		setUsernamesList(usernames)
    };


	async function handleSubmit(event) {
		event.preventDefault()
		setLoading(true)
		const data = new FormData(event.currentTarget);
		if (usernamesMentionedCounter > MAX_USERNAMES_MENTIONED) {
			setError(t("createTopic.usernamesMentionedLimitError"))
		} else {
			if (externalLinksCounter > MAX_EXTERNAL_LINKS_PER_POST || localLinksCounter > MAX_LOCAL_LINKS_PER_POST) {			
				setError(t("createTopic.linksLimitError"))
			} else {
				if (data.get('title').length >= MIN_TOPIC_TITLE_LENGTH && post.length >= MIN_POST_LENGTH && invite.length <= MAX_INVITES_PER_TOPIC) {
					setShowCreateTopicLoading(true)
					const createTopicRes = await createTopic(data.get('title'), post, open, invite, usernamesList)
					if (createTopicRes.linksPerPostExceeded) {				
						setError(t("createTopic.linksLimitError"))
						setShowCreateTopicLoading(false)
					} else {
						if (createTopicRes.usernamesMentionsExceeded) {			
							setError(t("createTopic.usernamesMentionedLimitError"))
							setShowCreateTopicLoading(false)
						} else {		
							if (createTopicRes.webRiskThreat) {	
								setError(t("legals.postHasWebRiskThreat")) 
								setShowCreateTopicLoading(false)
							} else {
								if (createTopicRes.userBlockedDueToWebRiskThreat) {
									setError(t("legals.userHasBeenBlockedDueToWebRisk")) 
									setShowCreateTopicLoading(false)
								}else {
									await deleteUserTopicInvitesNotifications(createTopicRes.invite)
									navigate("/")
								}
							}
						}
					}
				} else {
					setError(t("createTopic.openingAndTitleMinLengthError"))
				}
			}
		}
		setLoading(false)
	}
	
    const postTextFieldRef = useRef();
    const removePostTextFieldRefListeners = () => {
        if (postTextFieldRef.current) {	
			// Remove PostTextField Listeners
			postTextFieldRef.current.removeListeners();
        }
    };
	return (	
		<>
		{showCreateTopicLoading ?    
		
		<Box sx={{ display: 'flex',
			alignContent: 'center', 
			height: "100vh",
			alignItems:"center",
			justifyContent:"center"}}>
			<CircularProgress />
		</Box> 
		: 

		<Grid item xs>
			<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, height: 'calc(100vh - ' + NAVBAR_HEIGHT + ')', marginTop: "0px!important"}}>	
				{error && <Alert sx={{height: "auto!important", marginTop: "8px"}} severity='error'>{error}</Alert>}
				<Grid container direction="row">
					<Grid item>
						<FormControlLabel style={{marginRight: 3}}
							control={<Checkbox value="open" color="primary" name="open" 
							checked={open} onChange={handleOpenChange}/>}
							label={<>{t('createTopic.open')}</>}
							/>
					</Grid>
					<Grid item>
						<Tooltip title={<>{t('createTopic.openTooltip')}</>}>
							<IconButton style={{margin:0, padding: 0, paddingTop: 10}}>
								<InfoIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
				<Autocomplete
					id="multiple-limit-tags"
					multiple
					limitTags={3}
					fullWidth
					open={inviteOpen}
					onOpen={() => {
						inviteSetOpen(true);
					}}
					onClose={() => {
						inviteSetOpen(false);
					}}
					noOptionsText={<>{t('createTopic.invite.noOptions')}</>}
					loadingText={<>{t('createTopic.invite.loadingOptions')}</>}
					isOptionEqualToValue={(option, value) => option.username === value.username}
					getOptionLabel={(option) => option.username}
					renderOption={(props, option) => (
						<li {...props}>
							<Chip avatar={option.avatarSmallUrl !== null && option.avatarSmallUrl !== "" ? <Avatar src={option.avatar}/> : <Avatar sx={{ color:"white!important" }}>{option.username[0].toUpperCase()}</Avatar>} label={option.username} />
						</li>
					)}
					onChange={(event, item) => handleInviteSelect(event, item)}
					onInputChange={(event) => handleInviteInputChange(event)}
					options={inviteOptions}
					loading={inviteLoading}
					renderInput={(params) => (
						<TextField
						spellCheck={false}
						className='MuiBodyTextField'
						{...params}
						label={<>{t('createTopic.invite.invite')}</>}
						InputProps={{
							...params.InputProps,
							endAdornment: (
							<React.Fragment>
								{inviteLoading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
							),
						}}
						/>
					)}
					/>
				<TextField
					className='MuiBodyTextField'
					
					multiline 
					rows={2} 
					
					margin="normal"
					required
					fullWidth
					id="outlined-required"
					label={<>{t('createTopic.title')}</>}
					name="title"
					value={title}
					onChange={handleTitleChange}
					spellCheck={false}
					/>
					
				{textFieldRows && 
				
					<div style={{backgroundColor: "none!important"}}>
						<PostTextFieldComponent ref={postTextFieldRef} onTextChange={handlePostChange} onUsernamesListChange={handleUsernamesListChange} type="opening"/>
						<LinearProgress variant="determinate" value={(post.length / MAX_POST_LENGTH) * 100} sx={{
							borderRadius: '0px 0px 4px 4px'}}/>
					</div>
				}

				<Button
					type="submit"
					variant="contained"
					sx={{ mt: "15px!important", mb: 2, float: "right", paddingRight: "12px!important", paddingLeft: "12px!important" }}
					disabled={loading}
					>
					{<>{t('createTopic.create')}</>}
				</Button>
			</Box>
		</Grid>
		}
		</>	
		
	);
}
