import React, {useState, useEffect, useRef} from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import { AVATAR_SMALL_SIZE, MAX_MOBILE_WIDTH, BUTTON_REPLY_DRAWER_STACK_HEIGHT_ABSOLUTE, MAX_POST_LENGTH, NAVBAR_HEIGHT_ABSOLUTE, REPLY_DRAWER_FIXED_POST_HEADER_HEIGHT_ABSOLUTE, MAX_USERNAMES_MENTIONED, MAX_EXTERNAL_LINKS_PER_POST, MAX_LOCAL_LINKS_PER_POST } from '../../utils/Constants';
import { FeedDate } from '../../utils/Dates';
import AvatarPreviewComponent from '../AvatarPreviewComponent';
import Drawer from '@mui/material/Drawer';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { replyTopicOpeningPost, deleteUserTopicRepliesNotifications, replyDiscussionPost } from '../../firebaseFunctions/spannerFunctions/topicFunctions';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { auth } from "../../firebase";
import ReportedTopicTitleComponent from '../ReportedTopicTitleComponent';

import PostTextFieldComponent from '../PostTextFieldComponent';
import PostWithLinksComponent from '../PostWithLinksComponent';
import { getDataForPostLocalLinks } from '../../firebaseFunctions/spannerFunctions/postLocalLinksFunctions';


export default function ReplyDrawerComponent(props) {
	
	const { t } = useTranslation();
	
	const navigate = useNavigate()	
	
	const [loading, setLoading] = useState(false)
	const [topicId, setTopicId] = useState(props.topicId)
	const [discussionId, setDiscussionId] = useState(props.discussionId)
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    
	// variables to determine limits and show alert
	const [usernamesMentionedCounter, setUsernamesMentionedCounter] = useState(0);
	const [externalLinksCounter, setExternalLinksCounter] = useState(0);
	const [localLinksCounter, setLocalLinksCounter] = useState(0);
	
	const [replyText, setReplyText] = useState('');
	async function handleSubmit(event) {
		event.preventDefault()
        
        if (props.postType === "reply" && typeof topicId === "string" && typeof discussionId === "string") {
            setLoading(true)
            const data = new FormData(event.currentTarget);
            
            if (usernamesMentionedCounter > MAX_USERNAMES_MENTIONED) {
                setError(t("replyDrawerComponent.usernamesMentionedLimitError"))
                openErrorAlert()
            } else {
                if (externalLinksCounter > MAX_EXTERNAL_LINKS_PER_POST || localLinksCounter > MAX_LOCAL_LINKS_PER_POST) {			
                    setError(t("replyDrawerComponent.linksLimitError"))
                    openErrorAlert()
                } else {
                    const replyResponse = await replyDiscussionPost(topicId, discussionId, replyText.toString(), usernamesList)
                    if (replyResponse.linksPerPostExceeded) {				
						setError(t("replyDrawerComponent.linksLimitError"))
                        openErrorAlert()
					} else {
						if (replyResponse.usernamesMentionsExceeded) {			
							setError(t("replyDrawerComponent.usernamesMentionedLimitError"))
							openErrorAlert()
						} else {		
							if (replyResponse.webRiskThreat) {	
								setError(t("legals.postHasWebRiskThreat")) 
								openErrorAlert()
							} else {
								if (replyResponse.userBlockedDueToWebRiskThreat) {
									setError(t("legals.userHasBeenBlockedDueToWebRisk")) 
									openErrorAlert()
								}else {
                                    props.handleAuthorOfLastPost(auth.currentUser.uid)
                                    props.handleCloseTextDialog()	
                                    navigate("/")
                                }
                            }
                        }
                    }
                }
            }
            setLoading(false)

        }else if (props.postType === "opening" && typeof topicId === "string" && discussionId === null) {
            
            setLoading(true)
            const data = new FormData(event.currentTarget);

            if (usernamesMentionedCounter > MAX_USERNAMES_MENTIONED) {
                setError(t("replyDrawerComponent.usernamesMentionedLimitError"))
                openErrorAlert()
            } else {
                if (externalLinksCounter > MAX_EXTERNAL_LINKS_PER_POST || localLinksCounter > MAX_LOCAL_LINKS_PER_POST) {			
                    setError(t("replyDrawerComponent.linksLimitError"))
                    openErrorAlert()
                } else {
                    const replyResponse = await replyTopicOpeningPost(topicId, replyText.toString(), usernamesList)
					if (replyResponse.linksPerPostExceeded) {				
						setError(t("replyDrawerComponent.linksLimitError"))
                        openErrorAlert()
					} else {
						if (replyResponse.usernamesMentionsExceeded) {			
							setError(t("replyDrawerComponent.usernamesMentionedLimitError"))
							openErrorAlert()
						} else {		
							if (replyResponse.webRiskThreat) {	
								setError(t("legals.postHasWebRiskThreat")) 
								openErrorAlert()
							} else {
								if (replyResponse.userBlockedDueToWebRiskThreat) {
									setError(t("legals.userHasBeenBlockedDueToWebRisk")) 
									openErrorAlert()
								}else {
                                    deleteUserTopicRepliesNotifications(replyResponse.topicCreatorId)
                                    props.handleCloseTextDialog()	
                                    navigate("/")
                                }
                            }
                        }
                    }
                }
            }
            setLoading(false)
        }
	}
    
	const [usernamesList, setUsernamesList] = useState([]);
	const handleUsernamesListChange = (usernames) => {
		setUsernamesList(usernames)
    };
	const handleReplyTextChange = (text, mentionsCounter, externalLinksCounter, localLinksCounter) => {
        if (text.length <= MAX_POST_LENGTH) {
			setUsernamesMentionedCounter(mentionsCounter)
			setLocalLinksCounter(externalLinksCounter)
			setExternalLinksCounter(localLinksCounter)
            setReplyText(text.toString());
        }
    };
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth)
    }	
	useEffect(() => {  
		// Anything in here is fired on component mount.	
		window.addEventListener('resize', setWindowDimensions);
		return () => {
			window.removeEventListener('resize', setWindowDimensions)
		}

    }, [])

    const postTextFieldRef = useRef();
    const removePostTextFieldRefListeners = () => {
        if (postTextFieldRef.current) {
          // Remove PostTextField Listeners
          postTextFieldRef.current.removeListeners();
        }
    };

	const [error, setError] = useState("")
	const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
	const openErrorAlert = () => {
		setErrorAlertOpen(true);
	};
	const closeErrorAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setErrorAlertOpen(false);
	};


	const [postLocalLinksDisplayData, setPostLocalLinksDisplayData] = useState([]);	
    useEffect(() => {
		
		getDataForPostLocalLinks(props.lastPost.post).then((dataForLinks) => {
			setPostLocalLinksDisplayData(dataForLinks)
		})
		return () => {
		}
	}, [])
	return (
		<>	
        <Drawer
            anchor='bottom'
            disableEnforceFocus 
            open={props.open} 
            className='ReplyDrawer'
            onClose={
                (
                    removePostTextFieldRefListeners()
                )
            }
            sx={{
                width: "100vw",
                height: `calc(100vh - ${NAVBAR_HEIGHT_ABSOLUTE}px)`,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: "100vw",
                    height:  `calc(100vh - ${NAVBAR_HEIGHT_ABSOLUTE}px)`, boxSizing: 'border-box' }
            }}
            >
            {error &&
                <Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>	
            }
            <Box component="form" 
                onSubmit={
                    (
                        handleSubmit
                    )
                } 
                noValidate sx={{ mt: "6px" }}>	
                <Stack direction="row">
                    {windowWidth < MAX_MOBILE_WIDTH ? 
                    null :
                    <Paper sx={{width: "33%", marginRight:"1%", marginLeft: "1%", padding: "1%", paddingBottom: "24px!important", height:`calc(100vh - ${NAVBAR_HEIGHT_ABSOLUTE + BUTTON_REPLY_DRAWER_STACK_HEIGHT_ABSOLUTE}px)!important`}} className='CustomCard3' >
                        <Stack direction="column" spacing={2}>
                            <Stack sx={{height: REPLY_DRAWER_FIXED_POST_HEADER_HEIGHT_ABSOLUTE + "px"}}>
                                {props.reportedTitle === true ?
                                    <Typography variant='h1' sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "1",
                                        WebkitBoxOrient: "vertical"}}>
                                            <ReportedTopicTitleComponent />
                                        </Typography>
                                    :
                                    <Tooltip title={props.topicTitle}>	
                                        <Typography variant='h1' sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "1",
                                            WebkitBoxOrient: "vertical"}}>{props.topicTitle}</Typography>
                                    </Tooltip>
                                }
                                {
                                    <Stack direction="row" spacing={1} sx={{marginTop: "6px"}} justifyContent="flex-start" alignItems="center">
                                        {
                                            props.user.avatarSmallUrl !== "" ?  														
                                                <AvatarPreviewComponent avatar={props.user.avatarSmallUrl}
                                                    avatarBig={props.user.avatarBigUrl}
                                                    size={AVATAR_SMALL_SIZE + "px"}/>
                                            :
                                                <Avatar sx={{ width: AVATAR_SMALL_SIZE, color: "white", height:AVATAR_SMALL_SIZE }} alt="avatar">
                                                    {props.user.username[0].toUpperCase()}
                                                </Avatar>	
                                        }
                                        <Stack direction="column">																				
                                            <Typography variant='body1'sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: "1",
                                                WebkitBoxOrient: "vertical"}}>{props.user.username}</Typography>
                                            <Typography variant='caption'sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: "1",
                                                WebkitBoxOrient: "vertical"}}>{FeedDate(props.lastPost.timestamp)}</Typography>
                                        </Stack>
                                    </Stack>	
                                }
                            </Stack>
                            <Stack sx={{overflow: "auto", height:`calc(100vh - ${NAVBAR_HEIGHT_ABSOLUTE + REPLY_DRAWER_FIXED_POST_HEADER_HEIGHT_ABSOLUTE + BUTTON_REPLY_DRAWER_STACK_HEIGHT_ABSOLUTE}px)!important`}}>
                                {props.reportedPost !== true ?
        							<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
										<PostWithLinksComponent showReferencedPosts={true} post={props.lastPost.post} localLinksData={postLocalLinksDisplayData}/>
                                    </Typography>
                                :
                                    <Typography variant='body1' sx={{fontStyle: "italic!important"}}>
								        {t('reportedPostComponent.description')}
                                    </Typography>    
                                }
                            </Stack>
                        </Stack>
                    </Paper>
                    }
                    <Stack direction="column" sx={{width: windowWidth < MAX_MOBILE_WIDTH ? "100%" : "64%", marginRight: windowWidth < MAX_MOBILE_WIDTH ? "0%" : "1%"}}>
						<PostTextFieldComponent ref={postTextFieldRef} onTextChange={handleReplyTextChange} onUsernamesListChange={handleUsernamesListChange} type="reply"/>
                        <LinearProgress variant="determinate" value={(replyText.length / MAX_POST_LENGTH) * 100} sx={{
                            borderRadius: '0px 0px 4px 4px'}}/>
                        <Stack direction="row" alignSelf="end">
                            <Button 
                                disabled={loading}
                                onClick={
                                    props.handleCloseTextDialog()
                                }
                                >{t("cancel")}</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 3, mb: 2, marginTop: "12px", float:'right'}}
                                disabled={loading || replyText.length >= MAX_POST_LENGTH || replyText.length === 0}
                                >
                                {t("reply")}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Box> 
        </Drawer>
		</>
	)
}

