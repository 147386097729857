import { auth, functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { convertBackendResponseKeysToFrontEndFormat } from "../../utils/ConvertObjectToFirstCharLowerCase";

export async function followUser(userId){
	if (userId !== auth.currentUser.uid) {
					
		const copyfollowedusertocloudspanner = httpsCallable(functions, 'copyfollowedusertocloudspanner');
		return await copyfollowedusertocloudspanner({follow: userId}).then((res) => {
			const data = res.data;
			if (data.error === true && data.followsLimit === true) {
				return {res: false, followingLimit: true, followedUser: null}
			} else {
				if (data.success === true) {
					return {res: true, followingLimit: false, followedUser: data.followedUser}
				} else {
					return {res: false, followingLimit: false, followedUser: null}
				}
			}
		})
	}
}
export async function deleteUserFollowedNotifications(followedUser){
	const deleteuselessfollowedusersnotificationscount = httpsCallable(functions, 'deleteuselessfollowedusersnotificationscount');
	return await deleteuselessfollowedusersnotificationscount({ followedUser: followedUser })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function unfollowUser(userId){
	if (userId !== auth.currentUser.uid) {
		const deleteunfolloweduserfromcloudspanner = httpsCallable(functions, 'deleteunfolloweduserfromcloudspanner');
		return await deleteunfolloweduserfromcloudspanner({unfollow: userId}).then((res) => {
			return res.data.success // true if user has been unfollowed
		})
	}
}

export async function isUserFollowed(userId){
	if (userId && auth.currentUser) {
		const isuserfollowedincloudspanner = httpsCallable(functions, 'isuserfollowedincloudspanner');
		// Back-end does not allow 'Following' to be set as column name, therefore Follows/follows in the backend refers to Following/following in the front-end
		return await isuserfollowedincloudspanner({follows: userId}).then((res) => {
			return res.data // true if followed, false if not, null if error
		})
	}
}

export async function getFollowsFromCloudSpanner(userId, lastUsername, usernameSearched){

	// Back-end does not allow 'Following' to be set as column name, therefore Follows/follows in the backend refers to Following/following in the front-end
	const getfollowsfromcloudspanner = httpsCallable(functions, 'getfollowsfromcloudspanner');
	return await getfollowsfromcloudspanner({ userId: userId, lastUsername:lastUsername, usernameSearched: usernameSearched })
		.then((result) => {
			const data = result.data.data;

			const dataInLocalFormat = data !== null ? data.map((row) => { return convertBackendResponseKeysToFrontEndFormat(row)}) : []
			return {data: dataInLocalFormat, isLastUser: result.data.isLastUser}
		})
		.catch((error) => {
		});
}
export async function getFollowersFromCloudSpanner(userId, lastUsername, usernameSearched){
	const getfollowersfromcloudspanner = httpsCallable(functions, 'getfollowersfromcloudspanner');
	return await getfollowersfromcloudspanner({ userId: userId, lastUsername:lastUsername, usernameSearched: usernameSearched })
		.then((result) => {
			const data = result.data.data;
			const dataInLocalFormat = data !== null ? data.map((row) => { return convertBackendResponseKeysToFrontEndFormat(row)}) : []
			return {data: dataInLocalFormat, isLastUser: result.data.isLastUser}
		})
		.catch((error) => {
		});
}