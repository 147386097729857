import React, {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import './CreateUserProfileErrorComponent.css'
import { USERNAME_CHARACTER_MAX, USERNAME_CHARACTER_MIN, USERNAME_REGEX } from '../../utils/Constants';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext'
import Alert from '@mui/material/Alert';
import { createUser } from '../../firebaseFunctions/spannerFunctions/profileFunctions';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';

export default function CreateUserProfileErrorComponent(props) {

	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const { t } = useTranslation();
	const { setCurrentProfileForContext } = useAuth()
	const { setDocumentTitle } = useContext(DocumentTitleContext)

	useEffect(() => {  
		// Anything in here is fired on component mount.	

		setDocumentTitle(t('documentTitles.userCreationError'))
	}, [])
	
	async function handleSubmit(event) {
		event.preventDefault()
		const data = new FormData(event.currentTarget);
		if ((data.get('username') === "")){
			return setError(t("signUpComponent.allFieldsRequired"))
		}else {
            const usernameValid = USERNAME_REGEX.test(data.get('username'));
            if (usernameValid) {
                if (data.get('username').length < USERNAME_CHARACTER_MIN || data.get('username').length > USERNAME_CHARACTER_MAX) {
                    return setError(t("signUpComponent.usernameCharacterMinimum"))
                } else {
                    try {
                        setError("")
                        setLoading(true)

                        // Create user 
                        createUser(data.get('username')).then((userCreated) => {
                            if (!userCreated.usernameTaken) {
                                if (userCreated.success) {
                                    // user has been created, navigate to home
                                    setCurrentProfileForContext(true)
                                    // navigate("/")
                                } else {
                                    if (userCreated.error) {
                                        setError(t("genericErrorMessage"))
                                    }
                                }
                            } else {
                                // user was not created because:
                                setError(t("signUpComponent.usernameAlreadyExists")) 
                            }
                            setLoading(false)
                        })
                    } catch {
                        setError(t("signUpComponent.accountCreationFailed"))
                    } 
                }
            } else {
                setError(t("signUpComponent.usernameCharacterType"))
            }
        }
	}
	return (
        <>				
            <Box
                sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: "20px"
            }}
            >
                {error && <Alert sx={{height: "auto!important", width: "250px!important", marginTop: "8px"}} severity='error'>{error}</Alert>}
                            
                <Typography style={{ width: '250px'}} display='block' variant="h6">
                    {t("createUserProfileErrorComponent.choseUsernameMessage")}
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sx={{ marginTop: "16px"}}>
                            <TextField
                                spellCheck={false}
                                margin="dense"
                                autoComplete="user-name"
                                name="username"
                                required
                                fullWidth
                                id="username"
                                label={t("createUserProfileErrorComponent.username")}
                                autoFocus
                                size="small"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ marginTop: "28px!important", marginLeft: "0px!important", marginRight:"0px!important", borderRadius: "22px" }}
                        disabled={loading}
                    >
                        {t("createUserProfileErrorComponent.createProfile")}
                    </Button>
                </Box>
            </Box>
        </>
	);
}
