import {useState, useContext, useEffect} from 'react';
import { useParams } from "react-router-dom";
import './UserTopicHistoryPage.css'
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';

import { useTranslation } from 'react-i18next';
import {NavBar, SidebarComponent, RightSidebarComponent, UserTopicHistoryComponent, CenterScrollableStyledComponent, ErrorNotFoundComponent} from '../../components'
import Grid from '@mui/material/Grid';
import {  NAVBAR_HEIGHT } from '../../utils/Constants';
import { getUserIdByUsername } from '../../firebaseFunctions/spannerFunctions/profileFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export default function UserTopicHistoryPage() {
	const params = useParams()
	
	
	const { t } = useTranslation();
	const [userId, setUserId] = useState(null)
	const [loading, setLoading] = useState(true)

	const [firstUsername, setFirstUsername] = useState(params.username)
	const [username, setUsername] = useState(params.username)

	const { setDocumentTitle } = useContext(DocumentTitleContext)
	useEffect(() => {  
		// set username at mount
		setFirstUsername(params.username)
		setUsername(params.username)
		getUserIdByUsername(username).then((userId) => {
			if (userId !== null && userId !== false && userId) {
				setDocumentTitle(username.toString() + " - " + t('documentTitles.intergraphy'))
			} else {
				setDocumentTitle(t('documentTitles.userDoesNotExist'))
			}

			setUserId(userId)
			setLoading(false)
		})
	}, [])


	useEffect(() => {
		if (firstUsername !== params.username) {
			window.location.reload()
		}
	}, [params.username]);

	return (
		<Grid 
			container
			direction="column"
			justifyContent="center"
			alignItems="center">
			<Grid container item xs={12}>
				<NavBar/>
			</Grid>
			<Grid container 
				justifyContent="center"
				alignItems="center" 
				sx={{marginTop: NAVBAR_HEIGHT}}>
				<Grid item xs={12}>
					<Grid xs={12} container direction="row">
						<SidebarComponent/>
						{
							userId !== null && userId !== false && userId ?
								<CenterScrollableStyledComponent >
									<UserTopicHistoryComponent userId={userId}/>
								</CenterScrollableStyledComponent>	
							: userId === null && !loading ?
								<ErrorNotFoundComponent type="user"/>
								: 
								<CenterScrollableStyledComponent >
									<Box sx={{ display: 'flex',
										alignContent: 'center', 
										height: "100vh",
										alignItems:"center",
										justifyContent:"center"}}>
										<CircularProgress />
									</Box> 
								</CenterScrollableStyledComponent>	
						}
						<RightSidebarComponent/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}