import React, {useState} from 'react';
import './DeleteLastDiscussionPostComponent.css'
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { deleteDiscussionLastPostFromCloudSpanner } from '../../firebaseFunctions/spannerFunctions/deleteFunctions';

export default function DeleteLastDiscussionPostComponent(props) {
	
	const { t } = useTranslation();

	const navigate = useNavigate()
	
	const { currentUser } = useAuth()
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);


	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	async function submitDeleteDiscussionLastPost(e) {
		e.preventDefault();
		setLoading(true)
		if (props.topicId && props.discussionId && props.postId) {
			deleteDiscussionLastPostFromCloudSpanner(props.topicId, props.discussionId, props.postId).then((res) => {
				if (res.error === true) {
					openErrorAlert()
					handleCloseModal()
					setLoading(false)
				} else {
					navigate(0)
					handleCloseModal()
					setLoading(false)
				}
			})
		}
	}
	
	const openErrorAlert = () => {
		setErrorAlertOpen(true);
	};
	const closeErrorAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setErrorAlertOpen(false);
	};
	return (
		<>	
			
			<Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={closeErrorAlert}>
				<Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
					{t("deleteLastDiscussionPostComponent.error")}
				</Alert>
			</Snackbar>		

			{/* Delete Last Post Dialog */}
			{
				currentUser.uid === props.userId ? 
				<>
					<Dialog
						open={openModal}
						onClose={handleCloseModal}
						sx={{padding: "12px"}}
						PaperProps={{
						component: 'form',
						onSubmit: (event) => submitDeleteDiscussionLastPost(event),
						}}
					>
						
						<DialogTitle sx={{ m: 0, p: 2 }}>
							<Typography	variant='h2' sx={{paddingBottom: "12px"}}>
								{t('deleteLastDiscussionPostComponent.title')}
							</Typography>
						</DialogTitle>
						<DialogContent>
							<Typography	variant='body1' sx={{paddingBottom: "12px"}}>
								{t('deleteLastDiscussionPostComponent.description')}
							</Typography>
						</DialogContent>
						{	loading ?
							<Box sx={{ width: '100%' }}>
								<LinearProgress />
							</Box> : null
						}
						<DialogActions>
							<Button variant="contained" color="error" disabled={loading} onClick={handleCloseModal}>{t('cancel')}</Button>
							<Button variant="contained" type="submit" disabled={loading}>{t('submit')}</Button>
						</DialogActions>
					</Dialog>
					
					<Box sx={{ display: 'flex', float: "right", alignItems: 'center', textAlign: 'center' }}>
						<Tooltip title={t('deleteLastDiscussionPostComponent.title')}>
							<IconButton
							disabled={loading}
							onClick={handleOpenModal}
							size="small"
							>
								<DeleteIcon fontSize='small'/>
							</IconButton>
						</Tooltip>
					</Box>
				</>
				:
				null
			}

		</>
	)
}