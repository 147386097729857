import React from 'react';
import './AppUsersQuantityLimitComponent.css'
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';

export default function AppUsersQuantityLimitComponent() {
	
	const { t } = useTranslation();

	return (
		<>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={2}
				sx={{marginLeft: "25%", marginTop: "10%", marginRight: "25%", marginBottom: "10%"}}
			>
				<Typography variant="h2" sx={{textAlign: "center"}}>	
					{t("appUsersQuantityLimit.body")}
				</Typography>
			</Stack>
		</>
	)

}