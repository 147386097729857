import {Component} from 'react';
import './VerifyUserPage.css'

import {NavBar, VerifyUserComponent} from '../../components'
import Grid from '@mui/material/Grid';

import { MIN_CONTENT_WIDTH, NAVBAR_HEIGHT } from '../../utils/Constants';


class VerifyUserPage extends Component {
	render() {
		return (
			<Grid 
				container
				direction="column"
				justifyContent="center"
				alignItems="center" spacing={3}>
				<Grid container item xs={12}>
					<NavBar/>
				</Grid>
				<Grid container 
					justifyContent="center"
  					alignItems="center"
					sx={{marginTop: NAVBAR_HEIGHT}}>
					<Grid container item direction="column"
						justifyContent="center"
						alignItems="center" xs={6} sx={{minWidth: MIN_CONTENT_WIDTH}}>
						<VerifyUserComponent/>
					</Grid>
				</Grid>
			</Grid>
		)
	}
  }


export default VerifyUserPage;