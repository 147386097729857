import React, {useState} from 'react';
import './AvatarPreviewComponent.css'
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import Avatar from '@mui/material/Avatar';
import { AVATAR_MAX_SIZE } from '../../utils/Constants';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
  };

export default function AvatarPreviewComponent(props) {
	
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		if (props.avatar) {
			setOpen(true);
		}
	}
	const handleClose = () => setOpen(false);

	return (
		<>
			{props.avatar ? 
			
				<IconButton onClick={handleOpen} aria-label="preview avatar" component="label">				
					<Avatar src={props.avatar} sx={{ width: props.size, height:props.size, color:"white" }} aria-label="avatar"/>
				</IconButton> 
				:
				<Avatar  sx={{ width: props.size, height:props.size, color:"white" }} aria-label="avatar">
					{props.user.username[0].toUpperCase()}
				</Avatar>
			}     
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
			
				<Box sx={style}>
					<Avatar src={props.avatarBig} sx={{ width: AVATAR_MAX_SIZE, height:AVATAR_MAX_SIZE }} aria-label="avatar full">
						{props.avatar ? "" : props.user.username[0].toUpperCase()}
					</Avatar>
				</Box>
			</Modal>
		</>
	)

}