import React, {useState, useEffect, useContext} from 'react';
import { useParams } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { getTopicData, getInvitedUsersIdsOfTopic, userAlreadyDiscussedThisTopic } from '../../firebaseFunctions/spannerFunctions/topicFunctions';
import { AVATAR_SMALL_SIZE, REPLY_BUTTON__STICKY_COLLAPSIBLE_INFO_BAR_HEIGHT } from '../../utils/Constants';
import { FeedDate } from '../../utils/Dates';
import { useNavigate } from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import ReplyIcon from '@mui/icons-material/Reply';

import Button from '@mui/material/Button';

import { auth } from "../../firebase";
import { useTranslation } from 'react-i18next';
import StickyCollapsibleInfoBarComponent from '../StickyCollapsibleInfoBarComponent';
import { DeleteTopicComponent, ErrorNotFoundComponent, PostWithLinksComponent, ReplyDrawerComponent, ReportComponent, ReportedPostContentComponent, ReportedTopicTitleComponent, TopicDiscussionsDrawerComponent } from '..';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import { DocumentDescriptionContext } from '../../contexts/DocumentDescriptionContext';
import Stack from '@mui/material/Stack';
import { getDataForPostLocalLinks } from '../../firebaseFunctions/spannerFunctions/postLocalLinksFunctions';


function TopicPostListItem(props) {	
	const [postLocalLinksDisplayData, setPostLocalLinksDisplayData] = useState([]);	

	useEffect(() => {
		getDataForPostLocalLinks(props.post.post.post).then((dataForLinks) => {
			setPostLocalLinksDisplayData(dataForLinks)
		})

		return () => {
		}
	}, [])
	return(
					
		<ListItem sx={{padding:0, marginTop: "12px"}} key={props.post.id}>
			{props.post.topic.reportedOpening === true ?
				<ReportedPostContentComponent user={props.post.user}/>
			:
				<Card sx={{ flexGrow: 1, padding:0}}  className='CustomCard1'>	
					<CardHeader
						avatar={
							(	
								props.post.user.avatarSmallUrl !== "" ?  
									<Avatar sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE }} alt="avatar" src={props.post.user.avatarSmallUrl}/>
								:
									<Avatar sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE ,color:"white" }} alt="avatar">
										{props.post.user.username[0].toUpperCase()}
									</Avatar>
							) 
						}
						action={null}
						title={
							
							<Stack direction="row" 
							justifyContent="space-between"
							alignItems="flex-start">
								<Stack direction="column">
									<Typography variant="body1">
										<Link component={RouterLink} to={"/" + props.post.user.username} color="inherit" underline="none">
											{props.post.user.username}
										</Link>	
									</Typography>
									<Typography variant="caption">
										{FeedDate(props.post.post.timestamp)}
									</Typography>
								</Stack>
								{auth.currentUser &&
									// type set to openingPost to not show title report in options.
									<ReportComponent userId={props.post.user.userId} topicId={props.post.topic.topicId} type="openingPost" reportedTitle={null} reportedPost={props.post.topic.reportedOpening} postId={props.post.post.postId} /> 
								}
							</Stack>
						}
					/>
					<CardContent>
						<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
							<PostWithLinksComponent showReferencedPosts={true} post={props.post.post.post} localLinksData={postLocalLinksDisplayData}/>
						</Typography>
					</CardContent>
					<Divider  sx={{ flexGrow: 1 }}/>
				</Card>
			}
		</ListItem>
	)
}

export default function TopicPostsComponent(props) {
	const { t } = useTranslation();
	
	const params = useParams();
	const { setDocumentTitle } = useContext(DocumentTitleContext)
	const { setDocumentDescription } = useContext(DocumentDescriptionContext)
	
	const [postList, setPostList] = useState([])
	const [topic, setTopic] = useState([])
	const [invitedUsersIds, setInvitedUsersIds] = useState([])
	const [topicId, setTopicId] = useState(params.topicId)
	
	const [loading, setLoading] = useState(false)
	const [loadingStart, setLoadingStart] = useState(false)
	const navigate = useNavigate()	
	
	const [topicDoesNotExist, setTopicDoesNotExist] = useState(null);
	const [textDialog, setTextDialog] = React.useState(false);

	const handleCloseTextDialog = () => {
        setTextDialog(false);
	};
	const handleOpenTextDialog = () => {		
		setLoading(true)
		userAlreadyDiscussedThisTopic(topicId).then((userAlreadyDiscussedThis) => {
			if (userAlreadyDiscussedThis !== null) {		
				// if not null returns the discussion id of the already discussed topic
				navigate("/topic/"+topicId.toString()+"/discussion/"+userAlreadyDiscussedThis)
			} else {
				setTextDialog(true);
			}
			setLoading(false)
		})
	};

	const handleNavigateToVerifyEmail = () => {
		navigate("/page/verifyuser")
	}

	const [scrollY, setScrollY] = useState(0);

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};
	useEffect(() => {
		// runs at component did mount
		// if the type is opening, get topic first post
		setLoadingStart(true)
		getTopicData(topicId).then((topicData) => {
			if (topicData !== null){
				setDocumentTitle(topicData.topic.title)
				setDocumentDescription(topicData.post.post)
				setTopicDoesNotExist(false)
				setPostList([topicData])
				setTopic(topicData)
				getInvitedUsersIdsOfTopic(topicId).then((invitedUsersIds) => {
					setInvitedUsersIds(invitedUsersIds)
					setLoadingStart(false)
				})
			}else{
				setDocumentTitle(t('documentTitles.topicDoesNotExist'))
				setTopicDoesNotExist(true)
				setLoadingStart(false)
			}
		})



		window.addEventListener('scroll', handleScroll);
		return () => {
		  window.removeEventListener('scroll', handleScroll);
		}
	}, [])
	
	
	return (		
	<>
		{loadingStart ?    
		
		<Box sx={{ display: 'flex',
			alignContent: 'center', 
			height: "100vh",
			alignItems:"center",
			justifyContent:"center"}}>
			<CircularProgress />
		</Box> 
		: <>
			{
				topicDoesNotExist === true ?
					<ErrorNotFoundComponent type="topic"/>
					 	:
						 <Grid item xs>
					
						 { postList.length !== 0 &&
						 <Box>  		
							 <Grid
								 container
								 direction="row"
								 justifyContent="center"
								 >
								 <Grid item xs={12}>
									<StickyCollapsibleInfoBarComponent
										scrollY = {scrollY}
										replyButton={
											topic && auth.currentUser &&	
												(((invitedUsersIds.includes(auth.currentUser.uid) || topic.topic.open === true) && topic.user.userId !== auth.currentUser.uid) ? 																		 
													<>
														<Button className='ReplyButtonStickyCollapsibleInfoBar' sx= {{margin: "0px!important", width: "100%", height: REPLY_BUTTON__STICKY_COLLAPSIBLE_INFO_BAR_HEIGHT + "px"}} variant='contained'
															disabled={loading}
															onClick={
																(	auth.currentUser.emailVerified ?
																		handleOpenTextDialog
																	:
																		handleNavigateToVerifyEmail
																)
															}
															startIcon={<ReplyIcon />}>
															{t("reply")}
														</Button>
														<ReplyDrawerComponent open={textDialog} handleCloseTextDialog={() => handleCloseTextDialog} handleAuthorOfLastPost={null}											
														postType="opening" topicId={topicId} discussionId={null}  topicTitle={topic.topic.title} user={topic.user} lastPost={postList[postList.length - 1].post} reportedTitle={topic.topic.reportedTitle} reportedPost={topic.topic.reportedOpening}/>
													</>
												:
												null)				
										}
										collapsedComponent={
											<>
											
												<Grid direction="row" container 
													justifyContent="space-between"
													alignItems="center">
													<Grid item xs={9} container direction="column" 
														justifyContent="flex-start"
														alignItems="flex-start">	
														<Grid item 
															sx={{marginLeft: "12px", width: "inherit!important"}}>
															{topic.topic.reportedTitle === true ?
																<Typography noWrap style={{ textOverflow: "ellipsis!important" }} display='block' variant='h3'>
																	<ReportedTopicTitleComponent />
																</Typography>
																:
																<Tooltip title={topic.topic.title}>	
																	<Typography noWrap style={{ textOverflow: "ellipsis!important" }} display='block' variant='h3'>{topic.topic.title}</Typography>
																</Tooltip>
															}
														</Grid>	
														<Grid item
															sx={{marginLeft: "12px"}}>
															{topic ? <Typography variant="caption">{FeedDate(topic.post.timestamp)}</Typography> : null}
														</Grid>		
													</Grid>
													<Grid item>
														<Stack direction="row">
															{auth.currentUser &&
																// type set to topicPage to not show option of report post in topic page title.
																<ReportComponent userId={topic.user.userId} topicId={topic.topic.topicId} type="topicPage" reportedTitle={topic.topic.reportedTitle} reportedPost={null} /> 
															}
															{auth.currentUser &&
																<DeleteTopicComponent topicId={topic.topic.topicId} color={null}/> 
															}
														</Stack>
													</Grid>
												</Grid>
											</>
										}
										expandedComponent={null}
										fixedComponent={null}
										/>
								 </Grid>
								 <Grid item xs={12}>
		 
									 <>
										 <List sx={{ width: '100%', padding:0 }}>
											 {postList.length === 1 ? postList
												 .map((post) => (
													 <TopicPostListItem post={post}/>
												 ))
											 : null}				
										 </List>
									 </>
									 
								</Grid>
							</Grid>
						</Box>	
					 }
					 <TopicDiscussionsDrawerComponent topicId={topicId}/>
					 </Grid>
			}
		</>
			}
		</>
	)
}

