import { useEffect } from 'react';

const UserStructuredData = ({ timestamp, username, followers, discussionsCounter, following, bio, avatarSmallUrl, avatarMediumUrl, avatarBigUrl, lastProfileUpdate }) => {
  
  useEffect(() => {
    const script = document.createElement('script');
    const userCreatedDate = new Date(timestamp);
    const userUpdatedDate = new Date(lastProfileUpdate);
    const userCreatedIsoDate = userCreatedDate.toISOString()
    const userUpdatedIsoDate = userUpdatedDate.toISOString()

    script.type = 'application/ld+json';

    script.innerHTML = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "ProfilePage",
        "dateCreated": userCreatedIsoDate,
        "dateModified": userUpdatedIsoDate,
        "mainEntity": {
            "@type": "Person",
            "name": username,
            "interactionStatistic": {
                "@type": "InteractionCounter",
                "interactionType": "https://schema.org/FollowAction",
                "userInteractionCount": followers
            },
            "agentInteractionStatistic": [{
                "@type": "InteractionCounter",
                "interactionType": "https://schema.org/WriteAction",
                "userInteractionCount": discussionsCounter
            },{
                "@type": "InteractionCounter",
                "interactionType": "https://schema.org/FollowAction",
                "userInteractionCount": following
            }],
            "description": bio,
            "image": [
                avatarSmallUrl,
                avatarMediumUrl,
                avatarBigUrl
            ],
            "sameAs": [
                window.location.href
            ]
        }
      }
    );
    document.head.appendChild(script);

    addMetaTag('og:title', username);
    addMetaTag('og:description', bio);
    addMetaTag('og:image', `${window.location.origin}/logoIcon.svg`); 
    addMetaTag('og:url', window.location.href);

    return () => {
      document.head.removeChild(script);
      const metaTags = document.querySelectorAll('meta[data-custom-meta-tag="true"]');
      metaTags.forEach(tag => tag.remove());
    };
  }, [timestamp, username, followers, discussionsCounter, following, bio, avatarSmallUrl, avatarMediumUrl, avatarBigUrl, lastProfileUpdate]); 


  return null;
};
function addMetaTag(property, content){
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('property', property);
  metaTag.setAttribute('content', content);
  metaTag.setAttribute('data-custom-meta-tag', 'true'); 
  document.head.appendChild(metaTag);
}
export default UserStructuredData;