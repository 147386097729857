import React, {useState} from 'react';
import './LoginToUseDialogComponent.css'
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { LegalNoticeComponent, SignInComponent, SignUpComponent} from '../../components'
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Stack } from '@mui/material';
import logoIconFinalColor from '../../utils/logoIconFinalColor.svg'

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <Box sx={{ p: 3 }}>
			<Typography>{children}</Typography>
		  </Box>
		)}
	  </div>
	);
}
	
CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function LoginToUseDialogComponent(props) {
	
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [value, setValue] = React.useState(0);

	const handleClickOpen = () => {
		setOpen(true);

	};
	const handleClose = () => setOpen(false);

	const handleChange = (event, newValue) => {
	  setValue(newValue);
	};
	return (
		<>
			<Button sx={{color: "text.primary", "&:hover": {color: "text.primary", backgroundColor: "transparent"}}} variant="text" onClick={handleClickOpen}>
				<Grid container direction="column">
					<Grid item>
						<Typography variant="body1" sx={{textTransform: 'none'}} color="inherit">
							{props.type === 'following' ? 
								t("following")
							: props.type === "followers" ? 
								t("followers")
								: null}
						</Typography>
					</Grid>
					<Grid item sx={{marginLeft: "6px"}}>
						<Typography sx={{textTransform: 'none'}} variant="subtitle2" color="text.primary">
							{props.quantity}
						</Typography>
					</Grid>
				</Grid>
			</Button>	
			
			<Dialog onClose={handleClose} open={open}
				sx={{pointerEvents: 'all',
					"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						overflow:'hidden',
						maxWidth: "400px!important",
						maxHeight: "80vh!important",
						height: "80vh",
						width: "90vw" , 
						},
						
					}
				}}>
					<Stack 
						direction="column"
						justifyContent="center"
						alignItems="center">
						<img src={logoIconFinalColor} width={65} height={65} alt="intergraphy_logo" />
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
								<Tab label={<Typography sx={{textTransform: "none!important"}} variant='body2'>{t("signIn")}</Typography>} {...a11yProps(0)} />
								<Tab label={<Typography sx={{textTransform: "none!important"}} variant='body2'>{t("signUp")}</Typography>} {...a11yProps(1)} />
							</Tabs>
						</Box>
						<CustomTabPanel value={value} index={0}>
							<Stack 
								direction="column"
								justifyContent="center"
								alignItems="center">
								<SignInComponent homePage={false} />
								<Link reloadDocument component={RouterLink} to="/flow/recover" underline="none">
									<Typography variant="body1" color="text.secondary">
										{t("signInPage.forgotPasswordQuestion")}
									</Typography>
								</Link>
							</Stack>
						</CustomTabPanel>
						<CustomTabPanel value={value} index={1}>
							<SignUpComponent homePage={false} />
							<LegalNoticeComponent />
						</CustomTabPanel>
					</Stack>
			</Dialog>
		</>
	)

}