import React, {useContext} from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { PoliciesAcceptedContext } from '../contexts/PoliciesAcceptedContext'

export default function AcceptNewPoliciesRoute() {
	const { currentUser } = useAuth()
	const { acceptedPolicies } = useContext(PoliciesAcceptedContext)

	return (currentUser && !acceptedPolicies ? <>
		<Outlet/>
	</> : <Navigate to='/'/>)
	}

