import React, { createContext, useState, useEffect } from 'react'
import { MAX_DOCUMENT_DESCRIPTION_LENGTH } from '../utils/Constants'

export const DocumentDescriptionContext = createContext()

export const DocumentDescriptionProvider = ({ children }) => {
    const [documentDescription, setDocumentDescription] = useState()

    useEffect(() => {    
        let descriptionMetaTag = document.querySelector('meta[name="description"]');
        if (documentDescription === "" || documentDescription === undefined || documentDescription === null) {
            if (descriptionMetaTag) {
                descriptionMetaTag.content = "";
            } else {
                descriptionMetaTag = document.createElement('meta');
                descriptionMetaTag.name = "description";
                descriptionMetaTag.content = "";
                document.getElementsByTagName('head')[0].appendChild(descriptionMetaTag);
            }
        } else {
            if (documentDescription.length > MAX_DOCUMENT_DESCRIPTION_LENGTH) {
                if (descriptionMetaTag) {
                    descriptionMetaTag.content = documentDescription.substring(0, MAX_DOCUMENT_DESCRIPTION_LENGTH) + '...';
                } else {
                    descriptionMetaTag = document.createElement('meta');
                    descriptionMetaTag.name = "description";
                    descriptionMetaTag.content = documentDescription.substring(0, MAX_DOCUMENT_DESCRIPTION_LENGTH) + '...';
                    document.getElementsByTagName('head')[0].appendChild(descriptionMetaTag);
                }
            } else {
                if (descriptionMetaTag) {
                    descriptionMetaTag.content = documentDescription;
                } else {
                    descriptionMetaTag = document.createElement('meta');
                    descriptionMetaTag.name = "description";
                    descriptionMetaTag.content = documentDescription;
                    document.getElementsByTagName('head')[0].appendChild(descriptionMetaTag);
                }
            }
        }
    }, [documentDescription])

    return (
        <DocumentDescriptionContext.Provider value={{ documentDescription, setDocumentDescription }}>
            {children}
        </DocumentDescriptionContext.Provider>
    )
}