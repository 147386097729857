import React, {useState, useEffect} from 'react';
import './CenterScrollableStyledComponent.css'
import { CONTENT_MARGIN, CONTENT_MARGIN_ABSOLUTE, CONTENT_MOBILE_MARGIN, CONTENT_MOBILE_MARGIN_ABSOLUTE, MAX_MOBILE_WIDTH } from '../../utils/Constants';



export default function CenterScrollableStyledComponent(props) {	
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [windowHeight, setWindowHeight] = useState(window.innerHeight)
	const setWindowDimensions = () => {
		setWindowWidth(window.innerWidth)
		setWindowHeight(window.innerHeight)
	}	
	useEffect(() => {  
		// Anything in here is fired on component mount.	

		window.addEventListener('resize', setWindowDimensions);
		return () => {
		  	window.removeEventListener('resize', setWindowDimensions)
		}

    }, [])

	return (
		<div style={{marginLeft: windowWidth < MAX_MOBILE_WIDTH ? CONTENT_MOBILE_MARGIN : CONTENT_MARGIN, marginRight: windowWidth < MAX_MOBILE_WIDTH ? CONTENT_MOBILE_MARGIN : CONTENT_MARGIN, width: windowWidth < MAX_MOBILE_WIDTH ? windowWidth-(2*CONTENT_MOBILE_MARGIN_ABSOLUTE) : 0.5*windowWidth-(2*CONTENT_MARGIN_ABSOLUTE)}}>
			{props.children}	
		</div>
	)

}