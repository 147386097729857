import {useContext, useEffect} from 'react';
import './SignInPage.css'

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { HomeSloganComponent, LegalNoticeComponent, SignInComponent} from '../../components'

import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import { DocumentDescriptionContext } from '../../contexts/DocumentDescriptionContext';
import OrganizationStructuredData from '../../utils/structuredData/OrganizationStructuredData';
import Typography from '@mui/material/Typography';


export default function SignInPage() {
	const { t } = useTranslation();
	const { setDocumentTitle } = useContext(DocumentTitleContext)
	const { setDocumentDescription } = useContext(DocumentDescriptionContext)
    useEffect(() => {  
		// Anything in here is fired on component mount.	
  
		setDocumentTitle(t('documentTitles.signIn'))
		setDocumentDescription(t('documentDescriptions.signIn'))
		return () => {
		}
	
		}, [])
	return (
		<Grid container component="main" sx={{ height: '100vh' }} >
			<OrganizationStructuredData />
			<HomeSloganComponent/>
			<Grid sx={{paddingTop: "55px"}} direction="column" item xs={12} sm={12} md={5} component={Paper} elevation={6} square >					
				<SignInComponent homePage={true} />
				<Grid container justifyContent="center" spacing={2}>
					<Grid item>
						<Link to="/flow/recover" style={{textDecoration: "none", color: "inherit"}}>
							<Typography variant='subtitle2' sx={{"&:hover": {textDecoration: "underline!important"},color: "inherit!important"}}>
								{t("signInPage.forgotPasswordQuestion")}
							</Typography>
						</Link>
					</Grid>
					<Grid item>
						<Link to="/flow/signup" style={{textDecoration: "none", color: "inherit"}}>
							<Typography variant='subtitle2' sx={{"&:hover": {textDecoration: "underline!important"},color: "inherit!important"}}>
								{t("signInPage.doNotHaveAccountSignUp")}
							</Typography>
						</Link>
					</Grid>
				</Grid>						
				<LegalNoticeComponent sx={{ mt: 3 }} />

			</Grid>
		</Grid>
	)
}

