import React, {useState, useEffect} from 'react';
import './SidebarComponent.css'
import {AvatarPreviewComponent, RecentDiscussionsListComponent} from '../../components'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CreateIcon from '@mui/icons-material/Create';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ListIcon from '@mui/icons-material/List';
import { auth } from "../../firebase";
import { AVATAR_MEDIUM_SIZE, MAX_LAPTOP_WIDTH, MAX_MOBILE_WIDTH, MIN_SIDEBAR_WIDTH, NAVBAR_HEIGHT, SIDEBAR_WIDTH } from '../../utils/Constants';

import { getCurrentUserProfile } from '../../firebaseFunctions/spannerFunctions/profileFunctions';
import { useTranslation } from 'react-i18next';


export default function SidebarComponent(props) {
	const { t } = useTranslation();

	const navigate = useNavigate() 
	
	const handleNavigateToCreateTopic = () => {
		navigate("/page/newtopic")
	}
	const handleNavigateToManageTopics = () => {
		navigate("/page/manage")
	}
	const handleNavigateToUserMentions = () => {
		navigate("/page/mentions")
	}
	
	const [user, setUser]  = useState();

	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const setWindowDimensions = () => {
	  setWindowWidth(window.innerWidth)
	}	
	useEffect(() => {  
		// Anything in here is fired on component mount.
		if (auth.currentUser) {
			getCurrentUserProfile().then((res) => {
				setUser(res)
			})
		}
		

		window.addEventListener('resize', setWindowDimensions);
		return () => {
		  	window.removeEventListener('resize', setWindowDimensions)
		}

    }, [])

	return (
		<>
			{windowWidth < MAX_MOBILE_WIDTH ? 	
				<></>
			:
				<Grid item sx={{width: windowWidth * 0.25 +"px"}}>
					{
						user && auth.currentUser &&							
							<Grid container sx={{marginTop: '12px', position: 'fixed', width: "inherit", height: 'calc(100vh - ' + NAVBAR_HEIGHT + ')' }}
								direction="column"
								justifyContent="flex-start"
								alignItems="flex-end">

								<Grid container 
								sx={{width: windowWidth < MAX_LAPTOP_WIDTH ? MIN_SIDEBAR_WIDTH : SIDEBAR_WIDTH}}>
									<Grid container direction="column" sx={{width: "inherit"}}>
										<Grid item alignSelf="center">
											{user.avatarSmallUrl !== "" ?  														
												<AvatarPreviewComponent avatar={user.avatarMediumUrl}
													avatarBig={user.avatarBigUrl}
													size={AVATAR_MEDIUM_SIZE + "px"}
													/>
											:
												<Avatar sx={{ width: AVATAR_MEDIUM_SIZE, height:AVATAR_MEDIUM_SIZE, color:"white" }} alt="avatar">
													{user.username[0].toUpperCase()}
												</Avatar>
											}
										</Grid>
										<Grid align='center' item sx={{width: "inherit"}}>
											<Typography noWrap align='center' style={{ width: "70%", marginTop: '4px', wordWrap: "break-word" }} display='block' variant='body'>
												<Link component={RouterLink} to={"/" + user.username} color="inherit" underline="hover">
													{user.username}
												</Link>	
											</Typography>
										</Grid>
									</Grid>

									<Grid item sx={{width: 'inherit'}}>
										
										<List
											sx={{ bgcolor: 'transparent', padding: 0, marginTop: "12px", marginBottom: "6px" }}
											component="nav"
											aria-labelledby="nested-list-subheader"
											>				
											
											<ListSubheader sx= {{backgroundColor:"transparent", marginLeft: "0px", height: "42px"}}><Typography variant="list_subheader">{t("sidebar.actionsCaps")}</Typography></ListSubheader>
											<ListItemButton onClick={handleNavigateToCreateTopic}>
												<ListItemIcon>
													<CreateIcon fontSize='small'/>
												</ListItemIcon>
												<ListItemText sx= {{marginLeft: "-15px"}} primary={<Typography variant="body1">{t("sidebar.createTopic")}</Typography>}/>
											</ListItemButton>
											<ListItemButton onClick={handleNavigateToManageTopics}>
												<ListItemIcon>
													<ListIcon fontSize='small'/>
												</ListItemIcon>
												<ListItemText sx= {{marginLeft: "-15px"}} primary={<Typography variant="body1">{t("sidebar.manageTopics")}</Typography>}/>
											</ListItemButton>
											<ListItemButton onClick={handleNavigateToUserMentions}>
												<ListItemIcon>
													<AlternateEmailIcon fontSize='small'/>
												</ListItemIcon>
												<ListItemText sx= {{marginLeft: "-15px"}} primary={<Typography variant="body1">{t("sidebar.mentions")}</Typography>}/>
											</ListItemButton>

										</List>

										<RecentDiscussionsListComponent />
									</Grid>
								</Grid>
							</Grid>
					}
				</Grid>
			}
		</>
	)
}

