import { useEffect } from 'react';

const DiscussionStructuredData = ({ posts,  topicId, discussionId, postId, title, opening, topicCreatorUsername, topicTimestamp, discussionRepliesCounter }) => {
  useEffect(() => {
    const script = document.createElement('script');
    const date = new Date(topicTimestamp);
    const isoTopicDate = date.toISOString()

    script.type = 'application/ld+json';

    const comments = posts.map((post) => {
      const date = new Date(post.post.timestamp);
      const isoDate = date.toISOString()
      const comment = {
        "@type": "Comment",
        "text": post.post.post,
        "url": window.location.origin + (topicId !== null ? "/topic/" + topicId : "") + (discussionId !== null ? "/discussion/" + discussionId : "") + (postId !== null ? "/post/" + postId : ""),
        "author": {
          "@type": "Person",
          "name": post.user.username,
          "url": window.location.origin + "/" + post.user.username,
        }, 
        "datePublished": isoDate
      }
      return comment
    })
    script.innerHTML = JSON.stringify(    {
      "@context": "https://schema.org",
      "@type": "DiscussionForumPosting",
      "headline": title,
      "text": opening,
      "url": window.location.origin + (topicId !== null ? "/topic/" + topicId : "") + (discussionId !== null ? "/discussion/" + discussionId : ""),
      "author": {
        "@type": "Person",
        "name": topicCreatorUsername,
        "url": window.location.origin + "/" + topicCreatorUsername,
      },
      "datePublished": isoTopicDate,
      "interactionStatistic": {
        "@type": "InteractionCounter",
        "interactionType": "https://schema.org/CommentAction",
        "userInteractionCount": discussionRepliesCounter
      }, 
      "comment": comments
    }
    );
    if (topicId !== null && discussionId !== null) {
      document.head.appendChild(script);
    }

    addMetaTag('og:title', title);
    addMetaTag('og:description', opening);
    addMetaTag('og:image', `${window.location.origin}/logoIcon.svg`); 
    addMetaTag('og:url', window.location.href);

    return () => {
      document.head.removeChild(script);
      const metaTags = document.querySelectorAll('meta[data-custom-meta-tag="true"]');
      metaTags.forEach(tag => tag.remove());
    };
  }, [posts,  topicId, discussionId, postId, title, opening, topicCreatorUsername, topicTimestamp, discussionRepliesCounter]); 

  return null;
};
function addMetaTag(property, content){
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('property', property);
  metaTag.setAttribute('content', content);
  metaTag.setAttribute('data-custom-meta-tag', 'true'); 
  document.head.appendChild(metaTag);
}


export default DiscussionStructuredData;