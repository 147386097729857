import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { convertBackendResponseKeysToFrontEndFormat } from "../../utils/ConvertObjectToFirstCharLowerCase";

export async function hasUserAlreadyRequestedPersonalData() {	
	const hasuseralreadyrequestedpersonaldata = httpsCallable(functions, 'hasuseralreadyrequestedpersonaldata');
	return await hasuseralreadyrequestedpersonaldata()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function getCurrentUserSiteNotificationsEmailAndLanguage() {	
	const getcurrentusersitenotificationsemailandlanguage = httpsCallable(functions, 'getcurrentusersitenotificationsemailandlanguage');
	return await getcurrentusersitenotificationsemailandlanguage()
	.then((result) => {
		const data = result.data;
		
		const dataInLocalFormat = convertBackendResponseKeysToFrontEndFormat(data)
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}
export async function getUserPersonalDataLogsRequestsStatus() {	
	const getuserpersonaldatalogsrequestsstatus = httpsCallable(functions, 'getuserpersonaldatalogsrequestsstatus');
	return await getuserpersonaldatalogsrequestsstatus()
	.then((result) => {
		const data = result.data;
		
		const dataInLocalFormat = convertBackendResponseKeysToFrontEndFormat(data)
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}

export async function getCurrentUserPostLogs() {	
	const getcurrentuserpostlogsfromcloudspanner = httpsCallable(functions, 'getcurrentuserpostlogsfromcloudspanner');
	return await getcurrentuserpostlogsfromcloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function getCurrentUserTopicTitleLogs() {	
	const getcurrentusertopictitlelogsfromcloudspanner = httpsCallable(functions, 'getcurrentusertopictitlelogsfromcloudspanner');
	return await getcurrentusertopictitlelogsfromcloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function getCurrentUserBioLogs() {	
	const getcurrentuserbiologsfromcloudspanner = httpsCallable(functions, 'getcurrentuserbiologsfromcloudspanner');
	return await getcurrentuserbiologsfromcloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function getCurrentUserAvatarLogs() {	
	const getcurrentuseravatarlogsfromcloudspanner = httpsCallable(functions, 'getcurrentuseravatarlogsfromcloudspanner');
	return await getcurrentuseravatarlogsfromcloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function getCurrentUserUserLogs() {	
	const getcurrentuseruserlogsfromcloudspanner = httpsCallable(functions, 'getcurrentuseruserlogsfromcloudspanner');
	return await getcurrentuseruserlogsfromcloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function getCurrentUserUsernameLogs() {	
	const getcurrentuserusernamelogsfromcloudspanner = httpsCallable(functions, 'getcurrentuserusernamelogsfromcloudspanner');
	return await getcurrentuserusernamelogsfromcloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function getCurrentUserAvatarsLogsZip() {	
	const generateuseravatarlogsavatarsfileandgetdownloadlink = httpsCallable(functions, 'generateuseravatarlogsavatarsfileandgetdownloadlink');
	return await generateuseravatarlogsavatarsfileandgetdownloadlink()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
