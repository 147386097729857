import React, {useState} from 'react';
import './SignInComponent.css'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import logoIconFinalColor from '../../utils/logoIconFinalColor.svg'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HOME_LOGO_ICON_WIDTH } from '../../utils/Constants';

const theme = createTheme();
export default function SignInComponent(props) {
	const { t } = useTranslation();
	const { signIn } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	  
	async function handleSubmit(event) {
		event.preventDefault()
		const data = new FormData(event.currentTarget);  
		try {
			setError("")
			setLoading(true)
			await signIn(data.get('email'), data.get('password'))
			navigate("/")
		} catch {
			setError(t("signInComponent.failedLogIn"))
		}
  
	  setLoading(false)
	}
	
	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xs">
				<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
				>
				
					{error && <Alert sx={{height: "auto!important", width: "100%!important", marginTop: "8px"}} severity='error'>{error}</Alert>}
					{
						props.homePage ?
							<>
								<img src={logoIconFinalColor} width={HOME_LOGO_ICON_WIDTH} alt="intergraphy_logo" />
								<Typography component="h1" variant="h6">
									{t("signIn")}
								</Typography>
							</>
						: null
					}
					<Box component="form" onSubmit={handleSubmit} noValidate>
						<TextField
							margin="dense"
							spellCheck={false}
							sx={{backgroundColor:"#fff", borderRadius: "4px"}}
							required
							fullWidth
							id="email"
							label={t("emailAdress")}
							name="email"
							autoComplete="email"
							autoFocus
							size="small"
						/>
						<TextField
							margin="dense"
							required
							fullWidth
							spellCheck={false}
							sx={{backgroundColor:"#fff", borderRadius: "4px"}}
							name="password"
							label={t("password")}
							type="password"
							id="password"
							autoComplete="current-password"
							size="small"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							className="FlowButton"
							sx={{ margin: "16px!important", marginLeft: "0px!important", marginRight:"0px!important", borderRadius: "22px" }}
							disabled={loading}
							>
							{t("signIn")}
						</Button>
					</Box>
				</Box>
			</Container>
		</ThemeProvider>
	)
}
  

