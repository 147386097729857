import React, {useState, useEffect} from 'react';
import './UserPostMentionsComponent.css'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

import { useTranslation } from 'react-i18next';
import { AVATAR_SMALL_SIZE, NAVBAR_HEIGHT,MAX_CHARACTERS_PER_MENTION_PREVIEW_POST,MAX_MENTION_POST_ITEM_LINES_COLLAPSED} from '../../utils/Constants';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { getCurrentUserMentionedPosts, getDataForPostLocalLinks } from '../../firebaseFunctions/spannerFunctions/postLocalLinksFunctions';
import PostWithLinksComponent from '../PostWithLinksComponent/PostWithLinksComponent';
import UserPopoverCardComponent from '../UserPopoverCardComponent';
import AvatarPreviewComponent from '../AvatarPreviewComponent';

function PostItem(props) {
	const { t } = useTranslation();
	const [postLocalLinksDisplayData, setPostLocalLinksDisplayData] = useState([]);	

	const [expanded, setExpanded] = useState(false);
	
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	useEffect(() => {
		getDataForPostLocalLinks(props.mention.data.post).then((dataForLinks) => {
			setPostLocalLinksDisplayData(dataForLinks)
		})

		return () => {
		}
	}, [])
  
	return (
		<>
			{
				props.mention &&
				<>
					<ListItem alignItems="flex-start" >
						<ListItemAvatar>
							{
								props.mention.data.user.avatarSmallUrl !== "" ?
									<AvatarPreviewComponent avatar={props.mention.data.user.avatarSmallUrl}
										avatarBig={props.mention.data.user.avatarBigUrl}
										size={AVATAR_SMALL_SIZE + "px"}/>
								: 
									<Avatar src={props.mention.data.user.avatarSmallUrl} sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE, color:"white" }} aria-label="avatar">
										{props.mention.data.user.username[0].toUpperCase()}
									</Avatar>
							}
						</ListItemAvatar>
						<ListItemText
							primary={
								
								<Stack direction="column" sx={{marginLeft: "10px"}}>
									<Typography variant='body2' sx={{marginLeft: '10px'}}>
										<UserPopoverCardComponent uid={props.mention.data.user.userId} user={props.mention.data.user} type='mention' place={null}/>
									</Typography>
									<Link component={RouterLink} to={(props.mention.data.discussionId !== "" && props.mention.data.discussionId !== null) ? '/topic/' + props.mention.data.topicId  + '/discussion/' + props.mention.data.discussionId + '/post/' + props.mention.data.postId : '/topic/' + props.mention.data.topicId} color="inherit" underline="hover">									
										<Typography variant='caption' style={{ fontStyle:'italic', whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis'}}>
											{props.mention.data.title} 
										</Typography>
									</Link> 
								</Stack>
							}
							secondary={
							<>
								<Stack direction="column" sx={{marginTop: "12px"}}>
									{!expanded ? 
										<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: MAX_MENTION_POST_ITEM_LINES_COLLAPSED, overflow: 'hidden', textOverflow: 'ellipsis'}}>
											{props.mention.data.post.length > MAX_CHARACTERS_PER_MENTION_PREVIEW_POST ? 
													<PostWithLinksComponent key={0} showReferencedPosts={false} post={props.mention.data.post.substring(0, MAX_CHARACTERS_PER_MENTION_PREVIEW_POST)+"..."} localLinksData={postLocalLinksDisplayData} />
												:
													<PostWithLinksComponent key={1} showReferencedPosts={false} post={props.mention.data.post.substring(0, MAX_CHARACTERS_PER_MENTION_PREVIEW_POST)} localLinksData={postLocalLinksDisplayData}  />
											}
										</Typography> 
										: 
										<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
											<PostWithLinksComponent key={2} showReferencedPosts={false} post={props.mention.data.post} localLinksData={postLocalLinksDisplayData} />
										</Typography>
									}
									{
										props.mention.data.post.length <= MAX_CHARACTERS_PER_MENTION_PREVIEW_POST ? 
											null
											:
											<div style= {{marginLeft: 'auto'}}>
												
												<Button
													disabled={props.mention.data.post.length <= MAX_CHARACTERS_PER_MENTION_PREVIEW_POST}
													onClick={handleExpandClick}
													sx={{
														justifyContent: "flex-end",
														textTransform: 'none',
														'&:hover': {
															textDecoration: "underline",
															background: "transparent!important"
														}}}>
													<Typography variant='caption' color="inherit">
														{!expanded ? t('userPostMentionsComponent.readMore') : t('userPostMentionsComponent.readLess')}
													</Typography>
												</Button>
											</div>
									}
								</Stack>
							</>
						}
						/>	
					</ListItem>
					<Divider />
				</> 
			}
		</>
	)
}
export default function UserPostMentionsComponent() {

	const { t } = useTranslation();
	const [loading, setLoading] = useState(true)
	const [loadingMorePosts, setLoadingMorePosts] = useState(false)
	const [lastPostId, setLastPostId] = useState(null)
	const [lastPostUserId, setLastPostUserId] = useState(null)
	const [isLastMention, setIsLastMention] = useState(false)
	const [mentionList, setMentionList] = useState([])

    useEffect(() => {  
		// Anything in here is fired on component mount.	
		setLoading(true)
		getCurrentUserMentionedPosts(lastPostId, lastPostUserId).then((res) => {
			if (res !== null) {
				setMentionList(res.data)
				setLastPostUserId(res.data[res.data.length-1].data.user.userId)
				setLastPostId(res.data[res.data.length-1].data.postId)
				setLoadingMorePosts(false)
				setLoading(false)
				setIsLastMention(res.isLastMention)
			} else {
				setLoadingMorePosts(false)
				setLoading(false)
				setIsLastMention(true)
			}
		})
  
	}, [])

	const loadMorePosts = () => {	
		getCurrentUserMentionedPosts(lastPostId, lastPostUserId).then((res) => {
			if (res !== null) {
				setMentionList(prevList => [...prevList, ...res.data])
				setLastPostUserId(res.data[res.data.length-1].data.user.userId)
				setLastPostId(res.data[res.data.length-1].data.postId)
				setLoadingMorePosts(false)
				setLoading(false)
				setIsLastMention(res.isLastMention)
			} else {
				setLoadingMorePosts(false)
				setLoading(false)
				setIsLastMention(true)
			}
		})
	}
	
	return (	
		<>
		{loading ?    
		
			<Box sx={{ display: 'flex',
				alignContent: 'center', 
				height: "100vh",
				alignItems:"center",
				justifyContent:"center"}}>
				<CircularProgress />
			</Box> 
			: 
			mentionList.length > 0 ?
				<List className='MentionsList' sx={{ width: '100%', padding:'0!important', height: "calc(100vh - " + NAVBAR_HEIGHT +")" }}>
					{mentionList.map((mention) => {
						return (
							<>
								<PostItem mention={mention} />
							</>
						)
					})}
					
					{
						loadingMorePosts === true ? 
							<Box sx={{ display: 'flex',
								alignContent: 'center', 
								height: "auto",
								margin:"12px",
								alignItems:"center",
								justifyContent:"center"}}>
								<CircularProgress size='26px'/>
							</Box>  : null
					}
					{!isLastMention ?									
						<Button className='LoadMoreButton' fullWidth disabled={loading} onClick={loadMorePosts}>
							<Stack direction="row"
								justifyContent="center"
								alignItems="center">
								<Typography color="inherit" variant="body1">{t("manageTopics.loadMoreDiscussions")}</Typography>
								<ArrowDropDownIcon />
							</Stack>
						</Button>
						:
						null
					}
				</List>
				:
				
				<Box sx={{ display: 'flex',
					alignContent: 'center', 
					height: 'calc(100vh - ' + NAVBAR_HEIGHT + ' - 68px)',
					alignItems:"center",
					justifyContent:"center"}}>
					<Typography variant='caption'>{t('userPostMentionsComponent.noResults')}</Typography>
				</Box> 
		}
		</>	
		
	);
}
