export const buildImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.src = url
  })

export default async function getCroppedImg(imageSrc, cropSpecs) {
  const image = await buildImage(imageSrc)
  const canvas = document.createElement('canvas')
  const canvasContext = canvas.getContext('2d')

  if (!canvasContext) {
    return null
  }

  canvas.width = image.width
  canvas.height = image.height

  canvasContext.drawImage(image, 0, 0)

  const data = canvasContext.getImageData(
    cropSpecs.x,
    cropSpecs.y,
    cropSpecs.width,
    cropSpecs.height
  )
  canvas.width = cropSpecs.width
  canvas.height = cropSpecs.height
  
  canvasContext.putImageData(data, 0, 0)

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file); 
      reader.onloadend = function() {
        let base64data = reader.result; 
        resolve(base64data)
      }
    }, 'image/jpeg')
  })
}
