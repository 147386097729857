import React, {useState, useEffect} from 'react';
import './NavigateToExternalLinkModalComponent.css'
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


export default function NavigateToExternalLinkModalComponent(props) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const navigateToExternalLink = () => {
        window.open(props.url, '_blank', 'noopener,noreferrer');
		setOpen(false)
	}
	useEffect(() => {
		setOpen(props.open)
	}, [props.open])

	return (
	  <div>
		<Dialog onClose={props.handleCloseDialog()} open={open}
			sx={{pointerEvents: 'all',
				"& .MuiDialog-container": {
				"& .MuiPaper-root": {
					overflow:'hidden',
					maxWidth: "400px!important",
					maxHeight: "500px!important",
					height: "auto",
					minWidth: "200px" , 
					}
				}
			}}>
      		<DialogTitle>
				<Typography	variant='h2' sx={{paddingBottom: "12px"}}>
					{t("navigateToExternalLinkModalComponent.title")}
				</Typography>
			</DialogTitle>

			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">				
					<Typography color="inherit" variant="body1" sx={{textOverflow:'ellipsis', overflow:'auto'}}>
						{t("navigateToExternalLinkModalComponent.description") + ": " + props.url}
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="error" variant="contained"  onClick={props.handleCloseDialog()}>{t("navigateToExternalLinkModalComponent.cancel")}</Button>
				<Button variant="contained" onClick={() => navigateToExternalLink()}>{t("navigateToExternalLinkModalComponent.proceed")}</Button>
			</DialogActions>
		</Dialog>
	  </div>
	);
}