import React, {useState, useContext, useEffect, useCallback} from 'react';
import './ProfileConfigurationComponent.css'
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { getCurrentUserProfile,updateUserProfile } from '../../firebaseFunctions/spannerFunctions/profileFunctions';
import { useAuth } from '../../contexts/AuthContext'
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { BIO_CHARACTER_LIMIT, AVATAR_MEDIUM_SIZE, USERNAME_CHARACTER_MAX, USERNAME_CHARACTER_MIN, USERNAME_REGEX } from '../../utils/Constants';
import Slider from '@mui/material/Slider';
import getCroppedImg from './cropImage'
import Skeleton from '@mui/material/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';
import ClearIcon from '@mui/icons-material/Clear';
import Cropper from 'react-easy-crop'
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import LinkPhoneNumberComponent from '../LinkPhoneNumberComponent';
import { deleteUserAvatarFromCloudSpanner, deleteUserFromCloudSpanner } from '../../firebaseFunctions/spannerFunctions/deleteFunctions';
import { auth } from "../../firebase";


function ChangePasswordModal() {
	const { t } = useTranslation();
	
	const { updateUserPassword } = useAuth()
	const [open, setOpen] = useState(false);
	const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")

	const handleOpen = () => {
		setError("")
		setOpen(true);
	}
	const handleClose = () => {
		setOpen(false)
	}

	const openSuccessAlert = () => {
		setSuccessAlertOpen(true);
	};
  
	const closeSuccessAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSuccessAlertOpen(false);
	};

	async function handleSubmit(event) {
		event.preventDefault()
		const data = new FormData(event.currentTarget);
		if ((data.get('password') === "")){
			return setError(t("profileConfiguration.allFieldsRequired"))
		}else {
			if(data.get('password').length < 6){
				setError(t("profileConfiguration.passwordShouldBeLonger"))
			} else {
				if (data.get('password') !== data.get('confirmPassword')) {
					return setError(t("profileConfiguration.passwordDoesNotMatch"))
				} else {
					try {
						setLoading(true)
						await updateUserPassword(data.get('password'))
						handleClose()
						openSuccessAlert()
					} catch (error) {
						setError(t("profileConfiguration.passwordUpdateFailed"))
					}
					setLoading(false)
				}
			}
		}
	}
	return(
		<>
			<IconButton style={{marginRight: 6}} color="primary" aria-label="upload picture" component="label" onClick={handleOpen}>
				<EditIcon fontSize='small'/>
			</IconButton>
			<Snackbar open={successAlertOpen} autoHideDuration={6000} onClose={closeSuccessAlert}>
				<Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
					{t("profileConfiguration.passwordUpdateSuccess")}
				</Alert>
			</Snackbar>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>	
				<Box component="form" noValidate onSubmit={handleSubmit}
					sx={{padding: "18px!important"}} >			
					<Typography id="modal-modal-title" variant="h2" sx={{paddingBottom: "18px!important"}}>
						{t("profileConfiguration.updatePassword")}
					</Typography>
					{error && <Alert severity='error' style={{marginTop: 8, marginBottom: 8}}>{error}</Alert>}
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								spellCheck={false}
								required
								fullWidth
								name="password"
								label={t("profileConfiguration.newPassword")}
								type="password"
								id="password"
								autoComplete="password"
								
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								spellCheck={false}
								required
								fullWidth
								name="confirmPassword"
								label={t("profileConfiguration.confirmPassword")}
								type="password"
								id="confirmPassword"
								autoComplete="confirm-new-password"
							/>
						</Grid>
					</Grid>
					<Stack
						direction="row"
						justifyContent="space-around"
						alignItems="center"
						spacing={2}
						sx={{padding: "10px!important"}}
						>
						<Button
							type="submit"
							variant="contained"
							sx={{ mt: 3, mb: 2, width: 1/2}}
							disabled={loading}
						>
							{t("profileConfiguration.updatePassword")}
						</Button>
						<Button
							style={{marginTop: 7}}
							sx={{ mt: 3, mb: 2, width: 1/2}}
							disabled={loading}
							variant="contained"
							color="error"
							onClick={handleClose}
						>
							{t("cancel")}
						</Button>
					</Stack>
				</Box>
			</Dialog>
		</>
	)
}
function ChangeEmailModal() {
	const { t } = useTranslation();
	const { updateUserEmail } = useAuth()
	const [open, setOpen] = useState(false);
	const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")
	
	const handleOpen = () => {
		setError("")
		setOpen(true);
	}
	const handleClose = () => {
		setOpen(false)
	}
	
	const openSuccessAlert = () => {
		setSuccessAlertOpen(true);
	};
  
	const closeSuccessAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSuccessAlertOpen(false);
	};
	async function handleSubmit(event) {
		event.preventDefault()
		const data = new FormData(event.currentTarget);
		if ((data.get('email') === "")){
			return setError(t("profileConfiguration.allFieldsRequired"))
		}else {
			if (data.get('email') !== data.get('confirmEmail')) {
				return setError(t("profileConfiguration.emailDoesNotMatch"))
			} else {
				try {
					setLoading(true)
					await updateUserEmail(data.get('email'))
					handleClose()
					openSuccessAlert()
				} catch (error) {
					setError(t("profileConfiguration.emailUpdateFailed"))
				}
				setLoading(false)
			}
		}
	}
	return(
		<>
			<IconButton style={{marginRight: 6}} color="primary" aria-label="upload picture" component="label" onClick={handleOpen}>
				<EditIcon fontSize='small'/>
			</IconButton>
			<Snackbar open={successAlertOpen} autoHideDuration={6000} onClose={closeSuccessAlert}>
				<Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
					{t("profileConfiguration.emailUpdateSuccess")}
				</Alert>
			</Snackbar>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{padding: "18px!important"}} component="form" noValidate onSubmit={handleSubmit} >			
					<Typography id="modal-modal-title" variant="h2" sx={{paddingBottom: "18px!important"}}>
						{t("profileConfiguration.updateEmail")}
					</Typography>
					{error && <Alert severity='error' style={{marginTop: 8, marginBottom: 8}}>{error}</Alert>}
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								spellCheck={false}
								required
								fullWidth
								name="email"
								label={t("profileConfiguration.newEmail")}
								type="email"
								id="email"
								autoComplete="email"
								
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								spellCheck={false}
								required
								fullWidth
								name="confirmEmail"
								label={t("profileConfiguration.confirmEmail")}
								type="email"
								id="confirmEmail"
								autoComplete="confirm-new-email"
							/>
						</Grid>
					</Grid>
					<Stack
						direction="row"
						justifyContent="space-around"
						alignItems="center"
						spacing={2}
						sx={{padding: "10px!important"}}
						>
						<Button
							type="submit"
							variant="contained"
							sx={{ mt: 3, mb: 2, width: 1/2}}
							disabled={loading}
						>
							{t("profileConfiguration.updateEmail")}
						</Button>
						<Button
							style={{marginTop: 7}}
							sx={{ mt: 3, mb: 2, width: 1/2}}
							disabled={loading}
							variant="contained"
							color="error"
							onClick={handleClose}
						>
							{t("cancel")}
						</Button>
					</Stack>
				</Box>
			</Dialog>
		</>
	)
}

export default function ProfileConfigurationComponent() {

	const { t } = useTranslation();
	const { currentUser } = useAuth()
	const [profile, setProfile] = useState()
	const [avatarLoading, setAvatarLoading] = useState(false)  
	const [loading, setLoading] = useState(false)  
	const [expanded, setExpanded] = useState(false);
	const [avatarMouseOver, setAvatarMouseOver] = useState(false);
	const [uploadedAvatar, setUploadedAvatar] = useState();
	const [previewAvatar, setPreviewAvatar] = useState();
	const [uploadedAvatarUrl, setUploadedAvatarUrl] = useState();
	const [bioReadOnly, setBioReadOnly] = useState(true);  
	const [bio, setBio] = useState('');
	const [bioUnchanged, setBioUnchanged] = useState(true);
	const [successAlertOpen, setSuccessAlertOpen] = useState(false);
	const [deleteUserSuccessAlertOpen, setDeleteUserSuccessAlertOpen] = useState(false);
	const [deleteUserAvatarSuccessAlertOpen, setDeleteUserAvatarSuccessAlertOpen] = useState(false);
	const [userUpdateMessage, setUserUpdateMessage] = useState();
	const [deleteUserUpdateMessage, setDeleteUserUpdateMessage] = useState();
	const [deleteUserAvatarUpdateMessage, setDeleteUserAvatarUpdateMessage] = useState();
	const [loadingAvatarDelete, setLoadingAvatarDelete] = useState(false);

	// username modal
	const [usernameModalOpen, setUsernameModalOpen] = useState(false);
	const [usernameModalError, setUsernameModalError] = useState("")
	const [username, setUsername] = useState("")
	// avatar crop modal
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const { setDocumentTitle } = useContext(DocumentTitleContext)
	// delete user modal
	const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
	const [deleteUserModalError, setDeleteUserModalError] = useState("")
	const [loadingUserDeletion, setLoadingUserDeletion] = useState(false)
	const navigate = useNavigate()
	
    useEffect(() => {  
      // Anything in here is fired on component mount.	
  
	  setDocumentTitle(t('documentTitles.configureProfile'))
  
	  if (auth.currentUser.emailVerified === false) {
		setOpenEmailVerificationNeededAlert(true)
	  } 
      return () => {
      }
  
      }, [])
  

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
	 	setCroppedAreaPixels(croppedAreaPixels)
	}, [])
  
	const [cropAvatarModalOpen, cropAvatarModalSetOpen] = useState(false);
	
	const handleUsernameModalOpen = () => {
		setUsernameModalError("")
		setUsernameModalOpen(true);
	}
	const handleUsernameModalClose = () => {
		setUsernameModalOpen(false)
	}
	const handleUsernameModalCancel = () => {
		setUsername("")
		handleUsernameModalClose()
	}
	
	const usernameModalHandleSubmit = (event) => {
		event.preventDefault()
		const data = new FormData(event.currentTarget);
		if(data.get('username').length > USERNAME_CHARACTER_MAX || data.get('username').length < USERNAME_CHARACTER_MIN){
			setUsernameModalError(t("profileConfiguration.usernameCharacterMinimum"))
		} else {
			const usernameValid = USERNAME_REGEX.test(data.get('username'));
			if (!usernameValid){
				setUsernameModalError(t("profileConfiguration.usernameCharacterType"))
			} else {
				setUsername(data.get('username'))
				setUsernameModalError("")
				handleUsernameModalClose()
			}
		}
	}

	// Delete user functions:
	const handleDeleteUserModalOpen = () => {
		setDeleteUserModalError("")
		setDeleteUserModalOpen(true);
	}
	const handleDeleteUserModalClose = (event, message) => {
		if (message === 'backdropClick') {
			// cant close modal unless clicking on cancel
			return; 
		} else {		
			setDeleteUserModalOpen(false)
		}
	}
	const handleDeleteUserModalCancel = () => {
		handleDeleteUserModalClose()
	}
	const deleteUserModalHandleSubmit = (event) => {
		event.preventDefault()
		const data = new FormData(event.currentTarget);
		if(data.get('username') !== profile.username){
			setDeleteUserModalError(t("profileConfiguration.deleteUsernamesDontMatch"))
		} else {
			setLoadingUserDeletion(true)
			deleteUserFromCloudSpanner().then((res) => {
				if (res.success === true) {
					// refresh id token of user in order to sign out on user deletion.
					currentUser.getIdToken(true).then(() => {
						navigate("/")
					})
				} else {
					setDeleteUserUpdateMessage({error: true})
					openDeleteUserSuccessAlert()
				}
			})
		}
	}

	const onAvatarMouseOver = () => {
		setAvatarMouseOver(true)
	}
	const onAvatarMouseOut = () => {
		setAvatarMouseOver(false)
	}

	const handleAvatarUpload = (event) => {
		setUploadedAvatarUrl(URL.createObjectURL(event.target.files[0]))
		cropAvatarModalSetOpen(true)
	}
	const cropAvatarModalHandleClose = () =>{
		setUploadedAvatarUrl()
		cropAvatarModalSetOpen(false)
	}	
	const handleCropAvatarSave = () => {
		// Changes uploaded avatar
		showCroppedImage()
		cropAvatarModalSetOpen(false)
	}
	const showCroppedImage = useCallback(async () => {
		try {
			setAvatarLoading(true)
			//returns blob
			const croppedImage = await getCroppedImg(
				uploadedAvatarUrl,
				croppedAreaPixels
			)
			setUploadedAvatar(croppedImage)
			setUploadedAvatarUrl(croppedImage) 
			setPreviewAvatar(croppedImage)
			setAvatarLoading(false)
		} catch (e) {
		  	console.error(e)
		}
	  }, [croppedAreaPixels, 0])


	const handleBioReadOnlyChange = () => {
		setBioReadOnly(false)
	}
	const handleBioChange = (event) => {
		setBio(event.target.value)
		setBioUnchanged(false)
	}
	
	const handleClearProfileData = (event) => {
		event.preventDefault()
		setAvatarLoading(false)
		setUploadedAvatar()
		setUploadedAvatarUrl()
		setPreviewAvatar()
		setBio('')
		setBioUnchanged(true)
		setUsername('')
		setCrop({ x: 0, y: 0 })
		setZoom(1)
		setCroppedAreaPixels(null)
	}
	const handleDeleteUserAvatarClick = (event) => {
		event.preventDefault()
		setLoadingAvatarDelete(true)
		deleteUserAvatarFromCloudSpanner().then((data) => {
			if (data.error === true) {
				openDeleteAvatarErrorAlert()
			} else {
				if (data.success === true) {
					setDeleteUserAvatarUpdateMessage(data)
					openDeleteUserAvatarSuccessAlert()
					setLoadingAvatarDelete(false)
					navigate(0)
				}
			}
		})
	}
	async function handleProfileConfigurationSubmit(event){
		event.preventDefault()
		try {
			if (auth.currentUser.emailVerified) {
				setLoading(true) // buttons disabled
				const newProfile = await updateUserProfile(username, bio, bioUnchanged, uploadedAvatar)
				setUsername('')
				setBio('')
				setBioUnchanged(true)
				setUploadedAvatar('')
				setProfile(newProfile)
				handleClearProfileData(event)
				setPreviewAvatar(uploadedAvatarUrl) // use last uploaded avatar to cropper as preview
				setLoading(false)
				setBioReadOnly(true)
				setUserUpdateMessage({avatarError: newProfile.avatarError, avatarExplicit: newProfile.avatarExplicit, usernameTaken: newProfile.usernameTaken, avatarExceedsSizeLimit: newProfile.avatarExceedsSizeLimit, maxAvatarUpdatesReached: newProfile.maxAvatarUpdatesReached, 
					success: newProfile.success, error: newProfile.error, linksPerPostExceeded: newProfile.linksPerPostExceeded, usernamesMentionsExceeded: newProfile.usernamesMentionsExceeded, webRiskThreat: newProfile.webRiskThreat, 
					userBlockedDueToWebRiskThreat: newProfile.userBlockedDueToWebRiskThreat
				})
				openSuccessAlert()
			} else {
				navigate('/page/verifyuser')
			}
		} catch (error) {
		}
	}
	
	const handleAccordionChange = (panel) => (event, isExpanded) => {
	  setExpanded(isExpanded ? panel : false);
	};

	useEffect(() => {
		// Your code here, equivalent to component did mount
		setLoading(true)
		getCurrentUserProfile().then((data) => {
			setProfile(data)
			setLoading(false)
		})

	}, []);
	
	const openSuccessAlert = () => {
		setSuccessAlertOpen(true);
	};
	const openDeleteUserSuccessAlert = () => {
		setDeleteUserSuccessAlertOpen(true);
	};
	const openDeleteUserAvatarSuccessAlert = () => {
		setDeleteUserAvatarSuccessAlertOpen(true);
	};
  
	const closeSuccessAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSuccessAlertOpen(false);
	};
	const deleteUserCloseSuccessAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setDeleteUserSuccessAlertOpen(false);
	};
	const deleteUserAvatarCloseSuccessAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setDeleteUserAvatarSuccessAlertOpen(false);
	};

	const [phoneHasBeenUnlinked, setPhoneHasBeenUnlinked] = useState(false);
	const handlePhoneHasBeenUnlinked = () => {
		setPhoneHasBeenUnlinked(true)
	}

	const [openDeleteAvatarModal, setOpenDeleteAvatarModal] = useState(false);
	const [deleteAvatarErrorAlertOpen, setDeleteAvatarErrorAlertOpen] = React.useState(false);
	const handleCloseDeleteAvatarModal = () => {
		setOpenDeleteAvatarModal(false);
	};

	const handleOpenDeleteAvatarModal = () => {
		setOpenDeleteAvatarModal(true);
	};
	
	const openDeleteAvatarErrorAlert = () => {
		setDeleteAvatarErrorAlertOpen(true);
	};
	const closeDeleteAvatarErrorAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setDeleteAvatarErrorAlertOpen(false);
	};
	// alert if user is not verified
	const [openEmailVerificationNeededAlert, setOpenEmailVerificationNeededAlert] = useState(false);
	return (
		
		<Grid item xs>
			<Snackbar open={openEmailVerificationNeededAlert} autoHideDuration={3000} onClose={() => setOpenEmailVerificationNeededAlert(false)}>
				<Alert
					severity="info">
					{t("profileConfiguration.userIsNotVerified")}
				</Alert>
			</Snackbar>

			{profile &&
				(cropAvatarModalOpen ? 
				<Stack>
					<Grid item className="crop-container" sx={{backgroundColor:"background.default"}}>
						<Cropper
							image={uploadedAvatarUrl}
							crop={crop}
							zoom={zoom}
							objectFit="contain"
							aspect={1}
							showGrid={false}
							cropShape="round"
							onCropChange={setCrop}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
							/>
					</Grid>
					<Box item className='controls-background' sx={{backgroundColor: "background.paper"}}/>
					<Grid item className="controls">
						<Slider
							value={zoom}
							min={1}
							max={3}
							step={0.1}
							aria-labelledby="Zoom"
							onChange={(e, zoom) => setZoom(zoom)}
							classes={{ container: 'slider' }}
							/>
						<Button onClick={handleCropAvatarSave} 
							vatiant="contained">
							{t("save")}
						</Button>
						<Button onClick={cropAvatarModalHandleClose} 
							variant="contained"
							color="error">
							{t("cancel")}
						</Button>
					</Grid>
				</Stack> 
				: 			
				
				<Box fullWidth sx={{ mt: 1 }}>	
					<Dialog
						open={usernameModalOpen}
						onClose={handleUsernameModalClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={{padding: "18px!important"}} component="form" noValidate onSubmit={usernameModalHandleSubmit} >			
							<Typography id="modal-modal-title" variant="h2" sx={{paddingBottom: "18px!important"}}>
								{t("profileConfiguration.updateUsername")}
							</Typography>
							{usernameModalError && <Alert severity='error' style={{marginTop: 8, marginBottom: 8}}>{usernameModalError}</Alert>}
							
							<Grid container spacing={2}>
								<Grid item xs={12}>
								
									<TextField
										required
										spellCheck={false}
										fullWidth
										name="username"
										label={t("profileConfiguration.newUsername")}
										id="username"															
									/>
								</Grid>
							</Grid>
							<Stack
								direction="row"
								justifyContent="space-around"
								alignItems="center"
								spacing={2}
								sx={{padding: "10px!important"}}
								>
								<Button
									type="submit"
									variant="contained"
									sx={{ mt: 3, mb: 2, width: 1/2}}
								>
									{t("profileConfiguration.updateUsername")}
								</Button>
								<Button
									style={{marginTop: 7}}
									sx={{ mt: 3, mb: 2, width: 1/2, height: "57px!important"}}
									variant="contained"
									color="error"
									onClick={handleUsernameModalCancel}
								>
									{t("cancel")}
								</Button>
							</Stack>
						</Box>
					</Dialog>
					<Dialog
						open={deleteUserModalOpen}
						onClose={handleDeleteUserModalClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={{padding: "18px!important"}} component="form" noValidate onSubmit={deleteUserModalHandleSubmit} >			
							<Typography id="modal-modal-title" variant="h2" sx={{paddingBottom: "18px!important"}}>
								{t("profileConfiguration.deleteUser")}
							</Typography>		
							<Typography id="modal-modal-description" variant="body1" sx={{paddingBottom: "18px!important"}}>
								{loadingUserDeletion ?
									t("profileConfiguration.loadingUserDeletion")	
									:
									t("profileConfiguration.deleteUserDescription")
								}
							</Typography>
							{deleteUserModalError && <Alert severity='error' style={{marginTop: 8, marginBottom: 8}}>{deleteUserModalError}</Alert>}
							{loadingUserDeletion ?
								<Box sx={{ width: '100%' }}>
									<LinearProgress />
								</Box>
								:
								<>
									<Grid container spacing={2}>
										<Grid item xs={12}>
										
											<TextField
												required
												spellCheck={false}
												fullWidth
												name="username"
												label={t("profileConfiguration.currentUsername")}
												id="username"															
											/>
										</Grid>
									</Grid>
									<Stack
										direction="row"
										justifyContent="space-around"
										alignItems="center"
										spacing={2}
										sx={{padding: "10px!important"}}
										>
										<Button
											type="submit"
											variant="contained"
											sx={{ mt: 3, mb: 2, width: 1/2}}
										>
											{t("profileConfiguration.delete")}
										</Button>
										<Button
											style={{marginTop: 7}}
											sx={{ mt: 3, mb: 2, width: 1/2}}
											variant="contained"
											color="error"
											onClick={handleDeleteUserModalCancel}
										>
											{t("cancel")}
										</Button>
									</Stack>
								</>	
							}
						</Box>
					</Dialog>
					
					<Dialog
						open={openDeleteAvatarModal}
						onClose={handleCloseDeleteAvatarModal}
						sx={{padding: "12px"}}
					>
						<DialogTitle sx={{ m: 0, p: 2 }}>
							<Typography	variant='h2' sx={{paddingBottom: "12px"}}>
								{t('profileConfiguration.deleteAvatarTitle')}
							</Typography>
						</DialogTitle>
						<DialogContent>
							<Typography	variant='body1' sx={{paddingBottom: "12px"}}>
								{t('profileConfiguration.deleteAvatarDescription')}
							</Typography>
							{	loadingAvatarDelete ?
								<Box sx={{ width: '100%' }}>
									<LinearProgress />
								</Box> : null
							}
						</DialogContent>
						<DialogActions>
							<Button variant="contained" color="error" disabled={loadingAvatarDelete} onClick={handleCloseDeleteAvatarModal}>{t('cancel')}</Button>
							<Button variant="contained" onClick={handleDeleteUserAvatarClick} disabled={loadingAvatarDelete} >{t('submit')}</Button>
						</DialogActions>
					</Dialog>
					<Snackbar open={successAlertOpen} autoHideDuration={6000} onClose={closeSuccessAlert}>
						{
							userUpdateMessage && 
							(userUpdateMessage.maxAvatarUpdatesReached === true ? 
								<Alert onClose={closeSuccessAlert} severity="error" sx={{ width: '100%' }}>
									{t("profileConfiguration.avatarUpdatePerDayExceeded")}
								</Alert>
								:
									userUpdateMessage.avatarError === true ?
									<Alert onClose={closeSuccessAlert} severity="error" sx={{ width: '100%' }}>
										{t("profileConfiguration.userUpdateAvatarError")}
									</Alert>
									:
										userUpdateMessage.avatarExplicit === true ?
											<Alert onClose={closeSuccessAlert} severity="error" sx={{ width: '100%' }}>
												{t("profileConfiguration.userUpdateAvatarExplicit")}
											</Alert>
										:
											userUpdateMessage.avatarExceedsSizeLimit === true ?
												<Alert onClose={closeSuccessAlert} severity="error" sx={{ width: '100%' }}>
													{t("profileConfiguration.avatarExceedsSizeLimit")}
												</Alert>
											:
											userUpdateMessage.usernameTaken === true ? 
												<Alert onClose={closeSuccessAlert} severity="error" sx={{ width: '100%' }}>
													{t("profileConfiguration.usernameTaken")}
												</Alert>
											:

												userUpdateMessage.linksPerPostExceeded === true ? 
													<Alert onClose={closeSuccessAlert} severity="error" sx={{ width: '100%' }}>
														{t("profileConfiguration.linksLimitError")}
													</Alert>
												:
													userUpdateMessage.usernamesMentionsExceeded === true ? 
														<Alert onClose={closeSuccessAlert} severity="error" sx={{ width: '100%' }}>
															{t("profileConfiguration.usernamesMentionedLimitError")}
														</Alert>
													:
														userUpdateMessage.webRiskThreat === true ? 
															<Alert onClose={closeSuccessAlert} severity="error" sx={{ width: '100%' }}>
																{t("legals.postHasWebRiskThreat")}
															</Alert>
														:
															userUpdateMessage.userBlockedDueToWebRiskThreat === true ? 
																<Alert onClose={closeSuccessAlert} severity="error" sx={{ width: '100%' }}>
																	{t("legals.userHasBeenBlockedDueToWebRisk")}
																</Alert>
															:
																<Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
																	{t("profileConfiguration.userUpdateSuccess")}
																</Alert>
							)

						}
					</Snackbar>
					<Snackbar open={deleteUserSuccessAlertOpen} autoHideDuration={6000} onClose={deleteUserCloseSuccessAlert}>
						{
							deleteUserUpdateMessage && 
							(deleteUserUpdateMessage.error === true ? 
								<Alert onClose={deleteUserCloseSuccessAlert} severity="error" sx={{ width: '100%' }}>
									{t("profileConfiguration.userDeleteError")}
								</Alert>
								:
								null
							)

						}
					</Snackbar>
					<Snackbar open={deleteUserAvatarSuccessAlertOpen} autoHideDuration={6000} onClose={deleteUserAvatarCloseSuccessAlert}>
						{
							deleteUserAvatarUpdateMessage && 
							(deleteUserAvatarUpdateMessage.error === true ? 
								<Alert onClose={deleteUserAvatarCloseSuccessAlert} severity="error" sx={{ width: '100%' }}>
									{t("profileConfiguration.userAvatarDeleteError")}
								</Alert>
								:
								deleteUserAvatarUpdateMessage.success === true ? 
									<Alert onClose={deleteUserAvatarCloseSuccessAlert} severity="success" sx={{ width: '100%' }}>
										{t("profileConfiguration.userAvatarDeleteSuccess")}
									</Alert>
									:
									null
							)

						}
					</Snackbar>
					
					<Snackbar open={deleteAvatarErrorAlertOpen} autoHideDuration={6000} onClose={closeDeleteAvatarErrorAlert}>
						<Alert onClose={closeDeleteAvatarErrorAlert} severity="error" sx={{ width: '100%' }}>
							{t("profileConfiguration.deleteAvatarError")}
						</Alert>
					</Snackbar>		
					<Box component="form" noValidate onSubmit={handleProfileConfigurationSubmit} >			
						<Grid container direction="column" 
							justifyContent="center"
							alignItems="center"
							spacing={0}>
							<Grid item container>
								<Grid item container direction="column" 
									justifyContent="center"
									alignItems="center" >
									<Grid item>
										{avatarLoading ?
											<Skeleton variant="circular" width={AVATAR_MEDIUM_SIZE} height={AVATAR_MEDIUM_SIZE} /> 
										:
											(previewAvatar ? 
												<IconButton onMouseOver={onAvatarMouseOver} onMouseOut={onAvatarMouseOut} aria-label="upload picture" component="label">
													<input onChange={handleAvatarUpload} hidden  accept="image/jpeg, image/gif, image/png" type="file" />
													<Avatar 
														className='profileConfigurationAvatar'
														sx={{ width: AVATAR_MEDIUM_SIZE, height: AVATAR_MEDIUM_SIZE}}
														src={previewAvatar}>
														{avatarMouseOver ? <PhotoCamera sx={{position:'absolute'}} /> : null}
													</Avatar>
												</IconButton>
											:
												(profile.avatarMediumUrl !== "" ?
													<IconButton onMouseOver={onAvatarMouseOver} onMouseOut={onAvatarMouseOut} aria-label="upload picture" component="label">
														<input onChange={handleAvatarUpload} hidden  accept="image/jpeg, image/gif, image/png" type="file" />
														<Avatar 
															className='profileConfigurationAvatar'
															sx={{ width: AVATAR_MEDIUM_SIZE, height: AVATAR_MEDIUM_SIZE}}
															src={profile.avatarMediumUrl}>
															{avatarMouseOver ? <PhotoCamera sx={{position:'absolute'}} /> : null}
														</Avatar>
													</IconButton>
												: 
													<IconButton onMouseOver={onAvatarMouseOver} onMouseOut={onAvatarMouseOut} aria-label="upload picture" component="label">
														<input onChange={handleAvatarUpload} hidden  accept="image/jpeg, image/gif, image/png" type="file" />
														<Avatar 
															className='profileConfigurationAvatar'
															sx={{ width: AVATAR_MEDIUM_SIZE, height: AVATAR_MEDIUM_SIZE, color:"white!important"}}>
															{avatarMouseOver ? <PhotoCamera sx={{position:'absolute'}} /> : profile.username[0].toUpperCase()}
														</Avatar>
													</IconButton>
												)
											)
										}
										{profile.avatarMediumUrl !== "" ?
										
											<Tooltip title={t("profileConfiguration.userAvatarDeleteTooltip")}>
												<IconButton onClick={handleOpenDeleteAvatarModal} disabled={loadingAvatarDelete} sx={{
													position: "relative",
													width:"25px",
													height:"25px",
													marginBottom: "64px",
													marginRight:"-25px"
												}}>
													<ClearIcon fontSize='small'/>
												</IconButton>
											</Tooltip>
											: null
										}
									</Grid>

									<Grid item>
										<Typography variant="h5" sx={{marginLeft: 6}}>
											{username ? username : profile.username}
											<IconButton style={{marginRight: 6}} color="primary" component="label" onClick={handleUsernameModalOpen}>
												<EditIcon fontSize='small' />
											</IconButton>
										</Typography>
									</Grid>

								</Grid>
								<Card sx={{  marginTop: '12px', marginBottom: '12px', flexGrow: 1, padding: '16px!important'}} className='CustomCard1' >	
									<Grid item container direction="row" spacing={0} sx={{marginTop: '20px'}}>
											<Grid item sx={{marginTop: '3px', marginRight: '6px'}}>
												<Typography variant="h4">{t("bio") + ": "}</Typography>
											</Grid>
											<Grid item xs>
												<TextField 
													name="bio"
													id="bio"	
													type='text' 
													defaultValue={profile.bio}
													variant='standard'
													spellCheck={false}
													multiline
													fullWidth
													maxRows={6}
													helperText={bioReadOnly ? null : `${bio.length}/${BIO_CHARACTER_LIMIT}`}
													onKeyUp={handleBioChange}
													inputProps={
														{ 
															readOnly: bioReadOnly,
															maxLength: BIO_CHARACTER_LIMIT
														}
													}
													InputProps={{
														disableUnderline: bioReadOnly,
													}}
												/>
											</Grid>
											<Grid item>
												<IconButton  style={{marginRight: 6}} color="primary" component="label" onClick={handleBioReadOnlyChange}>
													<EditIcon fontSize='small'/>
												</IconButton>
											</Grid>
									</Grid>
								</Card>
								{
									(username || bio || uploadedAvatar)	?
								<>

									<Button
										disabled={loading}
										type="submit"
										variant="contained"
										sx={{ mt: 3, mb: 2, height: 'fit-content!important', alignSelf:'center!important' }}
										>
										{t("profileConfiguration.saveProfile")}
									</Button>
									<Button
										disabled={loading}
										variant="contained"
										color="error"
										sx={{ mt: 3, mb: 2, marginLeft: 2, height: 'fit-content!important', alignSelf:'center!important' }}
										onClick={handleClearProfileData}
										>
										{t("clear")}
									</Button>
									</>
									: null
								}
							</Grid>
						</Grid>
					</Box>
					<div>
						<Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon fontSize='small'/>}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
								>
								<Typography variant="h6" sx={{ width: '33%', flexShrink: 0 }}>
									{t("profileConfiguration.loginSettings")}
								</Typography>
								<Typography sx={{ color: 'text.secondary' }}>{t("profileConfiguration.emailAndPassword")}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container direction="column" spacing={2}>
									<Grid item>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
											spacing={2}
											>
											<div>
												<Typography variant="body1" display="inline" sx={{mr: 6}}>{t("email")}</Typography>
												<Typography variant="body2" display="inline" > {currentUser.email}</Typography>		
											</div>
											<ChangeEmailModal/>
										</Stack>
									</Grid>	
									<Divider/>
									<Grid item>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
											spacing={2}
											>
											<div>
												<Typography variant="body1" display="inline" sx={{mr: 6}}>{t("password")}</Typography>	
											</div>
											<ChangePasswordModal/>
										</Stack>
									</Grid>	
									<Divider/>
									<Grid item>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
											spacing={2}
											>
												<div>
													<Typography variant="body1" display="inline" sx={{mr: 6}}>{t("phone")}</Typography>	
													<Typography variant="body2" display="inline" >  {phoneHasBeenUnlinked !== true ? currentUser.phoneNumber : null}</Typography>		
												</div>
												{phoneHasBeenUnlinked !== true ? 
													<LinkPhoneNumberComponent profileConfigurationComponent = {true} handlePhoneHasBeenUnlinked={() => handlePhoneHasBeenUnlinked()}/> : null}
											
										</Stack>
									</Grid>	
								</Grid>
							</AccordionDetails>
						</Accordion>
						<Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon fontSize='small' />}
								aria-controls="panel2bh-content"
								id="panel2bh-header"
								>
								<Typography variant="h6" sx={{ width: '33%', flexShrink: 0 }}>
									{t("profileConfiguration.contentSettings")}
								</Typography>
								<Typography sx={{ color: 'text.secondary' }}>{t("profileConfiguration.contentSettingsAndAccountDeletion")}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container 
									direction="column"
									spacing={2}
									>
									<Grid item>
										<Typography variant="body1">
											{t("profileConfiguration.deleteAccountExplanation")}
										</Typography>
									</Grid>
									<Grid item>
										<Button onClick={handleDeleteUserModalOpen} variant="contained" startIcon={<DeleteIcon fontSize='small'/>} color="error">{t("profileConfiguration.deleteAccount")}</Button>
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
					</div>
				</Box>	
				)
			}
		</Grid>
		
	)
}

