import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json'
import translationES from './locales/es/translation.json'

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  }
};

// WHEN ADDING TRANSLATIONS REMEMBER TO TRANSLATE DATES.JS
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'es',//fallbackLng: 'en', //to support english as well uncomment this
    load: "languageOnly",
    debug: true,
    supportedLngs: ["es"],//["en","es"], //to support english as well uncomment this IMPORTANT: FORMAT OF LANGUAGE SHOULD BE THE SAME AS THE ONE USED TO SEND POLICY UPDATE NOTIFICATIONS
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }, 
  });


export default i18n;