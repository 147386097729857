import './NotificationsComponent.css'
import React, {useState, useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { AVATAR_XS_SIZE, NOTIFICATIONS_MENU_WIDTH } from '../../utils/Constants';
import ListItem from '@mui/material/ListItem';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import { deleteTopicInviteNotificationData, deleteTopicReplyNotificationData, deleteFollowNotificationData, getUserNotifications, deleteReportNotifications } from '../../firebaseFunctions/spannerFunctions/notificationsFunctions';


import { useTranslation } from 'react-i18next';
import ReportedTopicTitleComponent from '../ReportedTopicTitleComponent';
import { getCurrentUserProfile } from '../../firebaseFunctions/spannerFunctions/profileFunctions';

export default function NotificationsComponent() {
	const { t } = useTranslation();
	const [moreAnchorEl, setMoreAnchorEl] = useState(null);
	const isMenuOpen = Boolean(moreAnchorEl);
	const [followsCounter, setFollowsCounter] = useState(0);
	const [topicInvitesCounter, setTopicInvitesCounter] = useState(0);
	const [topicRepliesCounter, setTopicRepliesCounter] = useState(0);
	const [notificationQty, setNotificationQty] = useState(0);
	const [lastFollowerUser, setLastFollowerUser] = useState(null); // {username:, userId:}
	const [lastTopicInviteTopic, setLastTopicInviteTopic] = useState(null); // {title:, topicId:}
	const [lastTopicReplyDiscussion, setLastTopicReplyDiscussion] = useState(null); // {title:, topicId:, discussionId:}
	const [lastTopicInviteUser, setLastTopicInviteUser] = useState(null); // {username:, userId:}
	const [lastTopicReplyUser, setLastTopicReplyUser] = useState(null); // {username:, userId:}
	const [randomUsernameAssignedNotification, setRandomUsernameAssignedNotification] = useState(0);
	const [avatarReportedAndDeletedNotification, setAvatarReportedAndDeletedNotification] = useState(0);
	const [bioReportedAndDeletedNotification, setBioReportedAndDeletedNotification] = useState(0);
	const [loading, setLoading] = useState(false);
	
	
	
	const navigate = useNavigate()
	const handleMenuClose = () => {
	  	setMoreAnchorEl(null);
	};
  
	const handleMenuOpen = (event) => {
		setMoreAnchorEl(event.currentTarget);
	};
	

	useEffect(() => {
		getUserNotifications().then((res) => {
			const notifications = res[0]
			setFollowsCounter(notifications.followsCounter)
			setTopicInvitesCounter(notifications.topicInvitesCounter)
			setTopicRepliesCounter(notifications.topicRepliesCounter)
			setNotificationQty(notifications.followsCounter + notifications.topicInvitesCounter + notifications.topicRepliesCounter + notifications.randomUsernameAssigned + notifications.avatarReportedAndDeleted + notifications.bioReportedAndDeleted)
			
			setLastFollowerUser({avatarSmallUrl: notifications.lastFollowerUserAvatarSmallUrl, username: notifications.lastFollowerUserUsername, userId: notifications.lastFollowerUserUserId})
			setLastTopicInviteTopic({title: notifications.lastTopicInviteTopicTitle, lastTopicInviteTopicReportedTitle: notifications.lastTopicInviteTopicReportedTitle,topicId: notifications.lastTopicInviteTopicTopicId})
			setLastTopicInviteUser({avatarSmallUrl: notifications.lastTopicInviteUserAvatarSmallUrl, username: notifications.lastTopicInviteUserUsername, userId: notifications.lastTopicInviteUserUserId})
			setLastTopicReplyDiscussion({title: notifications.lastTopicReplyDiscussionTitle, lastTopicReplyDiscussionReportedTitle: notifications.lastTopicReplyDiscussionReportedTitle, topicId: notifications.lastTopicReplyTopicId, discussionId: notifications.lastTopicReplyDiscussionId})
			setLastTopicReplyUser({avatarSmallUrl: notifications.lastTopicReplyUserAvatarSmallUrl, username: notifications.lastTopicReplyUserUsername, userId: notifications.lastTopicReplyUserUserId})

			setRandomUsernameAssignedNotification(notifications.randomUsernameAssigned)
			setAvatarReportedAndDeletedNotification(notifications.avatarReportedAndDeleted)
			setBioReportedAndDeletedNotification(notifications.bioReportedAndDeleted)
		})

		return () => {
		}
	}, [])


	// Follow notification
	async function handleNavigateToUserFollowNotification(username) {
		setLoading(true)
		deleteFollowNotificationData()
		navigate('/' + username)
		setLoading(false)
	}
	async function handleNavigateToManageFollowNotification() {
		setLoading(true)
		deleteFollowNotificationData()
		const currentUserProfile = await getCurrentUserProfile()
		navigate(`/${currentUserProfile.username}`)
		setLoading(false)
	}

	// Topic invite notification
	async function handleNavigateToUserTopicInviteNotification(username) {
		setLoading(true)
		deleteTopicInviteNotificationData()
		navigate('/' + username)
		setLoading(false)
	}
	async function handleNavigateToReplyTopicInviteNotification(topicId) {
		setLoading(true)
		deleteTopicInviteNotificationData()
		navigate('/topic/' + topicId)
		setLoading(false)
	}
	async function handleNavigateToManageTopicInviteNotification() {
		setLoading(true)
		deleteTopicInviteNotificationData()
		navigate('/page/manage/')
		setLoading(false)
	}

	// Topic reply notification
	async function handleNavigateToUserTopicReplyNotification(username) {
		setLoading(true)
		deleteTopicReplyNotificationData()
		navigate('/' + username)
		setLoading(false)
	}
	async function handleNavigateToTopicTopicReplyNotification(topicId, discussionId) {
		setLoading(true)
		deleteTopicReplyNotificationData()
		navigate('/topic/' + topicId + "/discussion/" + discussionId)
		setLoading(false)
	}
	async function handleNavigateToManageTopicReplyNotification() {
		setLoading(true)
		deleteTopicReplyNotificationData()
		navigate('/page/manage/')
		setLoading(false)
	}
	async function handleNavigateToProfileReportNotification() {
		setLoading(true)
		deleteReportNotifications()
		navigate('/page/profile/')
		setLoading(false)
	}



	return (
		<>
		
			<IconButton
				size="large"
				aria-label="notifications"
				color="inherit"
				aria-controls={'notifications-menu'}
				aria-haspopup="true"
				onClick={handleMenuOpen}
				disabled={loading}
				>
				{notificationQty > 0 ?
					<Badge badgeContent={notificationQty} color="error" max={99}>
						<NotificationsIcon />
					</Badge>
				:
					<NotificationsIcon />
				}
			</IconButton>
			<Menu
				anchorEl={moreAnchorEl}
				anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
				}}
				id={'notifications-menu'}
				keepMounted
				transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
				}}
				open={isMenuOpen}
				onClose={handleMenuClose}
			>
				{notificationQty ?
					<List
						sx={{ width: NOTIFICATIONS_MENU_WIDTH, bgcolor: 'inherit!important', padding: 0 }}
						component="nav"
						aria-labelledby="nested-list-subheader"
						>
						{followsCounter > 0 ? 
						<>
							<ListItem>
								{followsCounter > 1 ? 
									<Typography variant="subtitle1" sx={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "-webkit-box",
										WebkitLineClamp: "1",
										WebkitBoxOrient: "vertical"}}>	
										<Badge badgeContent={followsCounter} sx={{marginLeft: "18px", marginRight: "12px"}} color="error" max={99}/>	
										{lastFollowerUser.username
											?
											<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToUserFollowNotification(lastFollowerUser.username)} 
												disabled={loading}
												startIcon={lastFollowerUser.avatarSmallUrl ? <Avatar src={lastFollowerUser.avatarSmallUrl} sx={{width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important"}}/> : <Avatar sx={{ color:"white!important",fontSize: '14px!important',width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important" }}>{lastFollowerUser.username[0].toUpperCase()}</Avatar>}>
												<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
													{lastFollowerUser.username}
												</Typography>
											</Button>										
											: null
										}											
										<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToManageFollowNotification()}
											disabled={loading}>
											<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
												{t("notifications.moreUsersFollowingYouWithoutLastFollow")}
											</Typography>
										</Button>
									</Typography>
								:
									<Typography variant="subtitle1" sx={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "-webkit-box",
										WebkitLineClamp: "2",
										WebkitBoxOrient: "vertical"}}>			
										
										{lastFollowerUser.username ?									
											<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToUserFollowNotification(lastFollowerUser.username)}
												disabled={loading}
												startIcon={lastFollowerUser.avatarSmallUrl ? <Avatar src={lastFollowerUser.avatarSmallUrl} sx={{width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important"}}/> : <Avatar sx={{ color:"white!important",fontSize: '14px!important',width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important" }}>{lastFollowerUser.username[0].toUpperCase()}</Avatar>}>
												<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
													{lastFollowerUser.username + " " + t("notifications.isFollowingYou")}
												</Typography>
											</Button>
											:									
											<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToManageFollowNotification()}
												disabled={loading}>
												<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
													{t("notifications.moreUsersFollowingYouWithoutLastFollow")}
												</Typography>
											</Button>
										}
									</Typography>
								}
							</ListItem>
							{topicInvitesCounter > 0 || topicRepliesCounter > 0 ?
								<Divider />
								:
								null
							}
						</>
						:
						null}
						{topicInvitesCounter > 0 ? 
							<>
								<ListItem>
									{topicInvitesCounter > 1 ? 
										<Typography variant="subtitle1" sx={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											display: "-webkit-box",
											WebkitLineClamp: "2",
											WebkitBoxOrient: "vertical"}}>	
											<Badge badgeContent={topicInvitesCounter} sx={{marginLeft: "18px", marginRight: "12px"}} color="error" max={99}/>	
											{
												lastTopicInviteUser.username && lastTopicInviteTopic.title ?
														
												<>
													<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToUserTopicInviteNotification(lastTopicInviteUser.username)}
													disabled={loading}
													startIcon={lastTopicInviteUser.avatarSmallUrl ? <Avatar src={lastTopicInviteUser.avatarSmallUrl} sx={{width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important"}}/> : <Avatar sx={{ color:"white!important",fontSize: '14px!important',width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important" }}>{lastTopicInviteUser.username[0].toUpperCase()}</Avatar>}>
														<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
															{lastTopicInviteUser.username}
														</Typography>
													</Button>
													{" " + t("notifications.hasInvitedToHaveDiscussion") + " " }											
													<Button className='NotificationsButton'  sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToReplyTopicInviteNotification(lastTopicInviteTopic.topicId)}
													disabled={loading}>
														<Typography className='NotificationsButtonTypography'  variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
															{
																lastTopicInviteTopic.lastTopicInviteTopicReportedTitle === true ?
																	<ReportedTopicTitleComponent />
																	:
																	lastTopicInviteTopic.title
															}
														</Typography>
													</Button>
													{" " + t("notifications.andYouHave") + " " }										
													<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToManageTopicInviteNotification()}
													disabled={loading}>
														<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
															{topicInvitesCounter - 1 + " " + t("notifications.moreDiscussionInvites")}
														</Typography>
													</Button>
												</>
												:				
												<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToManageTopicInviteNotification()}
												disabled={loading}>
													<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
														{t("notifications.moreDiscussionInvites")}
													</Typography>
												</Button>
											}									
										</Typography>
									:
										<Typography variant="subtitle1" sx={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											display: "-webkit-box",
											WebkitLineClamp: "2",
											WebkitBoxOrient: "vertical"}}>	
											
											{
												lastTopicInviteUser.username && lastTopicInviteTopic.title ?
												<>
																						
													<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToUserTopicInviteNotification(lastTopicInviteUser.username)}
													disabled={loading}
													startIcon={lastTopicInviteUser.avatarSmallUrl ? <Avatar src={lastTopicInviteUser.avatarSmallUrl} sx={{width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important"}}/> : <Avatar sx={{ color:"white!important",fontSize: '14px!important',width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important" }}>{lastTopicInviteUser.username[0].toUpperCase()}</Avatar>}>
														<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
															{lastTopicInviteUser.username}
														</Typography>
													</Button>	
													{" " + t("notifications.hasInvitedToHaveDiscussion") + " " }							
													<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToReplyTopicInviteNotification(lastTopicInviteTopic.topicId)}
													disabled={loading}>
														<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
															{
																lastTopicInviteTopic.lastTopicInviteTopicReportedTitle === true ?
																	<ReportedTopicTitleComponent />
																	:
																	lastTopicInviteTopic.title
															}
														</Typography>
													</Button>
												</>		
												:
													
												<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToManageTopicInviteNotification()}
												disabled={loading}>
													<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
														{t("notifications.moreDiscussionInvitesWithoutLastInvite")}
													</Typography>
												</Button>
											}		
										</Typography>
									}
								</ListItem>
								
								{topicRepliesCounter > 0 ?
									<Divider />
									:
									null
								}
							</>
						:
						null}
						{topicRepliesCounter > 0 ? 
							<ListItem>
								{topicRepliesCounter > 1 ? 
									<Typography variant="subtitle1" sx={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "-webkit-box",
										WebkitLineClamp: "2",
										WebkitBoxOrient: "vertical"}}>		
										<Badge badgeContent={topicRepliesCounter} sx={{marginLeft: "18px", marginRight: "12px"}} color="error" max={99}/>	
										{
											lastTopicReplyUser.username && lastTopicReplyDiscussion.title ?
											<>				
												<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToUserTopicReplyNotification(lastTopicReplyUser.username)}
													disabled={loading}
													startIcon={lastTopicReplyUser.avatarSmallUrl ? <Avatar src={lastTopicReplyUser.avatarSmallUrl} sx={{width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important"}}/> : <Avatar sx={{ color:"white!important",fontSize: '14px!important',width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important" }}>{lastTopicReplyUser.username[0].toUpperCase()}</Avatar>}>
													<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
														{lastTopicReplyUser.username}
													</Typography>
												</Button>		
												{" " + t("notifications.hasRepliedToYouOn") + " "}									
												<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToTopicTopicReplyNotification(lastTopicReplyDiscussion.topicId, lastTopicReplyDiscussion.discussionId)}
													disabled={loading}>
													<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
														{
															lastTopicReplyDiscussion.lastTopicReplyDiscussionReportedTitle === true ?
																<ReportedTopicTitleComponent />
																:
																lastTopicReplyDiscussion.title
														} 
													</Typography>
												</Button>	
												{" " + t("notifications.andYouHave") + " " }							
												<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToManageTopicReplyNotification()}
													disabled={loading}>
													<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
														{topicRepliesCounter - 1 + " " + t("notifications.moreDiscussionReplies")}
													</Typography>
												</Button>	
											</>
											:
											<>						
												<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToManageTopicReplyNotification()}
													disabled={loading}>
													<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
														{t("notifications.moreDiscussionReplies")}
													</Typography>
												</Button>	
											</>
										}														
									</Typography>
								:
									lastTopicReplyDiscussion ? 
									<Typography variant="subtitle1" sx={{
										overflow: "hidden",
										textOverflow: "ellipsis",
										display: "-webkit-box",
										WebkitLineClamp: "2",
										WebkitBoxOrient: "vertical"}}>	
										
										{lastTopicReplyUser.username && lastTopicReplyDiscussion.title ?
										<>
											<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}, margin: "0px!important"}} onClick={() => handleNavigateToUserTopicReplyNotification(lastTopicReplyUser.username)}
												disabled={loading}
												startIcon={lastTopicReplyUser.avatarSmallUrl ? <Avatar src={lastTopicReplyUser.avatarSmallUrl} sx={{width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important"}}/> : <Avatar sx={{ color:"white!important",fontSize: '14px!important',width: AVATAR_XS_SIZE + "px!important", height: AVATAR_XS_SIZE + "px!important" }}>{lastTopicReplyUser.username[0].toUpperCase()}</Avatar>}>
												<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
													{lastTopicReplyUser.username}
												</Typography>
											</Button>		
											{" " + t("notifications.hasRepliedToYouOn")}						
											<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}, margin: "0px!important"}}
												disabled={loading}
												onClick={() => handleNavigateToTopicTopicReplyNotification(lastTopicReplyDiscussion.topicId, lastTopicReplyDiscussion.discussionId)}>
												<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
													{
														lastTopicReplyDiscussion.lastTopicReplyDiscussionReportedTitle === true ?
															<ReportedTopicTitleComponent />
															:
															lastTopicReplyDiscussion.title
													}
												</Typography>
											</Button>	
										</>
										:
										<>						
											<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToManageTopicReplyNotification()}
												disabled={loading}>
												<Typography className='NotificationsButtonTypography' variant="subtitle1" sx={{ textTransform: 'lowercase' }}>
													{t("notifications.moreDiscussionRepliesWithoutLastReply")}
												</Typography>
											</Button>	
										</>
														
										}
									</Typography>
									: null
								}
							</ListItem>
						:
						null}
						{
							randomUsernameAssignedNotification > 0 ? 
								<ListItem>
									<Typography variant="subtitle1">	
										<>						
											<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToProfileReportNotification()}
												disabled={loading}>
												<Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
													{t("notifications.randomUsernameAssignedNotification")}
												</Typography>
											</Button>	
										</>
									</Typography>
								</ListItem>
							:
								null
						}
						{
							avatarReportedAndDeletedNotification > 0 ? 
								<ListItem>
									<Typography variant="subtitle1">	
										<>						
											<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToProfileReportNotification()}
												disabled={loading}>
												<Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
													{t("notifications.avatarReportedAndDeletedNotification")}
												</Typography>
											</Button>	
										</>
									</Typography>
								</ListItem>
							:
								null
						}
						{
							bioReportedAndDeletedNotification > 0 ? 
								<ListItem>
									<Typography variant="subtitle1">	
										<>						
											<Button className='NotificationsButton' sx={{"&:hover": {backgroundColor: 'transparent', textDecoration: 'none'}}} onClick={() => handleNavigateToProfileReportNotification()}
												disabled={loading}>
												<Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
													{t("notifications.bioReportedAndDeletedNotification")}
												</Typography>
											</Button>	
										</>
									</Typography>
								</ListItem>
							:
								null
						}
					</List>
					:
					<List>
						<ListItem>
							<Typography className='NotificationsButtonTypography' variant="caption">{t("notifications.noNotifications")}</Typography>
						</ListItem>
					</List>
					}
			</Menu>
		</>
	)
}
