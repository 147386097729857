import React, { createContext, useState, useEffect } from 'react'
import { MAX_DOCUMENT_TITLE_LENGTH } from '../utils/Constants'
import { useTranslation } from 'react-i18next';

export const DocumentTitleContext = createContext()

export const DocumentTitleProvider = ({ children }) => {
    const [documentTitle, setDocumentTitle] = useState(document.title)
	const { t } = useTranslation();

    useEffect(() => {
        let newDocumentTitle = documentTitle

        if (newDocumentTitle === "" || newDocumentTitle === undefined || newDocumentTitle === null) {
            document.title = t('documentTitles.intergraphy')
        } else {
            if (newDocumentTitle.length > MAX_DOCUMENT_TITLE_LENGTH) {
                newDocumentTitle = documentTitle.substring(0, MAX_DOCUMENT_TITLE_LENGTH) + '...';
                document.title = newDocumentTitle
            } else {
                document.title = newDocumentTitle
            }
        }
    }, [documentTitle])

    return (
        <DocumentTitleContext.Provider value={{ documentTitle, setDocumentTitle }}>
            {children}
        </DocumentTitleContext.Provider>
    )
}