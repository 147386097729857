import React, {useState, useContext, useEffect} from 'react';
import { AvatarPreviewComponent, FollowListModalComponent, StickyCollapsibleInfoBarComponent, ScrollToTopComponent, LoginToUseDialogComponent, TopicSummaryItem, PostAdComponent, ReportComponent, UserDetailedBioDialogComponent, PostWithLinksComponent} from '..'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { auth } from "../../firebase";
import { AVATAR_MEDIUM_SIZE, AVATAR_SMALL_SIZE, MAX_LAPTOP_WIDTH, POST_AD_FREQUENCY, STICKY_COLLAPSIBLE_BIO_MAX_HEIGHT } from '../../utils/Constants';
import { Button } from '@mui/material';
import { getUserPosts } from '../../firebaseFunctions/spannerFunctions/topicFunctions';
import { followUser, isUserFollowed, unfollowUser } from '../../firebaseFunctions/spannerFunctions/followerFunctions';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { ShortenNumber, ShortenUserPointsNumber } from '../../utils/NumberShortener';
import CircularProgress from '@mui/material/CircularProgress';
import { getUserProfile, updateUserCounters } from '../../firebaseFunctions/spannerFunctions/profileFunctions';
import StarIcon from '@mui/icons-material/Star';
import Stack from '@mui/material/Stack';
import { DocumentDescriptionContext } from '../../contexts/DocumentDescriptionContext';
import UserStructuredData from '../../utils/structuredData/UserStructuredData';
  
export default function UserTopicHistoryComponent(props) {
	
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false)
	const [startloading, setStartLoading] = useState(false)
	const [postList, setPostList] = useState([])
	const [userId, setUserId] = useState(props.userId)
	const [user, setUser] = useState()
	const [following, setFollowing] = useState()
	const navigate = useNavigate()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const [followingsLimit, setFollowingLimit] = useState(false)
	const [oneNewFollowValue, setOneNewFollowValue] = useState(0)
	const { setDocumentDescription } = useContext(DocumentDescriptionContext)
	const [scrollHeight, setScrollHeight] = useState(0)
	const [scrollTop, setScrollTop] = useState(0)
	const [loadingMoreOlderPosts ,setLoadingMoreOlderPosts] = useState(false)
	const [isLastPost, setIsLastPost] = useState(false)
	const [loadingMoreNewerPosts ,setLoadingMoreNewerPosts] = useState(false)

    const setWindowDimensions = () => {
      setWindowWidth(window.innerWidth)
    }	

	async function handleFollowFunction(event) {
		event.preventDefault()
		if (auth.currentUser.emailVerified) {

			setLoading(true)
			if (following === true){
				const unfollowingValue = await unfollowUser(userId)
				if (unfollowingValue === true){
					setFollowing(false)
					setOneNewFollowValue((prevVal) => {
						const newVal = prevVal - 1 
						return newVal
					})
				}
			} 
			if (following === false){
				const followingValue = await followUser(userId)
				if (followingValue.res === true){
					setFollowing(true)
					setOneNewFollowValue((prevVal) => {
						const newVal = prevVal + 1 
						return newVal
					})
				}
				
				setFollowingLimit(followingValue.followingLimit)
			}
			setLoading(false)
		} else {
			navigate('/page/verifyuser')
		}
	}
	useEffect(() => {  
		// Anything in here is fired on component mount.
		setStartLoading(true)

		const handleScroll = () => {
			setScrollHeight(document.documentElement.scrollHeight - document.documentElement.clientHeight)
			setScrollTop(window.scrollY || document.documentElement.scrollTop)	
		}
		// Get user data:
		getUserPosts(userId, null).then((res) => {
			setPostList(res.data)
			setIsLastPost(res.isLastPost)
			setStartLoading(false)
			getUserProfile(userId).then((userData) => {
				setUser(userData)
				if (userData.bio !== "" && userData.bio !== null && userData.bio !== undefined){
					setDocumentDescription(userData.bio.toString())
				}
				isUserFollowed(userId).then((res2) => {
					setFollowing(res2)
				})
				updateUserCounters(userId)
			})
		})
		
		window.addEventListener('scroll', handleScroll);
		window.addEventListener('resize', setWindowDimensions);
		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', setWindowDimensions)
		}
    }, [])

	useEffect(() => {
		if (scrollTop === scrollHeight) {
			if (postList.length > 0 && !loading && !loadingMoreOlderPosts && !isLastPost){
				// fetch older posts
				handleLoadOldPosts()
			}
		}
	}, [scrollTop, scrollHeight])


	async function handleLoadOldPosts() {
		setLoadingMoreOlderPosts(true)

		getUserPosts(userId, postList[postList.length-1].postId).then((res) => {
			if (res !== undefined) {
				setPostList((prevList) => {
					const newList = [...prevList,...res.data]
					return newList
				})
				setIsLastPost(res.isLastPost)
			}
			setLoadingMoreOlderPosts(false)
		})
	}
	

	return ( 		<>
		{startloading ?    
		
		<Box sx={{ display: 'flex',
			alignContent: 'center', 
			height: "100vh",
			alignItems:"center",
			justifyContent:"center"}}>
			<CircularProgress />
		</Box> 
		: <>			
			<Grid item xs>
				{user && 
					<>
						<UserStructuredData timestamp={user.unixMillisUserTimestamp} username={user.username} followers={user.followers} discussionsCounter={user.discussionsCounter} following={user.following} bio={user.bio} 
						avatarSmallUrl={user.avatarSmallUrl} avatarMediumUrl={user.avatarMediumUrl} avatarBigUrl={user.avatarBigUrl} lastProfileUpdate={user.unixMillisLastProfileUpdateTimestamp}/>
						
						<Snackbar open={followingsLimit} autoHideDuration={6000} >
							<Alert severity='error' sx={{ width: '100%' }}>{t("userTopicHistory.followingLimit")}</Alert>
						</Snackbar>
					</>
				}
				
				<Grid container 
					justifyContent="center"
					alignItems="center" >
					<Grid item xs={12}>
					
						<StickyCollapsibleInfoBarComponent 
							userStickyCollapsibleInfoBar={true}
							fixedComponent={
								<Grid sx={{ marginTop: "8px"}} container
									direction="row"
									justifyContent="space-between"
									alignItems="center" >
									<Grid item xs={7}
										container
										direction="row"
										justifyContent="flex-start"
										alignItems="center" >
										<Grid item xs="auto">
											{user ? 
											
												<AvatarPreviewComponent avatar={user.avatarMediumUrl}
													avatarBig={user.avatarBigUrl}
													user={user}
													size={windowWidth < MAX_LAPTOP_WIDTH ? AVATAR_SMALL_SIZE + "px" : AVATAR_MEDIUM_SIZE + "px"}/>
											:
												<Skeleton animation="wave" variant="circular" width={windowWidth < MAX_LAPTOP_WIDTH ? AVATAR_SMALL_SIZE : AVATAR_MEDIUM_SIZE } height={windowWidth < MAX_LAPTOP_WIDTH ? AVATAR_SMALL_SIZE : AVATAR_MEDIUM_SIZE } />
											}
										</Grid> 
										<Grid item xs={8} container
											direction="column"
											justifyContent="flex-start" sx={{marginLeft: "12px"}}>
											<Grid item xs={6} sx={{marginTop: "18px", width: "inherit!important"}}>
												{user ? 
													<Typography noWrap variant="body2" color="text.primary" sx={{marginLeft: '6px', textOverflow: "ellipsis!important"}}>
														{user.username}
													</Typography>
													:
													<Skeleton animation="wave" height={25} width="80%" />
												}
											</Grid>
											<Grid item xs={6}>
												{user &&
													<Grid direction="row" container 
														justifyContent="flex-start">
														<Grid item xs="auto">
															{auth.currentUser ?
																<FollowListModalComponent type='following' quantity={ShortenNumber(user.following)} userData={user} userId={userId}/>
															:
																<LoginToUseDialogComponent type='following' quantity={ShortenNumber(user.following)} userData={user} userId={userId}/>	
															}
														</Grid>
														<Grid item xs="auto">
															{auth.currentUser ?
																<FollowListModalComponent type='followers' quantity={ShortenNumber(user.followers + oneNewFollowValue)} userData={user} userId={userId}/>
															:
																<LoginToUseDialogComponent  type='followers' quantity={ShortenNumber(user.followers + oneNewFollowValue)} userData={user} userId={userId}/>
															}
														</Grid>
													</Grid>
												}
											</Grid>
										</Grid>
									</Grid>
									{user &&
										<Grid item xs={5}>
											{auth.currentUser && (following === false || following === true) && userId !== auth.currentUser.uid ? 
												<>
													<ReportComponent userId={userId} type="userPage" bio={user.bio} avatar={user.avatarBigUrl}/> 
													<ScrollToTopComponent />
													<Button variant={following ? "contained": "outlined"} 
														sx={{
															float:"right",
															borderRadius: 5,
															padding: '4px',
															fontSize: '10.5px',
															marginRight:'12px'}} 
														disabled={loading || followingsLimit} onClick={handleFollowFunction}>{following ? t("unfollow") : t("follow")}</Button>
													
												</> : null}
										</Grid>
									}
								</Grid>
							}
							expandedComponent={
								user &&
								<>
									<Stack direction='row'>

										<Typography variant="body1" sx= {{ maxHeight: STICKY_COLLAPSIBLE_BIO_MAX_HEIGHT + "px", marginLeft: "18px", marginRight: "12px", overflow: "auto"}}>						
											<PostWithLinksComponent showReferencedPosts={false} post={user.bio} localLinksData={[]} type='bio'/>
										</Typography>
										{user.bio && user.bio.length > 0 ?									
											<UserDetailedBioDialogComponent user={user} userId={userId} oneNewFollowValue={oneNewFollowValue}/>
											: null
										}
									</Stack>
									
									<Divider sx={{marginTop:'10px'}} variant="middle" />
									<Grid direction="row" container 
										alignItems="center" sx={{textAlign: 'center', marginTop:'6px', display: "flex", justifyContent: "space-around"}}>
										<Grid item sx={{flex: "1"}}>
											<Typography variant="body1" color="text.secondary">
												{t('userTopicHistory.points')}
											</Typography>
											<Typography variant="body2" color="text.secondary">
												{ShortenUserPointsNumber(user.points.toFixed(0))}
											</Typography>
										</Grid>
										<Divider orientation="vertical" variant="middle" flexItem />
										<Grid item sx={{flex: "1"}}>
											<Typography variant="body1" color="text.secondary">
												{t('userTopicHistory.discussions')}
											</Typography>
											<Typography  variant="body2" color="text.secondary">
												{ShortenNumber(user.discussionsCounter)}
											</Typography>
										</Grid>
										<Divider orientation="vertical" variant="middle" flexItem />
										<Grid item sx={{flex: "1"}}>
											<Typography variant="body1" color="text.secondary">
												{t('userTopicHistory.ratingMean')}
											</Typography>
											
											<Typography variant="body2" color="text.secondary">
												<Stack direction='row'
													justifyContent="center"
													alignItems="center" sx={{textAlign: 'center'}}>
													<StarIcon fontSize='small' style={{ opacity: 0.55, color: '#faaf00' }}/>
													{user.discussionsRatingsSum !== 0 ? (user.discussionsRatingsSum/user.discussionsRatingsCounter).toFixed(1) : "-"}
												</Stack>
											</Typography>
										</Grid>
									</Grid>
								</>
							}
							collapsedComponent={
								<>
									<Grid sx={{height: "inherit!important", width: "inherit!important"}} container
										direction="row"
										justifyContent="space-between"
										alignItems="center">
										<Grid item xs={5}
											container
											direction="row"
											justifyContent="flex-start"
											alignItems="center">
											<Grid item xs="auto">
												
												{user ?
													<AvatarPreviewComponent avatar={user.avatarMediumUrl}
														avatarBig={user.avatarBigUrl}
														user={user}
														size={AVATAR_SMALL_SIZE + "px"}/>
													:
													<Skeleton animation="wave" variant="circular" width={AVATAR_SMALL_SIZE} height={AVATAR_SMALL_SIZE} />
												}

											</Grid> 
											<Grid item xs sx={{marginLeft: "8px"}}>
												{user ?
													<Typography noWrap variant="body2" color="text.primary" sx={{ textOverflow: "ellipsis"}}>
														{user.username}
													</Typography>
													:
													<Skeleton animation="wave" height={25} width="80%" />
												}
											</Grid>
										</Grid>
										<Grid item xs={7}>
											{auth.currentUser && (following === false || following === true) && userId !== auth.currentUser.uid ? 
												<>
													{user.bio && user.bio.length > 0 ?									
														<UserDetailedBioDialogComponent user={user} userId={userId} oneNewFollowValue={oneNewFollowValue}/>
														: null
													}
													<ReportComponent userId={userId} type="userPage"  bio={user.bio} avatar={user.avatarBigUrl}/> 
													<ScrollToTopComponent />
													<Button variant={following ? "contained": "outlined"} 
														sx={{
															float:"right",
															borderRadius: 5,
															padding: '4px',
															fontSize: '10.5px',
															marginRight:'12px'}} 
														disabled={loading || followingsLimit} onClick={handleFollowFunction}>{following ? t("unfollow") : t("follow")}</Button>
														
												</> : null}
										</Grid>
									</Grid>
								</>
							}
						/>
					</Grid>
					<Grid item xs={12} >
						
						{
							loadingMoreNewerPosts === true ?  
								<Box sx={{ display: 'flex',
									alignContent: 'center', 
									height: "auto",
									margin:"12px",
									alignItems:"center",
									justifyContent:"center"}}>
									<CircularProgress size='26px'/>
								</Box> 
							:
							null
						}
						{postList.length > 0 ? 
							postList
								.map((post, index) => (
									(index + 1) % POST_AD_FREQUENCY === 0 ?
										post !== undefined ?
										<>
											<TopicSummaryItem type="my-feed" loading={loading} post={post.post} sharedPost={post.sharedPost} sharedPostUser={post.sharedPostUser} sharedPostTopic={post.sharedPostTopic}
											sharedPostDiscussion={post.sharedPostDiscussion} user={post.user} topic={post.topic} discussion={post.discussion} id={post.postId} key={post.postId}/>
											<PostAdComponent />
										</> : null
									:
									
									post !== undefined ?
										<>
											<TopicSummaryItem type="my-feed" loading={loading} post={post.post} sharedPost={post.sharedPost} sharedPostUser={post.sharedPostUser} sharedPostTopic={post.sharedPostTopic}
											sharedPostDiscussion={post.sharedPostDiscussion} user={post.user} topic={post.topic} discussion={post.discussion} id={post.postId} key={post.postId}/>
										</> : null
							)) 
						
							:
							<Box sx={{ display: 'flex',
								alignContent: 'center', 
								height: "100vh",
								alignItems:"start",
								justifyContent:"center", mt: "16px"}}>
								<Typography variant="caption">
									{t("userTopicHistory.noUserPosts")}
								</Typography>
							</Box> 
						}
						
						{
							loadingMoreOlderPosts === true ?  
								<Box sx={{ display: 'flex',
									alignContent: 'center', 
									height: "auto",
									margin:"12px",
									alignItems:"center",
									justifyContent:"center"}}>
									<CircularProgress size='26px'/>
								</Box> 
							:
							null
						}
						<div style={{padding: "6px"}}/>
					</Grid>
				</Grid>
			</Grid>
		
		</>
		}
		</>
	);
}
