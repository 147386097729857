import './ProfileConfigurationPage.css'

import {CenterScrollableStyledComponent, NavBar, ProfileConfigurationComponent, RightSidebarComponent, SidebarComponent} from '../../components'
import Grid from '@mui/material/Grid';


import { NAVBAR_HEIGHT } from '../../utils/Constants';

export default function ProfileConfigurationPage() {
	return (
		<Grid 
			container
			direction="column"
			justifyContent="center"
			alignItems="center" spacing={0}>
			<Grid container item xs={12}>
				<NavBar/>
			</Grid>
			<Grid container 
				justifyContent="center"
				alignItems="center" 
				sx={{marginTop: NAVBAR_HEIGHT}}>
				<Grid item xs={12}>
					<Grid xs={12} container direction="row">
						<SidebarComponent/>
						<CenterScrollableStyledComponent>
							<ProfileConfigurationComponent/>
						</CenterScrollableStyledComponent>
						<RightSidebarComponent/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
  }

