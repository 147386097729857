import {useContext, useEffect, useState} from 'react';
import './SignUpPage.css'

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { HomeSloganComponent, LegalNoticeComponent, SignUpComponent, AppUsersQuantityLimitComponent} from '../../components'

import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import { DocumentDescriptionContext } from '../../contexts/DocumentDescriptionContext';
import Typography from '@mui/material/Typography';
import { getAppUsersQuantity } from '../../firebaseFunctions/spannerFunctions/appUsersQuantityFunctions';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function SignUpPage() {
	const { t } = useTranslation();
	const { setDocumentTitle } = useContext(DocumentTitleContext)
	const { setDocumentDescription } = useContext(DocumentDescriptionContext)
	const [userLimitReached, setUserLimitReached] = useState(null)
    
	useEffect(() => {  
		// Anything in here is fired on component mount.	
		
		setDocumentTitle(t('documentTitles.signUp'))
		setDocumentDescription(t('documentDescriptions.signUp'))
		getAppUsersQuantity().then((res) => {
			setUserLimitReached(res.userLimitReached)
		})
		return () => {
		}
	
		}, [])
	return (
		<>
		{
			<Grid container component="main" sx={{ height: '100vh' }}> 
				
				<HomeSloganComponent/>
				<Grid  sx={{paddingTop: "55px"}}  item xs={12} sm={12} md={5} component={Paper} elevation={6} square>	
					{
						userLimitReached !== null ? 
							userLimitReached === false ?				
								<SignUpComponent homePage={true} />
								:
								<AppUsersQuantityLimitComponent/>
						:
						<Box sx={{ display: 'flex',
							alignContent: 'center', 
							height: "100vh",
							alignItems:"center",
							justifyContent:"center"}}>
							<CircularProgress />
						</Box> 
					}
					<Grid container justifyContent="center" spacing={2}>
						<Grid item>
							<Link to="/flow/signin" style={{textDecoration: "none", color: "inherit"}}>
								<Typography variant='subtitle2' sx={{"&:hover": {textDecoration: "underline!important"},color: "inherit!important"}}>
									{t("signUpPage.haveAccountSignIn")}
								</Typography>
							</Link>
						</Grid>
					</Grid>

					<LegalNoticeComponent sx={{ mt: 3 }} />

				</Grid>
			</Grid>
		}
		</>
	)
	
}


