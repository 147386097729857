import { useContext, useEffect } from 'react';
import './AcceptNewPoliciesPage.css'
import { useTranslation } from 'react-i18next';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import { AcceptNewPoliciesComponent, NavBarClean } from '../../components';
import { MIN_CONTENT_WIDTH, NAVBAR_HEIGHT } from '../../utils/Constants';
import Grid from '@mui/material/Grid';



export default function AcceptNewPoliciesPage() {
	const { t } = useTranslation();
	const { setDocumentTitle } = useContext(DocumentTitleContext)
	
    useEffect(() => {  
		// Anything in here is fired on component mount.	
		setDocumentTitle(t('legals.updatedPolicies'))
		return () => {
		}
	
		}, [])
	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center" spacing={3}>
			<Grid container item xs={12}>
				<NavBarClean/>
			</Grid>
			<Grid container 
				justifyContent="center"
				  alignItems="center"
				sx={{marginTop: NAVBAR_HEIGHT}}>
				<Grid container item direction="column"
					justifyContent="center"
					alignItems="center" xs={6} sx={{minWidth: MIN_CONTENT_WIDTH}}>
						<AcceptNewPoliciesComponent />
				</Grid>
			</Grid>
		</Grid>
	)
}

