import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

// Rest of logs are inside other functions related to client functions.
export async function writeUserLog(){
	const writeuserlogincloudspanner = httpsCallable(functions, 'writeuserlogincloudspanner');
	return await writeuserlogincloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}