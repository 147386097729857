export const FeedDate = (timestamp) => {
	const millisecondsInOneDay = 1000*60*60*24 
	const millisecondsInOneHour = 1000*60*60
	const millisecondsInOneMinute = 1000*60
	const millisecondsInOneSecond = 1000
	const now = new Date().getTime() 
	const serverTimestamp = new Date(timestamp)
	const diffInDates = now - serverTimestamp
	const navigatorLang = navigator.language.split('-')[0] ? navigator.language.split('-')[0] : "en-US"

	if (diffInDates < millisecondsInOneDay){
		if (diffInDates > millisecondsInOneHour){
			return new Intl.RelativeTimeFormat(navigatorLang, { numeric: "auto", style: "short" }).format(Math.round(-diffInDates/millisecondsInOneHour), 'hours')
		} else {
			if (diffInDates > millisecondsInOneMinute){
				return new Intl.RelativeTimeFormat(navigatorLang, { numeric: "auto", style: "short" }).format(Math.round(-diffInDates/millisecondsInOneMinute), 'minutes')
			} else {
				if (diffInDates > millisecondsInOneSecond) {
					if (navigatorLang === "es"){
						return "justo ahora"
					} else {
						return "just now"
					}
				}
			}
		
		}
	} else {
		return new Intl.DateTimeFormat(navigatorLang, { year: 'numeric', month: 'short', day: 'numeric'}).format(serverTimestamp)
	}

}
export const TopicDate = (timestamp) => {
	const navigatorLang = navigator.language.split('-')[0] ? navigator.language.split('-')[0] : "en-US"
	const serverTimestamp = new Date(timestamp)
	return new Intl.DateTimeFormat(navigatorLang, { dateStyle: 'full', timeStyle: 'short'}).format(serverTimestamp)
}
export const DiscussionDate = (timestamp) => {
	const navigatorLang = navigator.language.split('-')[0] ? navigator.language.split('-')[0] : "en-US"
	const serverTimestamp = new Date(timestamp)
	return new Intl.DateTimeFormat(navigatorLang, { year: 'numeric', month: 'short', day: 'numeric'}).format(serverTimestamp)
}