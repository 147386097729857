import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import { convertBackendResponseKeysToFrontEndFormat } from "../../utils/ConvertObjectToFirstCharLowerCase";


export async function deleteFollowNotificationData(){
	
	const deletefollownotificationfromcloudspanner = httpsCallable(functions, 'deletefollownotificationfromcloudspanner');
	return await deletefollownotificationfromcloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function deleteTopicInviteNotificationData(){
	const deletetopicinvitenotificationfromcloudspanner = httpsCallable(functions, 'deletetopicinvitenotificationfromcloudspanner');
	return await deletetopicinvitenotificationfromcloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function deleteTopicReplyNotificationData(){
	const deletereplynotificationfromcloudspanner = httpsCallable(functions, 'deletereplynotificationfromcloudspanner');
	return await deletereplynotificationfromcloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function deleteReportNotifications(){
	const deletereportnotificationsfromcloudspanner = httpsCallable(functions, 'deletereportnotificationsfromcloudspanner');
	return await deletereportnotificationsfromcloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function getUserNotifications(){
	const getusernotificationsfromcloudspanner = httpsCallable(functions, 'getusernotificationsfromcloudspanner');
	return await getusernotificationsfromcloudspanner()
	.then((result) => {
		const data = result.data;
		const dataInLocalFormat = result.data !== null ? data.map((row) => { return convertBackendResponseKeysToFrontEndFormat(row)}) : []
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}