import React, {useState, useEffect,  forwardRef, useImperativeHandle, useRef} from 'react';

import Box from '@mui/material/Box';
import { BUTTON_REPLY_DRAWER_STACK_HEIGHT_ABSOLUTE, MAX_EXTERNAL_LINKS_PER_POST, MAX_LOCAL_LINKS_PER_POST, MAX_POST_LENGTH, MAX_USERNAMES_MENTIONED, NAVBAR_HEIGHT_ABSOLUTE, URL_REGEX, USERNAME_CHARACTER_MAX, USERNAME_CHARACTER_MIN, USERNAME_MENTION_REGEX, USERNAME_REGEX } from '../../utils/Constants';
import { useTranslation } from 'react-i18next';

import './PostTextFieldComponent.css'
import { styled } from '@mui/material/styles';
import NavigateToExternalLinkModalComponent from '../NavigateToExternalLinkModalComponent';
import { getUserIdByUsername } from '../../firebaseFunctions/spannerFunctions/profileFunctions';


const OverlayBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .overlay': {
    position: 'absolute',
    overflow: 'auto',
    top: "16px",
    left: "12px",
    width: 'inherit!important',
    pointerEvents: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    color: 'black',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight,
  },
  '& a': {
    pointerEvents: 'auto',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight,
  },
  '& .tooltip': {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight,
  },
  '& .content-editable-text-box': {
    width: 'inherit!important',
    overflow: 'auto',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    outline: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight,
    borderBottomLeftRadius: '0px!important',
    borderBottomRightRadius: '0px!important'
    },
    '& .blue-box': {
    display: 'inline-block',
    backgroundColor: 'blue',
    color: 'white',
    width: '100%',
    height: '100px',
    padding: '2px 4px',
    margin: '0 2px',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight,
    }
}));

// export default 
function PostTextFieldComponent(props, ref) {
	
	const { t } = useTranslation();
  const divRef = useRef(null);
  const [text, setText] = useState('');
  const [caretPosition, setCaretPosition] = useState(0);
  const tooltipRef = useRef(null);
	const [usernamesList, setUsernamesList] = useState([]);
  
  // Add click event listener to handle link clicks
  const handleClick = (event) => {
    if (event.target.tagName === 'A') {
      event.preventDefault();
      // Check if its external link or local
      const localDomain = window.location.hostname;
      const linkDomain = new URL(event.target.href).hostname;
      if (localDomain !== linkDomain) {
        handleClickOpenNavigateToExternalLinkModal(event.target.href)
      } else {
        window.open(event.target.href, '_blank', 'noopener,noreferrer');
      }
    }
  };
  // Add event listeners to handle link hover
  const handleMouseOver = (event) => {
    if (event.target.tagName === 'A') {
      tooltipRef.current.textContent = event.target.href;
      tooltipRef.current.style.display = 'block';
    }
  };
  const handleMouseOut = () => {
    tooltipRef.current.style.display = 'none';
  };

  useImperativeHandle(ref, () => ({
    // Remove listeners before closing drawer
    removeListeners: () => {
      divRef.current.removeEventListener('click', handleClick);
      divRef.current.removeEventListener('mouseover', handleMouseOver);
      divRef.current.removeEventListener('mouseout', handleMouseOut);
      return true
    }
  }));

  useEffect(() => {
    divRef.current.addEventListener('click', handleClick);
    divRef.current.addEventListener('mouseover', handleMouseOver);
    divRef.current.addEventListener('mouseout', handleMouseOut);
  }, [])

  const getCaretPosition = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(divRef.current);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      return preCaretRange.toString().length;
    }
    return 0;
  };

  const setCaret = (position) => {
    const selection = window.getSelection();
    const range = document.createRange();
    let charCount = 0, node;

    for (node of divRef.current.childNodes) {
      if (node.nodeType === 3) { // text node
        if (charCount + node.length >= position) {
          range.setStart(node, position - charCount);
          break;
        } else {
          charCount += node.length;
        }
      } else {
        if (charCount + node.textContent.length >= position) {
          range.setStart(node.childNodes[0], position - charCount);
          break;
        } else {
          charCount += node.textContent.length;
        }
      }
    }

    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleInput = (e) => {
    if (e.target.innerText.length <= MAX_POST_LENGTH) {
      const position = getCaretPosition();
      let newText = e.target.innerText;
      setText(newText);
      setCaretPosition(position);
    } else {
      e.preventDefault();
    }
  };
  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text');
    // If text length with pasting is longer than max length of post alert user.
    if (pastedText.length + text.length > MAX_POST_LENGTH) { 
      event.preventDefault();
      alert(t('postTextFieldComponent.pastedTextIsTooLong'));
    }
  };

  const handleKeyDown = (e) => {
    try {
      
    if (e.key === 'Backspace') {
      const position = getCaretPosition();
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const node = range.startContainer;

      if (node.nodeType === 1 && node.getAttribute('contenteditable') === 'false') {
        e.preventDefault();
        node.remove();
        setCaret(position - 1);
      }
    } else {
      if (e.target.innerText.length >= MAX_POST_LENGTH) {
        e.preventDefault();
      } else {
        if (e.key === 'Enter') {
          e.preventDefault()
          const selection = window.getSelection(); 
          const range = selection.getRangeAt(0); 
          const br = document.createElement('br'); 
          range.insertNode(br); 
          range.setStartAfter(br); 
          range.setEndAfter(br); 
          selection.removeAllRanges(); 
          selection.addRange(range)

          const position = getCaretPosition();
          let newText = e.target.innerText;
          setText(newText);
          setCaretPosition(position + 1);
        } else {
          if (e.key === 'Tab') {
            e.preventDefault(); 
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const tabNode = document.createTextNode('\u00a0\u00a0\u00a0\u00a0'); 
            range.insertNode(tabNode);
            range.setStartAfter(tabNode);
            range.setEndAfter(tabNode);
            selection.removeAllRanges();
            selection.addRange(range);

            const position = getCaretPosition();
            let newText = e.target.innerText;
            setText(newText);
            setCaretPosition(position);
          }
        }
      }
    } 
    } catch (error) {
      // textfield error handling
    }
  };

  useEffect(() => {
    if (divRef.current) {
      // Handle text urls
      let trackedText = text
      let content = [];

      // Add links parts:
      const linksParts = trackedText.split(URL_REGEX);
      let localLinksCounter = 0
      let externalLinksCounter = 0
      linksParts.forEach((part, index) => {
        if (URL_REGEX.test(part)) {
          // Check if the next part is followed by </a>
          const nextPart = linksParts[index + 1];
          // Check if its external link or local
          const url = part.startsWith('http') ? part : `https://${part}`;
          let localDomain = window.location.hostname;
          let linkDomain = new URL(url).hostname;
          // always return only the domain not the www. would only work if textfield only allows www. as suffix and not any other suffix like blog. .
          if (linkDomain.startsWith('www.') && linkDomain.split('.').length > 2) {
            linkDomain = linkDomain.slice(4);
          } 
          if (localDomain.startsWith('www.') && localDomain.split('.').length > 2) {
            localDomain = localDomain.slice(4);
          }

          if (localDomain !== linkDomain) {
            // link is external
            externalLinksCounter += 1
            if (nextPart && nextPart.startsWith('</a>') || externalLinksCounter > MAX_EXTERNAL_LINKS_PER_POST) {
              content.push(part);
            } else {
                // links
                content.push(
                  `<a id='postLink' key=${index} href="${url}">${part}</a>`
                );
            }
          } else {
            // link is local
            localLinksCounter += 1
            if (nextPart && nextPart.startsWith('</a>') || localLinksCounter > MAX_LOCAL_LINKS_PER_POST ) {
              content.push(part);
            } else {
                // links
                content.push(
                  `<a id='postLink' key=${index} href="${url}">${part}</a>`
                );
            }
          }
        } else {
          content.push(part);
        }
      });

      // Handle usernames mentions
      trackedText = content.join('');
      content = []
      let mentionsCounter = 0
      const usernames = usernamesList
      const usernameParts = trackedText.split(USERNAME_MENTION_REGEX);
      usernameParts.forEach((part, index) => {
        if (USERNAME_MENTION_REGEX.test(part)) {
          const username = part.startsWith('@') ? part.substring(1).replace(/\s+/g, '') : part;
          const nextPart = usernameParts[index + 1];
          if ((USERNAME_REGEX.test(username) && username.length <= USERNAME_CHARACTER_MAX && username.length >= USERNAME_CHARACTER_MIN && mentionsCounter < MAX_USERNAMES_MENTIONED) || usernames.some(user => user.username === username)) {
            if (nextPart && nextPart.startsWith('</div>')) {
              content.push(part);
            } else {
              if (!usernames.some(user => user.username === username)) {
                mentionsCounter += 1
                // if username has not already been added
                usernames.push({username:username, id:null, userIdSearched: false}) // only search userId for usernames that are showned as links in front end.
                getUsernamesListMissingUserIds(usernames)
              }
              if (usernames.some(user => user.username === username && user.id !== null)) {
                const url = '/' + username;
                content.push(
                  `<a id='userLink' key=${index} href="${url}">${part}</a>`
                );
              } else {
                content.push(part);
              }
            }
          } else {
            content.push(part);
          }
        } else {
          content.push(part);
        }
      });

      divRef.current.innerHTML = content.join('');
      setCaret(caretPosition);   
      props.onTextChange(text.toString(), mentionsCounter, externalLinksCounter, localLinksCounter)
      props.onUsernamesListChange(usernames)
    }
  }, [text]);

  const getUsernamesListMissingUserIds = (usernames) => {

    const newUsernamesList = usernames
    //search the ids of the usernames that where null
    newUsernamesList.map(user => {
      if (user.id === null && user.userIdSearched === false) {
        // only search id if it has not been searched yet
        getUserIdByUsername(user.username).then((id) => {
          setUsernamesList(prevList=> {
            const newList = prevList.map(newListUser => {        
              if (newListUser.id === null && newListUser.username === user.username && newListUser.userIdSearched === false) {
                return {username: user.username, id:id, userIdSearched:true}
              } else {
                return newListUser
              }
            }) 
            return newList
          })
        })
      }
    })
  }

  // Handle click on link
	const [openNavigateToExternalLinkModal, setOpenNavigateToExternalLinkModal] = useState(false);
	const [urlNavigateToExternalLinkModal, setUrlNavigateToExternalLinkModal] = useState(false);
	const handleClickOpenNavigateToExternalLinkModal = (url) => {
		setOpenNavigateToExternalLinkModal(true);
    setUrlNavigateToExternalLinkModal(url)
	};
	const handleCloseNavigateToExternalLinkModal = () => {
	  setOpenNavigateToExternalLinkModal(false);
	};
	return (
		<>	
      {
        props.type === 'opening' || props.type === 'reply' ?
          <OverlayBox className="overlay">
            <NavigateToExternalLinkModalComponent open={openNavigateToExternalLinkModal} url={urlNavigateToExternalLinkModal} handleCloseDialog={() => handleCloseNavigateToExternalLinkModal}/>
            <div id="tooltip" ref={tooltipRef} className="tooltip"></div>
            <div
              className="content-editable-text-box"
              style={{height: props.type === 'reply' ? `calc(100vh - ${NAVBAR_HEIGHT_ABSOLUTE + BUTTON_REPLY_DRAWER_STACK_HEIGHT_ABSOLUTE}px)`: `calc(100vh - ${NAVBAR_HEIGHT_ABSOLUTE + BUTTON_REPLY_DRAWER_STACK_HEIGHT_ABSOLUTE * 4}px)`}}
              contentEditable
              spellCheck='false'
              ref={divRef}
              onPaste={handlePaste}
              onInput={handleInput}
              onKeyDown={handleKeyDown}
              onBlur={() => setCaretPosition(getCaretPosition())}
              onFocus={() => setCaret(caretPosition)}
            >     
            </div>
          </OverlayBox>
        :null
      }
		</>
	)
}

export default forwardRef(PostTextFieldComponent)