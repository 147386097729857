import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
export default function PublicRoute() {
	const { currentUser, currentProfile } = useAuth()

	return (!(currentUser && !currentProfile) ? (!currentUser ? <>
		<Outlet/>
	</> : <Navigate to='/'/>) : <Navigate to="/error/userprofile" />)
	}

