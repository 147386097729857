import React, {useState, useEffect} from 'react';
import './PostWithLinksComponent.css'
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import { FeedDate } from '../../utils/Dates';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { AVATAR_SMALL_SIZE, MAX_CHARACTERS_PER_LOCAL_POST_MENTIONED, URL_OR_USERNAME_MENTION_REGEX, URL_REGEX, USERNAME_USER_ID_POINTER_REGEX } from '../../utils/Constants';
import ListItem from '@mui/material/ListItem';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useTranslation } from 'react-i18next';
import ReportedTopicTitleComponent from '../ReportedTopicTitleComponent';
import { getPostMentionedUsersProfiles } from '../../firebaseFunctions/spannerFunctions/postLocalLinksFunctions';
import NavigateToExternalLinkModalComponent from '../NavigateToExternalLinkModalComponent';
import UserPopoverCardComponent from '../UserPopoverCardComponent';

export default function PostWithLinksComponent(props) {
	
	const { t } = useTranslation();

	const [usersMentioned, setUsersMentioned] = useState([])
	const [loadingUsersMentioned, setLoadingUsersMentioned] = useState(true)
	useEffect(() => {
		setLoadingUsersMentioned(true)
		getPostMentionedUsersProfiles(props.post).then((usersMentionedData) => {
			setUsersMentioned(usersMentionedData)
			setLoadingUsersMentioned(false)
		})
	}, [])

	// Handle click on link
	const [openNavigateToExternalLinkModal, setOpenNavigateToExternalLinkModal] = useState(false);
	const [urlNavigateToExternalLinkModal, setUrlNavigateToExternalLinkModal] = useState(false);
	const handleClickOpenNavigateToExternalLinkModal = (e, url) => {
		e.preventDefault() // preventDefault so link can have href and can be tracked by robots but prevents navigation and instead runs this function on click
		setOpenNavigateToExternalLinkModal(true);
		setUrlNavigateToExternalLinkModal(url)
	};
	const handleCloseNavigateToExternalLinkModal = () => {
		setOpenNavigateToExternalLinkModal(false);
	};
	return(
		<>
		{loadingUsersMentioned ?    
		
			<Box sx={{ display: 'flex',
				alignContent: 'center', 
				height: "fit-content",
				alignItems:"center",
				justifyContent:"center"}}>
				<CircularProgress />
			</Box> 
			: 
			<>
				<NavigateToExternalLinkModalComponent open={openNavigateToExternalLinkModal} url={urlNavigateToExternalLinkModal} handleCloseDialog={() => handleCloseNavigateToExternalLinkModal}/>
				{
					props.post.split(URL_OR_USERNAME_MENTION_REGEX).map((part, index, arr) => {
						if (URL_REGEX.test(part)) {
							// Check if its external link or local
							const uri = part.startsWith('http') ? part : `https://${part}`;
							let localDomain = window.location.hostname;
							let linkDomain = new URL(uri).hostname;
							// always return only the domain not the www. would only work if textfield only allows www. as suffix and not any other suffix like blog. .
							if (linkDomain.startsWith('www.') && linkDomain.split('.').length > 2) {
							linkDomain = linkDomain.slice(4);
							} 
							if (localDomain.startsWith('www.') && localDomain.split('.').length > 2) {
							localDomain = localDomain.slice(4);
							}
				
							if (localDomain !== linkDomain) {
								//link is external
								return <a id='postLink' key={index} onClick={(e) => handleClickOpenNavigateToExternalLinkModal(e, uri)}>{part}</a>
							} else {
								// link is local
								const topicIdMatch = uri.match(/topic\/([^/]+)\/?/);
								const discussionIdMatch = uri.match(/discussion\/([^/]+)\/?/);
								const postIdMatch = uri.match(/post\/([^/]+)\/?/);
								const topicId = topicIdMatch ? topicIdMatch[1] : null
								const discussionId = discussionIdMatch ? discussionIdMatch[1] : null 
								const postId = postIdMatch ? postIdMatch[1] : null
								// search link data that matches link from props
								const searchCriteria = {topicId: topicId, discussionId: discussionId, postId: postId}
								const localLinksData = props.localLinksData.find(localLinkData =>
									Object.keys(searchCriteria).every(key => localLinkData[key] === searchCriteria[key])
								)		
								// if link data exists. This also prevents truncated links to not be read as links -------------------------------------------------------IMPORTANT -------------------------------------------------------IMPORTANT -------------------------------------------------------IMPORTANT -------------------------------------------------------
								if (localLinksData) {
									if (props.showReferencedPosts === true) {

										if (localLinksData.postData === true && localLinksData.data !== null) {
											//return post data
											return <>								
												<ListItem sx={{padding:0, marginTop: "12px"}} key={localLinksData.postId}>
													{!localLinksData.data.post ?	
														<CardActionArea href={`/topic/${localLinksData.topicId}/discussion/${localLinksData.discussionId}/post/${localLinksData.postId}`} target="_blank" rel="noopener noreferrer" 
															sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'none'}, marginTop: '12px', flexGrow: 1, padding:'0!important', marginRight:'6px!important', marginLeft: '6px!important'}} className='ReportedPostCard' >	
															<CardContent sx={{margin:"0px", paddingBottom: '16px!important', paddingTop: '16px!important', marginTop: "0px!important"}} >
																<Grid container direction="row" sx={{height: "inherit"}}>
																	<Grid item xs>
																		<Typography variant="body1">
																			{t('reportedPostComponent.description')} 
																		</Typography>
																	</Grid>
																</Grid>
															</CardContent>
														</CardActionArea>
													:
														<CardActionArea href={`/topic/${localLinksData.topicId}/discussion/${localLinksData.discussionId}/post/${localLinksData.postId}`} target="_blank" rel="noopener noreferrer" 
														sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'none'}, flexGrow: 1, padding:0, marginRight:'6px!important', marginLeft: '6px!important'}}  className='PostReferencedCard'>	
															<Stack direction='column'>
																{!localLinksData.data.title ? 
																	<Typography variant="h5" sx={{ marginLeft: "22px", paddingRight: "-22px!important", marginTop: "22px", width: "95%!important", fontStyle: "italic",
																		overflow: "hidden",
																		textOverflow: "ellipsis",
																		display: "-webkit-box",
																		WebkitLineClamp: "2",
																		WebkitBoxOrient: "vertical"}}>
																		<ReportedTopicTitleComponent />
																	</Typography>
																	:
																	<Typography variant="h5" sx={{ marginLeft: "22px", paddingRight: "-22px!important", marginTop: "22px", width: "95%!important", 
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			display: "-webkit-box",
																			WebkitLineClamp: "3",
																			WebkitBoxOrient: "vertical"}}>
																		{localLinksData.data.title} 
																	</Typography>
																}
																<CardHeader
																	avatar={
																		(	
																			localLinksData.data.user.avatarSmallUrl !== "" ?  
																				<Avatar sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE }} alt="avatar" src={localLinksData.data.user.avatarSmallUrl}/>
																			:
																				<Avatar sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE ,color:"white" }} alt="avatar">
																					{localLinksData.data.user.username[0].toUpperCase()}
																				</Avatar>
																		) 
																	}
																	action={null}
																	title={
																		<Stack direction="row" 
																		justifyContent="space-between"
																		alignItems="flex-start">
																			<Stack direction="column">
																				<Typography variant="body1">
																					<Link component={RouterLink} to={"/" + localLinksData.data.user.username} color="inherit" underline="none">
																						{localLinksData.data.user.username}
																					</Link>	
																				</Typography>
																				<Typography variant="caption">
																					{FeedDate(localLinksData.data.timestamp)}
																				</Typography>
																			</Stack>
																		</Stack>
																	}
																/>
																<CardContent>
																	<Typography variant="body1">
																		{localLinksData.data.post.length > MAX_CHARACTERS_PER_LOCAL_POST_MENTIONED ? 
																				localLinksData.data.post.substring(0, MAX_CHARACTERS_PER_LOCAL_POST_MENTIONED) + "..." 
																			: 
																				localLinksData.data.post
																		}
																	</Typography>
																</CardContent>
															</Stack>
														</CardActionArea>
													}
												</ListItem>	
											</>
										}
										if (localLinksData.topicData === true && localLinksData.data !== null) {
											//return topic data
											return <>					
												<ListItem sx={{padding:0, marginTop: "12px"}} key={localLinksData.topicId}>
													{!localLinksData.data.opening ?	
														<CardActionArea href={`/topic/${localLinksData.topicId}`} target="_blank" rel="noopener noreferrer" 
															sx={{  textDecoration: 'none', '&:hover': { textDecoration: 'none'},marginTop: '12px', flexGrow: 1, padding:'0!important', marginRight:'6px!important', marginLeft: '6px!important'}} className='ReportedPostCard' >	
															<CardContent sx={{margin:"0px", paddingBottom: '16px!important', paddingTop: '16px!important', marginTop: "0px!important"}} >
																<Grid container direction="row" sx={{height: "inherit"}}>
																	<Grid item xs>
																		<Typography variant="body1">
																			{t('reportedPostComponent.description')} 
																		</Typography>
																	</Grid>
																</Grid>
															</CardContent>
														</CardActionArea>
													:
														<CardActionArea href={`/topic/${localLinksData.topicId}`} target="_blank" rel="noopener noreferrer" 
															sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'none'},flexGrow: 1, padding:0, marginRight:'6px!important', marginLeft: '6px!important'}}  className='PostReferencedCard'>	
															<Stack direction='column'>
																{!localLinksData.data.title ? 
																	<Typography variant="h5" sx={{ marginLeft: "22px", paddingRight: "-22px!important", marginTop: "22px", width: "95%!important", fontStyle: "italic",
																		overflow: "hidden",
																		textOverflow: "ellipsis",
																		display: "-webkit-box",
																		WebkitLineClamp: "2",
																		WebkitBoxOrient: "vertical"}}>
																		<ReportedTopicTitleComponent />
																	</Typography>
																	:
																	<Typography variant="h5" sx={{ marginLeft: "22px", paddingRight: "-22px!important", marginTop: "22px", width: "95%!important", 
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			display: "-webkit-box",
																			WebkitLineClamp: "3",
																			WebkitBoxOrient: "vertical"}}>
																		{localLinksData.data.title} 
																	</Typography>
																}
																<CardHeader
																	avatar={
																		(	
																			localLinksData.data.user.avatarSmallUrl !== "" ?  
																				<Avatar sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE }} alt="avatar" src={localLinksData.data.user.avatarSmallUrl}/>
																			:
																				<Avatar sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE ,color:"white" }} alt="avatar">
																					{localLinksData.data.user.username[0].toUpperCase()}
																				</Avatar>
																		) 
																	}
																	action={null}
																	title={
																		<Stack direction="row" 
																		justifyContent="space-between"
																		alignItems="flex-start">
																			<Stack direction="column">
																				<Typography variant="body1">
																					<Link component={RouterLink} to={"/" + localLinksData.data.user.username} color="inherit" underline="none">
																						{localLinksData.data.user.username}
																					</Link>	
																				</Typography>
																				<Typography variant="caption">
																					{FeedDate(localLinksData.data.timestamp)}
																				</Typography>
																			</Stack>
																		</Stack>
																	}
																/>
																<CardContent>
																	<Typography variant="body1">
																		{localLinksData.data.opening.length > MAX_CHARACTERS_PER_LOCAL_POST_MENTIONED ? 
																				localLinksData.data.opening.substring(0, MAX_CHARACTERS_PER_LOCAL_POST_MENTIONED) + "..." 
																			: 
																				localLinksData.data.opening
																		}
																	</Typography>
																</CardContent>
															</Stack>
														</CardActionArea>
													}
												</ListItem>	
											</>
										}
									} else {
										if (localLinksData.postData === true && localLinksData.data !== null) {
											// post is of other type or has a determined length that only allows showing the local links
											return <a id='postLink' key={index} href={`/topic/${localLinksData.topicId}/discussion/${localLinksData.discussionId}/post/${localLinksData.postId}`} target="_blank" rel="noopener noreferrer">{part}</a>
										}
										if (localLinksData.topicData === true && localLinksData.data !== null) {
											// post is of other type or has a determined length that only allows showing the local links
											return <a id='postLink' key={index} href={`/topic/${localLinksData.topicId}`} target="_blank" rel="noopener noreferrer">{part}</a>
										}
									}
								}	else {
									//link is local but not a post							
									return <a id='postLink' key={index} href={uri} target="_blank" rel="noopener noreferrer">{part}</a>
								}	
							}
						} else if (USERNAME_USER_ID_POINTER_REGEX.test(part) && props.type !== 'bio') {	
							if (usersMentioned.length > 0) {
								// only if user is found in data return link to user mentioned, else the last return will return as text
								const userId = part.startsWith('@userid:') ? part.slice(8) : part;
								const searchCriteria = {userId: userId}
								const userMentionedData = usersMentioned.find(userMentionedData =>
									Object.keys(searchCriteria).every(key => userMentionedData[key] === searchCriteria[key])
								)		
								if (userMentionedData) {
									if (userMentionedData.username) {
										return <>
											<UserPopoverCardComponent user={userMentionedData} type='post'/> 
										</>
									} else {	
										return <>
										</>
									}
								} else {
									return <>
										<UserPopoverCardComponent type='post'/> 
									</>
								}
							} else {
								return <>
									<UserPopoverCardComponent type='post'/> 
								</>
							}
						}else if (props.wordsToBold) {
							// should bold searched words of part that is neither url nor username
							const escapedWords = props.wordsToBold.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
							const pattern = new RegExp(`\\b(${escapedWords.join('|')})\\b`, 'gi');
							return part.split('\n').map((line) => (
								<>
									{line.split(pattern).map((part2) =>
										props.wordsToBold.includes(part2.toLowerCase()) ? 
											<strong>{part2}</strong> 
										: 
											part2
									)}
									<br />
								</>
							));
						} 
						return part
					})
				}
			</>
		}
		</>
	)
}