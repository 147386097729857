
export const ShortenNumber = (number) => {
    if (number < 1000) {
        return number
    }
    if (number > 0 && number < 1000000) {
        return (number/1000).toFixed(2) + "k"
    }
    if (number > 999999 && number < 2000000){
        return "1M"
    }
    if (number > 1999999) {
        return (number/1000000).toFixed(2) + "MM"
    }
}
export const ShortenUserPointsNumber = (number) => {
    if (number < 10000) {
        return number
    }
    if (number > 0 && number < 1000000) {
        return (number/1000).toFixed(2) + "k"
    }
    if (number > 999999 && number < 2000000){
        return "1M"
    }
    if (number > 1999999) {
        return (number/1000000).toFixed(2) + "MM"
    }
}