import {Component} from 'react';
import './CreateUserProfileErrorPage.css'

import { CreateUserProfileErrorComponent, NavBarClean} from '../../components'
import Grid from '@mui/material/Grid';

import {  NAVBAR_HEIGHT } from '../../utils/Constants';


class CreateUserProfileErrorPage extends Component {
	render() {
		return (
			<Grid 
				container
				direction="column"
				justifyContent="center"
				alignItems="center">
				<Grid container item xs={12}>
					<NavBarClean/>
				</Grid>
				<Grid container 
					justifyContent="center"
					alignItems="center" 
					sx={{marginTop: NAVBAR_HEIGHT}}>
					<Grid item xs="auto">
						<CreateUserProfileErrorComponent />
					</Grid>
				</Grid>
			</Grid>
		)
	}
  }


export default CreateUserProfileErrorPage;
