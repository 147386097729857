import React, {useState, useEffect, useContext, useRef} from 'react';
import './SearchResultsComponent.css'
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';

import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { AVATAR_SMALL_SIZE,  MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST, MAX_SEARCH_RESULT_LINES_COLLAPSED, MAX_SEARCH_RESULT_LINES_EXPANDED, NAVBAR_HEIGHT } from '../../utils/Constants';

import CircularProgress from '@mui/material/CircularProgress';
import { SearchResultWordsArray } from '../../utils/SearchResultWordsArray';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import { getSearchedDiscussionPostsFromElasticsearch, getSearchedTopicPostsFromElasticsearch, getSearchedUsersFromElasticsearch } from '../../firebaseFunctions/elasticsearchFunctions/searchFunctions';
import UserPopoverCardComponent from '../UserPopoverCardComponent';
import AvatarPreviewComponent from '../AvatarPreviewComponent';
import { FeedDate } from '../../utils/Dates';
import DiscussionPopoverCardComponent from '../DiscussionPopoverCardComponent';
import ReportedTopicTitleComponent from '../ReportedTopicTitleComponent';
import PostWithLinksComponent from '../PostWithLinksComponent/PostWithLinksComponent';
import { getDataForPostLocalLinks } from '../../firebaseFunctions/spannerFunctions/postLocalLinksFunctions';
const AntTabs = styled(Tabs)({
	'& .MuiTabs-indicator': {
	  backgroundColor: 'primary',
	}
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: 'none',
	minWidth: 0,
	[theme.breakpoints.up('sm')]: {
		minWidth: 0,
	},
	fontWeight: theme.typography.fontWeightRegular,
	marginRight: theme.spacing(1),
	'&.Mui-selected': {
		color: 'primary',
		fontWeight: theme.typography.fontWeightMedium,
	},
	'&.Mui-focusVisible': {
		backgroundColor: 'primary',
	},
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <Box sx={{ p: 0 }}>
			<Typography>{children}</Typography>
		  </Box>
		)}
	  </div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function DiscussionsSearchResultsItem(props) {
	const { t } = useTranslation();
	const [postLocalLinksDisplayData, setPostLocalLinksDisplayData] = useState([]);	

	const [expanded, setExpanded] = useState(false);
	
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	useEffect(() => {
		getDataForPostLocalLinks(props.discussion.post.post).then((dataForLinks) => {
			setPostLocalLinksDisplayData(dataForLinks)
		})

		return () => {
		}
	}, [])
  
	return (
		
		<>	
		<ListItem alignItems="flex-start" >
			<ListItemAvatar>
				{
					props.discussion.user.avatarSmallUrl !== "" ?
						<AvatarPreviewComponent avatar={props.discussion.user.avatarSmallUrl}
							avatarBig={props.discussion.user.avatarBigUrl}
							size={AVATAR_SMALL_SIZE + "px"}/>
					: 
						<Avatar src={props.discussion.user.avatarSmallUrl} sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE, color:"white" }} aria-label="avatar">
							{props.discussion.user.username[0].toUpperCase()}
						</Avatar>
				}
			</ListItemAvatar>
			<ListItemText
				primary={
					
					<Stack direction="column" sx={{marginLeft: "10px"}}>
						<Typography variant='body1'>
							<UserPopoverCardComponent uid={props.discussion.user.userId} user={props.discussion.user} type={props.discussion.post.type} place={null}/>
						</Typography>
						<DiscussionPopoverCardComponent summary={true} topic={props.discussion.topic} discussion={props.discussion.discussion} type={props.discussion.post.type} postId={props.discussion.post.postId} />
					</Stack>
				}
				secondary={
				<>
					<Stack direction="column" sx={{marginTop: "12px"}}>
						{!expanded ? 
							<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: MAX_SEARCH_RESULT_LINES_COLLAPSED, overflow: 'hidden', textOverflow: 'ellipsis'}}>
								{props.discussion.post.post.length > MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST ? 
										<PostWithLinksComponent key={0} showReferencedPosts={false} post={props.discussion.post.post.substring(0, MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST)+"..."} localLinksData={postLocalLinksDisplayData} wordsToBold={props.searchedWords} />
									:
										<PostWithLinksComponent key={1} showReferencedPosts={true} post={props.discussion.post.post.substring(0, MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST)} localLinksData={postLocalLinksDisplayData} wordsToBold={props.searchedWords} />
								}
							</Typography> 
							: 
							<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: MAX_SEARCH_RESULT_LINES_EXPANDED, overflow: 'hidden', textOverflow: 'ellipsis'}}>
								<PostWithLinksComponent  key={2} showReferencedPosts={true} post={props.discussion.post.post} localLinksData={postLocalLinksDisplayData} wordsToBold={props.searchedWords} />
							</Typography>
						}
						{
							props.discussion.post.post.length <= MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST ? 
								null
								:
								<div style= {{marginLeft: 'auto'}}>
									
									<Button
										disabled={props.discussion.post.post.length <= MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST}
										onClick={handleExpandClick}
										sx={{
											justifyContent: "flex-end",
											textTransform: 'none',
											'&:hover': {
												textDecoration: "underline",
												background: "transparent!important"
											}}}>
										<Typography variant='caption' color="inherit">
											{!expanded ? t('searchResultsComponent.readMore') : t('searchResultsComponent.readLess')}
										</Typography>
									</Button>
								</div>
						}
					</Stack>
				</>
			}
			/>	
		</ListItem>
		<Divider />
	</>
	)
}
function TopicsSearchResultsItem(props) {
	const { t } = useTranslation();
	const [postLocalLinksDisplayData, setPostLocalLinksDisplayData] = useState([]);	
	
	const [expanded, setExpanded] = useState(false);
	
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	useEffect(() => {
		getDataForPostLocalLinks(props.topic.post.post).then((dataForLinks) => {
			setPostLocalLinksDisplayData(dataForLinks)
		})

		return () => {
		}
	}, [])
	return (
		<>	
		<Typography variant='h3' sx={{marginLeft: "18px", marginTop: "8px!important"}}>
			<Link component={RouterLink} to={'/topic/' + props.topic.topic.topicId} color="inherit" underline="hover">
				{props.topic.topic.reportedTitle === true ?
					<ReportedTopicTitleComponent />
				:
					props.topic.topic.title
				}
			</Link>
		</Typography>
		<ListItem alignItems="flex-start">
			<ListItemAvatar>
				{
					props.topic.user.avatarSmallUrl !== "" ?
						<AvatarPreviewComponent avatar={props.topic.user.avatarSmallUrl}
							avatarBig={props.topic.user.avatarBigUrl}
							size={AVATAR_SMALL_SIZE + "px"}/>
					: 
						<Avatar src={props.topic.user.avatarSmallUrl} sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE, color:"white" }} aria-label="avatar">
							{props.topic.user.username[0].toUpperCase()}
						</Avatar>
				}
			</ListItemAvatar>
			<ListItemText
				primary={
					
					<Stack direction="column">
						<Typography variant='body1' sx={{marginLeft: "10px"}}>
							<UserPopoverCardComponent uid={props.topic.user.userId} user={props.topic.user} type={props.topic.post.type} place={null}/>
						</Typography>
						<Typography variant='caption'>
							{FeedDate(props.topic.post.timestamp)}
						</Typography>
					</Stack>
				}
				secondary={
				<>
					<Stack direction="column" sx={{marginTop: "12px"}}>
						{!expanded ? 
							<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: MAX_SEARCH_RESULT_LINES_COLLAPSED, overflow: 'hidden', textOverflow: 'ellipsis'}}>
								{props.topic.post.post.length > MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST ? 							
										<PostWithLinksComponent key={0}  showReferencedPosts={false} post={props.topic.post.post.substring(0, MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST)+"..."} localLinksData={postLocalLinksDisplayData} wordsToBold={props.searchedWords} />
									:
										<PostWithLinksComponent key={1}  showReferencedPosts={true} post={props.topic.post.post.substring(0, MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST)} localLinksData={postLocalLinksDisplayData} wordsToBold={props.searchedWords} />
								}
							</Typography> 
							: 
							<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: MAX_SEARCH_RESULT_LINES_EXPANDED, overflow: 'hidden', textOverflow: 'ellipsis'}}>
								<PostWithLinksComponent key={2}  showReferencedPosts={true} post={props.topic.post.post} localLinksData={postLocalLinksDisplayData} wordsToBold={props.searchedWords} />
							</Typography>
						}
						{
							props.topic.post.post.length <= MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST ? 
								null
								:
								<div style= {{marginLeft: 'auto'}}>
									
									<Button
										disabled={props.topic.post.post.length <= MAX_CHARACTERS_PER_SEARCH_PREVIEW_POST}
										onClick={handleExpandClick}
										sx={{
											justifyContent: "flex-end",
											textTransform: 'none',
											'&:hover': {
												textDecoration: "underline",
												background: "transparent!important"
											}}}>
										<Typography variant='caption' color="inherit">
											{!expanded ? t('searchResultsComponent.readMore') : t('searchResultsComponent.readLess')}
										</Typography>
									</Button>
								</div>
						}
					</Stack>
				</>
			}
			/>	
		</ListItem>
		<Divider />
		</>
	)
}
function UsersSearchResultsItem(props) {
	return (
		<>	
			<ListItem
				justifyContent="flex-start"
				alignItems="center"
				spacing={2}>
				<ListItemAvatar
					sx={{marginBottom: "8px"}}>
					{
						props.user.avatarSmallUrl !== "" ?
							<AvatarPreviewComponent avatar={props.user.avatarSmallUrl}
								avatarBig={props.user.avatarBigUrl}
								size={AVATAR_SMALL_SIZE + "px"}/>
						: 
							<Avatar src={props.user.avatarSmallUrl} sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE, color:"white" }} aria-label="avatar">
								{props.user.username[0].toUpperCase()}
							</Avatar>
					}
				</ListItemAvatar>
				<ListItemText
					sx={{marginLeft: "12px", marginBottom: "8px"}}
					primary={
						<Typography variant="body2">
							<UserPopoverCardComponent uid={props.user.userId} user={props.user} />
						</Typography>
					}
				/>
			</ListItem>
			<Divider />
		</>
	)
}
  
export default function SearchResultsComponent(props) {
	const location = useLocation()

	const [myParam, setMyParam] = useState(new URLSearchParams(location.search).get('search_query'))
	const searchedWords = SearchResultWordsArray(myParam) 
	
	const [tabValue, setTabValue] = useState(0);
	const [loadingUsers, setLoadingUsers] = useState(true);
	const [loadingTopics, setLoadingTopics] = useState(true);
	const [loadingDiscussions, setLoadingDiscussions] = useState(true);
	const [loadingMoreUsers, setLoadingMoreUsers] = useState(false);
	const [loadingMoreTopics, setLoadingMoreTopics] = useState(false);
	const [loadingMoreDiscussions, setLoadingMoreDiscussions] = useState(false);
	const [isLastDiscussion, setIsLastDiscussion] = useState(false)
	const [isLastTopic, setIsLastTopic] = useState(false)
	const [isLastUser, setIsLastUser] = useState(false)
	const { setDocumentTitle } = useContext(DocumentTitleContext)

	
	const scrollerRef = useRef(null);
	const [discussionsScrollPosition, setDiscussionsScrollPosition] = useState(0);
	const [topicsScrollPosition, setTopicsScrollPosition] = useState(0);
	const [usersScrollPosition, setUserScrollPosition] = useState(0);

	const [discussionsList, setDiscussionsList] = useState([]);
	const [topicsList, setTopicsList] = useState([]);
	const [usersList, setUsersList] = useState([]);

	const { t } = useTranslation();
  
	useEffect(() => {
		// dont index this page in search engines
        const meta = document.createElement('meta');
        meta.name = "robots";
        meta.content = "noindex";
        document.head.appendChild(meta);

		setDocumentTitle(t('documentTitles.searchDefaultTitle'))
        // Cleanup function to remove the meta tag when the component unmounts
        return () => {
            document.head.removeChild(meta);
        };

	}, [])

	useEffect(() => {
		const newParams = new URLSearchParams(location.search).get('search_query')
		setMyParam(newParams)
		setLoadingDiscussions(true)
		setLoadingTopics(true)
		setLoadingUsers(true)
		
		setDocumentTitle(t('documentTitles.searchDefaultTitle'))
		// fetch discussions, default search at mount
		getSearchedDiscussionPostsFromElasticsearch(0, newParams.toString()).then((res) => {
			setDiscussionsList([...res.data])
			setIsLastDiscussion(res.isLastDiscussion)
			setLoadingDiscussions(false)
		})

		getSearchedTopicPostsFromElasticsearch(0, newParams.toString()).then((res) => {
			setTopicsList([...res.data])
			setIsLastTopic(res.isLastTopic)
			setLoadingTopics(false)
		})

		getSearchedUsersFromElasticsearch(0, newParams.toString()).then((res) => {
			setUsersList([...res.data])
			setIsLastUser(res.isLastUser)
			setLoadingUsers(false)
		})
	}, [location.search])

	useEffect(() => {

		// Scroll to top after tab change
		if(tabValue === 0){
			// TabValue is 0 (discussions)
			scrollerRef.current.scrollTop = discussionsScrollPosition
		}
		if (tabValue === 1){
			// TabValue is 1 (topics)
			scrollerRef.current.scrollTop = topicsScrollPosition
		}
		if (tabValue === 2){
			// TabValue is 2 (users)
			scrollerRef.current.scrollTop = usersScrollPosition
		}
	}, [tabValue])

	const setScrollPosition = () => {		
		if(tabValue === 0){
			// TabValue is 0 (discussions)
			setDiscussionsScrollPosition(scrollerRef.current.scrollTop);
			const bottom = (scrollerRef.current.scrollHeight - (scrollerRef.current.scrollTop + scrollerRef.current.clientHeight)) === 0;
			if (bottom && loadingMoreDiscussions === false && isLastDiscussion === false) {
				setLoadingMoreDiscussions(true)
				getSearchedDiscussionPostsFromElasticsearch(discussionsList.length, myParam.toString()).then((res) => {
					setDiscussionsList((prevList) => [...prevList,...res.data])
					setIsLastDiscussion(res.isLastDiscussion)
					setLoadingMoreDiscussions(false)
				})
			}
		}
		if (tabValue === 1){
			// TabValue is 1 (topics)
			setTopicsScrollPosition(scrollerRef.current.scrollTop);
			const bottom = (scrollerRef.current.scrollHeight - (scrollerRef.current.scrollTop + scrollerRef.current.clientHeight)) === 0;
			if (bottom && loadingMoreTopics === false && isLastTopic === false) {
				setLoadingMoreTopics(true)
				getSearchedTopicPostsFromElasticsearch(topicsList.length, myParam.toString()).then((res) => {
					setTopicsList((prevList) => [...prevList,...res.data])
					setIsLastTopic(res.isLastTopic)
					setLoadingMoreTopics(false)
				})
			}
		}
		if (tabValue === 2){
			// TabValue is 2 (users)
			setUserScrollPosition(scrollerRef.current.scrollTop);
			const bottom = (scrollerRef.current.scrollHeight - (scrollerRef.current.scrollTop + scrollerRef.current.clientHeight)) === 0;
			if (bottom && loadingMoreUsers === false && isLastUser === false) {
				setLoadingMoreUsers(true)
				getSearchedUsersFromElasticsearch(usersList.length, myParam.toString()).then((res) => {
					setUsersList((prevList) => [...prevList,...res.data])
					setIsLastUser(res.isLastUser)
					setLoadingMoreUsers(false)
				})
			}
		}
	}

	const handleChangeSearchTab = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<>
			<Paper className='SearchPaper' onScroll={setScrollPosition} 
			ref={scrollerRef} 
			sx={{ width: '100%', height: 'calc(100vh - ' + NAVBAR_HEIGHT + ')!important', borderRadius: 0,
					position: 'relative',
					overflow: 'auto'}}>
					
				<ListSubheader className='SearchPaper' sx={{height: "auto", width: "auto"}}>
					<AntTabs value={tabValue} onChange={handleChangeSearchTab} aria-label="intergraphy-search-tabs">
						<AntTab label={t('searchResultsComponent.discussions')} {...a11yProps(0)} />
						<AntTab label={t('searchResultsComponent.topics')} {...a11yProps(1)} />
						<AntTab label={t('searchResultsComponent.users')} {...a11yProps(2)} />
					</AntTabs>
				</ListSubheader>
				{ 
						<>
							{loadingDiscussions ? 
								<Box sx={{ display: 'flex',
									alignContent: 'center', 
									height: 'calc(100vh - ' + NAVBAR_HEIGHT + ' - 68px)',
									alignItems:"center",
									justifyContent:"center"}}>
									<CircularProgress />
								</Box> 
								:
								<CustomTabPanel value={tabValue} index={0} >
									{discussionsList.length > 0 ?
										<List sx={{ width: '100%' }}>
											{discussionsList.map((discussion) => {
												return (
													<DiscussionsSearchResultsItem discussion={discussion} searchedWords={searchedWords} />
												)
											})}
											
											{
												loadingMoreDiscussions === true ? 
													<Box sx={{ display: 'flex',
														alignContent: 'center', 
														height: "auto",
														margin:"12px",
														alignItems:"center",
														justifyContent:"center"}}>
														<CircularProgress size='26px'/>
													</Box>  : null
											}
										</List>
										:
										
										<Box sx={{ display: 'flex',
											alignContent: 'center', 
											height: 'calc(100vh - ' + NAVBAR_HEIGHT + ' - 68px)',
											alignItems:"center",
											justifyContent:"center"}}>
											<Typography variant='caption'>{t('searchResultsComponent.noResults')}</Typography>
										</Box> 
									}
								</CustomTabPanel>
							}
							
							{loadingTopics ? 
								<Box sx={{ display: 'flex',
									alignContent: 'center', 
									height: 'calc(100vh - ' + NAVBAR_HEIGHT + ' - 68px)',
									alignItems:"center",
									justifyContent:"center"}}>
									<CircularProgress />
								</Box> 
								:
								<CustomTabPanel value={tabValue} index={1}>
									{topicsList.length > 0 ?
										<List sx={{ width: '100%' }}>
											{topicsList.map((topic) => {
												return (
													<TopicsSearchResultsItem topic={topic} searchedWords={searchedWords} />
												)
											})}
											
											{
												loadingMoreTopics === true ? 
													<Box sx={{ display: 'flex',
														alignContent: 'center', 
														height: "auto",
														margin:"12px",
														alignItems:"center",
														justifyContent:"center"}}>
														<CircularProgress size='26px'/>
													</Box>  : null
											}
										</List>
										:
										
										<Box sx={{ display: 'flex',
											alignContent: 'center', 
											height: 'calc(100vh - ' + NAVBAR_HEIGHT + ' - 68px)',
											alignItems:"center",
											justifyContent:"center"}}>
											<Typography variant='caption'>{t('searchResultsComponent.noResults')}</Typography>
										</Box> 
									}
								</CustomTabPanel>
							}
							
							{loadingUsers ? 
								<Box sx={{ display: 'flex',
									alignContent: 'center', 
									height: 'calc(100vh - ' + NAVBAR_HEIGHT + ' - 68px)',
									alignItems:"center",
									justifyContent:"center"}}>
									<CircularProgress />
								</Box> 
								:
								<CustomTabPanel value={tabValue} index={2}> 
									{usersList.length > 0 ?
										<List sx={{ width: '100%' }}>
											{usersList.map((user) => {
												return (
													<UsersSearchResultsItem user={user} />
												)
											})}
											
											{
												loadingMoreUsers === true ? 
													<Box sx={{ display: 'flex',
														alignContent: 'center', 
														height: "auto",
														margin:"12px",
														alignItems:"center",
														justifyContent:"center"}}>
														<CircularProgress size='26px'/>
													</Box>  : null
											}
										</List>
										:
										
										<Box sx={{ display: 'flex',
											alignContent: 'center', 
											height: 'calc(100vh - ' + NAVBAR_HEIGHT + ' - 68px)',
											alignItems:"center",
											justifyContent:"center"}}>
											<Typography variant='caption'>{t('searchResultsComponent.noResults')}</Typography>
										</Box> 
									}
								</CustomTabPanel>
							}
						</>
				}
			</Paper>
		</>
	)

}