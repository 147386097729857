import React from 'react';
import './ReportedTopicTitleComponent.css'
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import ReportIcon from '@mui/icons-material/Report';
import Typography from '@mui/material/Typography';

export default function ReportedTopicTitleComponent(props) {
	const { t } = useTranslation();
	
	return(
		<>
			<Stack direction="row" sx={{marginLeft: "4px!important"}}>
				<ReportIcon sx={{marginTop: "-3px!important", marginRight: "4px"}}/>
				<Typography sx={{fontStyle: "italic!important",
				overflow: "hidden",
				textOverflow: "ellipsis",
				display: "-webkit-box",
				WebkitLineClamp: "1",
				WebkitBoxOrient: "vertical", color: "inherit!important"}}>
					{t("reportedTitleComponent.description")}
				</Typography>
			</Stack>
		</>
	)
}