import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

export async function getAppUsersQuantity(){
	const getappuserquantityfromcloudspanner = httpsCallable(functions, 'getappuserquantityfromcloudspanner');
	return await getappuserquantityfromcloudspanner()
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}