import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

export async function deleteUserFromCloudSpanner(){
	const deleteuserfromcloudspanner = httpsCallable(functions, 'deleteuserfromcloudspanner');
	return await deleteuserfromcloudspanner().then((res) => {
		const data = res.data;
		return data
	})
}
export async function deleteUserAvatarFromCloudSpanner(){
	const deleteuseravatarfromcloudspanner = httpsCallable(functions, 'deleteuseravatarfromcloudspanner');
	return await deleteuseravatarfromcloudspanner().then((res) => {
		const data = res.data;
		return data
	})
}
export async function deleteDiscussionLastPostFromCloudSpanner(topicId, discussionId, lastPostId){
	const deletediscussionlastpostfromcloudspanner = httpsCallable(functions, 'deletediscussionlastpostfromcloudspanner');
	return await deletediscussionlastpostfromcloudspanner({ topicId: topicId, discussionId: discussionId, lastPostId: lastPostId }).then((res) => {
		const data = res.data;
		return data
	})
}
export async function isLastDiscussionPostAReplyToOpening(topicId, discussionId, lastPostId){
	const islastdiscussionpostareplytoopeningincloudspanner = httpsCallable(functions, 'islastdiscussionpostareplytoopeningincloudspanner');
	return await islastdiscussionpostareplytoopeningincloudspanner({ topicId: topicId, discussionId: discussionId, lastPostId: lastPostId }).then((res) => {
		const data = res.data;
		return data
	})
}
export async function deleteDiscussionFromCloudSpanner(topicId, discussionId){
	const deletediscussionfromcloudspanner = httpsCallable(functions, 'deletediscussionfromcloudspanner');
	return await deletediscussionfromcloudspanner({ topicId: topicId, discussionId: discussionId }).then((res) => {
		const data = res.data;
		return data
	})
}
export async function deleteTopicFromCloudSpanner(topicId){
	const deletetopicfromcloudspanner = httpsCallable(functions, 'deletetopicfromcloudspanner');
	return await deletetopicfromcloudspanner({ topicId: topicId }).then((res) => {
		const data = res.data;
		return data
	})
}