import React, {useState} from 'react';

import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ColorModeContext } from '../../App';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function DarkModeButtonComponent(props) {

	const { t } = useTranslation();
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);

	return (    
        <>
        {props.text === true ? 
            <ListItemButton onClick={colorMode.toggleColorMode}>
                <ListItemIcon>
                    {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body1">{t("darkMode")}</Typography>}/>
            </ListItemButton>
        :
            <IconButton size="large" onClick={colorMode.toggleColorMode} color="inherit">
                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
        }
        </>
        
	);
}
