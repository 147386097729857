import {Component} from 'react';
import './CreateTopicPage.css'

import {NavBar, CreateTopicComponent, SidebarComponent, RightSidebarComponent, CenterScrollableStyledComponent} from '../../components'
import Grid from '@mui/material/Grid';

import { NAVBAR_HEIGHT } from '../../utils/Constants';


class CreateTopicPage extends Component {
	render() {
		return (
			<Grid 
				container
				direction="column"
				justifyContent="center"
				alignItems="center">
				<Grid container item xs={12}>
					<NavBar/>
				</Grid>
				<Grid container 
					justifyContent="center"
					alignItems="center" 
					sx={{marginTop: NAVBAR_HEIGHT}}>
					<Grid item xs={12}>
						<Grid xs={12} container direction="row">
							<SidebarComponent/>
							<CenterScrollableStyledComponent>
								<CreateTopicComponent/>
							</CenterScrollableStyledComponent>
							<RightSidebarComponent/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}
  }


export default CreateTopicPage;
