import React, {useState} from 'react';
import './ReportComponent.css'
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FlagIcon from '@mui/icons-material/Flag';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuth } from '../../contexts/AuthContext'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CloseIcon from '@mui/icons-material/Close';

import LinkPhoneNumberComponent from '../LinkPhoneNumberComponent';
import { reportAvatar, reportBio, reportPost, reportTopicTitle, reportUsername } from '../../firebaseFunctions/spannerFunctions/reportFunctions';
import VerifyUserComponent from '../VerifyUserComponent/VerifyUserComponent';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export default function ReportComponent(props) {
	
	const { t } = useTranslation();

	const navigate = useNavigate()
	
	const { currentUser } = useAuth()
	const [openReportPostModal, setOpenReportPostModal] = useState(false);
	const [openReportUsernameModal, setOpenReportUsernameModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [openReportTitleModal, setOpenReportTitleModal] = useState(false);
	const [openReportBioModal, setOpenReportBioModal] = useState(false);
	const [openReportAvatarModal, setOpenReportAvatarModal] = useState(false);
	const [phoneHasBeenLinked, setPhoneHasBeenLinked] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const [reportCategory, setReportCategory] = useState('other');
	const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);


	const handleClickOpenReportPostModal = () => {
		setOpenReportPostModal(true);
	};
	const handleCloseReportPostModal = () => {
		setOpenReportPostModal(false);
	};
	const handleClickOpenReportUsernameModal = () => {
		setOpenReportUsernameModal(true);
	};	
	const handleCloseReportUsernameModal = () => {
		setOpenReportUsernameModal(false);
	};
	const handleClickOpenReportTitleModal = () => {
		setOpenReportTitleModal(true);
	};
	const handleCloseReportTitleModal = () => {
		setOpenReportTitleModal(false);
	};
	const handleClickOpenReportBioModal = () => {
		setOpenReportBioModal(true);
	};
	const handleCloseReportBioModal = () => {
		setOpenReportBioModal(false);
	};
	const handleClickOpenReportAvatarModal = () => {
		setOpenReportAvatarModal(true);
	};
	const handleCloseReportAvatarModal = () => {
		setOpenReportAvatarModal(false);
	};
	
	const handleClickMore = (event) => {
	  setAnchorEl(event.currentTarget);
	};
	const handleCloseMore = () => {
	  setAnchorEl(null);
	};

	const handlePhoneHasBeenLinked = () => {
		setPhoneHasBeenLinked(true)
	}
	const handleReportCategoryChange = (event) => {
		setReportCategory(event.target.value);
	};

	async function submitReportTitleModal(e) {
		e.preventDefault();
		setLoading(true)
		reportTopicTitle(props.topicId, reportCategory).then((res) => {
			if (res.maxReportsReached === true) {
				openErrorAlert()
				handleCloseReportTitleModal()
				setLoading(false)
			} else {
				navigate(0)
				handleCloseReportTitleModal()
				setLoading(false)
			}
		})		
	}
	async function submitReportUsernameModal(e) {
		e.preventDefault();
		setLoading(true)
		reportUsername(props.userId, reportCategory).then((res) => {
			if (res.maxReportsReached === true) {
				openErrorAlert()
				handleCloseReportUsernameModal()
				setLoading(false)
			} else {
				navigate(0)
				handleCloseReportUsernameModal()
				setLoading(false)
			}
		})		
	}
	async function submitReportPostModal(e) {
		e.preventDefault();
		setLoading(true)
		reportPost(props.postId, props.userId, reportCategory).then((res) => {
			if (res.maxReportsReached === true) {
				openErrorAlert()
				handleCloseReportPostModal()
				setLoading(false)
			} else {
				navigate(0)
				handleCloseReportPostModal()
				setLoading(false)
			}
		})
	}
	async function submitReportBioModal(e) {
		e.preventDefault();
		setLoading(true)
		reportBio(props.userId, reportCategory).then((res) => {
			if (res.maxReportsReached === true) {
				openErrorAlert()
				handleCloseReportBioModal()
				setLoading(false)
			} else {
				navigate(0)
				handleCloseReportBioModal()
				setLoading(false)
			}
		})
	}
	async function submitReportAvatarModal(e) {
		e.preventDefault();
		setLoading(true)
		reportAvatar(props.userId, reportCategory).then((res) => {
			if (res.maxReportsReached === true) {
				openErrorAlert()
				handleCloseReportAvatarModal()
				setLoading(false)
			} else {
				navigate(0)
				handleCloseReportAvatarModal()
				setLoading(false)
			}
		})
	}
	
	const openErrorAlert = () => {
		setErrorAlertOpen(true);
	};
	const closeErrorAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setErrorAlertOpen(false);
	};
	return (
		<>	
			
			<Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={closeErrorAlert}>
				<Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
					{t("reportComponent.maxReportsReached")}
				</Alert>
			</Snackbar>
			{ 	currentUser ?
				(currentUser.uid !== props.userId && (props.reportedPost !== true || props.reportedTitle !== true) && (props.type !== "userPage"))
				|| 
				(currentUser.uid !== props.userId && (props.reportedPost !== true || props.reportedTitle !== true) && (props.type === "userPage"))  ?
				<>   		
					{/* Report Title Dialog */}	  
					{
						currentUser.emailVerified ? 
							currentUser.phoneNumber || phoneHasBeenLinked === true ? 
								<Dialog
									open={openReportTitleModal}
									onClose={handleCloseReportTitleModal}
									sx={{padding: "12px"}}
									PaperProps={{
									component: 'form',
									onSubmit: (event) => submitReportTitleModal(event),
									}}
								>
									<DialogContent>
										<FormControl>
											<FormLabel id="demo-radio-buttons-group-label">
												<Typography	variant='h2' sx={{paddingBottom: "12px"}}>
													{t('reportComponent.reportTopicTitle')}
												</Typography>
											</FormLabel>
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												defaultValue="other"
												name="radio-buttons-group"
												value={reportCategory}
												onChange={handleReportCategoryChange}
											>
												<FormControlLabel value="defamatory-content" control={<Radio />} label={t('reportComponent.defamatoryContent')} />
												<FormControlLabel value="illegal-content" control={<Radio />} label={t('reportComponent.illegalContent')} />
												<FormControlLabel value="sexual-content" control={<Radio />} label={t('reportComponent.sexualContent')} />
												<FormControlLabel value="misinformation" control={<Radio />} label={t('reportComponent.misinformation')} />
												<FormControlLabel value="rights-of-third-parties" control={<Radio />} label={t('reportComponent.rightsOfThirdParties')} />
												<FormControlLabel value="hate-and-discrimination" control={<Radio />} label={t('reportComponent.hateAndDiscrimination')} />
												<FormControlLabel value="other" control={<Radio />} label={t('reportComponent.other')} />
											</RadioGroup>
										</FormControl>
									</DialogContent>
									<DialogActions>
										<Button variant="contained" color="error" disabled={loading} onClick={handleCloseReportTitleModal}>{t('cancel')}</Button>
										<Button variant="contained" type="submit" disabled={loading}>{t('submit')}</Button>
									</DialogActions>
								</Dialog>
							:
								currentUser &&
								<>			
									<LinkPhoneNumberComponent reportModalClose={() => handleCloseReportTitleModal()} reportModalOpen={openReportTitleModal} handlePhoneHasBeenLinked={handlePhoneHasBeenLinked}/>
								</>
						:
							<Dialog
								open={openReportTitleModal}
								onClose={handleCloseReportTitleModal}
								sx={{padding: "12px"}}
							>
								<IconButton
								aria-label="close"
								onClick={handleCloseReportTitleModal}
								sx={{
									position: 'absolute',
									right: "10px",
									top: "10px",
								}}
								>
									<CloseIcon fontSize="small" />
								</IconButton>
								<DialogContent>
									<VerifyUserComponent/>
								</DialogContent>
							</Dialog>
					}
					{/* Report Post Dialog */}
					{
						currentUser.emailVerified ?
							currentUser.phoneNumber || phoneHasBeenLinked === true ? 
								<Dialog
									open={openReportPostModal}
									onClose={handleCloseReportPostModal}
									sx={{padding: "12px"}}
									PaperProps={{
									component: 'form',
									onSubmit: (event) => submitReportPostModal(event),
									}}
								>
									<DialogContent>
										<FormControl>
											<FormLabel id="demo-radio-buttons-group-label">		
												<Typography	variant='h2' sx={{paddingBottom: "12px"}}>
													{t('reportComponent.reportPost')}
												</Typography>			
											</FormLabel>
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												defaultValue="other"
												name="radio-buttons-group"
												value={reportCategory}
												onChange={handleReportCategoryChange}
											>
												<FormControlLabel value="defamatory-content" control={<Radio />} label={t('reportComponent.defamatoryContent')} />
												<FormControlLabel value="illegal-content" control={<Radio />} label={t('reportComponent.illegalContent')} />
												<FormControlLabel value="sexual-content" control={<Radio />} label={t('reportComponent.sexualContent')} />
												<FormControlLabel value="misinformation" control={<Radio />} label={t('reportComponent.misinformation')} />
												<FormControlLabel value="rights-of-third-parties" control={<Radio />} label={t('reportComponent.rightsOfThirdParties')} />
												<FormControlLabel value="hate-and-discrimination" control={<Radio />} label={t('reportComponent.hateAndDiscrimination')} />
												<FormControlLabel value="other" control={<Radio />} label={t('reportComponent.other')} />
											</RadioGroup>
										</FormControl>
									</DialogContent>
									<DialogActions>
										<Button variant="contained" color="error" disabled={loading} onClick={handleCloseReportPostModal}>{t('cancel')}</Button>
										<Button variant="contained" type="submit" disabled={loading}>{t('submit')}</Button>
									</DialogActions>
								</Dialog>
							:
								currentUser &&
								<>			
									<LinkPhoneNumberComponent reportModalClose={handleCloseReportPostModal} reportModalOpen={openReportPostModal} handlePhoneHasBeenLinked={handlePhoneHasBeenLinked}/>
								</>
						:
							<Dialog
								open={openReportPostModal}
								onClose={handleCloseReportPostModal}
								sx={{padding: "12px"}}
							>
								<IconButton
								aria-label="close"
								onClick={handleCloseReportPostModal}
								sx={{
									position: 'absolute',
									right: "10px",
									top: "10px",
								}}
								>
									<CloseIcon fontSize="small" />
								</IconButton>
								<DialogContent>
									<VerifyUserComponent/>
								</DialogContent>
							</Dialog>
					}
					{/* Report Bio Dialog */}
					{
						currentUser.emailVerified ?
							currentUser.phoneNumber || phoneHasBeenLinked === true ? 
								<Dialog
									open={openReportBioModal}
									onClose={handleCloseReportBioModal}
									sx={{padding: "12px"}}
									PaperProps={{
									component: 'form',
									onSubmit: (event) => submitReportBioModal(event),
									}}
								>
									<DialogContent>
										<FormControl>
											<FormLabel id="demo-radio-buttons-group-label">		
												<Typography	variant='h2' sx={{paddingBottom: "12px"}}>
													{t('reportComponent.reportBio')}
												</Typography>			
											</FormLabel>
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												defaultValue="other"
												name="radio-buttons-group"
												value={reportCategory}
												onChange={handleReportCategoryChange}
											>
												<FormControlLabel value="defamatory-content" control={<Radio />} label={t('reportComponent.defamatoryContent')} />
												<FormControlLabel value="illegal-content" control={<Radio />} label={t('reportComponent.illegalContent')} />
												<FormControlLabel value="sexual-content" control={<Radio />} label={t('reportComponent.sexualContent')} />
												<FormControlLabel value="misinformation" control={<Radio />} label={t('reportComponent.misinformation')} />
												<FormControlLabel value="rights-of-third-parties" control={<Radio />} label={t('reportComponent.rightsOfThirdParties')} />
												<FormControlLabel value="hate-and-discrimination" control={<Radio />} label={t('reportComponent.hateAndDiscrimination')} />
												<FormControlLabel value="other" control={<Radio />} label={t('reportComponent.other')} />
											</RadioGroup>
										</FormControl>
									</DialogContent>
									<DialogActions>
										<Button variant="contained" color="error" disabled={loading} onClick={handleCloseReportBioModal}>{t('cancel')}</Button>
										<Button variant="contained" type="submit" disabled={loading}>{t('submit')}</Button>
									</DialogActions>
								</Dialog>
							:
								currentUser &&
								<>			
									<LinkPhoneNumberComponent reportModalClose={handleCloseReportBioModal} reportModalOpen={openReportBioModal} handlePhoneHasBeenLinked={handlePhoneHasBeenLinked}/>
								</>
						:
							<Dialog
								open={openReportBioModal}
								onClose={handleCloseReportBioModal}
								sx={{padding: "12px"}}
							>
								<IconButton
								aria-label="close"
								onClick={handleCloseReportBioModal}
								sx={{
									position: 'absolute',
									right: "10px",
									top: "10px",
								}}
								>
									<CloseIcon fontSize="small" />
								</IconButton>
								<DialogContent>
									<VerifyUserComponent/>
								</DialogContent>
							</Dialog>
					}
					{/* Report Avatar Dialog */}
					{
						currentUser.emailVerified ?
							currentUser.phoneNumber || phoneHasBeenLinked === true ? 
								<Dialog
									open={openReportAvatarModal}
									onClose={handleCloseReportAvatarModal}
									sx={{padding: "12px"}}
									PaperProps={{
									component: 'form',
									onSubmit: (event) => submitReportAvatarModal(event),
									}}
								>
									<DialogContent>
										<FormControl>
											<FormLabel id="demo-radio-buttons-group-label">		
												<Typography	variant='h2' sx={{paddingBottom: "12px"}}>
													{t('reportComponent.reportAvatar')}
												</Typography>			
											</FormLabel>
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												defaultValue="other"
												name="radio-buttons-group"
												value={reportCategory}
												onChange={handleReportCategoryChange}
											>
												<FormControlLabel value="defamatory-content" control={<Radio />} label={t('reportComponent.defamatoryContent')} />
												<FormControlLabel value="illegal-content" control={<Radio />} label={t('reportComponent.illegalContent')} />
												<FormControlLabel value="sexual-content" control={<Radio />} label={t('reportComponent.sexualContent')} />
												<FormControlLabel value="misinformation" control={<Radio />} label={t('reportComponent.misinformation')} />
												<FormControlLabel value="rights-of-third-parties" control={<Radio />} label={t('reportComponent.rightsOfThirdParties')} />
												<FormControlLabel value="hate-and-discrimination" control={<Radio />} label={t('reportComponent.hateAndDiscrimination')} />
												<FormControlLabel value="other" control={<Radio />} label={t('reportComponent.other')} />
											</RadioGroup>
										</FormControl>
									</DialogContent>
									<DialogActions>
										<Button variant="contained" color="error" disabled={loading} onClick={handleCloseReportAvatarModal}>{t('cancel')}</Button>
										<Button variant="contained" type="submit" disabled={loading}>{t('submit')}</Button>
									</DialogActions>
								</Dialog>
							:
								currentUser &&
								<>			
									<LinkPhoneNumberComponent reportModalClose={handleCloseReportAvatarModal} reportModalOpen={openReportAvatarModal} handlePhoneHasBeenLinked={handlePhoneHasBeenLinked}/>
								</>
						:
							<Dialog
								open={openReportAvatarModal}
								onClose={handleCloseReportAvatarModal}
								sx={{padding: "12px"}}
							>
								<IconButton
								aria-label="close"
								onClick={handleCloseReportAvatarModal}
								sx={{
									position: 'absolute',
									right: "10px",
									top: "10px",
								}}
								>
									<CloseIcon fontSize="small" />
								</IconButton>
								<DialogContent>
									<VerifyUserComponent/>
								</DialogContent>
							</Dialog>
					}
					{/* Report Username Dialog */}
					{
						currentUser.emailVerified ?
							currentUser.phoneNumber || phoneHasBeenLinked === true ? 
								<Dialog
									open={openReportUsernameModal}
									onClose={handleCloseReportUsernameModal}
									sx={{padding: "12px"}}
									PaperProps={{
									component: 'form',
									onSubmit: (event) => submitReportUsernameModal(event),
									}}
								>
									<DialogContent>
										<FormControl>
											<FormLabel id="demo-radio-buttons-group-label">		
												<Typography	variant='h2' sx={{paddingBottom: "12px"}}>
													{t('reportComponent.reportUsername')}
												</Typography>			
											</FormLabel>
											<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												defaultValue="other"
												name="radio-buttons-group"
												value={reportCategory}
												onChange={handleReportCategoryChange}
											>
												<FormControlLabel value="defamatory-content" control={<Radio />} label={t('reportComponent.defamatoryContent')} />
												<FormControlLabel value="illegal-content" control={<Radio />} label={t('reportComponent.illegalContent')} />
												<FormControlLabel value="sexual-content" control={<Radio />} label={t('reportComponent.sexualContent')} />
												<FormControlLabel value="misinformation" control={<Radio />} label={t('reportComponent.misinformation')} />
												<FormControlLabel value="rights-of-third-parties" control={<Radio />} label={t('reportComponent.rightsOfThirdParties')} />
												<FormControlLabel value="hate-and-discrimination" control={<Radio />} label={t('reportComponent.hateAndDiscrimination')} />
												<FormControlLabel value="other" control={<Radio />} label={t('reportComponent.other')} />
											</RadioGroup>
										</FormControl>
									</DialogContent>
									<DialogActions>
										<Button variant="contained" color="error" disabled={loading} onClick={handleCloseReportUsernameModal}>{t('cancel')}</Button>
										<Button variant="contained" type="submit" disabled={loading}>{t('submit')}</Button>
									</DialogActions>
								</Dialog>
							:
								currentUser &&
								<>			
									<LinkPhoneNumberComponent reportModalClose={handleCloseReportUsernameModal} reportModalOpen={openReportUsernameModal} handlePhoneHasBeenLinked={handlePhoneHasBeenLinked}/>
								</>
						:
							<Dialog
								open={openReportUsernameModal}
								onClose={handleCloseReportUsernameModal}
								sx={{padding: "12px"}}
							>
								<IconButton
								aria-label="close"
								onClick={handleCloseReportUsernameModal}
								sx={{
									position: 'absolute',
									right: "10px",
									top: "10px",
								}}
								>
									<CloseIcon fontSize="small" />
								</IconButton>
								<DialogContent>
									<VerifyUserComponent/>
								</DialogContent>
							</Dialog>
					}

					<Box sx={{ display: 'flex', float: "right", alignItems: 'center', textAlign: 'center' }}>
						<Tooltip title={t('reportComponent.moreIconTooltip')}>
							<IconButton
							disabled={loading}
							onClick={handleClickMore}
							size="small"
							aria-controls={open ? 'account-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							>
								<MoreVertIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Box>
					<Menu
					anchorEl={anchorEl}
					id="account-menu"
					open={open}
					onClose={handleCloseMore}
					onClick={handleCloseMore}
					PaperProps={{
						elevation: 0,
						sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&::before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
						},
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						{
							(props.type === "userPage") && (props.bio !== "" && props.bio.length > 0) ?
							
							<MenuItem onClick={handleClickOpenReportBioModal}>
								<ListItemIcon>
									<FlagIcon fontSize="small" />
								</ListItemIcon>
								{t('reportComponent.reportBio')}
							</MenuItem>
							:
								null
						}
						{
							(props.type === "userPage") && (props.avatar !== "" && props.avatar.length > 0) ?
							
							<MenuItem onClick={handleClickOpenReportAvatarModal}>
								<ListItemIcon>
									<FlagIcon fontSize="small" />
								</ListItemIcon>
								{t('reportComponent.reportAvatar')}
							</MenuItem>
							:
								null
						}
						{
							(props.type === "opening" || props.type === "topicPage" || props.type === "discussionPage") && props.reportedTitle !== true ? 
							
							<MenuItem onClick={handleClickOpenReportTitleModal}>
								<ListItemIcon>
									<FlagIcon fontSize="small" />
								</ListItemIcon>
								{t('reportComponent.reportTopicTitle')}
							</MenuItem>
							:
								null
						}
						{
							props.reportedPost !== true && (props.type === "opening" || props.type === "reply" || props.type === "openingPost") ?
								<MenuItem onClick={handleClickOpenReportPostModal}>
									<ListItemIcon>
										<FlagIcon fontSize="small" />
									</ListItemIcon>
									{t('reportComponent.reportPost')}
								</MenuItem>
								:
								null
						}
						{
							(props.type === "userPage") ?
								<MenuItem onClick={handleClickOpenReportUsernameModal}>
									<ListItemIcon>
										<FlagIcon fontSize="small" />
									</ListItemIcon>
									{t('reportComponent.reportUsername')}
								</MenuItem>
								:
								null
						}
					</Menu>
				</> : null : null
			}
		</>
	)
}