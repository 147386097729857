import React, {useState, useContext, useEffect} from 'react';
import './ManageTopicsComponent.css'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { COLLAPSIBLE_HEIGHT_ABSOLUTE, DEBATE_LIST_CREATED_ITEM_HEIGHT, NAVBAR_HEIGHT, NAVBAR_HEIGHT_ABSOLUTE } from '../../utils/Constants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Tooltip from '@mui/material/Tooltip';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DeleteDiscussionComponent, DeleteTopicComponent } from '..';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import GroupIcon from '@mui/icons-material/Group';

import Button from '@mui/material/Button';
import { auth } from "../../firebase";
import { AVATAR_XS_SIZE, DEBATE_LIST_ITEM_HEIGHT } from '../../utils/Constants';
import { getFollowingDiscussionsList, unfollowDiscussion, getDiscussionsList, getCreatedTopicsList, changeTopicOpen, getDiscussionsFromTopicId, 
	getInvitedUsersOfTopic, getTopicInvitations, deleteUserInvitation,
	updateDiscussionLastReadTimestamp
 } from '../../firebaseFunctions/spannerFunctions/topicFunctions';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ReportedTopicTitleComponent from '../ReportedTopicTitleComponent';
const shapeStyles = { bgcolor: 'red', width: 7, height: 7 };
const shapeCircleStyles = { borderRadius: '50%' };
const circle = (
	<Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }} />
);

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
	  borderBottom: 0,
	},
	'&:before': {
	  display: 'none',
	},
  }));
  
  const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
	  expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />}
	  {...props}
	/>
  ))(({ theme }) => ({
	backgroundColor:
	  theme.palette.mode === 'dark'
		? 'rgba(255, 255, 255, .02)'
		: 'rgba(0, 0, 0, 0)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
	  transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
	  marginLeft: theme.spacing(1),
	},
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(1),	
  }));

  function RejectTopicInvitation(props) {
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = useState(false);

	const handleClickOpen = () => {
	  	setOpen(true);
	};
  
	const handleClose = () => {
	  	setOpen(false);
	};
	async function handleAgree() {
		setLoading(true)
		await deleteUserInvitation(auth.currentUser.uid, props.topicId)
		props.handleRejectTopicInvitation(props.topicId)
		setLoading(false)
	  	setOpen(false);
	};
	return (
		<>
			<IconButton sx={{":hover": {backgroundColor: "transparent"}}} size='small' onClick={handleClickOpen}>			
				<ClearIcon fontSize="inherit" />
			</IconButton>
			
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{t("manageTopics.rejectTopicInvitationStatementTitle")}
				</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					
					<Typography variant="caption" >{t("manageTopics.rejectTopicInvitationStatementDescription")}</Typography>	
					
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button onClick={handleClose}>
					{t("cancel")}
				</Button>
				<Button disabled={loading} onClick={handleAgree} autoFocus>
					{t("accept")}
				</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
function ChangeTopicOpenDialog(props) {
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = useState(false);
	const [isTopicOpen, setIsTopicOpen] = useState(props.isOpen)

	const handleClickOpen = () => {
	  	setOpen(true);
	};
  
	const handleClose = () => {
	  	setOpen(false);
	};
	async function handleAgree() {
		setLoading(true)
		await changeTopicOpen(props.topicId)
		setLoading(false)
		setIsTopicOpen((prevVal) => {
			const newVal = !prevVal
			return newVal
		})
	  	setOpen(false);
	};
	return (
		<>
			<IconButton sx={{":hover": {backgroundColor: "transparent"}}} onClick={handleClickOpen}>
				{isTopicOpen ? 
					<Tooltip title={t("opened")}>
						<LockOpenIcon fontSize="small" sx={{color:'secondary.icon', marginBottom: "21px!important"}}/>
					</Tooltip>
					:
					<Tooltip title={t("closed")}>
						<LockIcon fontSize="small" sx={{color:'secondary.icon', marginBottom: "21px!important"}}/>
					</Tooltip>
				}
			</IconButton>
			
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{t("manageTopics.closeTopicStatementQuestion")}
				</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					
					<Typography variant="caption" >{isTopicOpen ? t("manageTopics.closeTopicStatementDescription") : t("manageTopics.openTopicStatementDescription")}</Typography>	
					
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button onClick={handleClose}>
					{t("cancel")}
				</Button>
				<Button disabled={loading} onClick={handleAgree} autoFocus>
					{t("accept")}
				</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

// Created Topic DiscussionsList Dialog
function CreatedTopicDiscussionsListDialog(props){
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);
	
	const [loading, setLoading] = useState(false)

	const [discussionsList, setDiscussionsList] = useState([])
	const [isLastDiscussion, setIsLastDiscussion] = useState(false);
	const navigate = useNavigate()  
	const handleClickOpen = () => {
	  	setOpen(true);
	};
  
	const handleClose = () => {
	  	setOpen(false);
	};

	useEffect(() => {
		setLoading(true)
		getDiscussionsFromTopicId(null, props.topic.topicId).then((res) => {
			setDiscussionsList(res.data)
			setIsLastDiscussion(res.isLastDiscussion)
			setLoading(false)
		})
	
	}, [])
	
	async function loadMoreDiscussions(){
		setLoading(true)
		
		if (!isLastDiscussion){
			getDiscussionsFromTopicId(discussionsList[discussionsList.length - 1].discussionId, props.topic.topicId).then((res) => {
				setDiscussionsList((prevList) => [...prevList, ...res.data])
				setIsLastDiscussion(res.isLastDiscussion)
				setLoading(false)
			})
		}
	}
	
	async function handleDiscussionNavigate (e, discussionId) {
		
		if (e.button !== 1) {
			// not clicked with wheel
			const to = "/topic/" + props.topic.topicId + "/discussion/" + discussionId
			navigate(to)
		}
	}
	return (
		<>
		{discussionsList &&
			<>
			<Button onClick={handleClickOpen} variant="text" sx={{"&:hover":{backgroundColor: 'transparent', textDecoration: 'none', textDecorationColor: 'gray'}, marginRight: "-8px!important", marginLeft: "-8px!important", textTransform: "inherit!important"}}>
				<Stack direction="column">
					<Tooltip title={t("discussions")}>
						<FolderCopyIcon fontSize="small" sx={{color:'secondary.icon'}} />
					</Tooltip>
					<Typography variant="caption" sx={{color:'secondary.icon'}} >{discussionsList.length}</Typography>	
				</Stack>
			</Button>	
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				sx={{ maxHeight: "80vh!important"}}
			>
				<DialogTitle id="alert-dialog-title">
					{t("manageTopics.discussionsFrom") + " " + props.topic.title} 
				</DialogTitle>
				<DialogContent>
					<List>
						<>
						{discussionsList.length !== 0 ? 
							<List component="div" disablePadding sx={{overflow:"auto", maxHeight: "100%!important", minWidth: "170px!important"}}>
								{discussionsList.map((discussion) => (
									<Stack direction="row" 
									justifyContent="space-between"					
									alignItems="center">

										<ListItemButton sx={{ height: DEBATE_LIST_ITEM_HEIGHT}}
											href={"/topic/" + props.topic.topicId + "/discussion/" + discussion.discussionId}
											onMouseDown={(e) => handleDiscussionNavigate(e, discussion.discussionId)}>
											<ListItemText primary={
												<Stack direction="row" spacing={1}>
													{discussion.avatarSmallUrl !== "" ?  	
														<Tooltip title={discussion.username}>
															<Avatar
																alt={discussion.username + "_avatar"}
																src={discussion.avatarSmallUrl}
																sx={{ width: AVATAR_XS_SIZE, height: AVATAR_XS_SIZE }}
															/>
														</Tooltip>
													:
														<Tooltip title={discussion.username}>
															<Avatar sx={{ width: AVATAR_XS_SIZE, height:AVATAR_XS_SIZE, color:"white" }} 
																alt={discussion.username + "_avatar"}>
																<Typography variant='body1' sx={{color: "inherit"}}>
																	{discussion.username[0].toUpperCase()}
																</Typography>
															</Avatar>
														</Tooltip>
													}
													<Typography variant="body1" sx={{
														overflow: "hidden",
														textOverflow: "ellipsis",
														display: "-webkit-box",
														WebkitLineClamp: "1",
														WebkitBoxOrient: "vertical"}}>{discussion.username}</Typography>
												</Stack>
											} />
											
										</ListItemButton>
										{discussion.authorOfLastPost === auth.currentUser.uid ? null : circle}
									</Stack>
								))}		
								{!isLastDiscussion ?									
									<Button className='LoadMoreButton' fullWidth disabled={loading} onClick={loadMoreDiscussions}>
										<Stack direction="row"
											justifyContent="center"
											alignItems="center">
											<Typography color="inherit" variant="body1">{t("manageTopics.loadMoreDiscussions")}</Typography>
											<ArrowDropDownIcon />
										</Stack>
									</Button>
									:
									null
								}
							</List>
							: 
							<Typography variant="caption">{t("manageTopics.emptyTopicDiscussionsList")}</Typography>
						}
						</>
						
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t("close")}</Button>
				</DialogActions>
			</Dialog>
			</>
			}
		</>
	)
}

function UsersInvitedDialog(props){
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);
	
	const [loading, setLoading] = useState(false)

	const [userList, setUserList] = useState()
	const navigate = useNavigate()  
	const handleClickOpen = () => {
	  	setOpen(true);
	};
  
	const handleClose = () => {
	  	setOpen(false);
	};

	useEffect(() => {
	  	loadMoreUsers()
	
	}, [])
	
	async function loadMoreUsers(){
		getInvitedUsersOfTopic(props.topic.topicId).then((res) => {
			setUserList(res)
		})
	}
	
	async function handleUserNavigate (e, username) {
		if (e.button !== 1) {
			// clicked not with third mouse button
			const to = "/" + username
			navigate(to)
		}
	}
	async function handleDeleteInvitation(userId, topicId){
		setLoading(true)
		const res = await deleteUserInvitation(userId, topicId)
		if (res.success === true) {
			setUserList((prevList) => {
				const updatedList = [...prevList];
				const index = updatedList.findIndex(obj => obj.userId === userId);
				updatedList.splice(index, 1); 
				return updatedList
			})	

		}
		setLoading(false)
	}

	return (
		<>
		{
			userList && 
			<>
			<Button onClick={handleClickOpen} variant="text" sx={{"&:hover":{backgroundColor: 'transparent', textDecoration: 'none', textDecorationColor: 'gray'},marginRight: "-8px!important", marginLeft: "-8px!important", textTransform: "inherit!important"}}>	
				<Stack direction="column">
					<Tooltip title={t("invitations")}>
						<GroupIcon fontSize="small" sx={{color:'secondary.icon'}}  />
					</Tooltip>
					<Typography variant="caption" sx={{color:'secondary.icon'}}>{userList.length}</Typography>	
				</Stack>
			</Button>	
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				sx={{ maxHeight: "80vh!important"}}
			>
				<DialogTitle id="alert-dialog-title">
					{t("manageTopics.usersInvitedTo") + " " + props.topic.title}
				</DialogTitle>
				<DialogContent>
					<List>
						{userList && 
							<>
								{userList.length !== 0 ? 

									<List component="div" disablePadding sx={{overflow:"auto", maxHeight: "100%!important", minWidth: "170px!important"}}>
										{userList.map((user) => (
											<>
											
											<Stack direction="row" 
											justifyContent="space-between"					
											alignItems="center">

												<ListItemButton sx={{ height: DEBATE_LIST_ITEM_HEIGHT}}
													href={'/' + user.username}
													onMouseDown={(e) => handleUserNavigate(e, user.username)}>
													<ListItemText primary={
														<Stack direction="row" spacing={1}>
															{user.avatarSmallUrl !== "" ?  	
																<Tooltip title={user.username}>
																	<Avatar
																		alt={user.username + "_avatar"}
																		src={user.avatarSmallUrl}
																		sx={{ width: AVATAR_XS_SIZE, height: AVATAR_XS_SIZE }}
																	/>
																</Tooltip>
															:
																<Tooltip title={user.username}>
																	<Avatar sx={{ width: AVATAR_XS_SIZE, height:AVATAR_XS_SIZE, color:"white" }} 
																		alt={user.username + "_avatar"}>
																		<Typography variant='body1' sx={{color: "inherit"}}>
																			{user.username[0].toUpperCase()}
																		</Typography>
																	</Avatar>
																</Tooltip>
															}
															<Typography variant="body1" sx={{
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																	display: "-webkit-box",
																	WebkitLineClamp: "1",
																	WebkitBoxOrient: "vertical"}}>{user.username}</Typography>
														</Stack>
													} />
													
												</ListItemButton>

												<IconButton disabled={loading} aria-label="delete" size="small" onClick={() => handleDeleteInvitation(user.userId, props.topic.topicId)}>
													<ClearIcon fontSize="inherit" />
												</IconButton>
											</Stack>
											</>


										))}		
									</List>
									: 
									<Typography variant="caption">{t("manageTopics.noUsersInvited")}</Typography>
								}
							</>
						}
					</List>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t("close")}</Button>
				</DialogActions>
			</Dialog>
			</>
		}
			
		</>
	)
}

export default function ManageTopicsComponent() {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false)
	const [loadingDiscussionsList, setLoadingDiscussionsList] = useState(false)
	const [loadingFollowingDiscussionsList, setLoadingFollowingDiscussionsList] = useState(false)
	const [loadingCreatedTopicsList, setLoadingCreatedTopicsList] = useState(false)
	const [loadingTopicInvitations, setLoadingTopicInvitations] = useState(false)

	const [expanded, setExpanded] = useState("panel1")

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	const [followingDiscussionList, setFollowingDiscussionList] = useState([])
	const [isLastInvitation, setIsLastInvitation] = useState(false);
	const [openFollowingDiscussionDialog, setOpenFollowingDiscussionDialog] = useState(false);
	const [selectedFollowingDiscussion, setSelectedFollowingDiscussion] = useState()
	const [discussionsList, setDiscussionsList] = useState([])
	const [createdTopicList, setCreatedTopicList] = useState([])
	const [isLastDiscussion, setIsLastDiscussion] = useState(false);
	const [isLastFollowingDiscussion, setIsLastFollowingDiscussion] = useState(false);
	const [isLastTopicCreated, setIsLastTopicCreated] = useState(false);
	const [inviteFromFollowsCheckboxChecked, setInviteFromFollowsCheckboxChecked] = useState(false);
	const [previousUpdatedInviteFromFollowsCheckboxChecked, setPreviousUpdatedInviteFromFollowsCheckboxChecked] = useState(false);
	const [unansweredDiscussionsCheckboxChecked, setUnansweredDiscussionsCheckboxChecked] = useState(false);
	const [previousUpdatedUnansweredDiscussionsCheckboxChecked, setPreviousUpdatedUnansweredDiscussionsCheckboxChecked] = useState(false);
	const [openTopicsCheckboxChecked, setOpenTopicsCheckboxChecked] = useState(false);
	const [previousUpdatedOpenTopicsCheckboxChecked, setPreviousUpdatedOpenTopicsCheckboxChecked] = useState(false);

	const[topicInvitationsList, setTopicInvitationsList] = useState([])
	const navigate = useNavigate()  
	const { setDocumentTitle } = useContext(DocumentTitleContext)

	useEffect(() => {  
		// Anything in here is fired on component mount.
		setDocumentTitle(t('documentTitles.manageTopics'))
		setLoading(true)
		
		setLoadingDiscussionsList(true)
		setLoadingFollowingDiscussionsList(true)
		setLoadingCreatedTopicsList(true)
		setLoadingTopicInvitations(true)

		getDiscussionsList(null, unansweredDiscussionsCheckboxChecked).then((res) => {
			setIsLastDiscussion(res.isLastDiscussion)
			setDiscussionsList((prevList) => [...prevList,...res.data])
			setLoadingDiscussionsList(false)
		})
		getFollowingDiscussionsList(null).then((res) => {
			setFollowingDiscussionList(res.data)
			setIsLastFollowingDiscussion(res.isLastDiscussion)
			setLoadingFollowingDiscussionsList(false)
		})
		getCreatedTopicsList(null, openTopicsCheckboxChecked).then((res) => {
			setCreatedTopicList(res.data)
			setIsLastTopicCreated(res.isLastTopic)
			setLoadingCreatedTopicsList(false)
		})
		getTopicInvitations(null, inviteFromFollowsCheckboxChecked).then((res) => {
			setTopicInvitationsList(res.data)
			setIsLastInvitation(res.isLastInvitation)
			setLoadingTopicInvitations(false)
		})
		// Query the first page of docs
		setLoading(false)

		return () => {
		}

    }, [])

	
	const handleClickFollowingDialogOpen = (topicId, discussionId) => {
		setOpenFollowingDiscussionDialog(true);
		setSelectedFollowingDiscussion({topicId: topicId, discussionId: discussionId})
	};

	const handleFollowingDialogClose = () => {
		setOpenFollowingDiscussionDialog(false);
	};

	async function  handleUnfollowDiscussion (){
		if (auth.currentUser.emailVerified) {
			setOpenFollowingDiscussionDialog(false)
			if(selectedFollowingDiscussion) {
				const res = await unfollowDiscussion(selectedFollowingDiscussion.topicId, selectedFollowingDiscussion.discussionId)
				if (res.success === true){
					// topic has been unfollowed
					setFollowingDiscussionList((prevList) => {
						const updatedList = [...prevList];
						const index = updatedList.findIndex(obj => obj.topic.topicId === selectedFollowingDiscussion.topicId && obj.discussion.discussionId === selectedFollowingDiscussion.discussionId);
						updatedList.splice(index, 1); 
						return updatedList
					})	
				}
			}
		} else {
			navigate('/page/verifyuser')
		}
	}

	async function handleDiscussionNavigate (e, topicId, discussionId, isFollowingDiscussion, lastPostTimestamp, lastReadTimestamp) {
		if (e.button === 1) {
			// clicked with third mouse button
			if (lastPostTimestamp > lastReadTimestamp) { 
				if (isLastFollowingDiscussion) {
					setFollowingDiscussionList((prevList) => {
						const updatedList = [...prevList];
						const index = updatedList.findIndex(obj => obj.topic.topicId === topicId && obj.discussion.discussionId === discussionId);
						if (index !== -1) {
							updatedList[index].discussionRead = true;
						}
						return updatedList
					})	
				} else {
					// because following discussions are fetched based on last read timestamp and clicked discussion is the last read by definition. 
					// if following discussions still are not all fetched then remove this discussion from list.
					setFollowingDiscussionList((prevList) => {
						const updatedList = [...prevList];
						const index = updatedList.findIndex(obj => obj.topic.topicId === topicId && obj.discussion.discussionId === discussionId);
						updatedList.splice(index, 1); 
						return updatedList
					})	
				} 
			}
		} else {
			if (isFollowingDiscussion === true) {
				await updateDiscussionLastReadTimestamp(topicId, discussionId)
				const to = "/topic/" + topicId + "/discussion/" + discussionId
				navigate(to)
			} else {
				const to = "/topic/" + topicId + "/discussion/" + discussionId
				navigate(to)
			}
		}
	}
	async function handleTopicNavigate (e, topicId) {
		if (e.button !== 1) { 
			// clicked without wheel
			const to = "/topic/" + topicId
			navigate(to)
		}
	}

	async function handleRejectTopicInvitation(topicId){
		
		setTopicInvitationsList((prevList) => {
			const updatedList = [...prevList];
			const index = updatedList.findIndex(obj => obj.topicId === topicId);
			updatedList.splice(index, 1); 
			return updatedList
		})
	}
	async function loadMoreDiscussions(){
        // Anything in here is fired on component mount.
		setLoadingDiscussionsList(true)
		if (!isLastDiscussion){
			getDiscussionsList(discussionsList[discussionsList.length - 1].discussion.discussionId, unansweredDiscussionsCheckboxChecked).then((res) => {
				setIsLastDiscussion(res.isLastDiscussion)
				setDiscussionsList((prevList) => [...prevList,...res.data])
				setLoadingDiscussionsList(false)
			})
		}
	}
	async function loadMoreFollowingDiscussions(){
		// Anything in here is fired on component mount.
		setLoadingFollowingDiscussionsList(true)
		if (!isLastFollowingDiscussion) {
			getFollowingDiscussionsList(followingDiscussionList[followingDiscussionList.length - 1].discussion.discussionId).then((res) => {
				setFollowingDiscussionList((prevList) => [...prevList, ...res.data])
				setIsLastFollowingDiscussion(res.isLastDiscussion)
				setLoadingFollowingDiscussionsList(false)
			})
		}
	}	

	async function loadMoreCreatedTopics(){
		// Anything in here is fired on component mount.
		setLoadingCreatedTopicsList(true)
		if (!isLastTopicCreated) {
			getCreatedTopicsList(createdTopicList[createdTopicList.length - 1].topic.topicId, openTopicsCheckboxChecked).then((res) => {
				setCreatedTopicList((prevList) => [...prevList, ...res.data])
				setIsLastTopicCreated(res.isLastTopic)
				setLoadingCreatedTopicsList(false)
			})
		}
	}	

	async function loadMoreTopicInvitations(){
		// Anything in here is fired on component mount.
		setLoadingTopicInvitations(true)
		if (!isLastInvitation) {
			getTopicInvitations(topicInvitationsList[topicInvitationsList.length - 1].topicId, inviteFromFollowsCheckboxChecked).then((res) => {
				setTopicInvitationsList((prevList) => [...prevList, ...res.data])
				setIsLastInvitation(res.isLastInvitation)
				setLoadingTopicInvitations(false)
			})
		}		
	}	
	const handleUnansweredDiscussionsCheckboxCheckedChange = () => {
		setUnansweredDiscussionsCheckboxChecked(!unansweredDiscussionsCheckboxChecked)
	}
	async function handleSubmitUnansweredDiscussionsCheckbox(event) {
		event.preventDefault()
		
		if (unansweredDiscussionsCheckboxChecked !== previousUpdatedUnansweredDiscussionsCheckboxChecked) {
			// list of discussions
			setLoadingDiscussionsList(true)
			setPreviousUpdatedUnansweredDiscussionsCheckboxChecked(unansweredDiscussionsCheckboxChecked) // update previousUpdatedUnansweredDiscussionsCheckboxChecked for next possible update
			// load first batch of discussions according to checkbox value
			getDiscussionsList(null, unansweredDiscussionsCheckboxChecked).then((res) => {
				setIsLastDiscussion(res.isLastDiscussion)
				setDiscussionsList(res.data)
				setLoadingDiscussionsList(false)
			})
		}
	}
	const handleOpenTopicsCheckboxCheckedChange = () => {
		setOpenTopicsCheckboxChecked(!openTopicsCheckboxChecked)
	}
	async function handleSubmitOpenTopicsCheckbox(event) {
		event.preventDefault()
		
		if (openTopicsCheckboxChecked !== previousUpdatedOpenTopicsCheckboxChecked) {
			// list of topics
			setLoadingCreatedTopicsList(true)
			setPreviousUpdatedOpenTopicsCheckboxChecked(openTopicsCheckboxChecked) // update previousUpdatedOpenTopicsCheckboxChecked for next possible update
			// load first batch of topics according to checkbox value
			getCreatedTopicsList(null, openTopicsCheckboxChecked).then((res) => {
				setCreatedTopicList(res.data)
				setIsLastTopicCreated(res.isLastTopic)
				setLoadingCreatedTopicsList(false)
			})
		}
	}
	const handleInviteFromFollowsCheckboxCheckedChange = () => {
		setInviteFromFollowsCheckboxChecked(!inviteFromFollowsCheckboxChecked)
	}
	async function handleSubmitInviteFromFollowsCheckbox(event) {
		event.preventDefault()
		
		if (inviteFromFollowsCheckboxChecked !== previousUpdatedInviteFromFollowsCheckboxChecked) {
			// list of invites should update
			setLoadingTopicInvitations(true)
			setPreviousUpdatedInviteFromFollowsCheckboxChecked(inviteFromFollowsCheckboxChecked) // update previousUpdatedInviteFromFollowsCheckboxChecked for next possible update
			// load first batch of topic invitations according to checkbox value
			getTopicInvitations(null, inviteFromFollowsCheckboxChecked).then((res) => {
				setTopicInvitationsList(res.data)
				setIsLastInvitation(res.isLastInvitation)
				setLoadingTopicInvitations(false)
			})
		}
	}
	
 	return (
		<Grid item xs>
			<Paper sx={{backgroundColor: "manageTopics.paper",  borderRadius: "0px", height: "calc(100vh - " + NAVBAR_HEIGHT +" - 0px)", maxHeight: "calc(100vh - " + NAVBAR_HEIGHT +" - 0px)"}}>
				<Accordion sx={{backgroundColor: "manageTopics.summary", borderColor: "manageTopics.summary" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
					<AccordionSummary sx={{backgroundColor: "manageTopics.summary"}} aria-controls="panel1d-content" id="panel1d-header">
						<Typography variant='h3'>{t("manageTopics.currentDiscussions")}</Typography>
					</AccordionSummary>
					<Box component="form" onSubmit={handleSubmitUnansweredDiscussionsCheckbox} noValidate sx={{marginLeft: "22px", marginRight: "16px"}}>	
						<Stack 
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={0}>
							<FormControlLabel
								control={<Checkbox value="open" color="primary" name="unansweredDiscussionsCheckboxChecked" 
								checked={unansweredDiscussionsCheckboxChecked} onChange={handleUnansweredDiscussionsCheckboxCheckedChange}/>}
								label={<>{t('manageTopics.onlyUnansweredDiscussions')}</>}
								/>
							<Button
								type="submit"
								variant="contained"
								className="LoadMoreButton"
								disabled={loading || loadingDiscussionsList}
								sx={{ marginLeft: "0px!important", marginRight:"0px!important", borderRadius: "22px" }}
								>
								{t('update')}
							</Button>
						</Stack>
					</Box>
					<AccordionDetails sx= {{ backgroundColor: "manageTopics.details", maxHeight: window.innerHeight - NAVBAR_HEIGHT_ABSOLUTE - COLLAPSIBLE_HEIGHT_ABSOLUTE*4 + "px", overflowX: 'none',overflowY: 'scroll'}}>
						{ 
							discussionsList.length > 0 ? 
								<List component="div" disablePadding>
										{discussionsList.map((discussion) => (
											<Stack direction="row">
												<ListItemButton sx={{ pl: 4, height: DEBATE_LIST_ITEM_HEIGHT}}
													href={'/topic/' + discussion.topic.topicId  + '/discussion/' + discussion.discussion.discussionId}
													onMouseDown={(e) => handleDiscussionNavigate(e, discussion.topic.topicId, discussion.discussion.discussionId, false, null, null)}>
													<ListItemText sx={{width: "80%!important"}} primary={
														<Stack direction="row" spacing={1} >
															{discussion.user.avatarSmallUrl !== "" ?  	
																<Tooltip title={discussion.user.username}>
																	<Avatar
																		alt={discussion.user.username + "_avatar"}
																		src={discussion.user.avatarSmallUrl}
																		sx={{ width: AVATAR_XS_SIZE, height: AVATAR_XS_SIZE }}
																	/>
																</Tooltip>
															:
																<Tooltip title={discussion.user.username}>
																	<Avatar sx={{ width: AVATAR_XS_SIZE, height:AVATAR_XS_SIZE, color:"white" }} 
																		alt={discussion.user.username + "_avatar"}>
																		<Typography variant='body1' sx={{color: "inherit"}}>
																			{discussion.user.username[0].toUpperCase()}
																		</Typography>
																	</Avatar>
																</Tooltip>
															}
															<Typography variant="body1" sx={{
																overflow: "hidden",
																textOverflow: "ellipsis",
																display: "-webkit-box",
																WebkitLineClamp: "1",
																WebkitBoxOrient: "vertical"}}>
																{
																	discussion.topic.reportedTitle === true ?
																		<ReportedTopicTitleComponent />
																		:
																		discussion.topic.title
																}
															</Typography>
														</Stack>
													} />
													{discussion.discussion.authorOfLastPost === auth.currentUser.uid ? null : circle}
												</ListItemButton>
												
												{auth.currentUser ?
													<DeleteDiscussionComponent topicId={discussion.topic.topicId} discussionId={discussion.discussion.discussionId}/>
												: null}
											</Stack>
										))}		
										{!isLastDiscussion ?
											<Button className='LoadMoreButton' fullWidth disabled={loading || loadingDiscussionsList} onClick={loadMoreDiscussions}>
												<Stack direction="row"
													justifyContent="center"
													alignItems="center">
													<Typography color="inherit" variant="body1">{t("manageTopics.loadMoreDiscussions")}</Typography>
													<ArrowDropDownIcon />
												</Stack>
											</Button>
											:
											null
										}
								</List>
								: 
								<Typography variant="body1">{t("manageTopics.listIsEmptyCreateTopic")}</Typography>
						}	
					</AccordionDetails>
				</Accordion>
				<Accordion sx={{backgroundColor: "manageTopics.summary", border: "none!important"}} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
					<AccordionSummary sx={{ backgroundColor: "manageTopics.summary"}} aria-controls="panel2d-content" id="panel2d-header">
						<Typography variant='h3'>{t("manageTopics.followingDiscussions")}</Typography>
					</AccordionSummary>
					<AccordionDetails sx= {{ backgroundColor: "manageTopics.details", maxHeight: window.innerHeight - NAVBAR_HEIGHT_ABSOLUTE - COLLAPSIBLE_HEIGHT_ABSOLUTE*4 + "px", overflowX: 'none',overflowY: 'scroll'}}>
						<>      
							<Dialog
								open={openFollowingDiscussionDialog}
								onClose={handleFollowingDialogClose}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description">
								<DialogTitle id="alert-dialog-title">
									{t("manageTopics.unfollowDiscussionQuestion")}
								</DialogTitle>
								<DialogContent>
								<DialogContentText id="alert-dialog-description">
									{t("manageTopics.ifYouUnfollowDiscussion")}
								</DialogContentText>
								</DialogContent>
								<DialogActions>
								<Button onClick={handleFollowingDialogClose}>
									{t("cancel")}
								</Button>
								<Button onClick={handleUnfollowDiscussion} autoFocus>
									{t("accept")}
								</Button>
								</DialogActions>
							</Dialog>
							{followingDiscussionList.length > 0 ? 
								<List component="div" disablePadding>
										{followingDiscussionList.map((discussion) => (
											<Grid container direction="row"
												justifyContent="space-between"
												alignItems="center">
												<Grid item xs>
													<ListItemButton href={"/topic/" + discussion.topic.topicId + "/discussion/" + discussion.discussion.discussionId} sx={{ pl: 4, height: DEBATE_LIST_ITEM_HEIGHT }} onMouseDown={(e) => handleDiscussionNavigate(e, discussion.topic.topicId, discussion.discussion.discussionId, true, discussion.discussion.lastPostTimestamp, discussion.discussion.lastReadTimestamp)}>
														<ListItemText primary={<Typography variant="body1"  sx={{
															overflow: "hidden",
															textOverflow: "ellipsis",
															display: "-webkit-box",
															WebkitLineClamp: "2",
															WebkitBoxOrient: "vertical"}}>
															{
																discussion.topic.reportedTitle === true ?
																	<ReportedTopicTitleComponent />
																	:
																	discussion.topic.title
															}
															</Typography>} />
													</ListItemButton>
												</Grid>
												<Grid item xs="auto" sx={{marginLeft: "6px"}}>
													<Stack direction="row"
													justifyContent="flex-end"
													alignItems="center">
														{discussion.discussion.lastPostTimestamp < discussion.discussion.lastReadTimestamp || discussion.discussionRead === true ? null : circle}
														<IconButton  sx={{marginLeft: "2px"}} aria-label="delete" size="small" onClick={() => handleClickFollowingDialogOpen(discussion.topic.topicId, discussion.discussion.discussionId)}>
															<ClearIcon fontSize="inherit" />
														</IconButton>
													</Stack>
												</Grid>
											</Grid>
										))}		
										{!isLastFollowingDiscussion ?
										
											<Button className='LoadMoreButton' fullWidth disabled={loading || loadingFollowingDiscussionsList} onClick={loadMoreFollowingDiscussions}>
												<Stack direction="row"
													justifyContent="center"
													alignItems="center">
													<Typography color="inherit" variant="body1">{t("manageTopics.loadMoreDiscussions")}</Typography>
													<ArrowDropDownIcon />
												</Stack>
											</Button>
											:
											null
										}
								</List>
								: 
								<Typography variant="body1">{t("manageTopics.listIsEmptyFollowTopics")}</Typography>
							}
						</>
					</AccordionDetails>
				</Accordion>
				<Accordion sx={{backgroundColor: "manageTopics.summary", border: "none!important"}} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
					<AccordionSummary sx={{backgroundColor: "manageTopics.summary"}} aria-controls="panel3d-content" id="panel3d-header">
						<Typography variant='h3'>{t("manageTopics.createdTopics")}</Typography>
					</AccordionSummary>
					<Box component="form" onSubmit={handleSubmitOpenTopicsCheckbox} noValidate sx={{marginLeft: "22px", marginRight: "16px"}}>	
						<Stack 
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={0}>
							<FormControlLabel
								control={<Checkbox value="open" color="primary" name="openTopicsCheckboxChecked" 
								checked={openTopicsCheckboxChecked} onChange={handleOpenTopicsCheckboxCheckedChange}/>}
								label={<>{t('manageTopics.onlyOpenTopics')}</>}
								/>
							<Button
								type="submit"
								variant="contained"
								className="LoadMoreButton"
								disabled={loading || loadingCreatedTopicsList}
								sx={{ marginLeft: "0px!important", marginRight:"0px!important", borderRadius: "22px" }}
								>
								{t('update')}
							</Button>
						</Stack>
					</Box>
					<AccordionDetails sx= {{ backgroundColor: "manageTopics.details", maxHeight: window.innerHeight - NAVBAR_HEIGHT_ABSOLUTE - COLLAPSIBLE_HEIGHT_ABSOLUTE*4 + "px", overflowX: 'none',overflowY: 'scroll'}}>
						{createdTopicList.length > 0 ? 
							<List component="div" disablePadding>
								{createdTopicList.map((topic) => (	
									<Grid container direction="row"
										justifyContent="space-between"
										alignItems="center" sx={{height: DEBATE_LIST_CREATED_ITEM_HEIGHT}}>													
										<Grid item xs>
											<ListItemButton href={"/topic/" + topic.topic.topicId} sx={{ pl: 4, height: DEBATE_LIST_ITEM_HEIGHT }} onMouseDown={(e) => true}>										
												<Typography  variant="body1" sx={{
													overflow: "hidden",
													textOverflow: "ellipsis",
													display: "-webkit-box",
													WebkitLineClamp: "1",
													WebkitBoxOrient: "vertical"}}>
													{
														topic.topic.reportedTitle === true ?
															<ReportedTopicTitleComponent />
															:
															topic.topic.title
													}
													</Typography>
											</ListItemButton>
										</Grid>
										<Grid item xs="auto" sx={{marginLeft: "6px"}}>
											<Stack direction="row"
											justifyContent="flex-end"
											alignItems="center">
												<UsersInvitedDialog topic={topic.topic} />
												<CreatedTopicDiscussionsListDialog topic={topic.topic}/>
												<ChangeTopicOpenDialog isOpen={topic.topic.open} topicId={topic.topic.topicId} />	
												<DeleteTopicComponent topicId={topic.topic.topicId} color={'secondary.icon'}/>
											</Stack>
										</Grid>
									</Grid>
								))}		
								{!isLastTopicCreated ?
								
									<Button className='LoadMoreButton' fullWidth disabled={loading || loadingCreatedTopicsList} onClick={loadMoreCreatedTopics}>
										<Stack direction="row"
											justifyContent="center"
											alignItems="center">
											<Typography color="inherit" variant="body1">{t("manageTopics.loadMoreTopics")}</Typography>
											<ArrowDropDownIcon />
										</Stack>
									</Button>
									:
									null
								}
							</List>
							: 
							<Typography variant="body1">{t("manageTopics.listIsEmptyCreateTopic")}</Typography>
						}
					</AccordionDetails>
				</Accordion>
				<Accordion sx={{backgroundColor: "manageTopics.summary", border: "none!important"}} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
					<AccordionSummary sx={{backgroundColor: "manageTopics.summary"}} aria-controls="panel4d-content" id="panel4d-header">
						<Typography variant='h3'>{t("manageTopics.invitedTopics")}</Typography>
					</AccordionSummary>
					<Box component="form" onSubmit={handleSubmitInviteFromFollowsCheckbox} noValidate sx={{marginLeft: "22px", marginRight: "16px"}}>	
						<Stack 
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={0}>
							<FormControlLabel
								control={<Checkbox value="open" color="primary" name="inviteFromFollowsCheckboxChecked" 
								checked={inviteFromFollowsCheckboxChecked} onChange={handleInviteFromFollowsCheckboxCheckedChange}/>}
								label={<>{t('manageTopics.onlyInvitesFromFollowers')}</>}
								/>
							<Button
								type="submit"
								variant="contained"
								className="LoadMoreButton"
								disabled={loading || loadingTopicInvitations}
								sx={{ marginLeft: "0px!important", marginRight:"0px!important", borderRadius: "22px" }}
								>
								{t('update')}
							</Button>
						</Stack>
					</Box>
					<AccordionDetails sx= {{ backgroundColor: "manageTopics.details", maxHeight: window.innerHeight - NAVBAR_HEIGHT_ABSOLUTE - COLLAPSIBLE_HEIGHT_ABSOLUTE*4 + "px", overflowX: 'none',overflowY: 'scroll'}}>
						
						{topicInvitationsList.length !== 0 ? 
							<List component="div" disablePadding>
									{topicInvitationsList.map((topic) => (
										
										<Grid container direction="row"
										justifyContent="space-between"
										alignItems="center">
											<Grid item xs>
												<ListItemButton href={'/topic/' + topic.topicId} sx={{ pl: 4, height: DEBATE_LIST_ITEM_HEIGHT}} onClick={(e) => handleTopicNavigate(e, topic.topicId)}>
													<ListItemText primary={<Typography variant="body1"sx={{
														overflow: "hidden",
														textOverflow: "ellipsis",
														display: "-webkit-box",
														WebkitLineClamp: "1",
														WebkitBoxOrient: "vertical"}}>
														{
															topic.reportedTitle === true ?
																<ReportedTopicTitleComponent />
																:
																topic.title
														}</Typography>} />
												</ListItemButton>
											</Grid>
											<Grid item xs="auto" sx={{marginLeft: "6px"}}>
												<RejectTopicInvitation topicId={topic.topicId} handleRejectTopicInvitation={handleRejectTopicInvitation} />
											</Grid>
										</Grid>
									))}		
									{!isLastInvitation ?
										<Button className='LoadMoreButton' fullWidth disabled={loading || loadingTopicInvitations} onClick={loadMoreTopicInvitations}>
											<Stack direction="row"
												justifyContent="center"
												alignItems="center">
												<Typography color="inherit" variant="body1">{t("manageTopics.loadMoreTopics")}</Typography>
												<ArrowDropDownIcon />
											</Stack>
										</Button>
										:
										null
									}
							</List>
							: 
							<Typography variant="body1">{t("manageTopics.notInvitedToTopics")}</Typography>
						}						
					</AccordionDetails>
				</Accordion>
			</Paper>
		</Grid>
	)
}

