import React, {useContext} from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import PoliciesNotificationNonModalDialog from '../components/PoliciesNotificationNonModalDialog/PoliciesNotificationNonModalDialog'
import { PoliciesAcceptedContext } from '../contexts/PoliciesAcceptedContext'

export default function AnonymousPublicRoute() {
	const { currentUser, currentProfile } = useAuth()
	const { acceptedPolicies } = useContext(PoliciesAcceptedContext)

	return (
		currentUser && !acceptedPolicies ? <Navigate to='/flow/newpolicies'/> :
			!(currentUser && !currentProfile) ? 
			(<>
				<PoliciesNotificationNonModalDialog/>
				<Outlet/>
			</>) : <Navigate to="/error/userprofile" />
		)
}

