import React from 'react';
import './ReportedPostContentComponent.css'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { AVATAR_SMALL_SIZE } from '../../utils/Constants';
import Avatar from '@mui/material/Avatar';
import AvatarPreviewComponent from '../AvatarPreviewComponent';
import CardHeader from '@mui/material/CardHeader';
import UserPopoverCardComponent from '../UserPopoverCardComponent';


function PostItem({props}) {
	const { t } = useTranslation();
	return(
		<>
		<CardHeader
			avatar={
				props.user &&
				(
					props.user.avatarSmallUrl !== "" ?
						<AvatarPreviewComponent avatar={props.user.avatarSmallUrl}
							avatarBig={props.user.avatarBigUrl}
							size={AVATAR_SMALL_SIZE + "px"}/>
					: 
						<Avatar src={props.user.avatarSmallUrl} sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE,color:"white" }} aria-label="avatar">
							{props.user.username[0].toUpperCase()}
						</Avatar>
				)
			}
			title={
				<Typography variant="subtitle2" sx={{width: "fit-content"}}>
					<UserPopoverCardComponent uid={props.user.userId} user={props.user} type="reply" place={null}/>
				</Typography> 		
			}
		/>
		<CardContent sx={{margin:"12px", marginTop: "0px!important"}} className='ReportedPostCard'>
			<Grid container direction="row" sx={{height: "inherit"}}>
				<Grid item xs>
					<Typography variant="body1">
						{t('reportedPostComponent.description')}
					</Typography>
				</Grid>
			</Grid>
		</CardContent>
		</>
	)
}
export default function ReportedPostContentComponent(props) {
	
	return(
		<>
			{props.share === true ?
				<div  style={{paddingBottom: "8px"}}>
					<PostItem props={props}/>
				</div>
			:
				<Card sx={{  marginTop: '12px', flexGrow: 1, padding:0}} className='CustomCard1' >	
					<PostItem props={props}/>
				</Card>
			}
		</>
	)
}