import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

export async function reportUsername(reportedUserId, category){
	const reportusernameincloudspanner = httpsCallable(functions, 'reportusernameincloudspanner');
	return await reportusernameincloudspanner({ reportedUserId: reportedUserId, category: category })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}
export async function reportTopicTitle(topicId, category){
	const reporttopictitleincloudspanner = httpsCallable(functions, 'reporttopictitleincloudspanner');
	return await reporttopictitleincloudspanner({ topicId: topicId, category: category })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function reportPost(postId, reportedUserId, category){
	const reportpostincloudspanner = httpsCallable(functions, 'reportpostincloudspanner');
	return await reportpostincloudspanner({ postId: postId, reportedUserId: reportedUserId, category: category })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function reportBio(reportedUserId, category){
	const reportbioincloudspanner = httpsCallable(functions, 'reportbioincloudspanner');
	return await reportbioincloudspanner({ reportedUserId: reportedUserId, category: category })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function reportAvatar(reportedUserId, category){
	const reportavatarincloudspanner = httpsCallable(functions, 'reportavatarincloudspanner');
	return await reportavatarincloudspanner({ reportedUserId: reportedUserId, category: category })
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}


