import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const OrganizationStructuredData = () => {
	const { t } = useTranslation();
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.innerHTML = JSON.stringify( {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": window.location.origin,
            "name": t('organizationData.name'),
            "description": t('homeSloganComponent.slogan'),
        }
        );
        document.head.appendChild(script);

        addMetaTag('og:title', t('organizationData.name'));
        addMetaTag('og:description', t('homeSloganComponent.slogan'));
        addMetaTag('og:image', `${window.location.origin}/logoIcon.svg`); 
        addMetaTag('og:url', window.location.origin);

        return () => {
            document.head.removeChild(script);
            const metaTags = document.querySelectorAll('meta[data-custom-meta-tag="true"]');
            metaTags.forEach(tag => tag.remove());
        };
    }, []); 

    return null;
};
function addMetaTag(property, content){
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('property', property);
  metaTag.setAttribute('content', content);
  metaTag.setAttribute('data-custom-meta-tag', 'true'); 
  document.head.appendChild(metaTag);
}

export default OrganizationStructuredData;