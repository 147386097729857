import React, {useState, useEffect} from 'react';
import './TopicSummaryItem.css'
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import Chip from '@mui/material/Chip';
import { FeedDate } from '../../utils/Dates';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SyncIcon from '@mui/icons-material/Sync';
import { AVATAR_SMALL_SIZE, AVATAR_XS_SIZE, MAX_CHARACTERS_PER_PREVIEW_POST, MAX_SHARED_POST_ITEM_LINES, MAX_TOPIC_SUMMARY_ITEM_LINES_COLLAPSED, MAX_TOPIC_SUMMARY_ITEM_LINES_EXPANDED } from '../../utils/Constants';
import Divider from '@mui/material/Divider';
import ReplyIcon from '@mui/icons-material/Reply';
import Tooltip from '@mui/material/Tooltip';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { auth } from "../../firebase";

import { getInvitedUsersIdsOfTopic, getDiscussionParticipants } from '../../firebaseFunctions/spannerFunctions/topicFunctions';
import AvatarPreviewComponent from '../AvatarPreviewComponent';
import UserPopoverCardComponent from '../UserPopoverCardComponent';
import { useTranslation } from 'react-i18next';
import { ShortenNumber } from '../../utils/NumberShortener';
import DiscussionPopoverCardComponent from '../DiscussionPopoverCardComponent';
import ReplyDrawerComponent from '../ReplyDrawerComponent/ReplyDrawerComponent';
import ReportComponent from '../ReportComponent/ReportComponent';
import ReportedPostContentComponent from '../ReportedPostContentComponent';
import ReportedTopicTitleComponent from '../ReportedTopicTitleComponent';
import { getDataForPostLocalLinks } from '../../firebaseFunctions/spannerFunctions/postLocalLinksFunctions';
import PostWithLinksComponent from '../PostWithLinksComponent/PostWithLinksComponent';
import SharePostComponent from '../SharePostComponent';

const ExpandMore = styled((props) => {
	const { t } = useTranslation();
	const { expand, ...other } = props;
	return 	<Tooltip title={expand ? t("topicSummary.readLess"): t("topicSummary.readMore")}><IconButton {...other} /></Tooltip>;
  })(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
	  duration: theme.transitions.duration.shortest,
	}),
}));

export default function TopicSummaryItem(props) {
	const { t } = useTranslation();
	
	const [expanded, setExpanded] = useState(false);
	const [replyButton, setReplyButton] = useState(false);	
	const [invitedUsersIds, setInvitedUsersIds] = useState([]);	
	const [postLocalLinksDisplayData, setPostLocalLinksDisplayData] = useState([]);	

	const navigate = useNavigate()
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	useEffect(() => {
		getDataForPostLocalLinks(props.post.post).then((dataForLinks) => {
			setPostLocalLinksDisplayData(dataForLinks)
		})

		if (auth.currentUser) {
			getInvitedUsersIdsOfTopic(props.topic.topicId).then((invitedUsersIdsRes) => {
				setInvitedUsersIds(invitedUsersIdsRes)
			})
		}
		setReplyButtonToTrue()
		return () => {
		}
	}, [])
	

	
	const [textDialog, setTextDialog] = React.useState(false);
	const handleCloseTextDialog = () => {
        setTextDialog(false);
	};
	const handleOpenTextDialog = () => {
        setTextDialog(true);
	};
	const handleNavigateToVerifyEmail = () => {
		navigate("/page/verifyuser")
	}
	
	const [authorOfLastPost, setAuthorOfLastPost] = useState()
	const handleAuthorOfLastPost = (uid) => {
		setAuthorOfLastPost(uid)
	}
	const setReplyButtonToTrue = () => {
		if (auth.currentUser) {
			if (props.post.type === "reply"  && auth.currentUser.uid !== props.user.userId) {
				// post is open and not written by user, also is the last post of discussion
				if (props.discussion.lastPostId === props.post.postId){
					if (props.topic.open) {
						getDiscussionParticipants(props.topic.topicId, props.discussion.discussionId).then((newDiscussionParticipantsList) => {
							if (newDiscussionParticipantsList.includes(auth.currentUser.uid)) {
								setReplyButton(true)
							}
						})
					} else {
						getInvitedUsersIdsOfTopic(props.topic.topicId).then((newInvitedList) => {
							if (newInvitedList.includes(auth.currentUser.uid)) {
								getDiscussionParticipants(props.topic.topicId, props.discussion.discussionId).then((newDiscussionParticipantsList) => {
									if (newDiscussionParticipantsList.includes(auth.currentUser.uid)) {
										setReplyButton(true)
									}
								})
							} else {
								if (typeof props.topic.topicId === "string" && typeof props.discussion.discussionId === "string") {
									getDiscussionParticipants(props.topic.topicId, props.discussion.discussionId).then((newDiscussionParticipantsList) => {
										if (newDiscussionParticipantsList.includes(auth.currentUser.uid)) {
											setReplyButton(true)
										}
									})
								}
							}
						})
					}
				} 
			} 
		}
	}
	return(
		<>
			{props.post.reportedPost === true ? 
				<ReportedPostContentComponent user={props.user}/>
			:
				(props.post.type === "reply" || props.post.type === "opening"  ? 
					//its reply or opening
					<Card sx={{  marginTop: '12px'}}  className='CustomCard1'>	
						{props.post.type === "opening" ? 
							<Grid container
								direction="row"
								alignItems="flex-start"
								>
								<Grid item xs={8}>
									{props.topic.reportedTitle === true ? 
										<Typography variant="h3" sx={{ marginLeft: "22px", paddingRight: "-22px!important", marginTop: "22px", width: "inherit!important", fontStyle: "italic"}}>
											<Link component={RouterLink} to={'/topic/' + props.topic.topicId} color="inherit" underline="hover">
												<ReportedTopicTitleComponent />
											</Link>
										</Typography>
										:
										<Typography variant="h3" sx={{ marginLeft: "22px", paddingRight: "-22px!important", marginTop: "22px", width: "inherit!important"}}>
											<Link component={RouterLink} to={'/topic/' + props.topic.topicId} color="inherit" underline="hover">
												{props.topic.title} 
											</Link>
										</Typography>
									}
								</Grid>
								<Grid item xs sx={{ marginRight: "16px", marginTop: "16px"}}>
									<Stack direction="row" sx={{ float:"right!important"}}>
										{auth.currentUser ?
											<>
												<Typography variant="subtitle2">
													{
														invitedUsersIds.includes(auth.currentUser.uid) ?
															t("invited")
														:
															props.topic.open === true ? 
																t("open") 
															:
																props.topic.open === false ?
																	t("closed")
																: 
																	null	
													}
												</Typography>
												<Box sx={{marginRight: "5px"}}/>
											</> : null
										}
										<Typography variant="caption" >
											{FeedDate(props.post.timestamp)}
										</Typography>
									</Stack>
								</Grid>
							</Grid>
							:
							null
						}
						<CardHeader
							avatar={
								props.user &&
								(
									props.user.avatarSmallUrl !== "" ?
										<AvatarPreviewComponent avatar={props.user.avatarSmallUrl}
											avatarBig={props.user.avatarBigUrl}
											size={AVATAR_SMALL_SIZE + "px"}/>
									: 
										<Avatar src={props.user.avatarSmallUrl} sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE,color:"white" }} aria-label="avatar">
											{props.user.username[0].toUpperCase()}
										</Avatar>
								)
							}
							title={
								<>
									{props.post.type !=="opening" ? 	
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center">
											<Typography variant="subtitle2" sx={{width: "fit-content"}}>
												<UserPopoverCardComponent uid={props.user.userId} user={props.user} type="reply" place={null}/>
											</Typography> 
											<Typography variant="caption" sx={{float: "right!important"}}>
												{FeedDate(props.post.timestamp)}
											</Typography>
										</Stack>	
										:
										<Typography variant="subtitle2" sx={{width: "fit-content"}}>
											<UserPopoverCardComponent uid={props.user.userId} user={props.user} type="reply" place={null}/>
										</Typography> 										
									}
									<Typography variant="caption" >
										{props.post.type !== "opening" ?
											<DiscussionPopoverCardComponent summary={true} topic={props.topic} discussion={props.discussion} type={props.post.type} postId={props.id} />
											:
											null
										}
									</Typography>
									
								</>
							}
						/>
						<CardContent sx={{paddingTop:0}}>
							<Grid container direction="row" sx={{height: "inherit"}}>
								<Grid item xs>
									{!expanded ? 
									<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: MAX_TOPIC_SUMMARY_ITEM_LINES_COLLAPSED, overflow: 'hidden', textOverflow: 'ellipsis'}}>
										{props.post.post.length > MAX_CHARACTERS_PER_PREVIEW_POST ? 
											// should render links data
											<PostWithLinksComponent key={0} showReferencedPosts={false} post={props.post.post.substring(0, MAX_CHARACTERS_PER_PREVIEW_POST) + "..." } localLinksData={postLocalLinksDisplayData}/>
											: 
											// should render links data
											<PostWithLinksComponent key={1} showReferencedPosts={true} post={props.post.post.substring(0, MAX_CHARACTERS_PER_PREVIEW_POST)} localLinksData={postLocalLinksDisplayData}/>
										}
									</Typography> 
									: 
									<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: MAX_TOPIC_SUMMARY_ITEM_LINES_EXPANDED, overflow: 'hidden', textOverflow: 'ellipsis'}}>
										{
											//should render link data	
											<PostWithLinksComponent key={2} showReferencedPosts={true} post={props.post.post} localLinksData={postLocalLinksDisplayData}/>
										}
									</Typography>}
								</Grid>
								{
									auth.currentUser &&
									(replyButton === true && authorOfLastPost !== auth.currentUser.uid ?
										<Grid item sx={{ marginRight: "-26px", height: "inherit", position: "relative"}}>
											<ReplyDrawerComponent open={textDialog} handleCloseTextDialog={() => handleCloseTextDialog} handleAuthorOfLastPost={() => handleAuthorOfLastPost(auth.currentUser.uid)} 
												postType="reply" topicId={props.topic.topicId} discussionId={props.discussion.discussionId} topicTitle={props.topic.title} user={props.user} lastPost={props.post} reportedTitle={props.topic.reportedTitle} reportedPost={props.post.reportedPost} />
											<Tooltip title={t("reply")}>
												<Button 
													sx={{ minWidth: "44px", borderRadius: "10px"}}
													className='ReplyPostButton' aria-label="reply" 
													onClick={
														auth.currentUser.emailVerified ?
															handleOpenTextDialog
														:
															handleNavigateToVerifyEmail
													}
													>
														<ReplyIcon sx={{ fontSize: "22px", color: "white", marginRight: "8px"}} fontSize='small'/>
												</Button>
											</Tooltip>
										</Grid>
										: null
									)
								}
							</Grid>
						</CardContent>
						
						<Divider/>
						<CardActions disableSpacing sx={{ padding: 0, paddingLeft: "12px"}}>
							{auth.currentUser  &&
								<Tooltip title={t("topicSummary.sharePost")}>
									<span>
										<SharePostComponent topicId={props.topic.topicId} discussionId={props.discussion.discussionId} postId={props.post.postId} author={props.user.userId}/>
									</span>
								</Tooltip>
							}
							{props.post.type === "opening" ? 
								null
							: 
								<Tooltip title={t("following")} sx={{backgroundColor:"transparent"}}>
									<span>
										<Chip icon={<PersonIcon color="primary" fontSize='small'/>} label={ShortenNumber(props.discussion.followers)} />
									</span>
								</Tooltip>}
								
							<div style= {{marginLeft: 'auto'}}>
								
								<Stack
									direction="row"
									sx={{marginRight: "6px"}}
								>
									{
										props.post.post.length <= MAX_CHARACTERS_PER_PREVIEW_POST ? 
											null
											:
												<ExpandMore
													expand={expanded}
													disabled={props.post.post.length <= MAX_CHARACTERS_PER_PREVIEW_POST}
													onClick={handleExpandClick}
													aria-expanded={expanded}
													aria-label="show more"
													>
													<ExpandMoreIcon fontSize='small'/>
												</ExpandMore>
									}
									{auth.currentUser &&
										<ReportComponent userId={props.user.userId} topicId={props.topic.topicId} type={props.post.type} reportedTitle={props.topic.reportedTitle} reportedPost={props.post.reportedPost} postId={props.post.postId}/>
									}
								</Stack>
							</div>
						
						</CardActions>
					</Card>
				:
					props.post.type === "share" ?
						<Card sx={{ flexGrow: 1, marginTop: '12px'}}  className='CustomCard1'>	
							<CardContent sx={{paddingBottom: 0, paddingTop:"8px"}}>
								<Typography variant="subtitle2">
									<Grid container direction="row" spacing={0.5}>
										<Grid item>
											<SyncIcon fontSize='small'/>
										</Grid>
										<Grid item>
											<Avatar src={props.user.avatarSmallUrl} sx={{ width: AVATAR_XS_SIZE, height:AVATAR_XS_SIZE}} aria-label="avatar">
												<Typography variant="body1" sx={{ color:"white"}}>{props.user.username[0].toUpperCase()}</Typography>
											</Avatar>
										</Grid>
										<Grid item xs>	
											<Stack
												sx={{marginLeft: "16px"}}
												direction="row"
												justifyContent="space-between"
												alignItems="center">
												<Typography variant="subtitle2" sx={{width: "fit-content"}}>
													<UserPopoverCardComponent uid={props.user.userId} user={props.user} type="share" place="from"/>
												</Typography> 
												<Typography variant="caption" sx={{float: "right!important"}}>
													{FeedDate(props.post.timestamp)}
												</Typography>
											</Stack>	
										</Grid>
									</Grid>
								</Typography>
							</CardContent>
							
							{props.sharedPost.reportedSharedPost === true ?
								<Paper sx={{margin:"8px", padding:0}} elevation={0} className='ShareCardPaper'>
									<ReportedPostContentComponent user={props.sharedPostUser} share={true}/>								
								</Paper>
								:
								<Paper sx={{margin:"8px", padding:0}} elevation={0} className='ShareCardPaper'>
									<CardHeader
										avatar={
											props.sharedPostUser &&
											(
												props.sharedPostUser.avatarSmallUrl !== "" ?
													<AvatarPreviewComponent avatar={props.sharedPostUser.avatarSmallUrl}
														avatarBig={props.user.avatarBigUrl}
														size={AVATAR_SMALL_SIZE + "px"}/>
												: 
													<Avatar src={props.sharedPostUser.avatarSmallUrl} sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE, color:"white" }} aria-label="avatar">
														{props.sharedPostUser.username[0].toUpperCase()}
													</Avatar>
											)
										}
										title={
											<>
													
												<Grid container
													direction="row"
													alignItems="flex-start"
													>
													<Grid item xs={7.5}>
														<Typography variant="subtitle2"  >
															<UserPopoverCardComponent uid={props.sharedPostUser.userId} user={props.sharedPostUser} type="share" place="author"/>
														</Typography>
													</Grid>
													<Grid item xs >
														<Typography variant="caption" sx={{float: "right!important"}}>
															{FeedDate(props.sharedPost.timestamp)}
														</Typography>
													</Grid>
												</Grid>
												<Typography variant="caption" >
													<DiscussionPopoverCardComponent summary={true} topic={props.sharedPostTopic} discussion={props.sharedPostDiscussion} type={props.post.type} postId={props.post.sharedPostId} />
												</Typography>
											</>
										}
									/>
									
									<CardContent sx={{paddingTop:0, paddingBottom:"8px!important"}}>
										<Typography variant="body1" style={{ fontStyle:'italic',whiteSpace: 'pre-wrap', wordWrap: 'break-word', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: MAX_SHARED_POST_ITEM_LINES, overflow: 'hidden', textOverflow: 'ellipsis'}}>
											{props.sharedPost.post !== "" && props.sharedPost.post !== null && (props.sharedPost.post.length > MAX_CHARACTERS_PER_PREVIEW_POST ? props.sharedPost.post.substring(0, MAX_CHARACTERS_PER_PREVIEW_POST) + "..." : props.sharedPost.post.substring(0, MAX_CHARACTERS_PER_PREVIEW_POST))}
										</Typography> 
									</CardContent>
								</Paper>
							}
						</Card>			
					:
						null
				)
			}
		</>
	)
}