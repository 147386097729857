import React, {useState} from 'react';
import './SharePostComponent.css'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';
import { auth } from "../../firebase";

import { useTranslation } from 'react-i18next';
import { sharePost } from '../../firebaseFunctions/spannerFunctions/topicFunctions';

export default function SharePostComponent(props) {
	const navigate = useNavigate()
	const { t } = useTranslation();
	const [shareOpen, setShareOpen] = useState(false);	
	const [loading, setLoading] = useState(false);

	const handleShareOpen = () => {
		setShareOpen(true);
	};

	const handleShareClose = () => {
		setShareOpen(false);
	};
	const handleShareClick = () => {
		const topicId = props.topicId
		const discussionId = props.discussionId
		const postId = props.postId
		const author =  props.author
		if(typeof topicId === "string" && typeof postId === "string" && typeof author === "string" && loading === false){
			if (auth.currentUser.emailVerified) {
				setLoading(true)
				sharePost(topicId, discussionId, postId, author).then(() => {
					navigate(0)
					setLoading(false)
					setShareOpen(false);
				})
			} else {
				navigate('/page/verifyuser')
			}
		}
	}

	return (
		<>	
			<Dialog
				open={shareOpen}
				onClose={handleShareClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				>
				<DialogTitle id="alert-dialog-title">
					{t("topicSummary.sharePost")}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{t("topicSummary.wantToSharePost")}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="error" onClick={handleShareClose}>{t("cancel")}</Button>
					<Button disabled={loading} onClick={handleShareClick} autoFocus>
						{t("yes")}
					</Button>
				</DialogActions>
			</Dialog>
			
			<IconButton color="primary" sx={{borderRadius:'50%', border:"none!important", backgroundColor: "transparent", color: "inherit!important"}} size="small" disabled={loading} onClick={()=>handleShareOpen()} aria-label="Share Post">
				<SyncIcon fontSize='small'/>
			</IconButton>	
		</>
	)
}

