import React from 'react';
import './ErrorNotFoundComponent.css'
import ErrorIcon from '@mui/icons-material/Error';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function ErrorNotFoundComponent(props) {

	const { t } = useTranslation();
	return (
		<Grid item xs>
			<Stack
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={2}
				sx={{marginTop: "42px"}}
				>
				<ErrorIcon color="error" sx={{ fontSize: 56 }}/>
				<Typography variant="h4" color="text.primary" sx={{marginLeft: '6px', textOverflow: "wrap!important", textAlign: "center"}}>
					{props.type === "user" ? 
						<>
							{t("error.noUser")}
						</>
					:
						props.type === "discussion" ? 
							<>
								{t("error.noDiscussion")}
							</>
						: 
							props.type === "topic" ?
								<>
									{t("error.noTopic")}
								</>
							: null}
				</Typography>
			</Stack>
		</Grid>
	)

}