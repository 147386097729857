import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";

export async function addDiscussionToRecent(topicId, discussionId){
	const adddiscussiontorecentincloudspanner = httpsCallable(functions, 'adddiscussiontorecentincloudspanner');
	return await adddiscussiontorecentincloudspanner({topicId: topicId, discussionId:discussionId})
	.then((result) => {
	})
	.catch((error) => {
	});
} 

export async function deleteRecentDiscussion(topicId, discussionId){
	
	const deleterecentdiscussionfromcloudspanner = httpsCallable(functions, 'deleterecentdiscussionfromcloudspanner');
	return await deleterecentdiscussionfromcloudspanner({topicId: topicId, discussionId: discussionId})
	.then((result) => {
		const data = result.data;
		return data
	})
	.catch((error) => {
	});
}

export async function getRecentDiscussions(){

	const getrecentdiscussionsfromcloudspanner = httpsCallable(functions, 'getrecentdiscussionsfromcloudspanner');
	return await getrecentdiscussionsfromcloudspanner()
	.then((result) => {
		const data = result.data;
		const dataInLocalFormat =  data !== null ? data.map((row) => {
			return {
				topic: {
					title: row.Title,
					topicId: row.TopicId,
					reportedTitle: row.ReportedTitle
				},
				discussion: {
					discussionId: row.DiscussionId,
					timestamp: row.UnixMillisDiscussionTimestamp,
					followers: row.DiscussionFollowers,
					ratingsSum: row.RatingsSum,
					ratingsCounter: row.RatingsCounter,
					postsCounter: row.PostsCounter,
					lastVisitedTimestamp: row.UnixMillisRecentDiscussionsLastVisitedTimestamp,
					lastPostTimestamp: row.UnixMillisDiscussionLastPostTimestamp,
					authorOfLastPost: row.AuthorOfLastPost 
				}
			}
		}) : []
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}