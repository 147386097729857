import React, {useState, useEffect} from 'react';
import './UserPersonalDataComponent.css'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';
import { getCurrentUserAvatarLogs, getCurrentUserAvatarsLogsZip, getCurrentUserBioLogs, getCurrentUserPostLogs, getCurrentUserSiteNotificationsEmailAndLanguage, getCurrentUserTopicTitleLogs, getCurrentUserUserLogs, getCurrentUserUsernameLogs, getUserPersonalDataLogsRequestsStatus, hasUserAlreadyRequestedPersonalData } from '../../firebaseFunctions/spannerFunctions/userPersonalDataFunctions';
import { auth } from '../../firebase';
import { getCurrentUserProfile } from '../../firebaseFunctions/spannerFunctions/profileFunctions';
import { AVATAR_MEDIUM_SIZE } from '../../utils/Constants';
import AvatarPreviewComponent from '../AvatarPreviewComponent';
import { FeedDate } from '../../utils/Dates';
import handleDownloadAsJson from './downloadAsJson';


export default function UserPersonalDataComponent() {
	const { t } = useTranslation();

	const [userHasRequestedPersonalData, setUserHasRequestedPersonalData] = useState(false)
	const [loading, setLoading] = useState(false)
	const [user, setUser]  = useState();
	const [userEmailAndLanguage, setUserEmailAndLanguage]  = useState();
	const [userLogsStatus, setUserLogsStatus]  = useState();
	useEffect(() => {
		setLoading(true)
		hasUserAlreadyRequestedPersonalData().then((res) => {
			setUserHasRequestedPersonalData(res)
			setLoading(false)
		})
		getCurrentUserSiteNotificationsEmailAndLanguage().then((res) => {
			setUserEmailAndLanguage(res)
		})
		getCurrentUserProfile().then((res) => {
			setUser(res)
		})
		getUserPersonalDataLogsRequestsStatus().then((res) => {
			setUserLogsStatus(res)
		})
	}, []);

	async function downloadPostLogs(event) {
		event.preventDefault()
		setLoading(true)
		getCurrentUserPostLogs().then((res) =>{
			if (res) {
				handleDownloadAsJson('PostLogs', res)
			}
			setUserLogsStatus(prevState => ({ ...prevState, postLogDataRequest: false }));
			setLoading(false)
		})
	}
	async function downloadTopicTitleLogs(event) {
		event.preventDefault()
		setLoading(true)
		getCurrentUserTopicTitleLogs().then((res) =>{
			if (res) {
				handleDownloadAsJson('TopicTitleLogs', res)
			}
			setUserLogsStatus(prevState => ({ ...prevState, topicTitleDataRequest: false }));
			setLoading(false)
		})
	}
	async function downloadBioLogs(event) {
		event.preventDefault()
		setLoading(true)
		getCurrentUserBioLogs().then((res) =>{
			if (res) {
				handleDownloadAsJson('BioLogs', res)
			}
			setUserLogsStatus(prevState => ({ ...prevState, bioLogDataRequest: false }));
			setLoading(false)
		})
	}
	async function downloadAvatarLogs(event) {
		event.preventDefault()
		setLoading(true)
		getCurrentUserAvatarLogs().then((res) =>{
			if (res) {
				handleDownloadAsJson('AvatarLogs', res)
			}
			setUserLogsStatus(prevState => ({ ...prevState, avatarLogDataRequest: false }));
			setLoading(false)
		})
	}
	async function downloadUserUserLogs(event) {
		event.preventDefault()
		setLoading(true)
		getCurrentUserUserLogs().then((res) =>{
			if (res) {
				handleDownloadAsJson('UserLogs', res)
			}
			setUserLogsStatus(prevState => ({ ...prevState, userLogDataRequest: false }));
			setLoading(false)
		})
	}
	async function downloadUserUsernameLogs(event) {
		event.preventDefault()
		setLoading(true)
		getCurrentUserUsernameLogs().then((res) =>{
			if (res) {
				handleDownloadAsJson('UsernameLogs', res)
			}
			setUserLogsStatus(prevState => ({ ...prevState, usernameLogDataRequest: false }));
			setLoading(false)
		})
	}
	async function downloadAvatarsLogsZip(event) {
		event.preventDefault()
		setLoading(true)

		getCurrentUserAvatarsLogsZip().then((res) =>{
			if (res) {
				const a = document.createElement('a'); 
				a.style.display = 'none'; 
				a.href = res; 
				a.download = res.split('/').pop(); 
				document.body.appendChild(a); 
				a.click(); 
				document.body.removeChild(a);
			}
			setUserLogsStatus(prevState => ({ ...prevState, avatarLogDataZipRequest: false }));
			setLoading(false)
		})
	}

	return (
		<>
			
			{ loading ?
				<>
					<Box sx={{ display: 'flex',
						alignContent: 'center', 
						height: "100vh",
						alignItems:"center",
						justifyContent:"center"}}>
						<CircularProgress />
					</Box> 
				</>
				:
				<>
					{userHasRequestedPersonalData ?
						<Paper sx={{ marginRight:"1%", marginLeft: "1%", padding: "1%", paddingBottom: "24px!important", marginTop: '8px!important'}} className='CustomCard3' >
							<Stack direction='column'>
								<Typography variant='h1' textAlign='center'>
									{t('legals.userPersonalDataComponent.userData')}
								</Typography>
								<Stack direction='column'>
									<Stack direction='row'>
										{user.avatarSmallUrl !== "" ?  														
											<AvatarPreviewComponent avatar={user.avatarMediumUrl}
												avatarBig={user.avatarBigUrl}
												size={AVATAR_MEDIUM_SIZE + "px"}
												/>
										:
											<Avatar sx={{ width: AVATAR_MEDIUM_SIZE, height:AVATAR_MEDIUM_SIZE, color:"white" }} alt="avatar">
												{user.username[0].toUpperCase()}
											</Avatar>
										}
										<Stack direction='column'>
											<Typography noWrap align='center' style={{ width: "70%", marginTop: '4px', wordWrap: "break-word" }} display='block' variant='body'>
												<Link component={RouterLink} to={"/" + user.username} color="inherit" underline="hover">
													{user.username}
												</Link>	
											</Typography>
											{
												user.unixMillisCreatedTimestamp ?
													<Typography sx={{fontStyle:'italic', marginLeft: '18px'}} variant="caption">
														{t("userPopoverCard.joined") + ": " + FeedDate(user.unixMillisCreatedTimestamp)}
													</Typography>
													: null
											}
										</Stack>
									</Stack>
									<Stack direction='row' sx={{marginTop: `18px`, marginLeft: '12px'}}>
										<Typography variant='body2' textAlign='left'>
											{t('legals.userPersonalDataComponent.email')}:
										</Typography>
										<Typography variant='body1' textAlign='left' sx={{marginLeft: `2px!important`}}>
											{auth.currentUser.email}
										</Typography>
									</Stack>
									<Stack direction='row'  sx={{marginLeft: `12px!important`}}>
										<Typography variant='body2' textAlign='left'>
											{t('legals.userPersonalDataComponent.phone')}:
										</Typography>
										<Typography variant='body1' textAlign='left' sx={{marginLeft: `2px!important`}}>
											{auth.currentUser.phoneNumber}
										</Typography>
									</Stack>

									{
										userEmailAndLanguage && <>
											<Stack direction='row'  sx={{marginLeft: `12px!important`}}>
												<Typography variant='body2' textAlign='left'>
													{t('legals.userPersonalDataComponent.siteNotificationsEmail')}:
												</Typography>
												<Typography variant='body1' textAlign='left' sx={{marginLeft: `2px!important`}}>
													{userEmailAndLanguage.email}
												</Typography>
											</Stack>
											<Stack direction='row'  sx={{marginLeft: `12px!important`}}>
												<Typography variant='body2' textAlign='left'>
													{t('legals.userPersonalDataComponent.languagePreference')}:
												</Typography>
												<Typography variant='body1' textAlign='left' sx={{marginLeft: `2px!important`}}>
													{userEmailAndLanguage.languagePreference}
												</Typography>
											</Stack>
										</>
									}
								</Stack>
								<Stack direction='column'>
									<Stack direction='column' sx={{margin: '12px'}}>
										<Typography variant='h2' textAlign='center'>
											{t('legals.userPersonalDataComponent.activityLogsTitle')}
										</Typography>
										<Typography variant='body1'>
											{t('legals.userPersonalDataComponent.activityLogsDescription')}
										</Typography>
									</Stack>
									{
										userLogsStatus &&
										<>
											<Stack direction='row' sx={{marginTop: `18px`, marginLeft: '12px', alignItems: 'center'}}>
												<Typography variant='body2' textAlign='left'>
													{t('legals.userPersonalDataComponent.postLogs')}
												</Typography>
												<Button disabled={userLogsStatus.postLogDataRequest !== true} onClick={(e) =>downloadPostLogs(e)}>
													{t('legals.userPersonalDataComponent.download')}
												</Button>
											</Stack>
											<Stack direction='row' sx={{marginTop: `18px`, marginLeft: '12px', alignItems: 'center'}}>
												<Typography variant='body2' textAlign='left'>
													{t('legals.userPersonalDataComponent.topicTitleLogs')}
												</Typography>
												<Button disabled={userLogsStatus.topicTitleDataRequest !== true} onClick={(e) =>downloadTopicTitleLogs(e)}>
													{t('legals.userPersonalDataComponent.download')}
												</Button>
											</Stack>
											<Stack direction='row' sx={{marginTop: `18px`, marginLeft: '12px', alignItems: 'center'}}>
												<Typography variant='body2' textAlign='left'>
													{t('legals.userPersonalDataComponent.bioLogs')}
												</Typography>
												<Button disabled={userLogsStatus.bioLogDataRequest !== true} onClick={(e) =>downloadBioLogs(e)}>
													{t('legals.userPersonalDataComponent.download')}
												</Button>
											</Stack>
											<Stack direction='row' sx={{marginTop: `18px`, marginLeft: '12px', alignItems: 'center'}}>
												<Typography variant='body2' textAlign='left'>
													{t('legals.userPersonalDataComponent.avatarLogs')}
												</Typography>
												<Button disabled={userLogsStatus.avatarLogDataRequest !== true} onClick={(e) =>downloadAvatarLogs(e)}>
													{t('legals.userPersonalDataComponent.download')}
												</Button>
											</Stack>
											<Stack direction='row' sx={{marginTop: `18px`, marginLeft: '12px', alignItems: 'center'}}>
												<Typography variant='body2' textAlign='left'>
													{t('legals.userPersonalDataComponent.userLogs')}
												</Typography>
												<Button disabled={userLogsStatus.userLogDataRequest !== true} onClick={(e) =>downloadUserUserLogs(e)}>
													{t('legals.userPersonalDataComponent.download')}
												</Button>
											</Stack>
											<Stack direction='row' sx={{marginTop: `18px`, marginLeft: '12px', alignItems: 'center'}}>
												<Typography variant='body2' textAlign='left'>
													{t('legals.userPersonalDataComponent.usernameLogs')}
												</Typography>
												<Button disabled={userLogsStatus.usernameLogDataRequest !== true} onClick={(e) =>downloadUserUsernameLogs(e)}>
													{t('legals.userPersonalDataComponent.download')}
												</Button>
											</Stack>
											<Stack direction='row' sx={{marginTop: `18px`, marginLeft: '12px', alignItems: 'center'}}>
												<Typography variant='body2' textAlign='left'>
													{t('legals.userPersonalDataComponent.avatarsLogsZip')}
												</Typography>
												<Button disabled={userLogsStatus.avatarLogDataZipRequest !== true} onClick={(e) =>downloadAvatarsLogsZip(e)}>
													{t('legals.userPersonalDataComponent.download')}
												</Button>
											</Stack>
										</>
									}
								</Stack>
							</Stack>
						</Paper>
					:
						<Stack direction='column' sx={{marginTop: '16px'}}>
							<Typography variant='body1' textAlign='center'>
								{t('legals.requestUserPersonalData')}
							</Typography>
						</Stack>
					}
				</>
			}
		</>
	)

}