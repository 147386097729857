export const convertBackendResponseKeysToFrontEndFormat = (inputObj) => {
    const newObject = {};
    for (const key in inputObj) {
        if (inputObj.hasOwnProperty(key)) {
            const newKey = key.charAt(0).toLowerCase() + key.slice(1);
            
            if (key === 'Follows') {
                newObject["following"] = inputObj[key];
            } else{
                newObject[newKey] = inputObj[key];
            }
        }
    }
    return inputObj ? newObject : null;
}