import React, {useState, useEffect, useContext, useRef, createRef} from 'react';
import { useParams } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { ShortenNumber } from '../../utils/NumberShortener';
import Tooltip from '@mui/material/Tooltip';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import { DocumentDescriptionContext } from '../../contexts/DocumentDescriptionContext';
import ReplyIcon from '@mui/icons-material/Reply';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { getDiscussionData, updateDiscussionRating, getDiscussionRating, followDiscussion, unfollowDiscussion, getDiscussionPosts, getLastDiscussionPost, updateDiscussionLastReadTimestamp, updateDiscussionCounters, isDiscussionBeingFollowedByCurrentUser } from '../../firebaseFunctions/spannerFunctions/topicFunctions';
import { AVATAR_SMALL_SIZE, NAVBAR_HEIGHT_ABSOLUTE, POST_AD_FREQUENCY, REPLY_BUTTON__STICKY_COLLAPSIBLE_INFO_BAR_HEIGHT, STICKY_COLLAPSIBLE_FIXED_HEIGHT } from '../../utils/Constants';
import { DiscussionDate, FeedDate } from '../../utils/Dates';
import { useNavigate } from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import AvatarPreviewComponent from '../AvatarPreviewComponent';

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { auth } from "../../firebase";
import { useTranslation } from 'react-i18next';
import StickyCollapsibleInfoBarComponent from '../StickyCollapsibleInfoBarComponent';
import ScrollToTopComponent from '../ScrollToTopComponent';
import { DeleteDiscussionComponent, DeleteLastDiscussionPostComponent, ErrorNotFoundComponent, PostAdComponent, PostWithLinksComponent, ReplyDrawerComponent, ReportComponent, ReportedPostContentComponent, ReportedTopicTitleComponent } from '..';
import { addDiscussionToRecent } from '../../firebaseFunctions/spannerFunctions/recentDiscussionsFunctions';
import DiscussionStructuredData from "../../utils/structuredData/DiscussionStructuredData";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { updateUserCounters } from '../../firebaseFunctions/spannerFunctions/profileFunctions';
import { isLastDiscussionPostAReplyToOpening } from '../../firebaseFunctions/spannerFunctions/deleteFunctions';
import SharePostComponent from '../SharePostComponent';
import { getDataForPostLocalLinks } from '../../firebaseFunctions/spannerFunctions/postLocalLinksFunctions';

function DiscussionPostListItem(props) {
	const [postLocalLinksDisplayData, setPostLocalLinksDisplayData] = useState([]);	

	const [isOpeningResponse, setIsOpeningResponse] = useState();
	useEffect(() => {
		
		getDataForPostLocalLinks(props.post.post.post).then((dataForLinks) => {
			setPostLocalLinksDisplayData(dataForLinks)
		})
		if (auth.currentUser) {
			if (props.index === 0 && auth.currentUser.uid === props.post.user.userId) {
				// Is the last post of discussion list
				// Check if this post is the first response to the topic or is not
				isLastDiscussionPostAReplyToOpening(props.topicId, props.discussionId, props.post.post.postId).then((res) => {
					if (res === true) {
						// If it is opening response delete discussion
						setIsOpeningResponse(true)
					} else {
						if (res === false) {
							// If it is not opening response and there are more discussion posts delete last post
							setIsOpeningResponse(false)
						}
					}
				})
			}
		} else {
			// isOpeningReponse will remain undefined so no delete component will be showned
		}
		return () => {
		}
	}, [])
	return(
		<>
			{props.post.post.reportedPost === true ?
					<ReportedPostContentComponent user={props.post.user}/>
				:
					<Card sx={{ flexGrow: 1, padding:0, marginTop: '12px'}} className='CustomCard1'>	
						<CardHeader
							avatar={
								(	
									props.post.user.avatarSmallUrl !== "" ?  
										<Avatar sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE }} alt="avatar" src={props.post.user.avatarSmallUrl}/>
									:
										<Avatar sx={{ width: AVATAR_SMALL_SIZE, height:AVATAR_SMALL_SIZE ,color:"white" }} alt="avatar">
											{props.post.user.username[0].toUpperCase()}
										</Avatar>
								) 
							}
							action={null}
							title={
							
								<Stack direction="row" 
								justifyContent="space-between"
								alignItems="flex-start">
									<Stack direction="column">
										<Typography variant="body1">
											<Link component={RouterLink} to={"/" + props.post.user.username} color="inherit" underline="none">
												{props.post.user.username}
											</Link>	
										</Typography>
										<Typography variant="caption">
											{FeedDate(props.post.post.timestamp)}
										</Typography>
									</Stack>
									<Stack direction="row">
										{auth.currentUser &&
											// type set to openingPost to not show title report in options.
											<ReportComponent userId={props.post.user.userId} topicId={null} type="reply" reportedTitle={null} reportedPost={props.post.post.reportedPost} postId={props.post.post.postId} /> 
										}
										{
											isOpeningResponse === false && auth.currentUser && auth.currentUser.uid === props.post.user.userId ?
											<DeleteLastDiscussionPostComponent userId={props.post.user.userId} postId={props.post.post.postId} topicId={props.topicId} discussionId={props.discussionId}/> 
											: 
												isOpeningResponse === true && auth.currentUser && auth.currentUser.uid === props.post.user.userId ?
													<DeleteDiscussionComponent topicId={props.topicId} discussionId={props.discussionId} />
													: null
										}
									</Stack>

								</Stack>
							}
						/>
						<CardContent>
							<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
								<PostWithLinksComponent showReferencedPosts={true} post={props.post.post.post} localLinksData={postLocalLinksDisplayData}/>
							</Typography>
						</CardContent>
						<Divider  sx={{ flexGrow: 1 }}/>
						
						{auth.currentUser &&
							<CardActions disableSpacing sx={{ padding: 0, paddingLeft: "12px"}}>
								<SharePostComponent topicId={props.topicId} discussionId={props.discussionId} postId={props.post.post.postId} author={props.post.user.userId}/>
							</CardActions>
						}
					</Card>
			}
		</>
	)
}

export default function DiscussionPostsComponent(props) { 
	const { t } = useTranslation();
	const postRefs = useRef([])
	const labels = {
		1: t("topicPosts.labels.useless"),
		2: t("topicPosts.labels.poor"),
		3: t("topicPosts.labels.ok"),
		4: t("topicPosts.labels.good"),
		5: t("topicPosts.labels.excellent")
	};
	const params = useParams();
	const { setDocumentTitle } = useContext(DocumentTitleContext)
	const { setDocumentDescription } = useContext(DocumentDescriptionContext)
	const [postList, setPostList] = useState([])
	const [discussion, setDiscussion] = useState()
	const [topicId, setTopicId] = useState(params.topicId)
	
	const [loading, setLoading] = useState(false)
	const [loadingRating, setLoadingRating] = useState(false)
	const [loadingFollowDiscussion, setLoadingFollowDiscussion] = useState(false)
	const navigate = useNavigate()
	const [discussionId, setDiscussionId] = useState(params.discussionId)
	const [authorOfLastPost, setAuthorOfLastPost] = useState()
	const [enableFollowDiscussion, setEnableFollowDiscussion] = useState(false) // default false in case is opening at mount
	
	const [ratingValue, setRatingValue] = useState(0);
	const [ratingHover, setRatingHover] = useState(-1);
	const [topicRatingMean, setTopicRatingMean] = useState();
	const [topicRatingEnabled, setTopicRatingEnabled] = useState();

	const [discussionDoesNotExist, setDiscussionDoesNotExist] = useState(null);
	const [topicCreatorUser, setTopicCreatorUser] = useState(null);
	const [discussionCreatorUser, setDiscussionCreatorUser] = useState(null);
	const [lastDiscussionPost, setLastDiscussionPost] = useState(null);
	const [discussionFollowingsLimit, setDiscussionFollowingLimit] = useState(false)
	const [oneNewFollowValue, setOneNewFollowValue] = useState(0)
	const [isLastPost, setIsLastPost] = useState(false)
	const [loadingMoreNewerPosts ,setLoadingMoreNewerPosts] = useState(false)
	const [loadingMoreOlderPosts ,setLoadingMoreOlderPosts] = useState(false)
	const [oldestNewerPostListLength ,setOldestNewerPostListLength] = useState(0)
	
	const [ratingDialogOpen, setRatingDialogOpen] = React.useState(false);

	const handleRatingDialogClickOpen = () => {
		setRatingDialogOpen(true);
	};
  
	const handleRatingDialogClose = () => {
	  setRatingDialogOpen(false);
	};
	const onRatingChange = (value) => {
		if (typeof value === "number" && value > 0){
			setRatingValue(value)
			handleRatingDialogClickOpen()
		}
	}
	async function updateRating(event) {
		event.preventDefault()
		setLoading(true)
		if (typeof ratingValue === "number" && ratingValue > 0){
			await updateDiscussionRating(topicId, discussionId, ratingValue);
		}
		if (topicCreatorUser.userId && discussionCreatorUser.userId) {
			// update user counters 
			updateUserCounters(topicCreatorUser.userId)
			updateUserCounters(discussionCreatorUser.userId)
		} 
		handleRatingDialogClose()
		setLoading(false)
	}


	async function handleFollowDiscussion(event){
		event.preventDefault()
		if (auth.currentUser.emailVerified) {

			setLoadingFollowDiscussion(true)
			if (enableFollowDiscussion === "following") {
				const canTopicBeFollowed = await unfollowDiscussion(topicId, discussionId)
				setEnableFollowDiscussion(canTopicBeFollowed)
				setOneNewFollowValue((prevVal) => {
					const newVal = prevVal - 1 
					return newVal
				})
			} 
			if (enableFollowDiscussion === true) {
	
				const canTopicBeFollowed = await followDiscussion(topicId, discussionId)
				const tempEnable = canTopicBeFollowed.success === true ? "following" : false
				setEnableFollowDiscussion(tempEnable)
				setDiscussionFollowingLimit(canTopicBeFollowed.discussionFollowsLimit)
				setOneNewFollowValue((prevVal) => {
					const newVal = prevVal + 1 
					return newVal
				})
			}
			setLoadingFollowDiscussion(false)
		} else {
			navigate("/page/verifyuser")
		}
	}

	const handleNavigateToVerifyEmail = () => {
		navigate("/page/verifyuser")
	}
	
	const [scrollHeight, setScrollHeight] = useState(0)
	const [scrollTop, setScrollTop] = useState(0)


	useEffect(() => {
		
		// runs at component did mount
		// if the type is opening, get topic first post
		setLoading(true)
		setLoadingRating(true)
		getDiscussionData(topicId, discussionId).then((discussionData) => {
			if (discussionData === null) {
				setDocumentTitle(t('documentTitles.discussionDoesNotExist'))
				setDiscussionDoesNotExist(true)
				setTopicRatingEnabled(false)
				setLoading(false)
			} else {
				setDocumentTitle(discussionData.discussion.title)
				setDiscussionDoesNotExist(false)
				setTopicRatingEnabled(true)
				setTopicCreatorUser(discussionData.topicCreatorUser)
				setDiscussionCreatorUser(discussionData.discussionCreatorUser)
				
				updateUserCounters(discussionData.topicCreatorUser.userId)
				updateUserCounters(discussionData.discussionCreatorUser.userId) 
				
				setDiscussion(discussionData.discussion)
				
				setTopicRatingMean((discussionData.discussion.ratingsSum) === 0 ? 0 : (discussionData.discussion.ratingsSum/discussionData.discussion.ratingsCounter).toFixed(2)) // mean, to see the rating of the discussion
				
				setAuthorOfLastPost(discussionData.discussion.authorOfLastPost)
	
				const paramsPostId = params.postId !== undefined ? params.postId : null
				getDiscussionPosts(topicId, discussionId, null, paramsPostId, null).then((res) => {
					if (res.data !== null && res.data !== undefined) {
						// use first post as description of page for search results
						setDocumentDescription(res.data[0].post.post)
					}
					if (auth.currentUser) {

						addDiscussionToRecent(topicId, discussionId)
					}
					setPostList([...res.data])
					setIsLastPost(res.isLastPost)
					getLastDiscussionPost(topicId, discussionId).then((lastDiscussionPostData) => {
						setLastDiscussionPost(lastDiscussionPostData)
						
						if (auth.currentUser) {
							if (discussionData.topicCreatorUser.userId === auth.currentUser.uid || discussionData.discussionCreatorUser.userId === auth.currentUser.uid){
								setEnableFollowDiscussion(false)
							} else {
								isDiscussionBeingFollowedByCurrentUser(topicId, discussionId).then((isDiscussionBeingFollowed) => {
									if (isDiscussionBeingFollowed === true) {
										setEnableFollowDiscussion("following")
										updateDiscussionLastReadTimestamp(topicId, discussionId) // only if user follows discussion
									} else {
										if (isDiscussionBeingFollowed === false) {
											setEnableFollowDiscussion(true)
										} else {
											setEnableFollowDiscussion(false)
										}
									}
								})
							}
						} else {
							setEnableFollowDiscussion(false)
						}
						setLoading(false)
					})
					
				})
			}
				
		})

		if (auth.currentUser) {
			getDiscussionRating(topicId, discussionId).then((ratingValueRes) => {
				setRatingValue(ratingValueRes)
				setLoadingRating(false)
			})
		}
		updateDiscussionCounters(topicId, discussionId)

		const handleScroll = () => {
			setScrollHeight(document.documentElement.scrollHeight - document.documentElement.clientHeight)
			setScrollTop(window.scrollY || document.documentElement.scrollTop)	
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
		  window.removeEventListener('scroll', handleScroll);
		}
	}, [])

	useEffect(() => {
		if (scrollTop === 0){
			if (postList.length > 0 && !loading && !loadingMoreNewerPosts){
				// fetch newer posts
				handleLoadNewPosts()
			}
		} else if (scrollTop === scrollHeight) {
			if (postList.length > 0 && !loading && !loadingMoreOlderPosts && !isLastPost){
				// fetch older posts
				handleLoadOldPosts()
			}
		}
	}, [scrollTop, scrollHeight])

	useEffect(() => {
		if (oldestNewerPostListLength !== 0) {
			postRefs.current.forEach((postRef, index) => {
				if (index === oldestNewerPostListLength) {
					postRef.current.scrollIntoView({behavior: "instant", block: "start"})
					window.scrollBy(0, -(NAVBAR_HEIGHT_ABSOLUTE+STICKY_COLLAPSIBLE_FIXED_HEIGHT))
				}
			})
			setOldestNewerPostListLength(0)
		}
	}, [oldestNewerPostListLength])

	async function handleLoadNewPosts() {
		setLoadingMoreNewerPosts(true)

		getDiscussionPosts(topicId, discussionId, null, null, postList[0].postId).then((res) => {
			if (res !== undefined) {
				const resArrayReversed = res.data.reverse() 
				// update post list
				setPostList((prevList) => {
					const newList = [...resArrayReversed, ...prevList]
					newList.forEach((post, index) => {
						if (!postRefs.current[index]) {
							postRefs.current[index] = createRef()
						}
					})
					return newList
				})
				setOldestNewerPostListLength(resArrayReversed.length)
			}
			setLoadingMoreNewerPosts(false)
		})
	}
	async function handleLoadOldPosts() {
		setLoadingMoreOlderPosts(true)
		getDiscussionPosts(topicId, discussionId, postList[postList.length - 1].postId, null, null).then((res) => {
			if (res !== undefined) {
				setPostList((prevList) => {
					const newList = [...prevList,...res.data]
					return newList
				})
				setIsLastPost(res.isLastPost)
			}
			setLoadingMoreOlderPosts(false)
		})
	}
	
	const [textDialog, setTextDialog] = React.useState(false);
	const handleCloseTextDialog = () => {
        setTextDialog(false);
	};
	const handleOpenTextDialog = () => {
        setTextDialog(true);
	};
	const handleAuthorOfLastPost = (uid) => {
		setAuthorOfLastPost(uid)
	}
	
	return (
		<>
		{loading ?    
		
		<Box sx={{ display: 'flex',
			alignContent: 'center', 
			height: "100vh",
			alignItems:"center",
			justifyContent:"center"}}>
			<CircularProgress />
		</Box> 
		: <>
			<Snackbar open={discussionFollowingsLimit} autoHideDuration={6000} >
				<Alert severity='error' sx={{ width: '100%' }}>{t("topicPosts.discussionFollowingLimit")}</Alert>
			</Snackbar>
			{
				discussionDoesNotExist === true ?
					<ErrorNotFoundComponent type="discussion"/>
				:
					<Grid item xs>
			
					{ discussion && topicCreatorUser && !loading  &&
					<Box>  		
						{
							postList.length !== 0 &&
								<DiscussionStructuredData posts={postList} topicId={params.topicId !== undefined ? params.topicId : null} discussionId={params.discussionId !== undefined ? params.discussionId : null} postId={params.postId !== undefined ? params.postId : null} 
								title={discussion.title} opening={discussion.opening} topicCreatorUsername={topicCreatorUser.username} topicTimestamp={discussion.unixMillisTopicTimestamp} discussionRepliesCounter={discussion.postsCounter}/>
						}	
						{
							auth.currentUser ?
							(topicCreatorUser.userId !== auth.currentUser.uid && discussionCreatorUser.userId !== auth.currentUser.uid && auth.currentUser.emailVerified) ?
								<Dialog
										open={ratingDialogOpen}
										onClose={handleRatingDialogClose}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
									>
										<DialogTitle id="alert-dialog-title">
											{t("topicPosts.changeTopicRatingQuestion")}
										</DialogTitle>
										<DialogContent>
											{topicRatingEnabled || loadingRating ? 
												<ListItem>
													<Grid container direction='column'>
														<Grid item>
															<Typography style={{ width: 'inherit', wordWrap: "break-word" }} display='block' variant='body2'>{t("topicPosts.rateTopic")}</Typography>
														</Grid>
														<Grid item>
															<Box
																sx={{
																	width: 'inherit',
																	display: 'flex',
																	alignItems: 'center',
																}}
																>
																<Rating
																	size="small"
																	name="hover-feedback"
																	value={ratingValue}
																	disabled={loading}
																	precision={1}
																	getLabelText={(value) => `${value} ${t("topicPosts.labels.star")}${value !== 1 ? t("topicPosts.labels.starPlural") : ''}, ${labels[value]}`}
																	onChange={(event, newValue) => {
																		onRatingChange(newValue);
																	}}
																	onChangeActive={(event, newHover) => {
																		setRatingHover(newHover);
																	}}
																	emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
																/>
															</Box>
														</Grid>
														<Grid item >											
															{ratingValue !== 0 && (
																<Typography variant='caption' >{labels[ratingHover !== -1 ? ratingHover : ratingValue]}</Typography>
															)}
														</Grid>
													</Grid>
												</ListItem>
												:
												null
											}
										</DialogContent>
										<DialogActions>
										<Button onClick={handleRatingDialogClose}>{t("disagree")}</Button>
										<Button disabled={ratingValue === 0} onClick={updateRating} autoFocus>
											{t("agree")}
										</Button>
										</DialogActions>
									</Dialog>
							: null: null
						}
						<Grid
							container
							direction="row"
							justifyContent="center"
							>
							<Grid item xs={12}>		 
							<StickyCollapsibleInfoBarComponent 
								replyButton={
									discussion && auth.currentUser  &&	
									(((topicCreatorUser.userId === auth.currentUser.uid || discussionCreatorUser.userId === auth.currentUser.uid) && authorOfLastPost !== auth.currentUser.uid) ? 																		 
										<>
											<Button className='ReplyButtonStickyCollapsibleInfoBar' sx= {{margin: "0px!important", width: "100%", height: REPLY_BUTTON__STICKY_COLLAPSIBLE_INFO_BAR_HEIGHT + "px"}} variant='contained'
												onClick={
													auth.currentUser.emailVerified ?
														handleOpenTextDialog
													:
														handleNavigateToVerifyEmail
												}
												startIcon={<ReplyIcon />}>

													{t("reply")}
											</Button>
											<ReplyDrawerComponent open={textDialog} handleCloseTextDialog={() => handleCloseTextDialog} handleAuthorOfLastPost={() => handleAuthorOfLastPost(auth.currentUser.uid)} 
											postType="reply" topicId={topicId} discussionId={discussionId} topicTitle={discussion.title} user={lastDiscussionPost.user} lastPost={lastDiscussionPost.post} reportedTitle={discussion.reportedTitle} reportedPost={lastDiscussionPost.post.reportedPost}/>

										</>
									:
									null)		
								}
								collapsedComponent={
									<>
									
										<Grid direction="row" container 
											justifyContent="space-between"
											alignItems="center">
											<Grid item xs container direction="column" 
												justifyContent="flex-start"
												alignItems="flex-start">	
												<Grid item>
													<Stack direction='row'>
														{
															discussion.reportedTitle === true ?
															<Typography sx={{
																overflow: "hidden",
																textOverflow: "ellipsis",
																display: "-webkit-box",
																WebkitLineClamp: "1",
																WebkitBoxOrient: "vertical"}} display='block' variant='h3'>
																	<ReportedTopicTitleComponent />
															</Typography>
															:
															<Tooltip title={discussion.title}>	
																<Typography sx={{
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																	display: "-webkit-box",
																	WebkitLineClamp: "1",
																	WebkitBoxOrient: "vertical"}} display='block' variant='h3'>
																		
																	<Link component={RouterLink} to={'/topic/' + topicId} color="inherit" underline="hover">
																		{discussion.title}
																	</Link>
																</Typography>
															</Tooltip>

														}
														<StarIcon fontSize='small' style={{ marginLeft: "8px", marginTop: "0px", opacity: 0.55, color: '#faaf00' }}/>
														<Typography variant='caption' sx={{marginTop: "1px", marginLeft: "2px"}}>{topicRatingMean ? topicRatingMean : "-"}</Typography>
													</Stack>
												</Grid>	
												<Grid item>
													{discussion ? <Typography variant="caption">{DiscussionDate(discussion.unixMillisDiscussionTimestamp)}</Typography> : null}
												</Grid>		
											</Grid>
											<Grid item xs="auto">
												<Stack direction="row" sx={{float: "right"}}>
													{auth.currentUser && 
														<>
															{
																enableFollowDiscussion === "following" || enableFollowDiscussion === true ?
																	<Button variant={enableFollowDiscussion === true ? "outlined": "contained"} 
																		sx={{
																			float:"right",
																			borderRadius: 5,
																			padding: '4px',
																			fontSize: '10.5px',
																			marginRight:'12px'}} 
																			disabled={loadingFollowDiscussion} onClick={handleFollowDiscussion}>{enableFollowDiscussion === true ? t("follow") : enableFollowDiscussion === "following" ? t("unfollow") : null}</Button>
																:
																null
															}
															{							
																(topicCreatorUser.userId !== auth.currentUser.uid && discussionCreatorUser.userId !== auth.currentUser.uid) ?
																	<Tooltip title={<>{t('topicPosts.rateDiscussion')}</>}>	
																		<IconButton aria-label="settings" onClick={
																				auth.currentUser.emailVerified ?
																					handleRatingDialogClickOpen
																				:
																					handleNavigateToVerifyEmail
																			} 
																			sx={{
																				float:"right"
																			}}>
																			<StarIcon fontSize='small'/>
																		</IconButton>
																	</Tooltip>
																	:
																	null
															}
														</>
													}
													{auth.currentUser &&
														discussion.reportedTitle === true ?
															null
														:
															// type set to topicPage to not show option of report post in topic page title.
															<ReportComponent userId={discussion.topicCreatorId} topicId={discussion.topicId} type="discussionPage" reportedTitle={discussion.reportedTitle} reportedPost={null} /> 
													}
													{auth.currentUser && 
														<DeleteDiscussionComponent topicId={topicId} discussionId={discussionId} />
													}
													<ScrollToTopComponent/>
												</Stack>
											</Grid>
										</Grid>
									</>
								}
								fixedComponent={
									<Stack direction="column">	
										<Grid direction="row" container 
											justifyContent="space-between"
											alignItems="center">
											<Grid item xs container direction="column" 
												justifyContent="flex-start"
												alignItems="flex-start">	
												<Grid item>
													<Stack direction='row'>
														{
															discussion.reportedTitle === true ?
																
															<Typography sx={{
																overflow: "hidden",
																textOverflow: "ellipsis",
																display: "-webkit-box",
																WebkitLineClamp: "1",
																WebkitBoxOrient: "vertical"}} display='block' variant='h3'>
																<ReportedTopicTitleComponent />
															</Typography>
															:
															<Tooltip title={discussion.title}>	
																<Typography sx={{
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																	display: "-webkit-box",
																	WebkitLineClamp: "1",
																	WebkitBoxOrient: "vertical"}} display='block' variant='h3'>		
																	<Link component={RouterLink} to={'/topic/' + topicId} color="inherit" underline="hover">
																		{discussion.title}
																	</Link>
																</Typography>
															</Tooltip>
														}
														<StarIcon fontSize='small' style={{ marginLeft: "8px", marginTop: "0px", opacity: 0.55, color: '#faaf00' }}/>
														<Typography variant='caption' sx={{marginTop: "1px", marginLeft: "2px"}}>{topicRatingMean ? topicRatingMean : "-"}</Typography>
													</Stack>
												</Grid>	
												<Grid item>
													{discussion ? <Typography variant="caption">{DiscussionDate(discussion.unixMillisDiscussionTimestamp)}</Typography> : null}
												</Grid>		
											</Grid>
											<Grid item xs="auto">
												<Stack direction="row" sx={{float: "right"}}>
													{
														auth.currentUser &&
														<>
															{
																enableFollowDiscussion === "following" || enableFollowDiscussion === true ?
																	<Button variant={enableFollowDiscussion === true ? "outlined": "contained"} 
																		sx={{
																			float:"right",
																			borderRadius: 5,
																			padding: '4px',
																			fontSize: '10.5px',
																			marginRight:'12px'}} 
																			disabled={loadingFollowDiscussion} onClick={handleFollowDiscussion}>{enableFollowDiscussion === true ? t("follow") : enableFollowDiscussion === "following" ? t("unfollow") : null}</Button>
																:
																null
															}	
															
															{							
																(topicCreatorUser.userId !== auth.currentUser.uid && discussionCreatorUser.userId !== auth.currentUser.uid) ?
																	<Tooltip title={<>{t('topicPosts.rateDiscussion')}</>}>	
																		<IconButton aria-label="settings" onClick={
																				auth.currentUser.emailVerified ?
																					handleRatingDialogClickOpen
																				:
																					handleNavigateToVerifyEmail
																			} 
																			sx={{
																				float:"right"
																			}}>
																			<StarIcon fontSize='small'/>
																		</IconButton>
																	</Tooltip>	
																	:
																	null
															}													
														</>
													}
													{auth.currentUser &&
														discussion.reportedTitle === true ?
															null
														:
														// type set to topicPage to not show option of report post in topic page title.
														<ReportComponent userId={discussion.topicCreatorId} topicId={discussion.topicId} type="discussionPage" reportedTitle={discussion.reportedTitle} reportedPost={null} /> 
													}
													
													{auth.currentUser && 
														<DeleteDiscussionComponent topicId={topicId} discussionId={discussionId} />
													}
													<ScrollToTopComponent/>
												</Stack>
											</Grid>
										</Grid>
										<Grid direction="row" container 
											alignItems="center" sx={{textAlign: 'center', marginTop:'6px', display: "flex", justifyContent: "space-around"}}>
											<Grid item sx={{flex: "1"}}>
												<Typography variant="body1" color="text.secondary">
													{t("topicPosts.ratingQty")}
												</Typography>
												<Typography  variant="body1" color="text.primary">
													{discussion ? ShortenNumber(discussion.ratingsCounter) : null}
												</Typography>
											</Grid>
											<Grid item sx={{flex: "1"}}>
												<Typography variant="body1" color="text.secondary">
													{t("topicPosts.discussionFollowers")}
												</Typography>
												<Typography variant="body1" color="text.primary">
													{discussion ? ShortenNumber(discussion.discussionFollowers) + oneNewFollowValue : null}
												</Typography>
											</Grid>
											<Grid item sx={{flex: "1"}}>
												<Typography variant="body1" color="text.secondary">
													{t("topicPosts.postQty")}
												</Typography>
												<Typography variant="body1" color="text.primary">
													{discussion ? ShortenNumber(discussion.postsCounter) : null}
												</Typography>
											</Grid>
										</Grid>
										<Divider sx={{width: "100%!important", marginTop:"9px"}}/>
									</Stack>
								}
								expandedComponent={
									<>								
										<Stack 
											container
											direction="row"
											justifyContent="center"
											alignItems="center"
											sx={{marginTop: "12px", marginBottom: REPLY_BUTTON__STICKY_COLLAPSIBLE_INFO_BAR_HEIGHT + "px"}}
											>		
											<Grid 
												container
												direction="row"
												justifyContent="flex-end"
												alignItems="center"
												sx={{width: "180px!important"}} 
												>
													<Grid item xs="auto" sx={{float: "right"}}>
														{
															discussionCreatorUser.avatarSmallUrl !== "" ?  														
																<AvatarPreviewComponent avatar={discussionCreatorUser.avatarSmallUrl}
																	avatarBig={discussionCreatorUser.avatarBigUrl}
																	size={AVATAR_SMALL_SIZE + "px"}/>
															:
																<Avatar sx={{ width: AVATAR_SMALL_SIZE, color: "white", height:AVATAR_SMALL_SIZE }} alt="avatar">
																	{discussionCreatorUser.username[0].toUpperCase()}
																</Avatar>
														}
													</Grid>
													<Grid item xs="auto" sx={{maxWidth: 180 - AVATAR_SMALL_SIZE + "px!important"}}>
														<Typography noWrap style={{ width:"inherit!important", marginLeft: "12px", textOverflow: "ellipsis", textAlign: "start" }} display='block' variant='body1'>
															<Link component={RouterLink} to={"/" + discussionCreatorUser.username} color="inherit" underline="none">
																{discussionCreatorUser.username}
															</Link>	
														</Typography>
													</Grid>
											</Grid>	
											<Grid item xs="auto" sx={{alignItems: "flex-end!important"}}>
												<KeyboardArrowRightIcon sx={{ marginLeft: "14px", marginRight: "14px"}} fontSize="medium" />
											</Grid>
											<Grid 
												container
												direction="row"
												justifyContent="flex-start"
												alignItems="flex-start"
												sx={{width: "180px!important"}}
												>
													<Grid item xs="auto" sx={{marginTop: "3px"}}>
														{topicCreatorUser.avatarSmallUrl !== "" ?  														
																<AvatarPreviewComponent avatar={topicCreatorUser.avatarSmallUrl}
																	avatarBig={topicCreatorUser.avatarBigUrl}
																	size={AVATAR_SMALL_SIZE + "px"}/>
															:
																<Avatar sx={{ width: AVATAR_SMALL_SIZE, color: "white", height:AVATAR_SMALL_SIZE }} alt="avatar">
																	{topicCreatorUser.username[0].toUpperCase()}
																</Avatar>
														}
													</Grid>
													<Grid item xs="auto"
														sx={{ marginLeft: "6px", marginTop: "6px"}}
														container
														direction="column"
														justifyContent="flex-start"
														alignItems="flex-start">
														<Grid item sx={{width: "inherit!important"}}>
															<Typography noWrap style={{ width:"inherit!important", marginTop: '4px', textOverflow: "ellipsis", textAlign: "center" }} display='block' variant='body1'>
																<Link component={RouterLink} to={"/" + topicCreatorUser.username} color="inherit" underline="none">
																	{topicCreatorUser.username}
																</Link>	
															</Typography>
														</Grid>
														<Grid item>										
															<Typography variant="caption">{" " + t("topicPosts.creator")}</Typography>
														</Grid>
													</Grid>
											</Grid>			
										</Stack>
									</>
								}
							/>
							</Grid>
							<Grid item xs={12} >
								<>						
									{
										loadingMoreNewerPosts === true ?  
											<Box sx={{ display: 'flex',
												alignContent: 'center', 
												height: "auto",
												margin:"12px",
												alignItems:"center",
												justifyContent:"center"}}>
												<CircularProgress size='26px'/>
											</Box> 
										:
										null
									}
									{(postList.length > 0 && topicCreatorUser && discussionCreatorUser) ? postList
										.map((post, index) => (
											(index + 1) % POST_AD_FREQUENCY === 0 ?
												<div key={post.postId} ref={postRefs.current[index]}>
													<PostAdComponent />
													<DiscussionPostListItem topicId={topicId} discussionId={discussionId} index={index} post={post} discussionCreatorUser={discussionCreatorUser} topicCreatorUser={topicCreatorUser}/>
												</div>	
											:
												<div key={post.postId} ref={postRefs.current[index]}>
													<DiscussionPostListItem topicId={topicId} discussionId={discussionId} index={index} post={post} discussionCreatorUser={discussionCreatorUser} topicCreatorUser={topicCreatorUser}/>
												</div>
										)) : 
											<Box sx={{ display: 'flex',
												alignContent: 'center', 
												alignItems:"center",
												justifyContent:"center", marginTop: "24px"}}>
													<Typography variant='caption'>{t('genericErrorMessage')}</Typography>
											</Box> 
									}	
									{
										loadingMoreOlderPosts === true ?  
											<Box sx={{ display: 'flex',
												alignContent: 'center', 
												height: "auto",
												margin:"12px",
												alignItems:"center",
												justifyContent:"center"}}>
												<CircularProgress size='26px'/>
											</Box> 
										:
										null
									}
									<div style={{padding: "6px"}}/>		
								</>
						</Grid>
					</Grid>
				</Box>	
				}
				</Grid>
			} </>
			}
		</>
	)
}

