import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, 
	CustomProvider,
	ReCaptchaEnterpriseProvider  } from "firebase/app-check";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCH6M8npfaI2eCb2-ekG96x9wtD1jFbk00",
	authDomain: "intergraphy-production-474e1.firebaseapp.com",
	projectId: "intergraphy-production-474e1",
	storageBucket: "intergraphy-production-474e1.firebasestorage.app",
	messagingSenderId: "319028127934",
	appId: "1:319028127934:web:65637849afc5faf151d6ef",
	measurementId: "G-FX5Y8LV162"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// // Custom appcheck provider: change index.html, run function to validate recaptcha, uncomment this also.
// /// Custom App Check Provider
// const recaptchaSiteKey = '6LfwCYsqAAAAADcLsC23AHpFMHl2UAlcIo6dNXOO'
// const appId = "1:319028127934:web:65637849afc5faf151d6ef"

// const fetchRecaptchaToken = async (siteKey) => {
// 	const checkGrecaptcha = (resolve, reject) => {
// 	  if (window.grecaptcha && window.grecaptcha.enterprise) {
// 		window.grecaptcha.enterprise.ready(() => {
// 		  window.grecaptcha.enterprise.execute(siteKey, { action: 'fire_app_check' }).then((token) => {
// 			resolve(token);
// 		  }).catch((error) => {
// 			reject(new Error("recaptcha is not defined"));
// 		  });
// 		});
// 	  } else {
// 		// Retry after 100ms
// 		setTimeout(() => checkGrecaptcha(resolve, reject), 100);
// 	  }
// 	};
  
// 	return new Promise((resolve, reject) => {
// 	  checkGrecaptcha(resolve, reject);
// 	});
// };
  
// // Function to validate reCAPTCHA token on the server
// const validateRecaptchaToken = async (recaptchaToken) => {
// 	const response = await fetch('https://us-central1-intergraphy-production-474e1.cloudfunctions.net/validateappcheckrecaptcha', {
// 		method: 'POST',
// 		headers: {
// 		'Content-Type': 'application/json',
// 		},
// 		body: JSON.stringify({ token: recaptchaToken })
// 	});

// 	const data = await response.json();
// 	if (data.success) {
// 		return data.appCheckToken;
// 	} else {
// 		throw new Error('Invalid reCAPTCHA token');
// 	}
// };

// // Initialize App Check with the Custom Provider
// const initializeAppCheckWithReCaptcha = async (siteKey) => {
//   try {
//     const recaptchaToken = await fetchRecaptchaToken(siteKey);
//     const validatedToken = await validateRecaptchaToken(recaptchaToken);

//     initializeAppCheck(app, {
//       provider: new CustomProvider({
//         getToken: () => Promise.resolve({
//           token: validatedToken,
//           expireTimeMillis: Date.now() + 60 * 60 * 1000 // 1 hour expiration
//         })
//       }),
// 	  appId: appId, 
//       isTokenAutoRefreshEnabled: true
//     });
//   } catch (error) {
//   }
// };

// await initializeAppCheckWithReCaptcha(recaptchaSiteKey);
// /// Custom App Check Provider
export const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaEnterpriseProvider('6LfwCYsqAAAAADcLsC23AHpFMHl2UAlcIo6dNXOO'),
	isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

export const auth = getAuth(app)
auth.useDeviceLanguage() // set device language for firebase emails
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);