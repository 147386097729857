import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

export async function getDataForPostLocalLinks(post) {	
	const getdataforpostlocallinksfromcloudspanner = httpsCallable(functions, 'getdataforpostlocallinksfromcloudspanner');
	return await getdataforpostlocallinksfromcloudspanner({ post: post })
	.then((result) => {
		const data = result.data;
		if (data === null || data.length === 0){
			return []
		}else {
			return data
		}
	})
	.catch((error) => {
	});
}


export async function getPostMentionedUsersProfiles(post) {	
	const getpostmentionedusersprofilesfromcloudspanner = httpsCallable(functions, 'getpostmentionedusersprofilesfromcloudspanner');
	return await getpostmentionedusersprofilesfromcloudspanner({ post: post })
	.then((result) => {
		const data = result.data;
		if (data === null || data.length === 0){
			return []
		}else {
			return data
		}
	})
	.catch((error) => {
	});
}

export async function getCurrentUserMentionedPosts(lastPostId, lastPostUserId) {	
	const getcurrentusermentionedpostsfromcloudspanner = httpsCallable(functions, 'getcurrentusermentionedpostsfromcloudspanner');
	return await getcurrentusermentionedpostsfromcloudspanner({ lastPostId: lastPostId, lastPostUserId: lastPostUserId })
	.then((result) => {
		const data = result.data;
		if (data === null || data.length === 0){
			return null
		}else {
			const responseArr = data.data.map((mention) => {
				return {
					data:{
						post: mention.Post,
						postId: mention.PostId,
						reportedPost: mention.ReportedPost,
						reportedTitle: mention.ReportedTitle,
						title:mention.Title,
						topicId: mention.TopicId,
						discussionId: mention.DiscussionId,
						type: mention.Type,
						unixMillisPostTimestamp: mention.UnixMillisPostTimestamp,
						user: {
							userId: mention.UserId,
							username: mention.Username,
							avatarBigUrl: mention.AvatarBigUrl,
							avatarMediumUrl: mention.AvatarMediumUrl,
							avatarSmallUrl: mention.AvatarSmallUrl, 
							bio: mention.Bio,
							followers: mention.Followers,
							follows: mention.Follows,
						}
					}	
				}
			})
			return {data: responseArr, isLastMention: data.isLastMention}
		}
	})
	.catch((error) => {
	});
}
