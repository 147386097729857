import React, {useState, useEffect} from 'react';
import './SideAdComponent.css'
import Paper from '@mui/material/Paper';


export default function SideAdComponent(props) {
	
	useEffect(() => {  
		// Anything in here is fired on component mount.	

    }, [])
	return (
		null
		// <Paper sx={{width:"inherit!important", height: "250px!important", backgroundColor:"white"}}>
        //     Ad space
		// </Paper>
	)

}