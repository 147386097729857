import React, {useState, useEffect, useContext} from 'react';
import './VerifyUserComponent.css'
import Button from '@mui/material/Button';
import { useAuth } from '../../contexts/AuthContext'
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


export default function VerifyUserComponent() {
	const { t } = useTranslation();
	const { verifyUser} = useAuth()
	const [error, setError] = useState("")
	const [info, setInfo] = useState("")
	const [loading, setLoading] = useState(false)
	const { setDocumentTitle } = useContext(DocumentTitleContext)
	
    useEffect(() => {  
		// Anything in here is fired on component mount.	
		setDocumentTitle(t('documentTitles.verifyUser'))
		return () => {
		}
	}, [])
	
		
		
	async function handleSubmit(event) {
		event.preventDefault()
		try {
			setError("")
			setInfo("")
			setLoading(true)
			await verifyUser()
			setInfo(t("verifyUser.verificationEmailSent"))
		} catch {
			setError(t("verifyUser.verificationEmailFailed"))
		}
  
	  setLoading(false)
	}
	return (
		<>			

			{error && <Alert severity='error'>{error}</Alert>}
			{info && <Alert severity='success'>{info}</Alert>}

			<Grid container direction="column" 
				justifyContent="center"
				alignItems="center">
				<Grid item sx={{mt: "24px!important", minWidth: "250px", width: "50%"}}>
					<Typography variant="body1"> 
						{t("verifyUser.verificationNeeded")}
					</Typography>
				</Grid>
				<Grid item container direction="column"
					justifyContent="center"
					alignItems="center">
					<Button
						type="submit"
						onClick={handleSubmit}
						variant="contained"
						sx={{ mt: "24px!important", mb: "8px", minWidth: "250px", width: "50%", borderRadius: "44px" }}
						disabled={loading}
						>
						{t("verifyUser.sendVerificationEmail")}
					</Button>
				</Grid>
			</Grid>
		</>
	)	
}