import React, {useState} from 'react';
import './UserDetailedBioDialogComponent.css'
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { AvatarPreviewComponent, FollowListModalComponent, LoginToUseDialogComponent, PostWithLinksComponent} from '..'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import { AVATAR_MEDIUM_SIZE } from '../../utils/Constants';
import Skeleton from '@mui/material/Skeleton';
import { auth } from '../../firebase';
import { ShortenNumber } from '../../utils/NumberShortener';


export default function UserDetailedBioDialogComponent(props) {
	
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);

	};
	const handleClose = () => setOpen(false);

	return (
		<>
			<Tooltip sx={{ float:"right", '&:hover': {backgroundColor: 'transparent!important'}}} title={<>{t('userDetailedBioDialogComponent.tooltip')}</>} >
				<IconButton size="small" style={{margin:'4px', padding: 0, '&:hover': {backgroundColor: 'transparent!important'}}}  onClick={handleClickOpen}>
					<OpenInNewIcon fontSize="small" />
				</IconButton>
			</Tooltip>
			
			<Dialog
				open={open}
				onClose={handleClose}
				sx={{padding: "12px"}}
			>
				<DialogTitle sx={{ m: 0, p: 2 }}>
					<Stack direction='row' sx={{ display: 'flex', alignItems:'center'}}>
						{props.user ? 			
							<AvatarPreviewComponent avatar={props.user.avatarMediumUrl}
								avatarBig={props.user.avatarBigUrl}
								user={props.user}
								size={AVATAR_MEDIUM_SIZE + "px"}/>
						:
							<Skeleton animation="wave" variant="circular" width={AVATAR_MEDIUM_SIZE } height={AVATAR_MEDIUM_SIZE } />
						}
						<Stack direction='column'>
							<Typography	variant='h2' sx={{paddingLeft:'6px', paddingBottom: "12px"}}>
								{props.user.username}
							</Typography>
							<Stack direction='row'>
								{auth.currentUser ?
									<FollowListModalComponent type='following' quantity={ShortenNumber(props.user.following)} userData={props.user} userId={props.user.userId}/>
								:
									<LoginToUseDialogComponent type='following' quantity={ShortenNumber(props.user.following)} userData={props.user} userId={props.user.userId}/>	
								}
								{auth.currentUser ?
									<FollowListModalComponent type='followers' quantity={ShortenNumber(props.user.followers + props.oneNewFollowValue)} userData={props.user} userId={props.user.userId}/>
								:
									<LoginToUseDialogComponent  type='followers' quantity={ShortenNumber(props.user.followers + props.oneNewFollowValue)} userData={props.user} userId={props.user.userId}/>
								}
							</Stack>
						</Stack>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Typography variant="body1" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
						<PostWithLinksComponent showReferencedPosts={false} post={props.user.bio} localLinksData={[]} type='bio'/>
					</Typography>
				</DialogContent>
			</Dialog>
		</>
	)

}