import React, { createContext, useState, useEffect } from 'react'
import { hasUserAcceptedLatestSitePolicies } from '../firebaseFunctions/spannerFunctions/policiesFunctions';
import { auth } from '../firebase';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const PoliciesAcceptedContext = createContext()

export const PoliciesAcceptedProvider = ({ children }) => {
    const [acceptedPolicies, setAcceptedPolicies] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
		setLoading(true)
		if (auth.currentUser) {
			hasUserAcceptedLatestSitePolicies().then((value) => {
				setAcceptedPolicies(value)
				setLoading(false)
			})
		} else {
			setAcceptedPolicies(true) // user is anonymous will show dialog with link to policies
			setLoading(false)
		}
    }, [])

    return (
        <PoliciesAcceptedContext.Provider value={{ 
			acceptedPolicies
		}}>
            {!loading ? children
			:
			<Box sx={{ display: 'flex',
				alignContent: 'center', 
				height: "100vh",
				alignItems:"center",
				justifyContent:"center"}}>
				<CircularProgress />
			</Box> }
        </PoliciesAcceptedContext.Provider>
    )
}