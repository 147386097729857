import { useContext, useEffect } from 'react';
import './PrivacyPolicyPage.css'
import { useTranslation } from 'react-i18next';
import { DocumentTitleContext } from '../../contexts/DocumentTitleContext';
import { NavBarClean } from '../../components';
import { NAVBAR_HEIGHT } from '../../utils/Constants';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';



export default function PrivacyPolicyPage() {
	const { t } = useTranslation();
	const { setDocumentTitle } = useContext(DocumentTitleContext)
	
    useEffect(() => {  
		// Anything in here is fired on component mount.	
  
		setDocumentTitle(t('legals.documentTitles.privacyPolicy'))
		return () => {
		}
	
		}, [])
	return (
		<Grid 
			container
			direction="column"
			justifyContent="center"
			alignItems="center" spacing={3}>
			<Grid container item xs={12}>
				<NavBarClean/>
			</Grid>
			<Grid container 
				justifyContent="center"
				  alignItems="center"
				sx={{marginTop: NAVBAR_HEIGHT}}>
				<Grid container item direction="column"
					justifyContent="center"
					alignItems="center" xs={6} sx={{ marginTop: '12px'}}>
					<Grid item>
						<Typography variant='h2'>
							{t('legals.privacyPolicy')}
						</Typography>
					</Grid>
					<Grid item>
						{t("legals.privacyPolicyContent", { returnObjects: true })
							.map((item) => {return (
								<Grid
									container
									direction="column"
									sx={{
										justifyContent: "center",
										alignItems: "center",
										marginTop: '16px'
									}}
									>
									<Grid item>
										<Typography variant='h3'>
											{item.title}
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant='body1'>
											{item.content}
										</Typography>
									</Grid>
								</Grid>
							)
						})}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

